import * as _ from "lodash"
import * as JSMapping from "../helpers/jsMapValue"
import { Cashier } from "./Cashier"
import { DimensionValue } from "./Product"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { countedProductName } from "../helpers/productName"

export class StockCountSession implements JSMapping.JSObjectConvertible {
    cashierId: string
    cashierName: string
    id: string

    constructor(json: any) {
        this.cashierId = String(json.cashier_id)
        this.cashierName = String(json.cashier_name)
        this.id = String(json.id)
    }

    json(): any {
        return {
            cashier_id: this.cashierId,
            cashier_name: this.cashierName,
            id: this.id
        }
    }
}

export class StockCountDevice implements JSMapping.JSObjectConvertible {
    active: boolean
    id: string
    name?: string
    sessions: StockCountSession[]

    constructor(json: any) {
        this.active = json.active
        this.id = json.device_id
        this.name = json.device_name
        this.sessions = json.sessions.map((sessionJson: any) => {
            return new StockCountSession(sessionJson)
        })
    }

    json(): any {
        const sessionsJson = this.sessions.map((session: StockCountSession) => {
            return session.json()
        })
        const result: any = {
            active: this.active,
            device_id: this.id,
            sessions: sessionsJson
        }
        if (!_.isNil(this.name)) {
            result.device_name = this.name
        }
        return result
    }
}

export class StockCountProductDimension implements JSMapping.JSObjectConvertible {
    id: string
    name?: L10nString
    value: DimensionValue

    constructor(json: any) {
        this.id = String(json.id)
        this.name = json.name ? new L10nString(json.name) : undefined
        this.value = new DimensionValue(json.value)
    }

    json(): any {
        return {
            id: this.id,
            name: this.name ? this.name.json() : undefined,
            value: this.value.json()
        }
    }
}

export class StockCountProductInfo implements JSMapping.JSObjectConvertible {
    barcode?: string
    dimensions?: StockCountProductDimension[]
    productId: string
    productName?: L10nString
    variantId?: string
    variantName?: L10nString
    deleted?: boolean

    constructor(json: any, productId: string, variantId: string | undefined) {
        this.barcode = json.barcode ? String(json.barcode) : undefined

        this.productId = productId
        // If the json is an actual product, we use the 'name' property
        // But this is also used with stock count events which contain the name in the 'product_name'
        this.productName = json.name ? new L10nString(json.name) : (json.product_name ? new L10nString(json.product_name) : undefined)
        if (!_.isNil(variantId)) {
            this.variantId = variantId
        }

        // If we have a variants list, then we have a variant product. If the variantId here is 'undefined'
        // then it means that we have registered stock on a non-variant product and later on it became a
        // variant product. This will be caught by the fact that the specific variant can't be looked up
        // which then marks the stock entry as deleted.
        if (!_.isNil(json.variants)) {
            const variants: any[] = json.variants ?? []
            const variant = variants.find((variant: any) => { return variant.id === variantId })

            if (!_.isNil(variant)) {
                this.variantName = variant.name ? new L10nString(variant.name) : undefined

                const dimensionValues: _.Dictionary<string> = variant.dimension_values
                if (!_.isNil(dimensionValues)) {
                    const dimensionsAndValues: any[] = []
                    for (const key in dimensionValues) {
                        const valueKey = dimensionValues[key]
                        const dimension = (json.dimensions ?? []).find((dim: any) => { return dim.id === key })
                        if (_.isNil(dimension)) { continue }
                        const value = (dimension.values ?? []).find((val: any) => { return val.id === valueKey })
                        if (_.isNil(value)) { continue }
                        const dimensionAndValue = { id: key, value: value, name: dimension.name }
                        dimensionsAndValues.push(dimensionAndValue)
                    }
                    this.dimensions = dimensionsAndValues.map((item: any) => { return new StockCountProductDimension(item) })
                }

            } else {
                this.deleted = true
            }
        } else if (!_.isNil(json.dimensions)) {
            // If we have dimensions but no variants, then we must have a stock count event which contains
            // dimensions with values.
            // It _could_ also just be a misconfigured product, with dimensions but no variants. Those will be ignored.

            const jsonDimensions = json.dimensions
            const dimensionsWithValue: any[] = []
            for (const dimension of jsonDimensions) {
                // This branch parses dimensions from stock count events which includes values
                if (!_.isNil(dimension.value)) {
                    dimensionsWithValue.push(dimension)
                } else {
                    // For this branch, we have a misconfigured product with dimensions, but without any variants
                    // so we ignore the dimension values as we can't point out any specific value.
                }
            }
            this.dimensions = dimensionsWithValue.map((item: any) => { return new StockCountProductDimension(item) })
        }

        if (json.deleted === true || json.archived === true) {
            this.deleted = true
        }
    }

    json(): any {
        let dimensions: any = undefined
        if (this.dimensions) {
            dimensions = this.dimensions.map((item: StockCountProductDimension) => {
                return item.json()
            })
        }
        return {
            barcode: this.barcode,
            dimensions: dimensions,
            product_id: this.productId,
            product_name: this.productName ? this.productName.json() : undefined,
            variant_id: this.variantId,
            variant_name: this.variantName ? this.variantName.json() : undefined,
            deleted: this.deleted
        }
    }
}

export class StockCountLineItem implements JSMapping.JSObjectConvertible {
    counted?: number
    diff?: number
    expected?: number
    hasCount: boolean
    id: string
    disconnect?: boolean
    product: StockCountProductInfo

    constructor(json: any) {
        this.counted = json.counted
        this.diff = json.diff
        this.expected = json.expected
        this.hasCount = json.has_count
        this.id = String(json.id)
        const comps = this.id.split("*")
        const productId = comps[0]
        let variantId: string | undefined = undefined
        if (comps.length > 1) {
            variantId = comps[1]
        }
        this.product = new StockCountProductInfo({}, productId, variantId)
        if (json.disconnect === true) {
            this.disconnect = true
        }
    }

    json(): any {
        const result: any = {
            counted: this.counted,
            diff: this.diff,
            expected: this.expected,
            has_count: this.hasCount,
            id: this.id,
            product: this.product.json()
        }
        if (this.disconnect === true) {
            result.disconnect = true
        }
        return result
    }
}

export enum StockCountEventType {
    Adjustment = "stock_count_adjustment",
    Disconnect = "stock_count_disconnect"
}

export class StockCountEventSource implements JSMapping.JSObjectConvertible {
    cashierId?: string
    cashierName?: string
    deviceId: string
    sessionId: string
    stockCountId: string
    userName?: string
    userUID?: string

    constructor(json: any) {
        this.cashierId = json.cashier_id ? String(json.cashier_id) : undefined
        this.cashierName = json.cashier_name ? String(json.cashier_name) : undefined
        this.deviceId = String(json.device_id)
        this.sessionId = String(json.session_id)
        this.stockCountId = String(json.stock_count_id)
        this.userName = json.user_name ? String(json.user_name) : undefined
        this.userUID = json.user_uid ? String(json.user_uid) : undefined
    }

    json(): any {
        return {
            cashier_id: this.cashierId,
            cashier_name: this.cashierName,
            device_id: this.deviceId,
            session_id: this.sessionId,
            stock_count_id: this.stockCountId,
            user_name: this.userName,
            user_uid: this.userUID
        }
    }
}

export class StockCountEvent implements JSMapping.JSObjectConvertible {
    adjustment: number
    product: StockCountProductInfo
    source: StockCountEventSource
    timestamp: number
    type: StockCountEventType

    constructor(json: any) {
        this.adjustment = json.adjustment
        this.product = new StockCountProductInfo(json.product, json.product.product_id, json.product.variant_id)
        this.source = new StockCountEventSource(json.source)
        this.timestamp = json.timestamp
        this.type = json.type
    }

    json(): any {
        return {
            adjustment: this.adjustment,
            product: this.product.json(),
            source: this.source.json(),
            timestamp: this.timestamp,
            type: this.type
        }
    }

    userName(cashiers: _.Dictionary<Cashier>): string {
        let result = "Unknown"
        if (!_.isNil(this.source.cashierName)) {
            result = this.source.cashierName
        } else if (!_.isNil(this.source.cashierId) && !_.isNil(cashiers[this.source.cashierId])) {
            const cashier = cashiers[this.source.cashierId]
            result = !_.isNil(cashier.fullName) ? cashier.fullName : cashier.displayName
        } else if (!_.isNil(this.source.userName)) {
            result = "Back Office (" + this.source.userName + ")"
        }
        return result
    }
}

export class StockCountIndex implements JSMapping.JSObjectConvertible {
    closedAt?: number
    closedByEmail?: string
    closedByUID?: string
    id: string
    name: string
    openedAt: number
    openedByEmail: string
    openedByUID: string
    cancelled?: boolean
    requestId?: string
    requestDueDate?: Date

    constructor(json: any) {
        this.closedAt = json.closed_at
        this.closedByEmail = json.closed_by_email
        this.closedByUID = String(json.closed_by_uid)
        this.id = String(json.id)
        this.name = String(json.name)
        this.openedAt = json.opened_at
        this.openedByEmail = json.opened_by_email
        this.openedByUID = String(json.opened_by_uid)
        this.cancelled = json.cancelled
        if (!_.isNil(json.request_id)) {
            this.requestId = json.request_id
        }
        if (!_.isNil(json.request_due_date)) {
            this.requestDueDate = new Date(json.request_due_date)
        }
    }

    json(): any {
        const result: any = {
            closed_at: this.closedAt,
            closed_by_email: this.closedByEmail,
            closed_by_uid: this.closedByUID,
            id: this.id,
            name: this.name,
            opened_at: this.openedAt,
            opened_by_email: this.openedByEmail,
            opened_by_uid: this.openedByUID,
            cancelled: this.cancelled
        }
        if (!_.isNil(this.requestId)) {
            result.request_id = this.requestId
        }
        if (!_.isNil(this.requestDueDate)) {
            result.request_due_date = this.requestDueDate.toISOString()
        }
    }
}

export enum StockCountLineState {
    initializing,
    loaded,
    deleted,
    disconnect
}

export class StockCountLine {
    count?: number
    diff?: number
    expected?: number
    isMutable: boolean
    productId: string
    variantId?: string
    product: StockCountProductInfo
    state: StockCountLineState

    public get name() {
        return countedProductName(this.product, this.state == StockCountLineState.disconnect, LanguageCode.da, true)
    }

    constructor(product: StockCountProductInfo, count: number | undefined, diff: number | undefined, expected: number | undefined, isMutable: boolean, productId: string, variantId: string | undefined, state: StockCountLineState) {
        this.product = product
        this.count = count
        this.diff = diff
        this.expected = expected
        this.isMutable = isMutable
        this.productId = productId
        this.variantId = variantId
        this.state = state
    }
}