export const defaultPDFTemplate = {
    "font_map": {},
    "lines": [
        {
            "type": "text",
            "text": "Tillykke med dit gavekort til"
        },
        {
            "type": "image",
            "image": "logo.png",
            "image_width": 300
        },
        {
            "type": "text",
            "text": "Til en værdi af"
        },
        {
            "type": "text",
            "font": "Times-Bold",
            "font_size": 48,
            "text": "{{amount}} {{currency}}",
            "text_color": "black"
        },
        {
            "type": "text",
            "text": "Vi håber at du finder noget godt blandt vores mange produkter."
        },
        {
            "type": "text",
            "text": "Når du ønsker at benytte gavekortet skal du bruge følgende kode:"
        },
        {
            "type": "barcode",
            "text": "{{id}}"
        },
        {
            "type": "text",
            "font": "Times-Bold",
            "font_size": 24,
            "text": "{{id}}",
            "text_color": "black"
        },
        {
            "type": "text",
            "text": "Gavekortet gælder til og med:"
        },
        {
            "type": "text",
            "font": "Times-Bold",
            "font_size": 24,
            "text": "{{formattedDate expiry}}",
            "text_color": "black"
        },
        {
            "type": "text",
            "text": "God fornøjelse"
        }
    ],
    "alignment": "center",
    "font": "Helvetica",
    "font_size": 16,
    "page_size": "A4",
    "text_color": "gray"
};
