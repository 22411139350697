import * as _ from "lodash"
import * as React from "react"

export function paymentTypeNameFromId(id: any, includeTitleDescriminator: boolean): string {
    let actualId
    if (typeof id === "object" && typeof id.id === "string") {
        actualId = id.id
    } else if (typeof id === "string") {
        actualId = id
    } else {
        return ""
    }
    let title = ""
    if (actualId.includes("cash.rounding")) {
        title = "Rounding"
    } else if (actualId.includes("cash.cashback")) {
        title = "Cashback"
    } else if (actualId.includes("cash")) {
        title = "Cash"
    } else if (actualId.includes("mobilepay")) {
        title = "MobilePay"
    } else if (actualId.includes("giftcard")) {
        title = "Giftcard"
    } else if (actualId.includes("invoice")) {
        title = "Invoice"
    } else if (actualId.includes("voucher")) {
        title = "Voucher"
    } else if (actualId.includes("customer_account")) {
        title = "Customer account"
    } else if (actualId.includes("transfer")) {
        title = "Transferred"
    } else if (actualId.includes("card")) {
        title = "Card"
    } else if (actualId.includes("externally_paid")) {
        title = "Externally billed"
    } else {
        return "Unknown" 
    }

    if (includeTitleDescriminator) {
        let subtype = (actualId as string).split(".")[1]
        if (!_.isNil(subtype)) {
            let titleDiscriminator = ""
            if (subtype == "external") {
                titleDiscriminator = "External"
            } else if (subtype == "izettle") {
                titleDiscriminator = "Zettle"
            } else if (subtype == "payex") {
                titleDiscriminator = "PayEx"
            } else if (subtype == "integration") {
                titleDiscriminator = "Integrated"
            } else if (subtype == "erp") {
                titleDiscriminator = "ERP"
            } else if (subtype == "verifone") {
                titleDiscriminator = "Verifone"
            } else if (subtype == "netsbaxi") {
                titleDiscriminator = "Nets"
            } else {
                titleDiscriminator = subtype
            }
            title += ` (${titleDiscriminator})`
        }
    }

    return title
}

function giftcardMetadata(payment: any) {
    let result: string = ""
    if (payment.metadata?.status === "used") {
        result = " used"
    } else {
        result = " created"
    }
    if (payment.metadata?.code) {
        return <span> {result + " - code: "} <a href={`/sales?giftcard_code=${payment.metadata?.code}`}>{payment.metadata?.code}</a> </span>
    }
    return null
}

function cardMetadata(payment: any) {
    const type = cardType(payment)
    if (!_.isNil(type)) {
        return ", " + type
    }
    return null
}

function cardType(payment: any): string | undefined {
    switch (payment.metadata?.card_type ?? "-") {
        case "visa":
            return "VISA"
        case "visadankort":
            return "VISA / Dankort"
        case "mastercard":
            return "Mastercard"
        case "dankort":
            return "Dankort"
        case "-":
            return undefined
        default:
            return payment.metadata?.card_type
    }
}

export function paymentTypeMetadata(payment: any) {
    switch (payment.payment_type) {
        case "giftcard.integration": {
            return giftcardMetadata(payment)
        }
        case "voucher.integration": {
            return giftcardMetadata(payment)
        }
        case "card.verifone": {
            return cardMetadata(payment)
        }
        case "card.payex": {
            return cardMetadata(payment)
        }
        case "card.izettle": {
            return cardMetadata(payment)
        }
        case "card.netsbaxi": {
            return cardMetadata(payment)
        }
        default:
            break
    }
    return null
}
