
enum BarcodeType {
    qrcode = "qrcode",
    code128 = "code128"
}
export const barcodeTypes = [BarcodeType.qrcode, BarcodeType.code128];
export function barcodeTypePrettyName(type: BarcodeType): string {
    switch (type) {
        case BarcodeType.code128: return "Code 128";
        case BarcodeType.qrcode: return "QR Code";
    }
}

export function getBarcodeTypeFromString(value: string): BarcodeType | undefined {
    switch (value) {
        case "code128": return BarcodeType.code128;
        case "qrcode": case "qr": return BarcodeType.qrcode;
        default: return undefined
    }
}

export const builtInFonts = [
    'Helvetica',
    'Helvetica-Bold',
    'Helvetica-Oblique',
    'Helvetica-BoldOblique',
    'Courier',
    'Courier-Bold',
    'Courier-Oblique',
    'Courier-BoldOblique',
    'Symbol',
    'Times-Roman',
    'Times-Bold',
    'Times-Italic',
    'Times-BoldItalic',
    'ZapfDingbats'
];
export const fontWithNoFontOptions: string[] = ["No font selected", ...builtInFonts];
