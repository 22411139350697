import * as React from "react"
import {
    Col,
    
    Form,
    FormGroup,
} from "../../wrappers"
import ImageDropControl from "../../ImageDropControl"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"

export class TASLandingPageModule extends React.Component<ModuleProps, {}> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
        }
    }

    // Helpers

    logoValidate = (width: number, height: number) => {
        return width <= 2732 && height <= 2048
    }

    setTASLandingPageLogo = (url: string | null) => {
        this.props.updateConfiguration(data => {
            data.logo_url = url
        })
    }

    // Component

    render() {
        const account = this.props.role.account_id
        const tasLandingPageLogoUrl = this.props.moduleData?.logo_url
        return (
            <Form>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Optional custom landing page logo</Col>
                    <Col sm={10}>
                        <ImageDropControl
                            fileName="tas_landing_page_logo"
                            filePath={account + "/configuration/modules/tas_landing_page/"}
                            imageURL={tasLandingPageLogoUrl}
                            dropTitle="Max size 2732×2048 2MB"
                            validateSize={this.logoValidate}
                            deleteAction={() => { this.setTASLandingPageLogo(null) }}
                            uploadAction={(url) => { this.setTASLandingPageLogo(url) }}
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
