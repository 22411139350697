import * as React from "react"
import { Spinner } from "react-bootstrap"
import { Nav, Navbar, NavItem, Button, Modal } from "../components/wrappers"
import { Blocker, useBlocker } from "react-router"
import { useEffect, useState } from "react"
import * as _ from "lodash"

interface PageStateProps {
    children?: any,
    customMessage?: string
    typeName: string,
    loading: boolean,
    publishing?: boolean,
    dirty?: boolean
    submit_disabled?: boolean

    title?: string
    submit_title?: string
    showButtonSpinner?: boolean
    submit_action?: () => Promise<void>
    discard_action?: () => void
}

interface LoadingButtonProps {
    onClick: () => Promise<void>
    disabled: boolean
    title?: string | undefined
    showSpinner?: boolean
}

async function sleep() {
    return new Promise((resolve) => setTimeout(resolve, 500));
}

function LoadingButton(props: LoadingButtonProps) {
    const [isLoading, setLoading] = useState(false);
    
    const handleClick = async () => {
        setLoading(true)
        if (_.isNil(props.showSpinner) || (!_.isNil(props.showSpinner) && props.showSpinner)) {
            await sleep()
        }
        await props.onClick()
        setLoading(false)
    }

    return (
      <Button
        variant="primary"
        disabled={isLoading || props.disabled}
        onClick={!isLoading ? handleClick : undefined}
      >
        { showTitleWithSpinner()}
      </Button>
    );

    function showTitleWithSpinner(): React.ReactNode {
        const title = props.title ?? 'Publish'
        if (!_.isNil(props.showSpinner) && !props.showSpinner) {
            return title
        }
        return isLoading ? <Spinner animation="border" size="sm"/> : title
    }
  }
export default LoadingButton;

export function PageState(props: PageStateProps) {
    const scheme = document.querySelector("meta[name=\"theme-color\"]")
    const color = (props.dirty === true) ? "rgb(57,52,90)" : "white"
    scheme?.setAttribute("content", color)

    useBeforeUnload(props)
    let blocker = usePageStateBlocker(props)

    if (props.customMessage) {
        return <h1 key="custom">{props.customMessage}...</h1>
    } else if (props.loading) {
        return <h1 key="loading">Loading {props.typeName}...</h1>
    } else if (props.publishing || false) {
        return <h1 key="publishing">Publishing {props.typeName}...</h1>
    } else {
        return (
            <div> 
                { props.dirty ? renderNavbar(blocker, props) : null }
                <div key="content">{props.children}</div>
            </div>
        )
    }
}

interface PageStateNavBarProps {
    blocker: Blocker
    discardAction?: () => void
    title?: string
    submitAction?: () => void
    submitDisabled?: boolean
    submitTitle?: string
    showButtonSpinner?: boolean
}

function usePageStateBlocker(props: PageStateProps) {
    return useBlocker(
        ({ currentLocation, nextLocation }) => {
            if (props.publishing === true) { return false} 
            return (props.dirty === true) && currentLocation.pathname !== nextLocation.pathname
        }
    )
}

function useBeforeUnload(props: PageStateProps) {
    useEffect(() => {
        const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string} ) => {
            event.preventDefault()
            event.returnValue = ''
        }
        if (props.publishing) { window.removeEventListener('beforeunload', handleBeforeUnload)} 
        else if (props.dirty) { window.addEventListener('beforeunload', handleBeforeUnload) } 
        else { window.removeEventListener('beforeunload', handleBeforeUnload)} 

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [props.publishing, props.dirty])
}

function renderNavbar(blocker: Blocker, props: PageStateProps) {
    return ( 
    <div>
        { renderAlert(props, blocker) }
        {<PageStateNavbar blocker={blocker} discardAction={props.discard_action} title={props.title} submitDisabled={props.submit_disabled} submitAction={props.submit_action} submitTitle={props.submit_title} showButtonSpinner={props.showButtonSpinner} />}
    </div>)
}

function renderAlert(props: PageStateProps, blocker: Blocker) {
    if (blocker.state === 'blocked') { 
        return(
                <Modal show={true}>
                    <Modal.Header> <b>Unsaved {props.typeName} changes</b></Modal.Header>
                    <Modal.Body> You have unsaved {props.typeName} changes. Do you wish to navigate away and discard changes? </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => { blocker.reset() }}> Cancel </Button>
                        <Button onClick={() => { blocker.proceed() }}> Ok </Button>
                    </Modal.Footer>
                    
                </Modal>
            )
        } else { return null }
}

function PageStateNavbar(props: PageStateNavBarProps) {

    return (
        <Navbar key="navbar_dirty" id="bootstrap-override" className="dirty" fixed="top" style={{ paddingLeft: "10px", paddingRight: "30px" }}>
            {/* <Navbar.Header> */}
            <Navbar.Text>
                {props.title ?? "Unpublished changes"}
            </Navbar.Text>
            <Navbar.Toggle />
            {/* </Navbar.Header> */}
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    {props.discardAction ?
                        (
                            <NavItem>
                                <Button variant="danger" onClick={() => { props.discardAction?.() }}>Discard</Button>
                            </NavItem>
                        ) : null}
                    {props.submitAction ? (
                        <NavItem>
                             <LoadingButton showSpinner={props.showButtonSpinner} disabled={props.submitDisabled ?? false} title={props.submitTitle} onClick={async () => { await props.submitAction?.() }}/>  
                        </NavItem>
                    ) : null}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}
