//
//  pdf_metadata.ts
//  giftcard-service
//
//  Created by Flemming Pedersen on 07/08/2019.
//  Copyright © 2019 Ka-ching. All rights reserved.
//

import * as _ from "lodash"

export class PDFMetadata {
    public static fromJSON(json: any | null): PDFMetadata | null {
        if (_.isNil(json)) {
            return null
        }

        const metadata = new PDFMetadata()
        metadata.author = json.author
        metadata.creator = json.creator
        metadata.title = json.title

        return metadata

    }

    public author?: string
    public creator?: string
    public title?: string
}
