import * as _ from "lodash";
import React, { useState } from "react";
import { Button, Col, DescriptionCol, FormControl, FormGroup, Modal, Row } from "../../../../components/wrappers";
import { EmailTemplateLine } from "../../../../models/email_template";
import { Form } from "react-bootstrap";
import { ToggleButton } from "../../../../components/ToggleButton";
import { FontButton, FontStyleButton, FontWeightButton } from "../GiftcardTemplateEditor/FontButton";

interface LineEditProps {
    lineItem: EmailTemplateLine;
    title: String
    onOK: (lineItem: EmailTemplateLine) => void;
    onCancel: () => void;
}
export function LineEdit(props: LineEditProps) {
    const [lineItem, setLineItem] = useState<EmailTemplateLine>(props.lineItem);
    const [okDisabled, setOkDisabled] = useState<boolean>(isOkDisabled(props.lineItem))

    return (
        <Modal show={true} size="lg">
            <Modal.Header>
                <Modal.Title> {props.title} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <LineBodyEdit lineItem={lineItem} updateLineItem={updateLineItem}></LineBodyEdit>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="primary" disabled={okDisabled} onClick={() => { props.onOK(lineItem); }}> OK </Button>
            </Modal.Footer>

        </Modal>
    );

    function updateLineItem(callback: (lineItem: EmailTemplateLine) => EmailTemplateLine) {
        let updatedLine = _.cloneDeep(lineItem);
        updatedLine = callback(updatedLine);
        setOkDisabled(isOkDisabled(updatedLine))
        setLineItem(updatedLine);
    }

    function isOkDisabled(lineItem: EmailTemplateLine) {
        if (_.isEmpty(lineItem.text)) { return true }
        return false
    }
}

interface LineBodyEditProps {
    updateLineItem: (callback: (lineItem: EmailTemplateLine) => EmailTemplateLine) => void
    lineItem: EmailTemplateLine
}

function LineBodyEdit(props: LineBodyEditProps) {
    return <Form>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Text*</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                <FormControl
                    disabled={false}
                    type="text"
                    name="Text"
                    value={props.lineItem.text}
                    placeholder="Enter text"
                    onChange={(event) => {
                        props.updateLineItem((lineItem) => {
                            const newValue = event.currentTarget.value as string;
                            lineItem.text = newValue;
                            return lineItem;
                        });
                    } } />
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Text color</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                <FormControl
                    disabled={false}
                    type="text"
                    name="Text color"
                    value={props.lineItem.textColor ?? ""}
                    placeholder="Enter text color"
                    onChange={(event) => {
                        props.updateLineItem((lineItem) => {
                            const newValue = event.currentTarget.value as string;
                            lineItem.textColor = newValue.length > 0 ? newValue : undefined;
                            return lineItem;
                        });
                    } } />
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Font family</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                
            <FontButton mode="email" selectedFont={props.lineItem.fontFamily} customFonts={[]} updatedFont={(font) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.fontFamily = font
                                return lineItem
                            })
                    }}></FontButton>
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Font style</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
            <FontStyleButton selectedStyle={props.lineItem.fontStyle} updatedStyle={(value) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.fontStyle = value
                                return lineItem
                            })
                    }}></FontStyleButton>
            </Col>
        </FormGroup>        
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Font weight</Form.Label>
            </DescriptionCol>

            <Col sm={9}>                
                    <FontWeightButton selectedWeight={props.lineItem.fontWeight} updatedWeight={(value) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.fontWeight = value
                                return lineItem
                            })
                    }}></FontWeightButton>
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Font size</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                <FormControl
                    disabled={false}
                    type="number"
                    name="Font size"
                    value={props.lineItem.fontSize ?? ""}
                    placeholder="Enter font size"
                    min={0}
                    onChange={(event) => {
                        props.updateLineItem((lineItem) => {
                            const value = Number(event.currentTarget.value)
                            lineItem.fontSize = value <= 0 ? undefined : value
                            return lineItem;
                        });
                    } } />
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Alignment</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                <FormControl
                    disabled={false}
                    type="text"
                    name="alignment"
                    value={props.lineItem.alignment ?? ""}
                    placeholder="Enter alignment"
                    onChange={(event) => {
                        props.updateLineItem((lineItem) => {
                            const newValue = event.currentTarget.value as string;
                            lineItem.alignment = newValue.length > 0 ? newValue : undefined;
                            return lineItem;
                        });
                    } } />
            </Col>
        </FormGroup>
        <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={3}>
                <Form.Label>Wallet</Form.Label>
            </DescriptionCol>

            <Col sm={9}>
                <ToggleButton active={props.lineItem.walletURL} performToggle={() => {
                    props.updateLineItem((lineItem) => {
                        const value = !lineItem.walletURL;
                        lineItem.walletURL = value;
                        return lineItem;
                    });
                } }></ToggleButton>
            </Col>
        </FormGroup>
    </Form>;
}

