import React, { useState } from "react";
import { Card } from "../../wrappers";
import { Checkbox, Col, FormGroup } from "../../wrappers";
import * as _ from "lodash";
import { DateComponents } from "../../../models/RuleModels";
import dayjs from "dayjs";
import { DateRange, RangeFocus } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Check2 } from "react-bootstrap-icons"

import { dayjsFromDateComponents } from "./RuleTemplateForm";
import { Dropdown, DropdownButton, Form, Row } from "react-bootstrap";
import { LanguageCode } from "../../../helpers/L10n";
import { DiscountOverallType } from "./RuleEdit";

interface AdvancedPropertiesProps {
    startDate?: DateComponents;
    endDate?: DateComponents;
    selectedShops?: string[];
    allShops: _.Dictionary<string>;
    continueEvaluation?: boolean;
    type: DiscountOverallType
    updateAdvancedProps: (startDate?: DateComponents, endDate?: DateComponents, continueEvaluation?: boolean, selectedShops?: string[]) => void;
}

interface SelectShopsProps {
    shopSelected(shop: string): void
    selectedShops?: string[]
    allShops: _.Dictionary<string>
}

function SelectShops(props: SelectShopsProps) {
    return <DropdownButton title={`Select shops`} id="a">
        <Dropdown.Item key="x." onClick={() => {
            props.shopSelected(".")
        }} eventKey=".">{props.selectedShops?.length === 0 ? <span>All shops&nbsp;<Check2 /></span> : "All shops"}</Dropdown.Item>
        {Object.keys(props.allShops ?? {}).map(key => {
            const option = props.allShops[key]

            return <Dropdown.Item key={key} onClick={() => {
                props.shopSelected(key)
            }} eventKey={key}>{props.selectedShops?.includes(key) ? <span>{option}&nbsp;<Check2 /></span> : option}</Dropdown.Item>
        })}
    </DropdownButton>
}

export function AdvancedPropertiesSelector(props: AdvancedPropertiesProps) {
    const [focusedInput, setFocusedInput] = useState<RangeFocus | null>(null);
    return <Card className="my-4" border="primary" /* defaultExpanded={false}*/>
        {/* <Card.Collapse> */}
        <Card.Body>
            <Card.Title>
                More options
            </Card.Title>
            {props.type === "discount" &&
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Optional time limit</Col>
                    <Col sm={10}>
                        <DateRange onRangeFocusChange={rangeFocus => setFocusedInput(rangeFocus)} className={focusedInput === null ? "collapsed" : ""} editableDateInputs={true}
                            onChange={item => {
                                const range = item["selection"]
                                if (!range) { return }
                                const start = dayjs(range.startDate).startOf("day")
                                const end = dayjs(range.endDate).endOf("day")
                                props.updateAdvancedProps({
                                    year: start.year(),
                                    month: start.month() + 1,
                                    day: start.date()
                                },
                                    {
                                        year: end.year(),
                                        month: end.month() + 1,
                                        day: end.date()
                                    },
                                    props.continueEvaluation,
                                    props.selectedShops)
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={[{
                                startDate: props.startDate ? dayjsFromDateComponents(props.startDate).toDate() : undefined,
                                endDate: props.endDate ? dayjsFromDateComponents(props.endDate).toDate() : undefined,
                                key: "selection"
                            }]} />
                    </Col>
                </FormGroup>
            }

            {props.type === "discount" &&
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Limit to shops</Col>
                    <Col sm={10}>
                        <SelectShops allShops={props.allShops} selectedShops={props.selectedShops} shopSelected={shop => {
                            let shops = _.clone(props.selectedShops) ?? []
                            if (shop === ".") {
                                shops = []
                            } else if (shops.includes(shop)) {
                                const index = shops.indexOf(shop)
                                shops.splice(index, 1)
                            } else {
                                shops.push(shop)
                            }
                            props.updateAdvancedProps(props.startDate, props.endDate, props.continueEvaluation, shops)
                        }} />
                        <Form.Text>
                            You may limit this discount to only apply to a specific list of shops <br />
                            If you don&apos;t select any shops here, the discount applies to all shops in the market.
                        </Form.Text>
                    </Col>
                </FormGroup>
            }

            <FormGroup className="mb-3" as={Row}>
                <Col sm={2}>Continue evaluation</Col>
                <Col sm={10}>
                    <Checkbox
                        label="Continue evaluation"
                        checked={(props.continueEvaluation ?? false)}
                        onChange={(e: any) => props.updateAdvancedProps(props.startDate, props.endDate, e.target.checked, props.selectedShops)}
                    />
                    <Form.Text>
                        Continue evaluation of other rules after this one applies? <br />
                        Note that this allows multiple discounts on the same items. <br />
                        Consider carefully if you really want this.
                    </Form.Text>
                </Col>
            </FormGroup>

        </Card.Body>
        {/* </Card.Collapse> */}
    </Card>;
}
