import dayjs from "dayjs"
import * as React from "react"
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Grid,
    Card,
    Row
} from "./wrappers"
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ReportBuilder } from "../services/ReportBuilder"
import { ReportType } from "../models/ReportModels"
import { Role } from "../config/role"

const FileDownload = require("js-file-download")

interface ReportComponentProps {
    shop: string
    role: Role
    report: ReportType
}

interface ReportComponentState {
    startAt?: Date
    endAt?: Date
    focusedInput?: any
}

export class ReportComponent extends React.Component<ReportComponentProps, ReportComponentState> {

    constructor(props: ReportComponentProps) {
        super(props)

        this.state = {
            startAt: new Date(),
            endAt: new Date()
        }
    }

    render() {
        return (
            <Card className="my-4">
                <Card.Header>{this.props.report.title()}</Card.Header>
                <Card.Body>
                    <Grid>
                        <Row className="show-grid">
                            <Col sm={6}>{this.props.report.description("csv")}</Col>
                        </Row>
                        <br />
                        <br />
                        <Row className="show-grid">
                            <Col sm={6}>
                                <DateRange editableDateInputs={true}
                                    onChange={item => {
                                        const range = item["selection"]
                                        if (!range) { return }
                                        const start = dayjs(range.startDate).startOf("day")
                                        const end = dayjs(range.endDate).endOf("day")
                                        this.setState({ startAt: range.startDate, endAt: range.endDate })
                                    }}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[{ startDate: this.state.startAt, endDate: this.state.endAt, key: "selection" }]} />
                            </Col>
                        </Row><Row>
                            <Col sm={6}>
                                <Button variant="secondary" onClick={this.generateAndDownloadCSV} disabled={!this.canGenerateReport()}>Download CSV</Button>
                                <Button className="mx-4" variant="secondary" onClick={this.generateAndDownloadPDF} disabled={!this.canGenerateReport()}>Download PDF</Button>
                            </Col>
                        </Row>
                    </Grid>
                </Card.Body>
            </Card >
        )
    }

    canGenerateReport(): boolean {
        return (this.state.startAt !== null) && (this.state.endAt !== null)
    }

    generateAndDownloadCSV = async () => {
        const startAt = dayjs(this.state.startAt)
        const endAt = dayjs(this.state.endAt)
        if (!startAt.isValid() || !endAt.isValid()) {
            return
        }

        const reportBuilder = new ReportBuilder(this.props.role.account_id, this.props.shop)
        const fieldDelimiter = ";"
        const decimalSeparator = ","
        const report = this.props.report
        const csv = await reportBuilder.buildCSVReport(report, fieldDelimiter, decimalSeparator, startAt, endAt)

        const filename = await report.buildDocumentName(this.props.role.account_id, this.props.shop, true, report.title())

        FileDownload(csv, `${filename}.csv`)
    }

    generateAndDownloadPDF = async () => {
        const startAt = dayjs(this.state.startAt)
        const endAt = dayjs(this.state.endAt)
        if (!startAt.isValid() || !endAt.isValid()) {
            return
        }

        const reportBuilder = new ReportBuilder(this.props.role.account_id, this.props.shop)
        const report = this.props.report
        const pdf = await reportBuilder.buildPDFReport(report, startAt, endAt)
        const filename = await report.buildDocumentName(this.props.role.account_id, this.props.shop, true, report.title())

        pdf.download(`${filename}.pdf`)
    }
}