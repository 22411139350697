import * as React from "react"
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    Card
} from "react-bootstrap"
import { getExistingApiKey } from "../../../helpers/existingApiKey"
import { currentDatabaseRef } from "../../../config/constants"
import { v4 as uuid } from "uuid"
import CopyToClipboard from "react-copy-to-clipboard"
import { RoleRouterProps, withRoleRouter } from "../../../routes"
import { Clipboard2Check } from "react-bootstrap-icons"
import { child, get, set } from "firebase/database"

interface RestIntegrationState {
    errorDescription?: string
    enabledKey?: string
    copied: boolean
}

export class RestIntegration extends React.Component<RoleRouterProps, RestIntegrationState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            copied: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const importIntegrationKeysRef = child(child(accountRef, "api_keys"), "rest")
        const integrationTypesSnap = await get(importIntegrationKeysRef)
        const restAPIKeys = (integrationTypesSnap.val() ?? {})
        let enabledKey: string | undefined
        for (const key in restAPIKeys) {
            const metadata = restAPIKeys[key].metadata
            if (metadata?.active === true) {
                enabledKey = key
                break
            }
        }
        this.setState({ enabledKey: enabledKey })
    }

    async addIntegration() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)

        // 1: See if any integrations already exist
        let existingAPIKey = await getExistingApiKey(account)

        // 2: If so, use key - if not, generate 
        if (existingAPIKey === undefined) {
            existingAPIKey = uuid()
        }

        // 3: lookup service user uid
        const serviceUserUID = (await get(child(child(accountRef, "api_keys"), "service_user_uid"))).val()
        if (!serviceUserUID) {
            const errorDescription = "No service user UID found on the account"
            console.error(errorDescription)
            this.setState({ errorDescription: errorDescription })
            return
        }

        // 4: Add integration entry and api key
        const apiKeyRef = child(accountRef, `api_keys/rest/${existingAPIKey}`)
        await set(apiKeyRef, { metadata: { uid: serviceUserUID, active: true } })

        this.setState({ errorDescription: undefined, enabledKey: existingAPIKey })
    }

    async disableIntegration() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        if (this.state.enabledKey === undefined) {
            return
        }
        const apiKeyActiveRef = child(accountRef, `api_keys/rest/${this.state.enabledKey}/metadata/active`)
        await set(apiKeyActiveRef, false)
        this.setState({ enabledKey: undefined })
    }

    render() {
        return <Card>
            <Card.Header>
                REST integration
            </Card.Header>

            <Card.Body>
                {this.state.copied ? <Alert variant="success">REST API key copied to clipboard.</Alert> : null}

                {this.state.errorDescription ? <Alert variant="warning">{this.state.errorDescription}</Alert> : null}

                {this.state.enabledKey !== undefined && <div>
                    <p>REST API enabled with key:</p>
                    <CopyToClipboard
                        text={this.state.enabledKey}
                        onCopy={() => { this.setState({ copied: true }); setTimeout(() => { this.setState({ copied: false }) }, 5000) }}
                    >
                        <InputGroup style={{ width: "100%" }}>
                            <FormControl type="text" readOnly={true} value={this.state.enabledKey} />
                            <InputGroup.Text>
                                <Clipboard2Check />
                            </InputGroup.Text>
                        </InputGroup>
                    </CopyToClipboard>
                    <br />

                    <Button variant="danger" onClick={async () => { await this.disableIntegration() }} >{"Disable integration"}</Button>
                </div>}
                {this.state.enabledKey === undefined &&
                    <Button variant="success" onClick={async () => { await this.addIntegration() }} >{"Enable integration"}</Button>
                }
            </Card.Body>
        </Card>
    }
}
export default withRoleRouter(RestIntegration)
