import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { FirestoreSettings, initializeFirestore } from "firebase/firestore"
import { FirebaseOptions, getApp, initializeApp } from "firebase/app"
import { getDatabase, DatabaseReference, ref} from "firebase/database"

const config: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

initializeApp(config)

let rootRef: DatabaseReference

export function currentDatabaseRef(): DatabaseReference {
    if (!rootRef) {
        throw new Error("Please initialize database")
    }
    return rootRef
}

function setRef(theRef: DatabaseReference) {
    rootRef = theRef
}

export function selectRTDBInstance(rtdbInstance: string | undefined, region: string | undefined) {
    let databaseURL: string | undefined
    if (rtdbInstance) {
        if (region) {
            databaseURL = `https://${rtdbInstance}.${region}.firebasedatabase.app`
        } else {
            databaseURL = `https://${rtdbInstance}.firebaseio.com`
        }
    }
    setRef(ref(getDatabase(getApp(), databaseURL)))
}

const settings: FirestoreSettings = { ignoreUndefinedProperties: true }
export const firestore = initializeFirestore(getApp(), settings)
export const defaultRef = ref(getDatabase())

export const firebaseAuth = getAuth
export const storage = getStorage()
export const displayLanguage = "en"
