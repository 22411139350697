import React, { useState } from "react";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "../../../../../../wrappers";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { ValueEditProps } from "../../ComponentEdit";


export function SpacingValueEdit(props: ValueEditProps) {
    return <FormGroup className="mb-3" as={Row}>
        <DescriptionCol sm={3}>
            <Form.Label>Spacing</Form.Label>
        </DescriptionCol>

        <Col sm={9}>
            <FormControl
                disabled={false}
                type="number"
                name="spacing"
                value={props.lineItem.spacing ?? ""}
                placeholder="Enter spacing"
                onChange={(event) => {
                    props.updateLineItem((lineItem) => {
                        const value = new Number(event.currentTarget.value).valueOf()
                        lineItem.spacing = value == 0 ? undefined : value
                        return lineItem;
                    });
                } } />
        </Col>
    </FormGroup>;
}
