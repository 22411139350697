import * as React from "react"

import { Card, Row } from "react-bootstrap"
import { Button, Form, FormGroup, Col, FormControl } from "react-bootstrap"
import { currentDatabaseRef } from "../../../config/constants"
import LoadingButton, { PageState } from "../../PageState"
import { RoleRouterProps, withRouter } from "../../../routes"
import * as _ from "lodash"
import { getFunctions, httpsCallable } from "firebase/functions"
import { child, get, remove, set } from "firebase/database"

interface VippsMobilePayConfigurationState {
    msn: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    showFailure: boolean
    configured: boolean
    test: boolean
}

class VippsMobilePayConfiguration extends React.Component<RoleRouterProps, VippsMobilePayConfigurationState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            msn: "",
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            showFailure: false,
            configured: false,
            test: true
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey

        const moduleRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/modules/mobile_pay`)
        const moduleSnap = await get(moduleRef)        

        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/vipps_mobile_pay`)
        const configSnap = await get(configRef)

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                msn: value.msn ?? "",
                loaded: true,
                configured: !_.isNil(value.msn),
                test: moduleSnap.val().environment === "sandbox"
            })
        } else {
            this.setState({
                loaded: true,
                test: moduleSnap.val().environment === "sandbox"
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Vipps MobilePay configuration">
                <Card className="my-4" key="upper_panel" border={this.state.showFailure ? "danger" : (this.state.showSuccess ? "success" : "default")}>
                    <Card.Header>
                        {this.state.showFailure ? "Failed to update Vipps MobilePay configuration. Did you enter a correct MSN?" : (this.state.showSuccess ? "Vipps MobilePay configuration updated successfully" : "Vipps MobilePay configuration")}
                    </Card.Header>

                    <Card.Body>
                        <p>In the MobilePay portal, you can find or create a Merchant Serial Number(MSN), which needs to be entered here.</p>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Merchant Serial Number (MSN)</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="msn"
                                        value={this.state.msn}
                                        placeholder="Enter Merchant Serial Number (MSN)"
                                        onChange={this.handleInputChange}
                                        disabled={this.state.configured}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>

                    <Card.Footer>
                        {this.state.configured ?
                            <Button variant="warning" onClick={this.unpublish} disabled={this.state.dirty}>Remove configuration</Button>
                            :
                            <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                        }
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value

        if (target.name === "msn") {
            this.setState({ msn: value, dirty: true })
        }
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey

        this.setState({ publishing: true })
        const clientApi = httpsCallable(getFunctions(), "clientApi")
        const args: any = {
            action: "mobilepay-register",
            account: this.props.role.account_id,
            test: this.state.test,
            msn: this.state.msn
        }

        try {
            await clientApi(args)

            const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/vipps_mobile_pay`)
            await set(configRef, { msn: this.state.msn })

            this.setState({ showSuccess: true, publishing: false, dirty: false, configured: true })
        } catch {
            this.setState({ showSuccess: false, showFailure: true, publishing: false, dirty: false })
        }

//        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }

    unpublish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey

        this.setState({ publishing: true })

        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/vipps_mobile_pay`)
        await remove(configRef)

        const clientApi = httpsCallable(getFunctions(), "clientApi")
        const args: any = {
            action: "mobilepay-unregister",
            account: this.props.role.account_id,
            test: this.state.test,
            msn: this.state.msn
        }

        try {
            await clientApi(args)
            this.setState({ showSuccess: true, publishing: false, dirty: false, msn: "", configured: false })
        } catch {
            this.setState({ showSuccess: false, publishing: false, dirty: false, msn: "", configured: false })
        }
    }
}

export default withRouter(VippsMobilePayConfiguration)