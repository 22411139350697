import * as _ from "lodash"

export function mapValuesToModelClass<T>(object: object | undefined, type: (new (json: any) => T)): _.Dictionary<T> | undefined {
    if (!object) {
        return undefined
    }
    const reduction = (aggregate: object, key: string) => {
        aggregate[key] = new type(object[key])
        return aggregate
    }
    return Object.keys(object).reduce(reduction, {})
}

export interface JSObjectConvertible {
    json(): () => any
}

export function mapValuesToJSObject<T extends JSObjectConvertible>(object: _.Dictionary<T> | undefined): object | undefined {
    if (!object) {
        return undefined
    }
    const reduction = (aggregate: object, key: string) => {
        aggregate[key] = object[key].json()
        return aggregate
    }
    return Object.keys(object).reduce(reduction, {})
}