import * as React from "react"
import { Globals } from "../helpers/globals"
import { DropdownButton } from "./wrappers"
import { isNil } from "lodash"
import { Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCircleMinus, faPlusSquare } from "@fortawesome/free-solid-svg-icons"
import { Market } from "../models/MarketModels"
import { Role } from "../config/role"
import { currentDatabaseRef } from "../config/constants"
import { child, get } from "firebase/database"

export function localize(l10nString: any, language: any) {
    if (!l10nString) { return "" }
    if (typeof (l10nString) !== "object") {
        return l10nString
    }
    return l10nString[language] || l10nString["en"] || l10nString[Object.keys(l10nString)[0]]
}

interface ConfirmDeleteIconProps {
    className?: string
    message: string
    onDelete(): void
    deletionText?: string
    variant?: string
}

export class ConfirmDeleteIcon extends React.Component<ConfirmDeleteIconProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onDelete()
        }
    }

    render() {
        return <FontAwesomeIcon style={{ color: "#dc3545" }} icon={faCircleMinus} className={this.props.className} onClick={(event) => {  event.preventDefault(); event.stopPropagation(); this.onClick() }}></FontAwesomeIcon>
    }
}

interface MarketPickerProps {
    resolveMarkets?: () => string[]
    onMarketSelect: (market: Market) => void
    removeMarket: (market: string) => void
    addMarket: (market: string) => void
    typeName: string
    currentMarket: Market | null
    role: Role
    className?: string
    readonly?: boolean
    dontDefault?: boolean
}

interface MarketPickerState {
    markets: Market[]
    shopMarket?: string
}

export class MarketPicker extends React.Component<MarketPickerProps, MarketPickerState> {
    _mounted: boolean = false
    constructor(props: MarketPickerProps) {
        super(props)

        this.state = {
            markets: []
        }
    }

    async componentDidMount() {
        const markets = await Globals.shared.getMarkets()
        const shopMarketId = await this.getShopMarketId()

        this.setState({ shopMarket: shopMarketId, markets: markets })

        if (this.props.readonly === true && this.props.currentMarket === null && markets.length > 0) {
            if (this.props.role.isShopOwner()) {
                if (!isNil(shopMarketId)) {
                    const found = markets.find(market => { return market.id === shopMarketId })
                    if (!isNil(found)) {
                        this.props.onMarketSelect(found)
                    }
                }
            } else {
                if (this.props.dontDefault !== true) {
                    this.props.onMarketSelect(markets[0])
                }
            }
        }
    }

    selectMarket = (marketKey: string | null) => {
        const market = this.state.markets.find(candidate => { return candidate.id === marketKey })
        if (!market) {
            return
        }
        this.props.addMarket(market.id)
        this.props.onMarketSelect(market)
    }

    private async getShopMarketId(): Promise<string | undefined> {
        if (!this.props.role.isShopOwner()) {
            return
        }
        const marketRef = child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/shops/${this.props.role.shop_id}/market`)
        const snapshot = await get(marketRef)
        return snapshot.val()
    }

    availableMarkets(props: MarketPickerProps = this.props): string[] {
        if (this.state.shopMarket) {
            return [this.state.shopMarket]
        } else if (props.resolveMarkets) {
            return props.resolveMarkets()
        } else {
            return this.state.markets.map(market => { return market.id })
        }
    }

    notAvailableMarkets(props: MarketPickerProps = this.props): string[] {
        const available = this.availableMarkets(props)
        const filtered = this.state.markets.filter(market => {
            return !available.includes(market.id)
        })
        return filtered.map(market => { return market.id })
    }

    title(): string {
        const defaultTitle = (this.props.readonly === true) ? "Select market" : "Add to market"
        if (!this.state.markets) {
            return defaultTitle
        }
        if (isNil(this.props.currentMarket)) {
            return defaultTitle
        }
        const market = this.props.currentMarket
        return "Market: " + market.name
    }

    showWarning(): boolean {
        if (this.props.readonly === true) {
            return false
        }
        if (this.state.markets.length <= 1) {
            return false
        }
        if (isNil(this.props.currentMarket)) {
            return true
        }
        return false
    }

    market(key: string): Market | null {
        return this.state.markets.find(m => { return m.id === key }) || null
    }

    marketName(key: string): string | null {
        const market = this.market(key)
        if (market) { return market.name }
        return null
    }

    render() {
        return this.state.markets.length > 1 && <DropdownButton
            key="market"
            className={this.props.className}
            variant={this.showWarning() ? "outline-warning" : "outline-primary"}
            title={this.title()}
            id="market"
            onSelect={(event: any) => { this.selectMarket(event as string) }}
        >
            {this.availableMarkets().length > 0 && <span>
                <Dropdown.Header>{this.props.readonly === true ? "Select market" : "Available in"}</Dropdown.Header>
            </span>
            }
            {
                this.availableMarkets().map(marketKey => {
                    const marketName = this.marketName(marketKey) ?? "-"
                    const selected = marketKey === this.props.currentMarket?.id
                    return <Dropdown.Item className="triggerHover" key={marketKey} eventKey={marketKey}><FontAwesomeIcon style={{ visibility: selected ? "visible" : "hidden", paddingRight: 5 }} icon={faCheck} />{marketName}&nbsp;<span className="float-sm-end">{this.props.readonly !== true && <ConfirmDeleteIcon className="hovers" message={`Are you sure you wish to remove the ${this.props.typeName} from the market: ${marketName}`} onDelete={() => { this.props.removeMarket(marketKey) }}></ConfirmDeleteIcon>}</span></Dropdown.Item>
                })
            }
            {this.props.readonly !== true && this.notAvailableMarkets().length > 0 && <span>
                < Dropdown.Header >{this.availableMarkets().length > 0 ? "Not available in" : "Add to market"} </Dropdown.Header>
                {
                    this.notAvailableMarkets().map(marketKey => {
                        const marketName = this.marketName(marketKey) ?? "-"
                        return <Dropdown.Item className="triggerHover" key={marketKey} eventKey={marketKey}><FontAwesomeIcon style={{ visibility: "hidden", paddingRight: 5 }} icon={faCheck} /> {marketName}&nbsp;<span className="float-sm-end"><FontAwesomeIcon style={{ verticalAlign: "revert-layer", color: "#0d6efd" }} className="hovers" icon={faPlusSquare} /></span></Dropdown.Item>
                    })
                }
            </span>}

        </DropdownButton>
    }
}
