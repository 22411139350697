import * as _ from "lodash"
import * as React from "react"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

export class ProductLabelModule extends React.Component<ModuleProps> {

    constructor(props: ModuleProps) {
        super(props)

        this.state = {
            modes: {
                product_label: "Product Label",
                shelf_label: "Shelf Label"
            }
        }
    }

    componentDidMount(): void {
        this.props.updateConfiguration(data => {
            data.enabled = this.props.moduleData.enabled
            data.mode = "product_label"
        })
    }

    render() {
        return (
            <div />
        )
    }
}
