import React, { useEffect, useState } from "react"
import { FormattedTime } from "react-intl"
import { StockCountRequest, stockCountRequestFromJSON } from "../../../models/StockCountRequest"
import { firestore } from "../../../config/constants"
import { FirestorePager } from "../../FirestorePager"
import { StockCountRequestModal } from "./StockCountRequestModal"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute } from "../../../models/Product"
import dayjs from "dayjs"
import { collection, orderBy, query, where } from "firebase/firestore"

interface StockCountRequestPagerProps {
    accountId: string
    stockLocation: string
    openStockCount: (request: StockCountRequest) => void
    hasCurrentCount: boolean
}

export function StockCountRequestPager(props: StockCountRequestPagerProps) {
    const [showRequest, setShowRequest] = useState<StockCountRequest | undefined>()
    const [attributes, setAttributes] = useState<_.Dictionary<Attribute> | undefined>()
    const path = `accounts/${props.accountId}/stock_locations/${props.stockLocation}/stock_count_requests`
    const stockCountRequestCollectionRef = collection(firestore, path)
    const q = query(stockCountRequestCollectionRef, where("status", "==", "created"), orderBy("due_date", "asc"))
    const showStockCountRequest = (request: StockCountRequest) => {
        setShowRequest(request)
    }

    const attributesObserver = new AttributeObserver(props.accountId)

    useEffect(() => {
        attributesObserver.attributesChangedCallback = () => {
            setAttributes(attributesObserver.attributesDict)
        }
        attributesObserver.start()
        return () => {
            attributesObserver.stop()
        }
    }, [])

    const thisMorning = dayjs().startOf("day").toDate()

    const renderElement = (request: StockCountRequest) => {
        let lineProps: React.CSSProperties = {}
        const result = (
            <tr style={lineProps} key={request.id} onClick={() => showStockCountRequest(request)}>
                <td>{request.name}</td>
                <td>{request.createdBy}</td>
                <td>
                    <FormattedTime
                        value={request.creationDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </td>
                <td>
                    <span style={{ color: request.dueDate < thisMorning ? "#FF0000" : "black" }}>
                        <FormattedTime
                            value={request.dueDate}
                            day="numeric"
                            month="long"
                            year="numeric"
                            hour="numeric"
                            minute="numeric"
                        />
                    </span>
                </td>
            </tr>
        )
        return result

    }
    const renderHeader = () => {
        return <tr>
            <th>Name</th>
            <th>Requested by</th>
            <th>Requested at</th>
            <th>Due by</th>
        </tr>
    }

    return <span>
        <FirestorePager
            query={q}
            transform={(doc) => { return stockCountRequestFromJSON(doc.data() ?? {}) }}
            title="Stock Count Requests"
            renderElement={renderElement}
            renderHeader={renderHeader}
            renderContainer={children => {
                return <span>
                    <hr />
                    {children}
                </span>
            }}
            limit={10}
        />
        {showRequest && <StockCountRequestModal
            request={showRequest}
            onComplete={() => setShowRequest(undefined)}
            hasCurrentCount={props.hasCurrentCount}
            openStockCount={(request) => { props.openStockCount(request) }}
            attributes={attributes}
        />}
    </span>
}
