//
//  GroupEdit.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 13/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { currentDatabaseRef } from "../../config/constants"
import { Button, Col, Form, FormGroup, Card, Alert, Row, FormControlStatic, DescriptionCol, MenuItem } from "../wrappers"
import { PageState } from "../PageState"
import { ProductGroup } from "../../models/Product"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { Dropdown } from "react-bootstrap"
import * as _ from "lodash"
import { child, get, off } from "firebase/database"

interface GroupEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
}

interface GroupEditState {
    group: ProductGroup
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
    fiscalRules: any
}

var norwegianArticleGroupCodeList: { id: string, description: string }[] = [
    { id: "04019", description: "Accomodation - full board" },
    { id: "04020", description: "Accomodation - half board" },
    { id: "04021", description: "Accomodation - with breakfast" },
    { id: "04010", description: "Alcopops/Cider" },
    { id: "04007", description: "Beer" },
    { id: "04017", description: "Cloakroom fee" },
    { id: "04006", description: "Food" },
    { id: "04016", description: "Free entrance (members etc)" },
    { id: "04018", description: "Free cloakroom" },
    { id: "04015", description: "Entrance fee (cover charge)" },
    { id: "04009", description: "Liquor" },
    { id: "04999", description: "Other" },
    { id: "04012", description: "Other drinks (tea, coffe etc)" },
    { id: "04014", description: "Other goods" },
    { id: "04005", description: "Sale of haircut" },
    { id: "04003", description: "Sale of goods" },
    { id: "04004", description: "Sale of treatment services" },
    { id: "04011", description: "Soft drinks/Mineral water" },
    { id: "04013", description: "Tobacco" },
    { id: "04008", description: "Wine" },
    { id: "04001", description: "Withdrawal of treatment services" },
    { id: "04002", description: "Withdrawal of goods used for treatment services" }
];

class GroupEdit extends React.Component<GroupEditProps, GroupEditState> {
    // Properties
    initialState: GroupEditState = {
        group: new ProductGroup({
            group: "",
            name: "",
            norwegianArticleGroupCode: undefined
        }),
        identifier: "",
        currentLanguage: this.props.currentLanguage || null,
        loaded: false,
        dirty: false,
        publishing: false,
        error: null,
        fiscalRules: { enabled: false }
    }

    // Component

    constructor(props: GroupEditProps) {
        super(props)

        this.state = this.initialState
    }

    componentWillUnmount() {
        const key = this.groupKey()
        off(child(this.groupsRef(), key))
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        const fiscalRulesSnap = await get(this.fiscalRulesModuleRef())
        const fiscalRules = fiscalRulesSnap.val() ?? { enabled: false }

        if (!this.isNewGroup()) {
            const snapshot = await get(child(this.groupsRef(), this.groupKey()))

            const group = new ProductGroup(snapshot.val())

            this.setState({ group: group, identifier: group.group, loaded: true, fiscalRules: fiscalRules })
        } else {
            this.setState({ loaded: true, fiscalRules: fiscalRules })
        }
    }

    checkArticleGroup(): string {
        let dropdownTitle = this.state.group.norwegianArticleGroupCode ?? "Select article group"
        for (let id of norwegianArticleGroupCodeList.keys()) {
            if (norwegianArticleGroupCodeList[id].id === dropdownTitle) {
                return norwegianArticleGroupCodeList[id].description
            }
        }
        return dropdownTitle
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="group"
            >
                <Form>

                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="group"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewGroup() ? "Create new group" : `Edit group '${this.state.group.name.localized(this.state.currentLanguage)}'`}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <div>
                                    <FormGroup className="mb-3" as={Row}>
                                        <DescriptionCol sm={2}>Name</DescriptionCol>
                                        <Col sm={10}>
                                            <L10nFormControl
                                                l10n={this.state.group.name}
                                                placeholder="Enter localized name"
                                                language={this.state.currentLanguage}
                                                onLocalizationChanged={l10n => { this.handleNameInputChange(l10n) }}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {
                                        !this.isNewGroup()
                                            ? (
                                                <FormGroup className="mb-3" as={Row}>
                                                    <DescriptionCol sm={2}>Identifier</DescriptionCol>
                                                    <Col sm={10}>
                                                        <FormControlStatic>{this.state.group.group}</FormControlStatic>
                                                    </Col>
                                                </FormGroup>
                                            ) :
                                            null
                                    }
                                </div>
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.groupsRef()}
                                isNew={this.isNewGroup()}
                                typeName="group"
                                identifierSource={this.state.group.name.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                            {this.shouldIncudeNorwegianProductGroups() ? (
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Article group for Norwegian fiscalization</DescriptionCol>
                                    <Col sm={10}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic-button">
                                                {
                                                    this.checkArticleGroup()
                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    norwegianArticleGroupCodeList.map((articleGroup) => {
                                                        return (
                                                            <Dropdown.Item active={articleGroup.id === this.state.group.norwegianArticleGroupCode ?? ""} key={articleGroup.id} onClick={() => {
                                                                const group = _.cloneDeep(this.state.group)
                                                                group.norwegianArticleGroupCode = articleGroup.id
                                                                this.setState({ group: group })
                                                                this.handleArticleGroupCodeChange(articleGroup.id)

                                                            }}> {articleGroup.description}

                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                </FormGroup>
                            ) : null}

                        </Card.Body>
                        <Card.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Card.Footer>
                    </Card>
                </Form>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing group:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }

    // Product group

    async publish() {
        const json = this.state.group.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "group", this.state.identifier, this.isNewGroup(), this.groupsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    // Language

    resolveLanguages = (): LanguageCode[] => {
        return this.state.group.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const group = _.cloneDeep(this.state.group)
        group.name.removeLocalization(language)
        this.setState({ group: group, dirty: true, currentLanguage: null })
    }

    // Handlers

    handleNameInputChange = (l10n: L10nString | null) => {
        const group = _.cloneDeep(this.state.group)
        group.name = l10n || new L10nString("")
        this.setState({ group: group, dirty: true })
    }

    handleArticleGroupCodeChange = (norwegianArticleGroupCode: string | undefined) => {
        const group = _.cloneDeep(this.state.group)
        group.norwegianArticleGroupCode = norwegianArticleGroupCode
        this.setState({ group: group, dirty: true })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    // Helpers

    pop() {
        const path = "/groups"
        this.props.router.navigate(path)
    }

    groupKey() {
        return this.props.router.params.groupKey
    }

    isNewGroup() {
        return this.groupKey() === "new"
    }

    shouldIncudeNorwegianProductGroups() {
        if (this.state.fiscalRules.enabled !== true) {
            return false
        }
        const hasNorwegianRules = Object.values(this.state.fiscalRules.countries ?? {}).includes("no")
        return hasNorwegianRules
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.group.name.hasEmptyLocalizations()) {
            return false
        }
        return true
    }

    groupsRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/product_groups")
    }

    fiscalRulesModuleRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "configuration/modules/fiscal_rules")
    }

}

export default withRoleRouter(GroupEdit)
