import * as _ from "lodash"
import * as JSMapping from "../helpers/jsMapValue"
import dayjs from "dayjs"
import Numeral from "numeral"
import { countedProductName } from "../helpers/productName"
import {
    fetchPage,
    StockCountFilterType
} from "./StockCountLinesQueryService"
import { LanguageCode } from "../helpers/L10n"
import {
    ProductCatalogService,
    ProductPrices
} from "./ProductCatalogService"
import { currentDatabaseRef } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"
import { StockCountLineItem, StockCountProductInfo } from "../models/StockCountModels"
import { Globals } from "../helpers/globals"
import { child, get } from "firebase/database"

// picked pretty arbitrarily but it should hold that it's not too much memory and not too slow for stock counts in the area of 10.000 lines
const fetchLimit = 500

class StockCountReportLineModel {
    private barcode?: string
    private count?: number
    private expected?: number
    private diff?: number
    private name?: string
    private productId: string
    private variantId?: string
    private costPrice?: number
    private retailPrice?: number
    private deleted?: boolean

    constructor(
        barcode: string | undefined,
        count: number | undefined,
        diff: number | undefined,
        expected: number | undefined,
        name: string | undefined,
        productId: string,
        variantId: string | undefined,
        prices: ProductPrices,
        deleted?: boolean
    ) {
        this.barcode = barcode
        this.count = count
        this.diff = diff
        this.expected = expected
        this.name = name
        this.productId = productId
        this.variantId = variantId
        this.costPrice = prices.costPrice
        this.retailPrice = prices.retailPrice
        this.deleted = deleted
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // private formattedValues(): [string, string, string, string, string, string, string, string, string, string] {
    private formattedValues() {
        const decimalSeparator = ","
        const variantId = `"${this.variantId ?? ""}"`
        const productId = `"${this.productId}"`
        const id = `"${this.variantId ?? this.productId}"`
        const name = `"${this.name || ""}"`
        const barcode = `"${this.barcode || ""}"`
        const count = `${this.count ?? ""}`
        const expected = `${this.expected ?? ""}`
        const diff = `${this.diff ?? ""}`
        const costPrice = `${!_.isNil(this.costPrice) ? this.formatPriceValue(this.costPrice, decimalSeparator) : ""}`
        const costPriceCount = `${!_.isNil(this.costPrice) && !_.isNil(this.count) ? this.formatPriceValue(this.costPrice * this.count, decimalSeparator) : ""}`
        const retailPrice = `${!_.isNil(this.retailPrice) ? this.formatPriceValue(this.retailPrice, decimalSeparator) : ""}`
        const retailPriceCount = `${!_.isNil(this.retailPrice) && !_.isNil(this.count) ? this.formatPriceValue(this.retailPrice * this.count, decimalSeparator) : ""}`
        return [productId, variantId, id, name, barcode, count, expected, diff, costPrice, costPriceCount, retailPrice, retailPriceCount, this.deleted]
    }

    private formatPriceValue(price: number, decimalSeparator: string): string {
        const value = Numeral(price).format("0.00")
        if (decimalSeparator !== ".") {
            return value.replace(".", decimalSeparator)
        } else {
            return value
        }
    }
}

export class StockCountReportBuilder {

    // Props

    private accountId: string
    private shopId: string
    private stockCountId: string
    private productCatalogService: ProductCatalogService
    private productsLoaded: number = 0

    // Cosntructor

    constructor(accountId: string, shopId: string, stockCountId: string, productCatalogService: ProductCatalogService) {
        this.accountId = accountId
        this.shopId = shopId
        this.stockCountId = stockCountId
        this.productCatalogService = productCatalogService
    }

    // Public methos

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // async buildStockCountReport(type: StockCountFilterType, progress: (loaded: number, aggregated: number) => void): Promise<[string, string]> {
    async buildStockCountReport(type: StockCountFilterType, progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        await this.productCatalogService.allProductsInCatalogue(this.accountId, this.shopId, (loaded) => {
            this.productsLoaded = loaded
            progress(loaded, 0)
        })
        const fieldDelimiter = ";"
        const reportModels = await this.buildReportModels(type, (created: number) => { progress(this.productsLoaded, created) })
        const reportLines = reportModels.map((model) => {
            return model.csvLine(fieldDelimiter)
        })
        const fileName = await this.buildDocumentName(type)
        const headerLine = ["Product id", "Variant id", "Id / Article number", "Name", "Barcode", "Count", "Expected", "Diff", "Cost price per item", "Cost price total (count)", "Retail price per item", "Retail price total (count)", "Deleted"].join(fieldDelimiter)
        let lines: string[] = [headerLine]
        lines = lines.concat(reportLines)
        return [lines.join("\n"), fileName]
    }

    async buildDocumentName(type: StockCountFilterType): Promise<string> {
        const shopNameSnapshot = await get(child(currentDatabaseRef(), `v1/accounts/${this.accountId}/shop_index/${this.shopId}/name`))
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const now = dayjs().format("MMMM Do YYYY, H_mm_ss")
        let result = ""
        switch (type) {
            case StockCountFilterType.ALL:
                result += "Stock count - all - "
                break
            case StockCountFilterType.DIFF_ONLY:
                result += "Stock count - diff only - "
                break
            case StockCountFilterType.NOT_COUNTED:
                result += "Stock count - not counted - "
                break
        }
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += now
        return result
    }

    // Private methods
    private async buildReportModels(type: StockCountFilterType, progress: (created: number) => void): Promise<StockCountReportLineModel[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/lines`

        const marketId = await Globals.getMarket(this.shopId)

        const result: StockCountReportLineModel[] = []
        let count = 0
        let done = false
        let fromLineItemId: string | undefined = undefined
        while (!done) {
            const lines: any = await fetchPage(path, fetchLimit, type, fromLineItemId)
            if (_.isNil(lines)) {
                done = true
                continue
            }

            // filtering out any with missing product info since new lines won't have product info until a function has run
            const validLineItemsJSON = _.filter(lines, (value) => {
                return typeof value.product === "object"
            })

            const lineItems = Object.values(JSMapping.mapValuesToModelClass(validLineItemsJSON, StockCountLineItem) || {})
            const batch = _.take(lineItems, fetchLimit)


            for (const lineItem of batch) {
                const productData = this.productCatalogService.product(lineItem.product.productId)?.json() ?? { deleted: true }
                lineItem.product = new StockCountProductInfo(productData, lineItem.product.productId, lineItem.product.variantId)
                const name = countedProductName(lineItem.product, lineItem.disconnect ?? false, LanguageCode.da, true)
                const prices = this.productCatalogService.productPrices(lineItem.product.productId, lineItem.product.variantId)
                const line = new StockCountReportLineModel(lineItem.product.barcode, lineItem.counted, lineItem.diff, lineItem.expected, name, lineItem.product.productId, lineItem.product.variantId, prices, lineItem.product.deleted)
                result.push(line)
            }

            count += batch.length
            progress(count)

            const lineItemKeys = lineItems.map(item => { return item.id })
            const sortedKeys = sortLikeFirebaseOrderByKey(lineItemKeys)
            const lastId = _.last(sortedKeys)
            if (!lastId) {
                done = true
                continue
            } else {
                fromLineItemId = lastId
            }

            if (lineItems.length < fetchLimit + 1) {
                done = true
            }
        }
        return result
    }
}
