import * as _ from "lodash"
import * as React from "react"

import { Button, Col, FormGroup, Card, ToggleButton, ToggleButtonGroup } from "../../wrappers"
import LoadingButton, { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { RoleProps } from "../../../routes"
import { Row } from "react-bootstrap"
import { child, get, set } from "firebase/database"

interface OpenRegisterModeConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    selectedOpenRegisterMode: string
}

class OpenRegisterModeConfiguration extends React.Component<RoleProps, OpenRegisterModeConfigurationState> {

    constructor(props: RoleProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            selectedOpenRegisterMode: "manual"
        }
    }

    // Helpers

    publish = async () => {
        this.setState({
            publishing: true
        })
        const account = this.props.role.account_id
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/open_register_mode`)
        await set(configRef, this.state.selectedOpenRegisterMode)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    onChange = (modes: any) => {
        const strings: string[] = modes
        _.remove(strings, (val: string) => { return val === this.state.selectedOpenRegisterMode })

        if (strings.length === 0) {
            return
        }

        const mode = _.head(strings) as string

        this.setState({
            dirty: true,
            selectedOpenRegisterMode: mode
        })
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id

        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/open_register_mode`)
        const configSnap = await get(configRef)

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                loaded: true,
                selectedOpenRegisterMode: value
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Open register mode configuration">
                <Card className="my-4" key="open_register_mode" /* XXX TODO  defaultExpanded={false} */>
                    <Card.Header>
                        Open register mode configuration
                    </Card.Header>
                    <Card.Body>
                        <p>The <i>open register mode</i> configuration describes whether the contents of the cash register should be counted manually - or if the &apos;opening float&apos; from the previous closing of the register should be used automatically.</p>
                        <br />
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>Open register mode</Col>
                            <Col sm={10}>
                                <ToggleButtonGroup
                                    type="checkbox"
                                    value={this.state.selectedOpenRegisterMode}
                                    onChange={this.onChange}
                                >
                                    <ToggleButton id="manual" key="manual" value="manual">Manual</ToggleButton>
                                    <ToggleButton id="automatic" key="automatic" value="automatic">Automatic</ToggleButton>
                                </ToggleButtonGroup>
                            </Col>
                        </FormGroup>
                    </Card.Body>
                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
}

export default OpenRegisterModeConfiguration