
import _, { Dictionary } from "lodash"
import { AttributeValue } from "./Product"

export class Customer {
    identifier: string
    balance?: number
    balance_overdue?: number
    name?: string
    street?: string
    city?: string
    postal_code?: string
    country?: string
    country_code?: string
    phone?: string
    email?: string
    index?: any
    additional_info?: string
    organization_number?: string
    credit_limit?: number
    block_sales?: boolean
    purchase_types?: string[]
    attributes?: Dictionary<AttributeValue>
    accepts_marketing?: boolean

    constructor(json: any) {
        this.identifier = json.identifier
        this.balance = json.balance
        this.balance_overdue = json.balance_overdue
        this.name = json.name
        this.street = json.street
        this.city = json.city
        this.postal_code = json.postal_code
        this.country = json.country
        this.country_code = json.country_code
        this.phone = json.phone
        this.email = json.email
        this.index = json.index
        this.additional_info = json.additional_info
        this.organization_number = json.organization_number
        this.credit_limit = json.credit_limit
        this.block_sales = json.block_sales
        this.purchase_types = json.purchase_types
        this.attributes = translateAttributesFromJSON(json.attributes)
        this.accepts_marketing = json.accepts_marketing
    }

    toJSON(): any {
        let object = { ...this }
        const attributes = this.attributes
        if (!_.isNil(attributes)) {
            object.attributes = translateAttributesToJSON(attributes)
        }
        return object
    }

    get isB2BCustomer() {
        const hasB2BFields = !_.isNil(this.balance) ||
            !_.isNil(this.balance_overdue) ||
            !_.isNil(this.credit_limit) ||
            (!_.isNil(this.purchase_types) && this.purchase_types.length !== 0)||
            !_.isNil(this.organization_number) ||
            this.block_sales === true
        return hasB2BFields
    }
}

function translateAttributesToJSON(attributes: _.Dictionary<AttributeValue>) {
    const attributesMap = attributes ? Object.keys(attributes).reduce((result, key) => {
        result[key] = attributes![key].json()
        return result
    }, {}) : undefined
    return attributesMap
}

function translateAttributesFromJSON(attributes: any) {
    const attributesDict = attributes ? Object.keys(attributes).reduce((result, key) => {
        result[key] = new AttributeValue(attributes[key])
        return result
    }, {}) : undefined
    return attributesDict
}
