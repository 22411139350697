import { Dictionary, values, isNil, reduce } from "lodash"
import { LanguageCode } from "../helpers/L10n"
import { soldProductName } from "../helpers/productName"
import { Attribute, AttributeValue } from "../models/Product"
import { BaseReport, formatTwoDigits, dataFormatCurrencyAmount, formatTime, ReportLine, ReportType, Sale, StyleField, formatNoDecimals } from "../models/ReportModels"
import { child, DatabaseReference, DataSnapshot, get } from "firebase/database"

class LineItemSalesReportLine implements ReportLine {
    private time: number
    private id: string
    private name: string
    private quantity: number
    private total: number
    private retail_price: number
    private discount_amount: number
    private sale_status: string
    private comment: string
    private product_group: string
    private tags: string[]
    private cashierName: string
    private registerName: string
    private shopName: string
    private isEmployeePurchase: boolean
    public preserve_attributes: string[]
    private attributeValues: string[]
    private attributes: any

    constructor(time: number, cashier_name: string, register_name: string, shop_name: string, id: string, name: string, quantity: number, total: number, retail_price: number, discount_amount: number, sale_status: string, comment: string, product_group: string, tags: string[], isEmployeePurchase: boolean, preserve_attributes: string[], attributeValues: any, attributes: any) {
        this.time = time
        this.id = id
        this.cashierName = cashier_name
        this.registerName = register_name
        this.shopName = shop_name
        this.name = name
        this.quantity = quantity
        this.total = total
        this.retail_price = retail_price
        this.discount_amount = discount_amount
        this.sale_status = sale_status
        this.comment = comment
        this.isEmployeePurchase = isEmployeePurchase
        this.preserve_attributes = preserve_attributes
        this.product_group = product_group
        this.tags = tags
        this.attributes = attributes
        const attrs: string[] = []
        for (const key of preserve_attributes) {
            const attributeValueJson = attributeValues[key]
            if (!attributeValueJson) {
                attrs.push("")
                continue
            }
            const attributeValue = new AttributeValue(attributeValueJson)
            const attributeJson = attributes[key]
            if (!attributeJson) {
                attrs.push(`${attributeValue.stringValue()}`)
                continue
            }

            const attr = new Attribute(attributeJson)
            if (attr.type.text) {
                attrs.push(attributeValue.localizedValue().localized(null))
            } else if (attr.type.number) {
                attrs.push(attributeValue.stringValue())
            } else if (attr.type.options) {
                const attrString = attributeValue.stringValue()
                const option = attr.type.options[attrString]
                if (option) {
                    const optionName = option.name.localized(null)
                    attrs.push(`${optionName}`)
                } else {
                    attrs.push(`${attrString}`)
                }

            } else {
                attrs.push(attributeValue.stringValue())
            }
        }
        this.attributeValues = attrs
    }

    forCSV(decimalSeparator: string): string[] {
        let items: string[] =
            [
                `"${formatTime(this.time)}"`,
                `"${this.cashierName}"`,
                `"${this.registerName}"`,
                `"${this.shopName}"`,
                `"${this.id}"`,
                `"${this.name}"`,
                `"${this.quantity}"`,
                `"${dataFormatCurrencyAmount(this.total, decimalSeparator)}"`,
                `"${dataFormatCurrencyAmount(this.retail_price, decimalSeparator)}"`,
                `"${dataFormatCurrencyAmount(this.discount_amount, decimalSeparator)}"`,
                `"${this.sale_status}"`,
                `"${this.comment}"`,
                `"${this.product_group}"`,
                `"${this.tags.join(", ")}"`,
                this.isEmployeePurchase ? `"true"` : `""`,
            ]
        items = items.concat(this.attributeValues.map(val => { return `"${val}"` }))
        return items
    }

    forPDF(): object[] {
        let items: object[] = [
            { text: formatTime(this.time), style: `${StyleField.leftTableValue}` },
            { text: this.cashierName, style: `${StyleField.leftTableValue}` },
            { text: this.registerName, style: `${StyleField.leftTableValue}` },
            { text: this.shopName, style: `${StyleField.leftTableValue}` },
            { text: this.id, style: `${StyleField.leftTableValue}` },
            { text: this.name, style: `${StyleField.leftTableValue}` },
            { text: this.formattedQuantity(), style: `${StyleField.rightTableValue}` },
            { text: this.formattedTotal(), style: `${StyleField.rightTableValue}` },
            { text: this.formattedRetailPrice(), style: `${StyleField.rightTableValue}` },
            { text: this.formattedDiscountAmount(), style: `${StyleField.rightTableValue}` },
            { text: this.sale_status, style: `${StyleField.leftTableValue}` },
            { text: this.comment, style: `${StyleField.leftTableValue}` },
            { text: this.product_group, style: `${StyleField.leftTableValue}` },
            { text: this.tags.join(", "), style: `${StyleField.leftTableValue}` },
            { text: this.isEmployeePurchase ? "true" : "", style: `${StyleField.leftTableValue}` }
        ]
        if (this.preserve_attributes.length > 0) {
            let idx = 0
            let values: string[] = []
            for (const attributeId of this.preserve_attributes) {
                const attrJson = this.attributes[attributeId] ?? {}
                const attr = new Attribute(attrJson)
                const name = attr.name.localized(null)
                const value = this.attributeValues[idx]
                if (value === "") { continue }
                values.push(`${name}: ${value}`)
                idx += 1
            }
            const attrs = { text: values.join("\n"), style: `${StyleField.leftTableValue}`}
            items.push(attrs)
        }
        return items
    }

    private formattedQuantity(): string {
        return formatNoDecimals(this.quantity)
    }

    private formattedTotal(): string {
        return formatTwoDigits(this.total)
    }

    private formattedRetailPrice(): string {
        return formatTwoDigits(this.retail_price)
    }

    private formattedDiscountAmount(): string {
        return formatTwoDigits(this.discount_amount)
    }
}

export class LineItemSalesReport extends BaseReport implements ReportType {
    title(): string {
        return "Sales report, line items" //TODO: change to localization key
    }

    description(type: "csv" | "pdf"): string {
        return `This report has a line per line item in a given sale. The following columns are exported: ${this.headers(type).join(", ")}.` //TODO: change to localization key
    }

    preserveAttributes: string[] = []
    attributes: any = undefined

    async prepare(accountRef: DatabaseReference): Promise<void> {
        this.preserveAttributes = []
        const attributesSnap = await get(child(accountRef, "inventory/attributes"))
        if (attributesSnap.exists()) {
            const attributes = attributesSnap.val()
            this.attributes = attributes
            for (const key in attributes) {
                const attribute = attributes[key]
                if (attribute.preserve_on_line_item) {
                    this.preserveAttributes.push(key)
                }
            }
        }
    }

    buildLines(salesSnapshot: DataSnapshot): ReportLine[] {
        if (!salesSnapshot.exists) {
            return []
        }

        const salesDict: Dictionary<Sale> = salesSnapshot.val()
        const lines: LineItemSalesReportLine[] = []
        for (const sale of values(salesDict)) {
            const isEmployeePurchase = (sale.summary.line_items ?? []).some(lineItem => {
                return !isNil(lineItem.behavior?.employee_purchase)
            })
            const lineItems = (sale.summary.line_items ?? [])
            for (const lineItem of lineItems) {
                if (!isNil(lineItem.behavior?.employee_purchase)) {
                    continue
                }
                const line = new LineItemSalesReportLine(
                    sale.timing.timestamp,
                    sale.source.cashier_name,
                    sale.source.register_name,
                    sale.source.shop_name,
                    lineItem.id || "",
                    soldProductName(lineItem, LanguageCode.da) || "", //TODO: consider other languages
                    lineItem.quantity,
                    lineItem.total,
                    lineItem.retail_price,
                    reduce(lineItem.discounts || [], (sum, n) => { return sum + n.amount }, 0),
                    sale.voided ? "voided" : "completed",
                    lineItem.comment ?? "",
                    lineItem.product_group ?? "",
                    lineItem.tags ?? [],
                    isEmployeePurchase,
                    this.preserveAttributes,
                    lineItem.attributes ?? {},
                    this.attributes
                )
                lines.push(line)
            }
        }
        return lines
    }

    headers(type: "csv" | "pdf"): string[] {
        const basicHeaders = [
            "Time",
            "Cashier",
            "Register",
            "Shop",
            "Id",
            "Name",
            "Quantity",
            "Total",
            "Retail Price",
            "Discount Amount",
            "Sale Status",
            "Comment",
            "Product Group",
            "Tags",
            "Employee purchase"
        ]
        if (type === "csv") {
            for (const key of this.preserveAttributes) {
                const attribute = this.attributes[key]
                if (attribute) {
                    const attr = new Attribute(attribute)
                    basicHeaders.push(attr.name.localized(null))
                } else {
                    basicHeaders.push(key)
                }
            }    
        } else if (type === "pdf") {
            if (this.preserveAttributes.length > 0) {
                basicHeaders.push("Attributes")
            }
        }

        return basicHeaders
    }
}
