import * as _ from "lodash"
import { StockCountLineItem, StockCountLine, StockCountLineState } from "../models/StockCountModels"

export function buildStockCountLines(lineItems: StockCountLineItem[], pageLimit: number): StockCountLine[] {
    return lineItems.map(lineItem => {
        const disconnect = lineItem.disconnect ?? false
        const loaded = (lineItem.product.productName !== undefined)
        const deleted = (lineItem.product.deleted || false)
        const state: StockCountLineState = (disconnect ? StockCountLineState.disconnect : (deleted ? StockCountLineState.deleted : (loaded ? StockCountLineState.loaded : StockCountLineState.initializing)))
        const line = new StockCountLine(lineItem.product, lineItem.counted, lineItem.diff, lineItem.expected, state !== StockCountLineState.initializing, lineItem.product.productId, lineItem.product.variantId, state)
        return line
    }).slice(0, pageLimit)
}
