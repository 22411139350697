import React from "react";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { TextValueEdit } from "./ValueEditComponents/TextValueEdit";
import { SpacingValueEdit } from "./ValueEditComponents/SpacingValueEdit";
import { BarcodeValueEdit } from "./ValueEditComponents/BarcodeValueEdit";
import { ValueEditProps } from "../ComponentEdit";

export function BarcodeEdit(props: ValueEditProps) {

    return (
        <Form>
            <TextValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></TextValueEdit>
            <SpacingValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></SpacingValueEdit>
            <BarcodeValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></BarcodeValueEdit>
        </Form>
    );
}
