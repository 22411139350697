import * as React from "react"
import { Card, Grid, Row, Col, FormControl } from "../../wrappers"
import { CashierSelectionMode } from "../../../models/CashierSelection"

interface CashierSelectionModeConfigurationProps {
    didSelectMode: (mode: CashierSelectionMode) => void
    cashierSelectionMode: CashierSelectionMode
}

interface CashierSelectionModeConfigurationState {
    cashier_selection_mode: CashierSelectionMode
}

export default class CashierSelectionModeConfiguration extends React.Component<CashierSelectionModeConfigurationProps, CashierSelectionModeConfigurationState> {
    constructor(props: CashierSelectionModeConfigurationProps) {
        super(props)

        this.state = {
            cashier_selection_mode: this.props.cashierSelectionMode
        }
    }

    render() {
        return (
            <Card className="mb-4" key="panel1">
                <Card.Header>
                    Cashier selection mode
                </Card.Header>
                <Card.Body>
                    <Grid>
                        <Row>
                            <Col xs={12} sm={8} md={8}>
                                <p>
                                    <FormControl
                                        as="select"
                                        name="cashier_selection_mode"
                                        placeholder="Select mode"
                                        value={this.state.cashier_selection_mode}
                                        onChange={this.handleInputChange}
                                    >
                                        <option value={CashierSelectionMode.manual}>Manual cashier selection</option>
                                        <option value={CashierSelectionMode.beforeEachSale}>Re-select before each sale</option>
                                    </FormControl>
                                </p>
                                <p><b>Manual cashier selection</b> allows the users of the POS to manually switch between cashiers.</p>
                                <p><b>Re-select before each sale</b> forces the users of the POS to select a cashier before each sale.</p>
                            </Col>
                        </Row>
                    </Grid>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange = async (event: any) => {
        const newValue = event.target.value

        this.props.didSelectMode(newValue)
        this.setState({ cashier_selection_mode: newValue })
    }
}
