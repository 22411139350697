import * as React from "react"
import {
    Button,
    Col,
    FormControl,
    FormControlStatic,
    FormGroup,
    InputGroup,
    Label,
    Card,
    Row,
} from "../../wrappers"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import {
    StockCountDevice,
    StockCountLine,
    StockCountLineState
} from "../../../models/StockCountModels"
import { StockCountDeviceEventListModal } from "../StockCountCurrentView/StockCountDeviceEventsListModal"
import { StockCountFilterType } from "../../../services/StockCountLinesQueryService"
import { StockCountLineEventListModal } from "../StockCountCurrentView/StockCountLineEventListModal"
import {
    StockCountPastViewModel
} from "./StockCountPastViewModel"
import { StripedTable } from "../../StripedTable"
import { RoleStockLocationProps, withStockLocationRouter } from "../../../routes"
import { Pagination, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import * as _ from "lodash"

const FileDownload = require("js-file-download")

interface StockCountPastState {
    deviceEventsModalDevice?: StockCountDevice
    devices: StockCountDevice[]
    lineEventsModalLine?: StockCountLine
    lines: StockCountLine[]
    loaded: boolean
    name: string
    publishing: boolean
    reportMessage?: string
    wasCancelled: boolean
    searchButtonDisabled: boolean
}

class StockCountPast extends React.Component<RoleStockLocationProps, StockCountPastState> {

    // Props

    viewModel: StockCountPastViewModel

    // Constructor

    constructor(props: RoleStockLocationProps) {
        super(props)

        const stockCountId = this.props.router.params.stockCountId

        this.viewModel = new StockCountPastViewModel(props.role.account_id, props.stockLocation, stockCountId)
        this.viewModel.csvReportCreationCompleted = (report, fileName, errorMessage) => {
            if (errorMessage) {
                alert(errorMessage)
                this.setState({ publishing: false, reportMessage: undefined })
                return
            }

            this.setState({ publishing: false, reportMessage: undefined })
            if (report) {
                FileDownload(report, `${fileName}.csv`)
            }
        }
        this.viewModel.stockCountLinesChanged = (lines) => {
            this.setState({ lines: lines, loaded: true })
        }
        this.viewModel.indexLoaded = (index) => {
            this.setState({ name: index.name, wasCancelled: index.cancelled || false })
        }
        this.viewModel.largeStockCountCreated = () => {
            this.setState({ loaded: true })
        }
        this.viewModel.searchCompleted = (lines, errorMessage) => {
            if (!lines || errorMessage) {
                alert(errorMessage)
                this.setState({ loaded: true })
                return
            }

            this.setState({ lines: lines, loaded: true })
        }

        this.viewModel.devicesChanged = (devices: StockCountDevice[]) => {
            this.setState({ devices: devices })
        }

        this.state = {
            devices: [],
            lines: [],
            loaded: false,
            name: "",
            wasCancelled: false,
            publishing: false,
            searchButtonDisabled: true
        }
    }

    // Helpers

    reportMessage(loaded: number, aggregated: number): string {
        let result = "Generating report - "
        if (aggregated) {
            result += `created ${aggregated} lines`
        } else {
            result += `loaded ${loaded} products`
        }
        return result
    }

    allCSVButtonClicked() {
        this.createCSVReport(StockCountFilterType.ALL)
    }

    diffOnlyCSVReportClicked() {
        this.createCSVReport(StockCountFilterType.DIFF_ONLY)
    }

    notCountedCSVReportClicked() {
        this.createCSVReport(StockCountFilterType.NOT_COUNTED)
    }

    createCSVReport(type: StockCountFilterType) {
        this.setState({ reportMessage: this.reportMessage(0, 0) })
        this.viewModel.createCSVReport(type, (loaded: number, aggregated: number) => {
            this.setState({ reportMessage: this.reportMessage(loaded, aggregated) })
        })
    }

    closeSearch() {
        this.viewModel.closeSearch()
        this.viewModel.startLoadOfCurrentStockCountLines()
        this.setState({ loaded: false })
    }

    filterValueChanged(data: any) {
        if (this.viewModel.filterValueSelected(data)) {
            this.setState({ loaded: false })
        }
    }

    handleKeyPress(value: any) {
        if (value.charCode === 13 && !this.viewModel.isSearchButtonDisabled()) {
            this.performSearch()
        }
    }

    handleLineClick(line: StockCountLine) {
        this.setState({ lineEventsModalLine: line })
    }

    handleDeviceLineClick(device: StockCountDevice) {
        this.setState({ deviceEventsModalDevice: device })
    }

    loadPrevious() {
        this.viewModel.startLoadOfPreviousStockCountLines()
    }

    loadNext() {
        this.viewModel.startLoadOfNextStockCountLines()
    }

    performInitialLoad() {
        this.viewModel.startLoadOfNextStockCountLines()
        this.viewModel.startLoadOfName()
        this.viewModel.startLoadDevices()
    }

    performSearch() {
        this.setState({ loaded: false })
        this.viewModel.performSearch()
    }

    searchTextChanged(text: string) {
        this.viewModel.searchTextChanged(text)
        this.setState({ searchButtonDisabled: this.viewModel.isSearchButtonDisabled() })
    }

    // Component

    componentDidMount() {
        this.performInitialLoad()
    }

    render() {
        let header = this.state.name
        if (this.viewModel.isShowingSearchResults()) {
            header += " - SEARCH RESULTS"
        }
        return (
            <PageState loading={false} publishing={this.state.publishing} customMessage={this.state.reportMessage} typeName="stock count">
                <Card className="my-4">
                    <Card.Header>
                        {header}
                    </Card.Header>
                    <Card.Body>
                        {
                            this.viewModel.isShowingSearchResults()
                                ?
                                (
                                    <FormGroup className="mb-3" as={Row} onSubmit={(value) => this.handleKeyPress(value)}>
                                        <Row>
                                            <Col sm={4}>Lines matching: {this.viewModel.searchTextValue()}</Col>
                                            <Col sm={8}>
                                                <Button className="float-sm-end" onClick={() => { this.closeSearch() }}>
                                                    Close
                                                </Button>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                )
                                :
                                (
                                    <div>
                                        <FormGroup className="mb-3" as={Row} onSubmit={(value) => this.handleKeyPress(value)}>
                                            <Row>
                                                <Col sm={4}>Search</Col>
                                                <Col sm={8}>
                                                    <InputGroup>
                                                        <FormControl
                                                            type="text"
                                                            name="search_text"
                                                            value={this.viewModel.searchTextValue()}
                                                            placeholder="Search for product by name, barcode or id"
                                                            onChange={(event: any) => { this.searchTextChanged(event.target.value) }}
                                                            onKeyPress={(value) => { this.handleKeyPress(value) }}
                                                        />
                                                        <Button onClick={() => { this.performSearch() }} disabled={this.state.searchButtonDisabled}>
                                                            Search
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>CSV reports</Col>
                                                <Col sm={6}>
                                                    <span className="float-sm-end">
                                                        <Button className="my-2" variant="outline-primary" onClick={() => { this.allCSVButtonClicked() }} >
                                                            All
                                                        </Button>
                                                        &nbsp;
                                                        <Button className="my-2" variant="outline-primary" onClick={() => { this.diffOnlyCSVReportClicked() }} >
                                                            Difference only
                                                        </Button>
                                                        &nbsp;
                                                        <Button className="my-2" variant="outline-primary" onClick={() => { this.notCountedCSVReportClicked() }} >
                                                            Not counted
                                                        </Button>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>Filters</Col>
                                                <Col sm={6}>
                                                    <span className="float-sm-end">

                                                        <ToggleButtonGroup
                                                            type="checkbox"
                                                            value={[this.viewModel.filterValue()]}
                                                            onChange={(data: any) => { this.filterValueChanged(data) }}
                                                        >
                                                            <ToggleButton variant="outline-primary" className="my-2" id={StockCountFilterType.ALL} key={StockCountFilterType.ALL} value={StockCountFilterType.ALL}>All</ToggleButton>
                                                            <ToggleButton variant="outline-primary" className="my-2" id={StockCountFilterType.DIFF_ONLY} key={StockCountFilterType.DIFF_ONLY} value={StockCountFilterType.DIFF_ONLY}>Difference only</ToggleButton>
                                                            <ToggleButton variant="outline-primary" className="my-2" id={StockCountFilterType.NOT_COUNTED} key={StockCountFilterType.NOT_COUNTED} value={StockCountFilterType.NOT_COUNTED}>Not counted</ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6}>{" "}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12}><Label variant="info">Info</Label> This stock count is closed and can not be changed.</Col>
                                                {
                                                    this.state.wasCancelled ?
                                                        <Col sm={12}>This stock count was cancelled and the values were not saved.</Col>
                                                        : null
                                                }
                                            </Row>
                                        </FormGroup>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Devices</th>
                                                    <th className="narrow">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.devices.map((device: StockCountDevice) => {
                                                        return (
                                                            <tr key={device.id} onClick={(event: any) => { this.handleDeviceLineClick(device) }}>
                                                                <td>{device.name || device.name}</td>
                                                                <td>Inactive</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    </div>
                                )
                        }
                    </Card.Body>
                    {
                        this.state.loaded ? (
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Product</th>
                                        <th>Barcode</th>
                                        <th>Count</th>
                                        <th>Expected</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.lines.map(line => {
                                            let lineProps: React.CSSProperties = {}
                                            switch (line.state) {
                                                case StockCountLineState.loaded:
                                                    break
                                                case StockCountLineState.initializing:
                                                    lineProps = { color: "#CCCCCC" }
                                                    break
                                                case StockCountLineState.deleted:
                                                    lineProps = { color: "#FF0000" }
                                                    break
                                                case StockCountLineState.disconnect:
                                                    lineProps = { color: "#883300" }
                                                    break
                                            }
                                            const linePropsSmall = _.clone(lineProps)
                                            linePropsSmall.fontSize = "smaller"
                                            linePropsSmall.opacity = 0.6

                                            const result = (
                                                <tr key={line.productId + "*" + (line.variantId || "")} onClick={(event) => { this.handleLineClick(line) }}>
                                                    {
                                                        line.variantId
                                                            ?
                                                            <td style={lineProps} className="narrow"><span style={linePropsSmall}>{line.productId}</span> <span>{line.variantId}</span></td>
                                                            :
                                                            <td style={lineProps} className="narrow">{line.productId}</td>
                                                    }
                                                    <td style={lineProps}>{line.name}</td> 
                                                    <td className="narrow">{line.product.barcode ?? ""}</td>
                                                    <td className="narrow">{typeof line.count === "number" ? line.count : ""}</td>
                                                    <td className="narrow">{typeof line.expected === "number" ? line.expected : ""}</td>
                                                    <td className="narrow">{typeof line.diff === "number" ? line.diff : ""}</td>
                                                </tr>
                                            )
                                            return result
                                        })}
                                </tbody>
                            </StripedTable>
                        ) : null
                    }
                </Card>
                {
                    this.viewModel.isShowingSearchResults()
                        ?
                        null
                        :
                        (
                            <Pagination>
                                <Pagination.Prev onClick={() => { this.loadPrevious() }} disabled={this.viewModel.isPreviousDisabled()}>&larr; Previous Page</Pagination.Prev>
                                <Pagination.Next onClick={() => { this.loadNext() }} disabled={this.viewModel.isNextDisabled()}>Next Page &rarr;</Pagination.Next>
                            </Pagination>
                        )
                }
                {
                    this.state.lineEventsModalLine
                        ?
                        (
                            <StockCountLineEventListModal
                                completed={() => { this.setState({ lineEventsModalLine: undefined }) }}
                                productId={this.state.lineEventsModalLine.productId}
                                productName={this.state.lineEventsModalLine.name}
                                role={this.props.role}
                                stockLocation={this.props.stockLocation}
                                stockCountId={this.props.router.params.stockCountId}
                                variantId={this.state.lineEventsModalLine.variantId}
                            />
                        )
                        :
                        null
                }
                {
                    this.state.deviceEventsModalDevice
                        ?
                        (
                            <StockCountDeviceEventListModal
                                completed={() => { this.setState({ deviceEventsModalDevice: undefined }) }}
                                deviceId={this.state.deviceEventsModalDevice.id}
                                deviceName={this.state.deviceEventsModalDevice.name}
                                role={this.props.role}
                                stockLocation={this.props.stockLocation}
                                stockCountId={this.props.router.params.stockCountId}
                            />
                        )
                        :
                        null
                }
            </PageState>
        )
    }
}

export default withStockLocationRouter(StockCountPast)
