//
//  ProductVideoAssetsEdit.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 24/01/2020.
//  Copyright © 2020 Ka-ching. All rights reserved.
//

import { cloneDeep, isNil } from "lodash"
import * as React from "react"
import { Button, Col, Form, FormControl, FormGroup, Modal, Table } from "../wrappers"
import ReactPlayer from "react-player"

import { Role } from "../../config/role"
import { ProductAsset, videoAssetMimeTypeFromURL, convertToEmbedURL } from "../../models/ProductAsset"
import { Row } from "react-bootstrap"
import { StripedTable } from "../StripedTable"
import { LabelledControl } from "./ProductEdit"

interface ProductVideoAssetsEditProps {
    assets: () => ProductAsset[]
    role: Role
    closeDialog: () => void
    updateAssets: (update: ProductAsset[]) => void
}

interface ProductVideoAssetsEditState {
    url: string | null
    newVideoAsset: ProductAsset | null
}

export class ProductVideoAssetsEdit extends React.Component<ProductVideoAssetsEditProps, ProductVideoAssetsEditState> {

    constructor(props: ProductVideoAssetsEditProps) {
        super(props)

        this.state = {
            newVideoAsset: null,
            url: null
        }
    }

    removeVideoAsset(index: number) {
        const assets = cloneDeep(this.props.assets())
        assets.splice(index, 1)
        this.props.updateAssets(assets)
    }

    appendVideoAsset() {
        const newVideoAsset = this.state.newVideoAsset
        if (isNil(newVideoAsset)) {
            return
        }

        const assets = cloneDeep(this.props.assets())
        assets.push(newVideoAsset)
        this.props.updateAssets(assets)

        this.setState({ url: null })
    }

    handleURLChange = (event: any) => {
        const target = event.target
        const value = target.value

        this.setState({ url: value })

        const mimeType = videoAssetMimeTypeFromURL(value)

        if (isNil(mimeType) || isNil(this.state.url)) {
            this.setState({ newVideoAsset: null })
            return
        }

        this.setState({ newVideoAsset: new ProductAsset(convertToEmbedURL(value), mimeType) })
    }

    closeDialog = () => {
        this.props.closeDialog()
    }

    isAddButtonDisabled = () => {
        return isNil(this.state.newVideoAsset)
    }

    render() {
        return (
            <Modal size="lg" show={true}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Video Assets</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e: any) => e.preventDefault()}>
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={12}>
                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Video</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.assets().filter((asset) => { return asset.isVideoAsset() }).map((asset: ProductAsset, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ "verticalAlign": "middle" }}>
                                                            <ReactPlayer url={asset.url} light={true} playing={true} />
                                                        </td>
                                                        <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                            <Button
                                                                variant="danger"
                                                                key={index}
                                                                onClick={(event: any) => {
                                                                    this.removeVideoAsset(index)
                                                                    event.stopPropagation()
                                                                }}
                                                            >
                                                                X
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </StripedTable>
                            </Col>
                            <LabelledControl label="URL">
                                <FormControl
                                    type="text"
                                    name="url"
                                    value={this.state.url || ""}
                                    placeholder="Enter Youtube or Vimeo embed URL"
                                    onChange={this.handleURLChange}
                                    onPaste={this.handleURLChange}
                                    autoComplete="off"
                                />

                            </LabelledControl>
                            <Col sm={2}>
                                <Button variant="success" disabled={this.isAddButtonDisabled()} onClick={() => { this.appendVideoAsset() }}>Add asset</Button>
                            </Col>

                        </FormGroup>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeDialog}>Done</Button>
                </Modal.Footer>

            </Modal >
        )
    }

}
