import * as React from "react"
import { RoleRouterProps, withRoleRouterFunc } from "../../../routes"
import { PageState } from "../../PageState"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { StockCountOpenModal } from "../StockCountList/StockCountOpenModal"
import { firestore, currentDatabaseRef } from "../../../config/constants"
import { v4 as uuidv4 } from "uuid"
import * as _ from "lodash"
import * as Auth from "../../../helpers/auth"
import { StockCountFilter } from "../../../models/StockCountFilter"
import { StockCountRequest, stockCountRequestToJSON } from "../../../models/StockCountRequest"
import { StockCountRequestList } from "./StockCountRequestList"
import dayjs from "dayjs"
import { addDoc, collection, doc, setDoc } from "firebase/firestore"
import { child, get, push } from "firebase/database"

function requestCollectionRef(accountId: string) {
    return collection(firestore, `accounts/${accountId}/stock_count_requests`)
}

function StockCountRequests(props: RoleRouterProps) {
    const [showModal, setShowModal] = useState(false)
    async function createStockCountRequest(name: string, filter: StockCountFilter, shops: Set<string>, dueDate: Date, filterDescription: string[]) {
        setShowModal(false)

        const uid = Auth.userId() ?? "_"

        const userPath = `v1/accounts/${props.role.account_id}/users/${uid}`
        const userSnapshot = await get(child(currentDatabaseRef(), userPath))
        const userJson = userSnapshot.val() ?? {}
        const email = userJson.email ?? Auth.currentUser()?.email ?? ""

        const request: StockCountRequest = {
            name: name,
            filter: filter,
            shops: Array.from(shops),
            dueDate: dueDate,
            id: uuidv4(),
            creationDate: new Date(),
            createdBy: email,
            status: "created"
        }
        const requestJSON = stockCountRequestToJSON(request)
        const collectionRef = requestCollectionRef(props.role.account_id)
        const documentReference = await addDoc(collectionRef, requestJSON)

        // For each shop, create a request (in Firestore) under the stock location
        // For each shop, create a 'status' document under 'documentReference' that is updated when they complete the count

        for (const shop of shops) {
            const stockLocationRef = collection(firestore, `accounts/${props.role.account_id}/stock_locations/${shop}/stock_count_requests`)
            await addDoc(stockLocationRef, requestJSON)
            const statusRef = doc(collection(documentReference, "status"), shop)
            await setDoc(statusRef, {
                status: "created"
            })
        }

        await sendEmailNotifyingAboutStockCountRequest(email, name, dueDate, filterDescription, props.role.account_id)
    }

    return <PageState typeName="stock count request" dirty={false} loading={false} publishing={false} submit_action={async () => { }}>
        <Button onClick={() => { setShowModal(true) }}>Request a stock count</Button>
        {
            showModal ? (
                <StockCountOpenModal
                    mode="stock_count_request"
                    stockLocation={""}
                    role={props.role}
                    openNew={async (name, filter, shops, dueDate, filterDescription) => { await createStockCountRequest(name, filter, shops, dueDate, filterDescription) }}
                    cancel={() => { setShowModal(false) }}
                />
            ) : null
        }
        <StockCountRequestList accountId={props.role.account_id} />

    </PageState>
}


export default withRoleRouterFunc(StockCountRequests)

async function sendEmailNotifyingAboutStockCountRequest(fromMail: any, name: string, dueDate: Date, filterDescription: string[], account: string) {
    const emails = await getShopOwnerEmails(account)
    for (const email of emails) {
        sendEmail(account, fromMail, email, name, dueDate, filterDescription)
    }
}

async function getShopOwnerEmails(account: string): Promise<Set<string>> {
    const users = child(currentDatabaseRef(), `v1/accounts/${account}/users`)
    const userSnapshot = await get(users)

    const emailPromises: Promise<string | undefined>[] = []
    userSnapshot.forEach(child => {
        const data = child.val()
        const id = child.key
        const potentialEmail = data.email
        if (!_.isNil(id) && !_.isNil(potentialEmail)) {
            let emailPromise = getShopOwnerEmail(potentialEmail, account, id)
            emailPromises.push(emailPromise)
        }
    })
    
    let emails = await Promise.all(emailPromises)
    let filteredEmails: string[] = emails.flatMap(value => value ? [value] : []);
    return new Set(filteredEmails)
}

function sendEmail(account: string, fromMail: string, toMail: string, name: string, dueDate: Date, filterDescription: string[]) {
    let html = `
    <!DOCTYPE html>
    <html>
    <head>
        <title>Stock Count Request</title>
    </head>
    <body>
        <p>New stock count request created by ${fromMail}.</p>
        <ul>
        <li>Stock count request name: ${name}</li>
        <li>Due date: ${dayjs(dueDate).local().format("DD/MM/YYYY HH:mm")}</li>
        </ul>`

    for (const filter of filterDescription) {
        html += `<p>${filter}</p>`;
    }

    html += `
        <p>Make sure to complete the stock count before its due date!</p>
    </body>
    </html>
    `

    const mailQueueRef = child(currentDatabaseRef(), `v1/accounts/${account}/mail/queue`)
    push(mailQueueRef, {
        from_mail: "backoffice@ka-ching.dk",
        from_name: "Ka-ching",
        subject: `Stock count request: ${name}`,
        html: html,
        to: toMail
    })
}

async function getShopOwnerEmail(email: string, account: string, userId: string): Promise<string | undefined> {
    const permissionsRef = child(currentDatabaseRef(), `v1/accounts/${account}/permissions/${userId}`)
    const snap = await get(permissionsRef)
    if (snap.exists()) {
        const val = snap.val()
        if (!_.isNil(val.shop_owner) && val.shop_owner) {
            return email
        }
    }
    return undefined
}
