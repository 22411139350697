import React, { useState, useEffect } from "react"
import { Card } from "../../wrappers"
import { Button, FormGroup } from "../../wrappers"
import { Market } from "../../../models/MarketModels"
import { FormType } from "../../../models/RuleModels"
import { StripedTable } from "../../StripedTable"
import { DiscountType } from "./DiscountSelector"
import { RequirementsType } from "./MinimumRequirementsSelector"
import { RowData, StairStep } from "./StairStep"
import { Row } from "react-bootstrap"

interface StairProps {
    discountType: DiscountType
    formType: FormType
    requirementsType: RequirementsType
    market: Market | null
    markets: string[]
    validation: boolean
    rows: RowData[]
    rowsChanged: (rows: RowData[]) => void
}

export function Stair(props: StairProps) {
    const [rows, setRows] = useState<RowData[]>(props.rows);
    const [rowId, setRowId] = useState<number>(1);
    useEffect(() => {
        props.rowsChanged(rows)
    }, [rows, props])
    const getRowId = () => {
        const id = rowId
        setRowId(id + 1)
        return id
    }

    return <Card className="my-4" border="primary">
        <Card.Body>
            <Card.Title>
                {props.formType === "discount" ? "Discount amount" : "Point earning"}
            </Card.Title>
            <FormGroup className="mb-3" as={Row}
                style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                {props.requirementsType === "none" ?
                    <div style={{ width: "100%" }}>
                        <StripedTable>
                            <tbody>
                                <StairStep
                                    markets={props.markets}
                                    discountType={props.discountType}
                                    formType={props.formType}
                                    requirementsType={props.requirementsType}
                                    market={props.market}
                                    row={rows.length > 0 ? rows[0] : { id: getRowId() }}
                                    rowChanged={(row) => {
                                        if (rows.length > 0 && rows[0] === row) {
                                            console.log("no reason to update")
                                        } else {
                                            setRows([row])
                                        }
                                    }} />
                            </tbody>
                        </StripedTable>
                    </div> :
                    <div>
                        <StripedTable>
                            <tbody>
                                {
                                    rows.map((row, index) => {
                                        return <StairStep
                                            markets={props.markets}
                                            formType={props.formType}
                                            key={row.id}
                                            discountType={props.discountType}
                                            requirementsType={props.requirementsType}
                                            market={props.market} row={row}
                                            onRowDeleted={(row) => {
                                                const clone = [...rows]
                                                clone.splice(index, 1)
                                                setRows(clone)
                                            }} rowChanged={(row) => {
                                                const clone = [...rows]
                                                const existing = clone[index]
                                                if (row.amount !== existing.amount ||
                                                    row.count !== existing.count ||
                                                    row.discountAmount !== existing.discountAmount ||
                                                    row.discountPercentage !== existing.discountPercentage) {
                                                    clone[index] = row
                                                    setRows(clone)
                                                }
                                            }} />
                                    })
                                }
                            </tbody>
                        </StripedTable>
                        <Button onClick={() => { setRows(rows.concat([{ id: getRowId() }])) }}>Add row</Button>
                    </div>
                }
            </FormGroup>
        </Card.Body>
    </Card>
}

