import { L10nString, LanguageCode } from "../helpers/L10n"
import { MarketTaxes } from "./Product"

export class Expense {
    name: L10nString
    id: string
    taxes?: MarketTaxes

    constructor(json: any) {
        this.name = new L10nString(json.name)
        this.id = json.id
        this.taxes = json.taxes ? new MarketTaxes(json.taxes) : undefined
    }

    json(): any {
        const value: any = {
            name: this.name.json(),
            id: this.id,
        }
        if (this.taxes !== undefined){
            value.taxes = this.taxes.json()
        }

        return value
    }

    setMarkets(allMarkets: string[]) {
        if (this.taxes) {
            this.taxes.setMarkets(allMarkets)
        }
    }

    removeMarket(marketKey: string, remaining: string[]) {
        if (this.taxes) {
            this.taxes.removeMarket(marketKey, remaining)
        }
    }

    localizeTo(language: LanguageCode) {
        if (this.name) {
            this.name.localizeTo(language)
        }
    }

    removeLocalization(language: LanguageCode) {
        if (this.name) {
            this.name.removeLocalization(language)
        }
    }

    localizedName(preferredLanguage: LanguageCode): string | null {
        if (!this.name) {
            return null
        }
        return this.name.localized(preferredLanguage)
    }
}