import * as React from "react"
import {
    Col,
    
    Form,
    FormGroup,
} from "../../wrappers"
import { ToggleButton } from "../../ToggleButton"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"
import { firestore } from "../../../config/constants"
import * as _ from "lodash"
import { doc, getDoc } from "firebase/firestore"

interface StockModuleState {
    allowStockgetStockReplenishmentSuggestions: boolean
}
const stockReplenishmentSuggestionsPath = "stock_replenishment_suggestions"
export class StockModule extends React.Component<ModuleProps, StockModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            allowStockgetStockReplenishmentSuggestions: false
        }
    }

    componentDidMount(): void {
        this.getStockReplenishmentSuggestionsAllowment()
    }

    // Component

    render() {
        const config = this.props.moduleData
        const stockReplenishmentSuggestions: boolean = config[stockReplenishmentSuggestionsPath] || false
        if (this.state.allowStockgetStockReplenishmentSuggestions) {
            return (
                <Form>
                    <FormGroup className="mb-3" as={Row}>
                        <Col sm={2}>Use stock replenishment suggestions</Col>
                        <Col sm={10}>
                            {"If enabled, you will be able to get and update stock replenishments in the Stock app from Uniconta"}
                            <br />
                            <ToggleButton
                                active={stockReplenishmentSuggestions}
                                performToggle={async () => {
                                    this.props.updateConfiguration(data => {
                                        data[stockReplenishmentSuggestionsPath] = !stockReplenishmentSuggestions
                                    })
                                }}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            )
        } else {
            return null
        }
    }

    private async getStockReplenishmentSuggestionsAllowment() {
        const stockReplenishment = await getDoc(doc(firestore, `licensing/${this.props.role.account_id}/modules/stock`))

        if (stockReplenishment.exists()) {
            const data = stockReplenishment.data()

            if (!_.isNil(data) && !_.isNil(data.allow_stock_replenishment_suggestions)) {
                this.setState({ allowStockgetStockReplenishmentSuggestions: data.allow_stock_replenishment_suggestions })
            }
        }
    }
}
