import React from "react"
import { FormGroup, Card } from "../../wrappers"
import { Form, Row } from "react-bootstrap"
import { MarketAmount } from "../../../models/MarketAmount"
import { Market } from "../../../models/MarketModels"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { MarketAmountFormControl } from "../../MarketAmountFormControl"
import { FormType } from "../../../models/RuleModels"

interface DiscountSelectorProps {
    type: DiscountType
    formType: FormType
    discountPercentage?: number
    discountAmount?: MarketAmount
    typeChanged: (type: DiscountType, percentage?: number, amount?: MarketAmount) => void
    showDiscountValue?: Boolean
    market?: Market
    markets: string[]
    validation: boolean
}

export type DiscountType = "percentage" | "amount_off" | "new_price"

export function getAmountOffTitle(formType: FormType): string {
    if (formType === "discount") {
        return "Amount Off"
    } else {
        return "Total earnings per item"
    }
}

export function DiscountSelector(props: DiscountSelectorProps) {
    return <Card className="my-4" border="primary">
        <Card.Body>
            <Card.Title>
                {props.formType === "discount" ? "Discount type" : "Point earning type"}
            </Card.Title>
            <Form validated={props.validation}>
                <FormGroup className="mb-3" as={Row} style={{ marginLeft: "0px", marginRight: "0px" }}
                >
                    <Form.Check
                        type="radio"
                        id="discountSelector"
                        label="Percentage"
                        checked={props.type === "percentage"}
                        onChange={() => { props.typeChanged("percentage", props.discountPercentage, props.discountAmount) }}
                    >
                    </Form.Check>
                    <Form.Check
                        type="radio"
                        id="discountSelector"
                        label={getAmountOffTitle(props.formType)}
                        checked={props.type === "amount_off"}
                        onChange={() => { props.typeChanged("amount_off", props.discountPercentage, props.discountAmount) }}
                    >
                    </Form.Check>
                    {props.formType === "discount" ?
                        <Form.Check
                            type="radio"
                            id="discountSelector"
                            label="New price"
                            checked={props.type === "new_price"}
                            onChange={() => { props.typeChanged("new_price", props.discountPercentage, props.discountAmount) }}
                        >
                        </Form.Check>
                        : null}

                    {
                        props.showDiscountValue === true && (
                            props.type === "percentage" ? <div>
                                <br />
                                Reduce price by
                                <DecimalValueFormControl
                                    value={props.discountPercentage !== undefined ? Math.round(props.discountPercentage * 1000) / 10 : null}
                                    onValueChanged={value => {
                                        let scaled: number | undefined = undefined
                                        if (value !== null) {
                                            scaled = value / 100
                                        }
                                        props.typeChanged(props.type, scaled, undefined)
                                    }}
                                    decimals={1}
                                    prefixSymbol="%"
                                    placeholder="Enter discount percentage"
                                />

                            </div>
                                :
                                <div>
                                    <br />
                                    {props.type === "amount_off" ? <span>Reduce price by</span> : <span>Set price to</span>}
                                    <MarketAmountFormControl
                                        allMarkets={props.markets}
                                        amount={props.discountAmount ?? null}
                                        market={props.market ?? null}
                                        onAmountChanged={amount => {
                                            props.typeChanged(props.type, undefined, amount ?? undefined)
                                        }}
                                        placeholder="Enter discount amount"
                                        currency={props.market?.currency ?? ""}
                                    />
                                </div>
                        )
                    }
                </FormGroup>
            </Form>
        </Card.Body >
    </Card >
}

