import * as React from "react"
// import * as scriptjs from "scriptjs"
import { login, resetPassword, signInWithMicrosoft, signInWithApple } from "../helpers/auth"
import { Col, Container, Row } from "react-bootstrap"

function setErrorMsg(error: string | undefined) {
    return {
        loginMessage: error
    }
}

interface LoginState {
    loginMessage?: string
    email?: string
    password?: string
}

export default class Login extends React.Component<any, LoginState> {
    constructor(props: any) {
        super(props)
        this.state = { loginMessage: undefined }
    }

    handleSubmit = (e: any) => {
        e.preventDefault()
        if (!this.state.email || !this.state.password) { return }
        login(this.state.email, this.state.password)
            .catch((error) => {
                this.setState(setErrorMsg("Invalid username/password."))
            })
    }

    resetPassword = async () => {
        if (!this.state.email) { return }
        try {
            await resetPassword(this.state.email)
        } catch (error) {
            this.setState(setErrorMsg(`Password reset email sent to ${this.state.email}.`))
        }
        this.setState(setErrorMsg(`Password reset email sent to ${this.state.email}.`))
    }

    signInWithMicrosoft = async () => {
        signInWithMicrosoft()
            .catch((error) => {
                this.setState(setErrorMsg(`${error}`))
            })
    }

    signInWithApple = async () => {
        signInWithApple()
            .catch((error) => {
                this.setState(setErrorMsg(`${error}`))
            })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>

                        <h1> Sign in </h1>
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    className="form-control"
                                    onChange={(e: any) => { this.setState({ email: e.target.value }) }}
                                    placeholder="Email"
                                    type="email"
                                    id="email"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                />
                            </div>
                            <br />

                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" id="password" placeholder="Password" onChange={(e: any) => { this.setState({ password: e.target.value }) }} />
                            </div>
                            {
                                this.state.loginMessage &&
                                (
                                    <div className="alert alert-danger" role="alert">
                                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" />
                                        <span className="sr-only">Error:</span>
                                        &nbsp;{this.state.loginMessage} <a href="#reset" onClick={this.resetPassword} className="alert-link">Forgot Password?</a>
                                    </div>
                                )
                            }
                            <br />
                            <button type="submit" id="submit" className="btn btn-primary">Sign in</button>
                        </form>
                        <br />
                        --- or ---
                        <br />
                        <br />
                        <button className="btn btn-default" onClick={this.signInWithApple} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px" }}><img alt="Apple logo" src="/backoffice/apple.svg" style={{ height: "44px" }} /> Sign in with Apple&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        <br />
                        <br />
                        <button className="btn btn-default" onClick={this.signInWithMicrosoft} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px", height: "44px" }}><img alt="Microsoft logo" src="/backoffice/microsoft.svg" style={{ height: "20px" }} />&nbsp;&nbsp;&nbsp;Sign in with Microsoft</button>
                        <br />
                        <br />

                    </Col>
                </Row>
            </Container>
        )
    }
}
