export function defaultEmailTemplate(name: string){
    return {
        "lines": [
            {
                "type": "text",
                "text": `Tillykke med dit gavekort til ${name}`,
                "font_size": 16
            },
            {
                "type": "text",
                "text": "Se vedhæftede pdf-fil for detaljer.",
                "font_size": 16
            },
            {
                "type": "text",
                "text": "Fortsat god dag.",
                "font_size": 16
            },
            {
                "type": "text",
                "text": "Udløb og betingelser fremgår af selve gavekortet.",
                "font_size": 12,
                "font_style": "italic",
                "text_color": "gray"
            }
        ],
        "title": `Gavekort fra ${name}`
    }
}
