import * as React from "react"
import { FormattedNumber } from "react-intl"

interface FormattedCurrencyProps {
    currency: string
    value: number
}

export class FormattedCurrency extends React.Component<FormattedCurrencyProps> {
    render() {
        return (
            <span>
                <FormattedNumber useGrouping={true} minimumFractionDigits={2} maximumFractionDigits={2} value={this.props.value} />
                &nbsp;
          {this.props.currency}
            </span>
        )
    }
}
