import React from "react";
import { Draggable } from "react-beautiful-dnd";

interface DraggableWrapperProps {
    index: number;
    component: JSX.Element;
    onClick: () => (void);
}
export function DraggableWrapper(props: DraggableWrapperProps): JSX.Element {
    return <Draggable key={props.index} draggableId={`${props.index}`} index={props.index}>
        {(provided) => (
            <div onClick={() => { props.onClick(); }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                {props.component}
            </div>
        )}
    </Draggable>;
}
