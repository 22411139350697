import * as _ from "lodash"
import * as React from "react"
import * as url from "url"
import {
    Alert,
    Button,
    Col,
    Form,
    FormControl,
    FormGroup,
    Modal
} from "../../wrappers"
import { ExportIntegrationFilterSelection } from "./ExportIntegrationFilterSelection"
import { exportIntegrationName, ExportIntegrationType } from "./ExportIntegrations"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { Row } from "react-bootstrap"
import { child, DatabaseReference, get, set, update } from "firebase/database"

export class ExportIntegrationFilter {
    name: string
    path: string
    type: ExportIntegrationType
    value: any

    constructor(name: string, type: ExportIntegrationType, path: any, value: any) {
        this.name = name
        this.path = path
        this.type = type
        this.value = value
    }

    update() {
        return { [this.path]: this.value }
    }

    static unknownStockExportEvenTypeFilter(eventTypeId: string): ExportIntegrationFilter {
        return new ExportIntegrationFilter(`${eventTypeId} - opt in`, ExportIntegrationType.stockEvents, `event_types/${eventTypeId}`, true)
    }

    static stockCountResetFilter(): ExportIntegrationFilter {
        return new ExportIntegrationFilter("Stock count reset - opt in", ExportIntegrationType.stockEvents, "event_types/stock_count_reset", true)
    }

    static skipVoidedSalesFilter(): ExportIntegrationFilter {
        return new ExportIntegrationFilter("Skip voided sales", ExportIntegrationType.sales, "skip_voided", true)
    }

    static skipReturnsFilter(): ExportIntegrationFilter {
        return new ExportIntegrationFilter("Skip returns", ExportIntegrationType.sales, "skip_return", true)
    }

    static ecomOnlyFilter(): ExportIntegrationFilter {
        return new ExportIntegrationFilter("Skip sales without e-com lines", ExportIntegrationType.sales, "ecom_only", true)
    }

    static unknownSalesExportFilter(filterName: string): ExportIntegrationFilter {
        return new ExportIntegrationFilter(`${filterName} - opt in`, ExportIntegrationType.sales, `${filterName}`, true)
    }

    static stockExportFiltersFromJSON(json: any): _.Dictionary<ExportIntegrationFilter> {
        const result: _.Dictionary<ExportIntegrationFilter> = {}
        for (const key in json) {
            const value = json[key]
            switch (key) {
                case "event_types": {
                    for (const eventType in value) {
                        switch (eventType) {
                            case "stock_count_reset": {
                                const resetFilter = ExportIntegrationFilter.stockCountResetFilter()
                                result[resetFilter.path] = resetFilter
                                break
                            }

                            default: {
                                const unknownEventTypeFilter = ExportIntegrationFilter.unknownStockExportEvenTypeFilter(eventType)
                                result[unknownEventTypeFilter.path] = unknownEventTypeFilter
                                break

                            }
                        }
                    }
                    break
                }

                default:
                    break
            }
        }
        return result
    }

    static saleFiltersFromJSON(json: any): _.Dictionary<ExportIntegrationFilter> {
        const result: _.Dictionary<ExportIntegrationFilter> = {}
        for (const key in json) {
            const value = json[key]
            if (value !== true) { continue }
            switch (key) {
                case "skip_voided": {
                    const filter = ExportIntegrationFilter.skipVoidedSalesFilter()
                    result[filter.path] = filter
                    break
                }
                case "skip_return": {
                    const filter = ExportIntegrationFilter.skipReturnsFilter()
                    result[filter.path] = filter
                    break
                }
                case "ecom_only": {
                    const filter = ExportIntegrationFilter.ecomOnlyFilter()
                    result[filter.path] = filter
                    break
                }
                default: {
                    const filter = ExportIntegrationFilter.unknownSalesExportFilter(key)
                    result[filter.path] = filter
                    break
                }
            }
        }
        return result
    }

    static filtersFromJSON(json: any, type: ExportIntegrationType): _.Dictionary<ExportIntegrationFilter> | undefined {
        switch (type) {
            case ExportIntegrationType.stockEvents:
                return this.stockExportFiltersFromJSON(json)

            case ExportIntegrationType.sales:
                return this.saleFiltersFromJSON(json)

            default:
                return undefined
        }
    }

    static updatesFromFilters(filters: _.Dictionary<ExportIntegrationFilter>): _.Dictionary<any> {
        let result: _.Dictionary<any> = {}
        for (const key in filters) {
            const filter = filters[key]
            const update = filter.update()
            result = { ...result, ...update }
        }
        return result
    }
}

interface ExportIntegrationEditProps {
    role: Role
    integrationType: ExportIntegrationType
    editComplete: () => (void)
    existingIntegration?: any
    existingIntegrationId?: string
    availableKnownFilters?: _.Dictionary<ExportIntegrationFilter>
}

interface ExportIntegrationEditState {
    addIntegrationName?: string
    addIntegrationId?: string
    addIntegrationEndpoint?: string
    addIntegrationHeaders?: any
    addIntegrationHeaderName?: string
    addIntegrationHeaderValue?: string
    addAnotherHeader?: boolean
    errorDescription?: string
    isNew: boolean
    availableFilters?: _.Dictionary<ExportIntegrationFilter>
    selectedFilters?: _.Dictionary<ExportIntegrationFilter>
}

export class ExportIntegrationEdit extends React.Component<ExportIntegrationEditProps, ExportIntegrationEditState> {
    constructor(props: ExportIntegrationEditProps) {
        super(props)

        let isNew: boolean
        if (this.props.existingIntegration === undefined) {
            isNew = true
        } else {
            isNew = false
        }
        const integration = this.props.existingIntegration || {}

        let selectedFilters: _.Dictionary<ExportIntegrationFilter> | undefined = undefined
        if (!_.isNil(integration.filters)) {
            selectedFilters = ExportIntegrationFilter.filtersFromJSON(integration.filters, this.props.integrationType)
        }
        let availableFilters: _.Dictionary<ExportIntegrationFilter> | undefined = undefined
        if (!_.isNil(props.availableKnownFilters)) {
            availableFilters = props.availableKnownFilters
        }
        if (!_.isNil(selectedFilters)) {
            availableFilters = { ...availableFilters, ...selectedFilters }
        }

        this.state = {
            addIntegrationName: integration.name,
            addIntegrationId: this.props.existingIntegrationId,
            addIntegrationEndpoint: ((integration.delivery || {}).parameters || {}).url,
            addIntegrationHeaders: ((integration.delivery || {}).parameters || {}).headers,
            isNew: isNew,
            availableFilters: availableFilters,
            selectedFilters: selectedFilters
        }
    }

    integrationRef(): DatabaseReference {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        return child(accountRef, `configuration/export_integrations/${this.props.integrationType}`)
    }

    addIntegrationEnabled(): boolean {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        const endpoint = this.state.addIntegrationEndpoint
        for (const param of [id, name, endpoint]) {
            if (param === undefined || param.length === 0) {
                return false
            }
        }
        try {
            const endpointOrEmpty = endpoint ?? ""
            const parsedURL = new URL(endpointOrEmpty)
            if ((parsedURL.host ?? "").length === 0) { return false }
            if (parsedURL.protocol !== "https:" && parsedURL.protocol !== "http:") {
                return false
            }
            if (!endpointOrEmpty.startsWith("http://") && !endpointOrEmpty.startsWith("https://")) {
                return false
            }
            return true
        } catch {
            return false
        }
    }

    async addIntegration() {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        const integrationType = this.props.integrationType
        const endpoint = this.state.addIntegrationEndpoint
        if (id === undefined) { return }
        if (name === undefined) { return }
        if (endpoint === undefined) { return }
        this.setState({ errorDescription: undefined })
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)

        const existingIntegrationRef = child(accountRef, `configuration/export_integrations/${integrationType}/${id}`)
        if (this.state.isNew) {
            if ((await get(existingIntegrationRef)).exists()) {
                const errorDescription = `Integration with type ${integrationType} and id ${id} already exists`
                console.error(errorDescription)
                this.setState({ errorDescription: errorDescription })
                return
            }
        }

        const headers = this.state.addIntegrationHeaders || {}

        await set(existingIntegrationRef, {
            name: name,
            transformation: { type: "identity" },
            trigger: { type: "realtime" },
            delivery: { type: "web_hook", parameters: { url: endpoint, method: "POST", headers: headers } }
        })

        if (!_.isNil(this.state.selectedFilters)) {
            const filterUpdates: _.Dictionary<any> = ExportIntegrationFilter.updatesFromFilters(this.state.selectedFilters)
            await update(child(existingIntegrationRef, `filters`), filterUpdates)
        }

        this.setState({ addIntegrationName: undefined, addIntegrationEndpoint: undefined, addIntegrationId: undefined, addIntegrationHeaders: undefined })
        this.props.editComplete()
    }

    render() {
        return (
            <Modal size="lg" show={true} onHide={() => { this.props.editComplete() }} >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.isNew ? "Add new web hook" : "Edit web hook"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errorDescription ? <Alert variant="warning">{this.state.errorDescription}</Alert> : null}
                    Please configure the {exportIntegrationName(this.props.integrationType).toLowerCase()} export integration.
                    <br /><br />
                    <Form onSubmit={e => e.preventDefault()}>
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>Integration name</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="integrationname"
                                    value={this.state.addIntegrationName || ""}
                                    placeholder="Enter integration name"
                                    onChange={(event: any) => {
                                        this.setState({ addIntegrationName: event.target.value })
                                    }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                        <ValidatingIdEntryControl
                            collectionRef={this.integrationRef()}
                            isNew={this.state.isNew}
                            typeName="export integration"
                            identifierSource={this.state.addIntegrationName || ""}
                            existingIdentifier={this.props.existingIntegrationId || ""}
                            handleIdChange={(id, valid) => {
                                this.setState({ addIntegrationId: id })
                            }}
                        />

                        {
                            !_.isNil(this.state.availableFilters)
                                ?
                                (
                                    <ExportIntegrationFilterSelection
                                        availableFilters={this.state.availableFilters}
                                        selectedFilters={this.state.selectedFilters || {}}
                                        onChange={(filters) => {
                                            this.setState({ selectedFilters: filters })
                                        }}
                                    />
                                )
                                :
                                null
                        }

                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>Endpoint</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="endpoint"
                                    value={this.state.addIntegrationEndpoint || ""}
                                    placeholder="Enter webhook endpoint (an http/https URL)"
                                    onChange={(event: any) => {
                                        this.setState({ addIntegrationEndpoint: event.target.value })
                                    }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>

                        {
                            this.state.addIntegrationHeaders ? (
                                <FormGroup className="mb-3" as={Row}>
                                    <Col sm={2}>HTTP request headers</Col>
                                    <Col sm={10}>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Header name</th>
                                                    <th>Header value</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(this.state.addIntegrationHeaders || {}).map((headerName: string) => {
                                                        const value = (this.state.addIntegrationHeaders || {})[headerName] || ""

                                                        return (
                                                            <tr
                                                                key={headerName}
                                                                onClick={() => {
                                                                    if (this.state.addAnotherHeader) {
                                                                        // Already editing
                                                                        return
                                                                    }
                                                                    const headers = this.state.addIntegrationHeaders || {}
                                                                    delete headers[headerName]
                                                                    this.setState({
                                                                        addIntegrationHeaders: headers,
                                                                        addAnotherHeader: true,
                                                                        addIntegrationHeaderName: headerName,
                                                                        addIntegrationHeaderValue: value
                                                                    })
                                                                }}
                                                            >
                                                                <td>{headerName}</td>
                                                                <td>{value}</td>
                                                                <td className="narrow">
                                                                    <Button
                                                                        variant="danger"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation()
                                                                            const headers = this.state.addIntegrationHeaders || {}
                                                                            delete headers[headerName]
                                                                            this.setState({ addIntegrationHeaders: headers })
                                                                        }}
                                                                    >X
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    </Col>
                                </FormGroup>
                            ) : null
                        }

                        {
                            this.state.addAnotherHeader ? (
                                <div>
                                    <FormGroup className="mb-3" as={Row}>
                                        <Col sm={2}>Header name</Col>
                                        <Col sm={10}>
                                            <FormControl
                                                type="text"
                                                name="headername"
                                                value={this.state.addIntegrationHeaderName || ""}
                                                placeholder="Enter header name"
                                                onChange={(event: any) => {
                                                    this.setState({ addIntegrationHeaderName: event.target.value })
                                                }}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-3" as={Row}>
                                        <Col sm={2}>Header value</Col>
                                        <Col sm={10}>
                                            <FormControl
                                                type="text"
                                                name="headervalue"
                                                value={this.state.addIntegrationHeaderValue || ""}
                                                placeholder="Enter header value"
                                                onChange={(event: any) => {
                                                    this.setState({ addIntegrationHeaderValue: event.target.value })
                                                }}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-3" as={Row}>
                                        <Col sm={2}>&nbsp;</Col>
                                        <Col sm={10}>
                                            <Button
                                                onClick={() => {
                                                    let name = this.state.addIntegrationHeaderName
                                                    const value = this.state.addIntegrationHeaderValue
                                                    if (name === undefined || name.length === 0 || value === undefined || value.length === 0) {
                                                        return
                                                    }
                                                    const headers = this.state.addIntegrationHeaders || {}
                                                    name = _.trim(name, ":")
                                                    headers[name] = value
                                                    this.setState({
                                                        addAnotherHeader: undefined,
                                                        addIntegrationHeaders: headers,
                                                        addIntegrationHeaderName: undefined,
                                                        addIntegrationHeaderValue: undefined
                                                    })
                                                }}
                                            >
                                                Done
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </div>
                            ) : (
                                <FormGroup className="mb-3" as={Row}>
                                    <Col sm={2}>&nbsp;</Col>
                                    <Col sm={10}>
                                        <Button onClick={() => { this.setState({ addAnotherHeader: true }) }}>Add HTTP header</Button>
                                    </Col>
                                </FormGroup>
                            )
                        }

                    </Form>
                    <Button variant="success" onClick={async () => { await this.addIntegration() }} disabled={!this.addIntegrationEnabled()}>{this.state.isNew ? "Add web hook" : "Update web hook"}</Button>

                </Modal.Body>
            </Modal>
        )
    }
}
