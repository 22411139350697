//
// Orders a list of strings according to the description of how the Firebase Query orderByKey method does it.
// See https://firebase.google.com/docs/database/web/lists-of-data#sort_data for reference
//
// The gist is:
// When using orderByKey() to sort your data, data is returned in ascending order by key.
//  1. Children with a key that can be parsed as a 32-bit integer come first, sorted in ascending order.
//  2. Children with a string value as their key come next, sorted lexicographically in ascending order.
//
// The first bullet is not the complete truth. If two children have a key that can be parsed as the SAME int,
// then the string length apparently decides the sort order. In other words, "01" comes after "1" but before "2"
// The following items: 1, 00, 2, 01, 001, 0000000, sko, -sko, 0, 10 should sort as:
// 0, 00, 0000000, 1, 01, 001, 2, 10, -sko, sko

// See https://github.com/firebase/firebase-js-sdk/blob/84c1583c1cc52fbbc32da76d157205f64c7c960f/packages/database/src/core/util/util.ts nameCompare function
// for the actual js-implementation.

/**
 * Used to test for integer-looking strings
 * @type {RegExp}
 * @private
 */
const INTEGER_REGEXP_ = new RegExp("^-?(0*)\\d{1,10}$")

/**
 * If the string contains a 32-bit integer, return it.  Else return null.
 * @param {!string} str
 * @return {?number}
 */
const tryParseInt = function (str: string): number | null {
    if (INTEGER_REGEXP_.test(str)) {
        const intVal = Number(str)
        if (intVal >= -2147483648 && intVal <= 2147483647) {
            return intVal
        }
    }
    return null
}

export function sortLikeFirebaseOrderByKey(list: string[]): string[] {

    return list.sort((a, b) => {
        if (a === b) {
            return 0
        } else {
            const aAsInt = tryParseInt(a)
            const bAsInt = tryParseInt(b)

            if (aAsInt !== null) {
                if (bAsInt !== null) {
                    return aAsInt - bAsInt === 0 ? a.length - b.length : aAsInt - bAsInt
                } else {
                    return -1
                }
            } else if (bAsInt !== null) {
                return 1
            } else {
                return a < b ? -1 : 1
            }
        }
    })
}