import { AppliesTo } from "../AppliesToSelector";
import { CustomerCondition } from "../CustomerConditionSelector";
import { DiscountType } from "../DiscountSelector";
import { RequirementsType } from "../MinimumRequirementsSelector";
import { RowData } from "../StairStep";
import * as _ from "lodash";
import { CouponValues, DateComponents } from "../../../../models/RuleModels";
import { L10nString } from "../../../../helpers/L10n";
import { MarketAmount } from "../../../../models/MarketAmount";
import { validateCoupon } from "../Shared/Helpers";
import { DiscountOverallType } from "../RuleEdit";

interface Discount {
    type: DiscountType;
    value?: number | MarketAmount;
}
function parseDiscountType(value: any): Discount {
    if (value === undefined) {
        return { type: "percentage" };
    }
    if (!_.isNil(value.percentage)) {
        return { type: "percentage", value: value.percentage };
    } else if (!_.isNil(value.amount_off)) {
        return { type: "amount_off", value: new MarketAmount(value.amount_off) };
    } else if (!_.isNil(value.new_price)) {
        return { type: "new_price", value: new MarketAmount(value.new_price) };
    }
    return { type: "percentage" };
}
function parseAppliesTo(value: any): AppliesTo {
    if (value === undefined) {
        return { type: "all", products: [], tags: [], attributes: [] };
    }
    if (!_.isNil(value.all)) {
        return { type: "all", products: [], tags: [], attributes: [] };
    } else if (!_.isNil(value.basket)) {
        return { type: "basket", products: [], tags: [], attributes: [] };
    } else if (!_.isNil(value.products)) {
        return { type: "products", products: value.products, tags: [], attributes: [] };
    } else if (!_.isNil(value.tags)) {
        return { type: "tags", products: [], tags: value.tags, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", products: [], tags: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }
    return { type: "all", products: [], tags: [], attributes: [] };
}
function parseCustomerCondition(value: any): CustomerCondition {
    if (value === undefined) {
        return { type: "all", customers: [], attributes: [] };
    }
    if (!_.isNil(value.all)) {
        return { type: "all", customers: [], attributes: [] };
    } else if (!_.isNil(value.members)) {
        return { type: "members", customers: [], attributes: [] };
    } else if (!_.isNil(value.customers)) {
        return { type: "customer_selection", customers: value.customers, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", customers: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }

    return { type: "all", customers: [], attributes: [] };
}
interface Condition {
    type: RequirementsType;
    value: number | MarketAmount;
}
function parseCondition(value: any): Condition {
    if (value === undefined) {
        return { type: "count", value: 1 };
    }
    if (!_.isNil(value.count)) {
        return { type: "count", value: value.count };
    } else if (!_.isNil(value.amount)) {
        return { type: "amount", value: new MarketAmount(value.amount) };
    }
    return { type: "count", value: 1 };
}
interface Steps {
    requirementsType: RequirementsType;
    steps: RowData[];
    discountType: DiscountType;
}
function parseSteps(value: any): Steps {
    if (value === undefined) {
        return { requirementsType: "none", steps: [{ id: 0 }], discountType: "percentage" };
    }

    if (_.isNil(value.steps) && !_.isNil(value.discount)) {
        // No steps, but a 'discount'
        const discount = parseDiscountType(value.discount);
        const step: RowData = { id: 0 };
        if (discount.type === "percentage") {
            step.discountPercentage = discount.value as number;
        } else {
            step.discountAmount = discount.value as MarketAmount;
        }
        return { requirementsType: "none", steps: [step], discountType: discount.type };
    } else if (!_.isNil(value.steps)) {
        if (value.steps.length > 0) {
            const first = value.steps[0];
            const discount = parseDiscountType(first.discount);
            const condition = parseCondition(first.condition);

            let index = 0;
            const steps: RowData[] = value.steps.map((step: any) => {
                const row: RowData = {
                    id: index++,
                };
                const condition = parseCondition(step.condition);
                const discount = parseDiscountType(step.discount);
                if (condition.type === "amount") {
                    row.amount = condition.value as MarketAmount;
                } else if (condition.type === "count") {
                    row.count = condition.value as number;
                }
                if (discount.type === "percentage") {
                    row.discountPercentage = discount.value as number;
                } else {
                    row.discountAmount = discount.value as MarketAmount;
                }
                return row;
            });

            return {
                requirementsType: condition.type,
                steps: steps,
                discountType: discount.type
            };
        }
    }

    return { requirementsType: "none", steps: [{ id: 0 }], discountType: "percentage" };
}

export class CustomizableTemplate {
    discountType: DiscountType;
    appliesTo: AppliesTo;
    customerCondition: CustomerCondition;
    requirementsType: RequirementsType;
    steps: RowData[];
    priority?: number;
    continue_evaluation?: boolean;
    start_date?: DateComponents;
    end_date?: DateComponents;
    display_name?: L10nString;
    shop_ids?: string[]
    type: DiscountOverallType
    coupon?: CouponValues

    constructor(json: any) {
        if (!_.isNil(json.display_name)) {
            this.display_name = new L10nString(json.display_name);
        }
        this.appliesTo = parseAppliesTo(json.applies_to);
        this.customerCondition = parseCustomerCondition(json.customer_condition);
        const steps = parseSteps(json);
        this.discountType = steps.discountType;
        this.requirementsType = steps.requirementsType;
        this.steps = steps.steps;
        this.priority = !_.isNil(json.priority) ? json.priority : undefined;
        if (json.continue_evaluation === true) {
            this.continue_evaluation = true;
        }
        if (!_.isNil(json.start_date)) {
            this.start_date = json.start_date;
        }
        if (!_.isNil(json.end_date)) {
            this.end_date = json.end_date;
        }
        if (!_.isNil(json.shop_ids)) {
            this.shop_ids = json.shop_ids
        }
        if (!_.isNil(json.coupon)) {
            this.coupon = CouponValues.fromJSON(json.coupon)
        }
        if (!_.isNil(json.type)) {
            this.type = json.type
        } else {
            // Backwards compatibility
            this.type = (json.is_coupon === true) ? "coupon" : "discount"            
        }
    }

    setMarkets(allMarkets: string[]): void {
        for (const index in this.steps) {
            let step = this.steps[index];
            step.amount?.setMarkets(allMarkets);
            step.discountAmount?.setMarkets(allMarkets);
        }
    }
    json(): any {
        const value: any = {
            display_name: this.display_name?.json() ?? new L10nString("-"),
            name: this.display_name?.localized(null) ?? "-",
            priority: this.priority ?? 0,
            applies_to: this.appliesToJson(),
            customer_condition: this.customerConditionsJson(),
            type: this.type
        };
        if (this.requirementsType === "none") {
            if (this.steps.length === 0) {
                return undefined;
            }
            const step = this.steps[0];
            const discount = {
                [this.discountType]: (this.discountType === "percentage") ? (step.discountPercentage ?? 0) : (step.discountAmount ?? new MarketAmount(0)).json()
            };
            value.discount = discount;
        } else {
            value.steps = this.stepsJson();
        }
        if (this.continue_evaluation === true) {
            value.continue_evaluation = true;
        }
        if (!_.isNil(this.start_date)) {
            value.start_date = this.start_date;
        }
        if (!_.isNil(this.end_date)) {
            value.end_date = this.end_date;
        }
        if (!_.isNil(this.shop_ids) && this.shop_ids.length > 0) {
            value.shop_ids = this.shop_ids
        }
        if (!_.isNil(this.coupon)) {
            value.coupon = this.coupon.toJSON()
        }

        return value
    }

    appliesToJson(): any {
        if (this.appliesTo.type === "all") {
            return { all: true };
        } else if (this.appliesTo.type === "basket") {
            return { basket: true };
        } else if (this.appliesTo.type === "products") {
            return { products: this.appliesTo.products };
        } else if (this.appliesTo.type === "tags") {
            return { tags: this.appliesTo.tags };
        } else if (this.appliesTo.type === "attributes") {
            return { attributes: this.appliesTo.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    stepsJson(): any {
        if (this.requirementsType === "none") {
            return null;
        }
        return this.steps
            .sort((a, b) => {
                if ((a.count ?? 0) === (b.count ?? 0)) {
                    const aAmount = a.amount ?? new MarketAmount(0);
                    const bAmount = b.amount ?? new MarketAmount(0);
                    return (aAmount.amount(null) ?? 0) - (bAmount.amount(null) ?? 0);
                }
                return (a.count ?? 0) - (b.count ?? 0);
            })
            .map((step) => {
                const discount = {
                    [this.discountType]: (this.discountType === "percentage") ? (step.discountPercentage ?? 0) : (step.discountAmount ?? new MarketAmount(0)).json()
                };
                const condition = {
                    [this.requirementsType]: (this.requirementsType === "amount") ? (step.amount ?? new MarketAmount(0)).json() : (step.count ?? 0)
                };
                return {
                    condition: condition,
                    discount: discount
                };
            });
    }

    customerConditionsJson(): any {
        if (this.customerCondition.type === "all") {
            return { all: true };
        } else if (this.customerCondition.type === "members") {
            return { members: true };
        } else if (this.customerCondition.type === "customer_selection") {
            return { customers: this.customerCondition.customers };
        } else if (this.customerCondition.type === "attributes") {
            return { attributes: this.customerCondition.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    valid(selectedMarkets: string[]): boolean {
        return this.validateAppliesTo() &&
            this.validateCustomerCondition() &&
            this.validateSteps() &&
            this.validateShared() && 
            validateCoupon(this.type === "coupon", this.coupon)
    }

    validateAppliesTo(): boolean {
        if (this.appliesTo.type === "products") {
            return this.appliesTo.products.length !== 0;
        } else if (this.appliesTo.type === "tags") {
            return this.appliesTo.tags.length !== 0;
        } else if (this.appliesTo.type === "attributes") {
            return this.appliesTo.attributes.length !== 0;
        }
        return true;
    }

    validateCustomerCondition(): boolean {
        if (this.customerCondition.type === "attributes") {
            return this.customerCondition.attributes.length !== 0;
        } else if (this.customerCondition.type === "customer_selection") {
            return this.customerCondition.customers.length !== 0;
        }
        return true;
    }

    validateShared(): boolean {
        if (_.isNil(this.display_name)) {
            return false;
        }
        if (_.isNil(this.priority)) {
            return false;
        }

        return true;
    }

    validateSteps(): boolean {
        if (this.steps.length === 0) {
            return false;
        }
        for (const step of this.steps) {
            if (this.requirementsType === "count") {
                if (_.isNil(step.count)) {
                    return false;
                }
            } else if (this.requirementsType === "amount") {
                if (_.isNil(step.amount)) {
                    return false;
                }
            }
            if (this.discountType === "percentage") {
                if (_.isNil(step.discountPercentage)) {
                    return false;
                }
            } else {
                if (_.isNil(step.discountAmount)) {
                    return false;
                }
            }
        }
        return true;
    }
}
