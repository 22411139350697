import React from "react"
import { Form, Card } from "react-bootstrap"

interface MinimumRequirementsSelectorProps {
    type: RequirementsType
    typeChanged: (type: RequirementsType) => void
}

export type RequirementsType = "none" | "count" | "amount"

export function MinimumRequirementsSelector(props: MinimumRequirementsSelectorProps) {
    return <Card className="my-4" border="primary">
        <Card.Body>
            <Card.Title>
                Minimum requirements
            </Card.Title>
            <Form.Check
                type="radio"
                id="requirementsSelector"
                label="No requirements"
                checked={props.type === "none"}
                onChange={() => { props.typeChanged("none") }}
            >
            </Form.Check>
            <Form.Check
                type="radio"
                id="requirementsSelector"
                label="Minimum quantity of items"
                checked={props.type === "count"}
                onChange={() => { props.typeChanged("count") }}
            >
            </Form.Check>
            <Form.Check
                type="radio"
                id="requirementsSelector"
                label="Minimum basket amount"
                checked={props.type === "amount"}
                onChange={() => { props.typeChanged("amount") }}
            >

            </Form.Check>
        </Card.Body>
    </Card>
}

