import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    Button,
    Col,
    
    FormControlStatic,
    FormGroup,
    Card,
    Row
} from "../../wrappers"
import { EcomIntegration } from "../../../models/EcomIntegration"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { child, DatabaseReference, get, remove, set } from "firebase/database"

interface EcommerceConfigurationProps {
    role: Role
}

interface EcommerceConfigurationState {
    dirty: boolean
    ecomIntegration?: EcomIntegration
    loaded: boolean
    moreThanOneIntegrations: boolean
    publishing: boolean
    showSuccess: boolean
}

export class EcommerceConfiguration extends React.Component<EcommerceConfigurationProps, EcommerceConfigurationState> {

    constructor(props: EcommerceConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            moreThanOneIntegrations: false,
            publishing: false,
            showSuccess: false
        }
    }

    // Helpers

    ecomIntegrationsRef = (): DatabaseReference => {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/ecom_integrations`)
    }

    stockLocationIndexRef = (): DatabaseReference => {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}/stock_location_index`)
    }

    createAndPublishDefaultConfiguration = async () => {
        this.setState({ publishing: true })

        const stockLocationIndexEntryRef = child(this.stockLocationIndexRef(), "ecom")
        const stockLocationIndexEntrySnap = await get(stockLocationIndexEntryRef)
        if (!stockLocationIndexEntrySnap.exists()) {
            await set(stockLocationIndexEntryRef, {
                type: "ecom"
            })
        }

        const defaultConfiguration: EcomIntegration = {
            id: "default",
            name: "Online",
            stock_location: "ecom"
        }

        this.setState({ ecomIntegration: defaultConfiguration }, async () => {
            if (_.isNil(this.state.ecomIntegration)) {
                return
            }

            const configRef = child(this.ecomIntegrationsRef(), `${this.state.ecomIntegration.id}`)

            this.setState({ publishing: true })

            await set(configRef, this.state.ecomIntegration)

            this.setState({ showSuccess: true, publishing: false, dirty: false })
        })
    }

    removeEcomConfiguration = async () => {
        if (_.isNil(this.state.ecomIntegration)) {
            return
        }

        const reply = window.confirm(`Really delete ecommerce configuration?`)
        if (reply === true) {
            const configRef = child(this.ecomIntegrationsRef(), `${this.state.ecomIntegration.id}`)

            this.setState({ publishing: true })

            await remove(configRef)

            this.setState({ showSuccess: true, publishing: false, dirty: false, ecomIntegration: undefined })
        }
    }

    // Component

    async componentDidMount() {
        const configSnap = await get(this.ecomIntegrationsRef())

        const state: any = {
            loaded: true
        }
        if (configSnap.exists()) {
            const config = configSnap.val()
            const keys = Object.keys(config)
            state.ecomIntegration = config[keys[0]]
            if (keys.length > 1) {
                state.moreThanOneIntegrations = true
            }
        }

        this.setState(state)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="ecommerce configuration">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"} >
                    <Card.Header>
                        {this.state.showSuccess ? "Ecommerce configuration published successsfully" : "Ecommerce configuration"}
                    </Card.Header>
                    <Card.Body>
                        {
                            this.state.moreThanOneIntegrations
                                ?
                                <Alert variant="warning">You have more than 1 ecommerce configuration in the database. Editing more than 1 configuration is not supported in the Back Office. Please reach out to Ka-ching support if you need to change your ecommerce configuration.</Alert>
                                :
                                null
                        }
                        {
                            !this.state.moreThanOneIntegrations && _.isNil(this.state.ecomIntegration)
                                ?
                                <p>Ecommerce not configured. Click setup the ecommerce stock location on your account.</p>
                                :
                                null
                        }
                        {
                            !this.state.moreThanOneIntegrations && !_.isNil(this.state.ecomIntegration)
                                ?
                                (
                                    <div key="a">
                                        <FormGroup className="mb-3" as={Row}>
                                            <Row>
                                                <Col sm={2}>Name</Col>
                                                <Col sm={10}>
                                                    <FormControlStatic>{this.state.ecomIntegration!.name}</FormControlStatic>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={2}>Identifier</Col>
                                                <Col sm={10}>
                                                    <FormControlStatic>{this.state.ecomIntegration!.stock_location}</FormControlStatic>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </div>
                                )
                                :
                                null
                        }
                    </Card.Body>
                    <Card.Footer>
                        {
                            !this.state.moreThanOneIntegrations
                                ?
                                (
                                    _.isNil(this.state.ecomIntegration)
                                        ?
                                        <Button onClick={this.createAndPublishDefaultConfiguration}>Create</Button>
                                        :
                                        <Button onClick={this.removeEcomConfiguration} variant={"danger"}>Remove</Button>
                                )
                                :
                                null
                        }
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }
}
