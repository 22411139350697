import * as React from "react"
import { Button, Col, Form, FormGroup, Modal, Table } from "../wrappers"
import * as _ from "lodash"
import { Role } from "../../config/role"
import ImageDropControl from "../ImageDropControl"
import { ProductAsset, imageAssetMimeTypeFromFile } from "../../models/ProductAsset"
import { Row } from "react-bootstrap"
import { StripedTable } from "../StripedTable"

const pushid = require("pushid")

interface ProductGalleryEditProps {
    assets: () => ProductAsset[]
    role: Role
    productId: string
    closeDialog: () => any
    updateState: (update: any) => any
}

export class ProductGalleryEdit extends React.Component<ProductGalleryEditProps, any> {

    // BG: Couldn't find a way to reset the dropzone component.
    // Incremented on succesfull image upload to make react think that we have a new dropzone component.
    dropzoneComponentKey = 1

    closeDialog = () => {
        this.props.closeDialog()
    }

    addImageAsset(url: string, file: any) {
        const mimeType = imageAssetMimeTypeFromFile(file)
        if (_.isNil(url) || _.isNil(mimeType)) {
            return
        }

        const asset = new ProductAsset(url, mimeType)

        const assets = _.cloneDeep(this.props.assets())
        assets.push(asset)

        this.dropzoneComponentKey += 1
        this.props.updateState(assets)
    }

    removeImageAsset(index: number) {
        const assets = _.cloneDeep(this.props.assets())
        assets.splice(index, 1)
        this.props.updateState(assets)
    }

    // React component

    render() {
        return (
            <Modal size="lg" show={true}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit image assets</span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={(e: any) => e.preventDefault()}>
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={12}>
                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.assets().filter((asset) => { return asset.isImageAsset() }).map((asset: ProductAsset, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ "verticalAlign": "middle" }}>
                                                            <img key="img" src={asset.url} alt="" height="150" />
                                                        </td>
                                                        <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                            <Button
                                                                variant="danger"
                                                                key={index}
                                                                onClick={(event: any) => {
                                                                    this.removeImageAsset(index)
                                                                    event.stopPropagation()
                                                                }}
                                                            >
                                                                X
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </StripedTable>
                            </Col>
                            <ImageDropControl
                                key={this.dropzoneComponentKey}
                                fileName={pushid()}
                                filePath={this.props.role.account_id + "/public/products/" + this.props.productId + "/assets/"}
                                dropTitle="Add new image (Max size: 2048×2048)"
                                validateSize={(width: number, height: number) => {
                                    return width <= 2048 && height <= 2048
                                }}
                                isPublic={true}
                                deleteAction={() => { /* dummy */ }}
                                uploadAction={(url, file) => { this.addImageAsset(url, file) }}
                            />

                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeDialog}>Done</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}
