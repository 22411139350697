import * as React from "react"
import { Form, InputGroup } from "react-bootstrap"
import { MarketAmount } from "../models/MarketAmount"
import { isNil } from "lodash"
import { Market } from "../models/MarketModels"
import { injectIntl, WrappedComponentProps } from "react-intl"
import * as _ from "lodash"

interface MarketAmountFormControlProps {
    market: Market | null
    amount: MarketAmount | null
    allMarkets: string[]
    onAmountChanged: (amount: MarketAmount | null) => void
    type?: any
    as?: any
    placeholder?: string
    placeholderAmount?: MarketAmount
    currency: string
    children?: React.ReactNode
}

interface MarketAmountFormControlState {
    amount: MarketAmount | null
    focus: boolean
    textValue: string
}

class MarketAmountFormControlWrapped extends React.Component<MarketAmountFormControlProps & WrappedComponentProps, MarketAmountFormControlState> {
    constructor(props: MarketAmountFormControlProps & WrappedComponentProps) {
        super(props)
        const amount = props.amount
        amount?.setMarkets(props.allMarkets)
        const newMarketId = props.market?.id ?? null
        const newAmount = (!_.isNil(amount) && amount.hasAmountFor(newMarketId)) ? amount.amount(newMarketId) : null

        this.state = {
            amount: amount,
            focus: false,
            textValue: !_.isNil(newAmount) ? `${newAmount}` : ""
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: MarketAmountFormControlProps) {
        const amount = nextProps.amount
        // Override textValue when market or amount changes
        const newMarketId = nextProps.market?.id ?? null
        const currentMarketId = this.props.market?.id ?? null

        amount?.setMarkets(nextProps.allMarkets)
        const state: any = { amount: amount, market: nextProps.market }

        const newAmount = (!_.isNil(amount) && amount.hasAmountFor(newMarketId)) ? amount.amount(newMarketId) : null
        const currentAmount = (!_.isNil(this.state.amount) && this.state.amount.hasAmountFor(currentMarketId)) ? this.state.amount.amount(currentMarketId) : null
        if (newMarketId !== currentMarketId || newAmount !== currentAmount) {
            state.textValue = !_.isNil(newAmount) ? `${newAmount}` : ""
        }

        this.setState(state)
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.value as string
        value = value.replace(/[^0-9.,]/g, "")
        value = value.replace(/^([^.,]*[.,])(.*)$/, function (a, b, c) {
            return b + c.replace(/[.,]/g, "")
        })

        this.setState({ textValue: value })

        value = value.replace(/,/g, ".")

        this.updateAmount(value)
    }

    private displayedCurrency(): string {
        if (this.props.currency !== "") {
            return this.props.currency
        }
        return "Please select a market"
    }

    private updateAmount(value: string) {
        const marketId = this.props.market?.id ?? null
        const numberValue = value === "" ? null : Number(value)
        const amount = this.state.amount
        let newAmount: MarketAmount | null = null
        if (!_.isNil(amount)) {
            newAmount = amount.setAmount(numberValue, marketId)
        } else if (!_.isNil(numberValue)) {
            newAmount = MarketAmount.create(numberValue, marketId)
        }
        this.setState({ amount: newAmount }, () => {
            this.props.onAmountChanged(newAmount)
        })
    }

    placeholder(): string {
        if (this.props.placeholderAmount) {
            const marketId = this.props.market?.id ?? null
            const amount = this.props.placeholderAmount
            if (!_.isNil(amount) && amount.hasAmountFor(marketId)) {
                const number = amount.amount(marketId)
                if (!_.isNil(number)) {
                    return this.props.intl.formatNumber(number, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            }
        }
        if (this.props.placeholder) {
            if (isNil(this.props.market)) {
                return this.props.placeholder
            } else {
                return this.props.placeholder + ` for market: ${this.props.market.name}`
            }
        }
        return ""
    }

    amountString(): string {
        if (this.state.focus) {
            return this.state.textValue
        }
        const marketId = this.props.market?.id ?? null
        if (!_.isNil(this.state.amount)) {
            if (this.state.amount.hasAmountFor(marketId)) {
                const number = this.state.amount.amount(marketId)
                if (!_.isNil(number)) {
                    return this.props.intl.formatNumber(number, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            }
        }
        return ""
    }

    onBlur() {

        this.setState({ focus: false })
    }

    render() {
        return (
            <InputGroup size="sm">
                <InputGroup.Text>{this.displayedCurrency()}</InputGroup.Text>
                <Form.Control
                    onBlur={() => { this.onBlur() }}
                    onFocus={() => { this.setState({ focus: true }) }}
                    type={this.props.type || "text"}
                    as={this.props.as}
                    className="currency"
                    value={this.amountString()}
                    placeholder={this.placeholder()}
                    onChange={this.handleInputChange}
                    autoComplete="off"
                />
                {this.props.children}
            </InputGroup>
        )
    }
}

const MarketAmountFormControl = injectIntl(MarketAmountFormControlWrapped)

export { MarketAmountFormControl }