import React, { useEffect, useState } from "react";
import { Card, Pagination } from "react-bootstrap";
import { StripedTable } from "./StripedTable";

import * as _ from "lodash";
import { DocumentData, Query, QueryDocumentSnapshot, limit, onSnapshot, startAt, query } from "firebase/firestore";

interface FirestorePagerProps<Element> {
    query: Query<DocumentData>
    transform: (doc: QueryDocumentSnapshot<DocumentData>) => Element
    renderElement: (element: Element) => JSX.Element
    renderHeader: () => JSX.Element
    title: string
    renderContainer?: (children: React.ReactNode | React.ReactNode[]) => JSX.Element
    limit?: number
}

interface ContainerProps {
    children: React.ReactNode | React.ReactNode[];
}

function Container(props: ContainerProps) {
    return (
        <div> {props.children} </div>
    )
}

export function FirestorePager<Element, Container>(props: FirestorePagerProps<Element>) {
    const [elements, setElements] = useState<Element[]>([])
    const [navigationStack, setNavigationStack] = useState<QueryDocumentSnapshot<DocumentData>[]>([])
    const [moreSnap, setMoreSnap] = useState<QueryDocumentSnapshot<DocumentData> | undefined>()

    const loadNext = () => {
        if (moreSnap === undefined) {
            return
        }
        const stack = _.cloneDeep(navigationStack)
        stack.push(moreSnap)
        setMoreSnap(undefined)
        setNavigationStack(stack)
        subscribe(stack)
    }

    const loadPrevious = () => {
        const stack = _.cloneDeep(navigationStack)
        stack.pop()
        setMoreSnap(undefined)
        setNavigationStack(stack)
        subscribe(stack)
    }

    function subscribe(stack: QueryDocumentSnapshot<DocumentData>[] = navigationStack) {
        unsubscribe()
        let q = query(props.query, limit(documentLimit + 1))
        if (stack.length > 0) {
            q = query(q, startAt(stack[stack.length - 1]))
        }
        unsubscribe = onSnapshot(q, (snap => {
            const elements: Element[] = []
            let count = 0
            for (const document of snap.docs) {
                elements.push(props.transform(document))
                count += 1
                if (count >= documentLimit) {
                    break
                }
            }
            setElements(elements)
            if (snap.docs.length === documentLimit + 1) {
                setMoreSnap(snap.docs[snap.docs.length - 1])
            } else {
                setMoreSnap(undefined)
            }
        }))
    }

    const isNextDisabled = () => { return moreSnap === undefined }
    const isPreviousDisabled = () => { return navigationStack.length === 0 }

    const documentLimit = props.limit ?? 25

    let unsubscribe: () => void = () => { }

    useEffect(() => {
        subscribe()

        return () => {
            unsubscribe()
        }

    }, [])

    if (elements.length === 0) {
        return <div />
    }

    const contents: React.ReactNode = <span>
        <Card key="card" className="my-4">
            <Card.Header>
                {props.title}
            </Card.Header>
            <StripedTable>
                <thead>
                    {props.renderHeader()}
                </thead>
                <tbody>
                    {elements.map(element => { return props.renderElement(element) })}
                </tbody>
            </StripedTable>
        </Card>
        <Pagination>
            <Pagination.Prev onClick={() => { loadPrevious() }} disabled={isPreviousDisabled()}>&larr; Previous Page</Pagination.Prev>
            <Pagination.Next onClick={() => { loadNext() }} disabled={isNextDisabled()}>Next Page &rarr;</Pagination.Next>
        </Pagination>
    </span>

    return props.renderContainer?.(contents) ?? <Container>{contents}</Container>
}
