import { DropzoneComponent, DropzoneComponentProps } from "react-dropzone-component"

interface CSVDropzoneComponentProps extends DropzoneComponentProps {
    submitRequest?: (xhr: any, formdata: any, files: any) => void
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post"ing.
// More fiddling may be necessary in the future.

// TODO: This is still basically javascript - 'any' is used to silence typescript-compiler
export class CSVDropzoneComponent extends DropzoneComponent<CSVDropzoneComponentProps> {
    dropzone?: Dropzone
    componentDidMount() {
        if (super.componentDidMount) {
            super.componentDidMount()
        }
        if (this.dropzone) {
            if (this.props.submitRequest) {
                (this.dropzone as any).submitRequest = this.props.submitRequest
            } else {
                (this.dropzone as any).submitRequest = function (xhr: any, formData: any, files: any) {
                    xhr.send(files[0])
                }
            }
        }
    }
}