//
//  index.tsx
//  Versions
//
//  Created by Flemming Pedersen on 17/01/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//
import * as React from "react"
import { App } from "./App"
import { createRoot } from 'react-dom/client';

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
    <App />
)