import * as PDFMake from "pdfmake/build/pdfmake"
import * as PDFMakeFonts from "pdfmake/build/vfs_fonts"
import * as PDFMakeTypes from "pdfmake/interfaces"
import dayjs from "dayjs"
import Numeral from "numeral"
import {
    Dictionary,
    isNil,
    round
    } from "lodash"
import {
    LanguageCode,
    localizedProperty
    } from "../helpers/L10n"
import {
    ProductLeaderboardStats,
    ProductLeaderboardStatsCollection
    } from "../models/StatsModels"
import { currentDatabaseRef } from "../config/constants"
import { DatabaseReference, DataSnapshot, child, get } from "firebase/database"

require("numeral/locales/da-dk") // register da-dk locale settings

const totalPeriodInDays: number = 90

class ProductStatReportModel {
    private barcode?: string
    private costPrice?: number
    private productId?: string
    private itemsToday: number
    private itemsYesterday: number
    private itemsLast7Days: number
    private itemsLast90Days: number
    private name: string
    private product_group?: string
    private quantityInStock: number | null = null
    private stockCountForReport: string = ""

    constructor(name: string) {
        this.name = name
        this.itemsToday = 0
        this.itemsYesterday = 0
        this.itemsLast7Days = 0
        this.itemsLast90Days = 0
        this.updateStockCount(undefined)
    }

    aggregateValuesFromStats = (stats: ProductLeaderboardStats, offsetInDays: number) => {
        if (!isNil(stats.item_count)) {
            if (offsetInDays === 0) {
                this.itemsToday += stats.item_count
            }
            if (offsetInDays === 1) {
                this.itemsYesterday += stats.item_count
            }
            if (offsetInDays < 7) {
                this.itemsLast7Days += stats.item_count
            }
            if (offsetInDays < totalPeriodInDays) {
                this.itemsLast90Days += stats.item_count
            }    
        }
        if (stats.barcode) {
            this.barcode = stats.barcode
        }
        if (stats.cost_price) {
            this.costPrice = round(stats.cost_price / stats.item_count, 2)
        }
        if (stats.id) {
            this.productId = stats.id
        }
        if (stats.name) {
            this.name = localizedProperty(stats, "name", LanguageCode.da)
        }
        if (stats.product_group) {
            this.product_group = stats.product_group
        }
    }

    updateStockCount(stock: object | number | undefined) {
        if (!stock) {
            this.quantityInStock = null
            this.stockCountForReport = "-"
        } else if (typeof stock === "number") {
            this.quantityInStock = (this.quantityInStock || 0) + stock
            this.stockCountForReport = this.quantityInStock.toString()
        } else {
            this.quantityInStock = null
            this.stockCountForReport = "variants"
        }
    }

    compare(other: ProductStatReportModel): number {
        const orderedProperties = ["itemsYesterday", "itemsLast7Days", "itemsLast90Days", "name"]
        for (const property of orderedProperties) {
            const value = this[property]
            const value2 = other[property]
            if (value === null || value === undefined || value2 === null || value2 === undefined) {
                continue
            }
            if (value < value2) {
                return 1
            } else if (value > value2) {
                return -1
            }
        }
        return 0
    }

    csvLine(fieldDelimeter: string, decimalSeparator: string): string {
        const [barcode, id, group, itemValue, totalStockValue, daysUntilOutOfStock] = this.optionalValues(true, decimalSeparator)

        const values: string[] = [
            this.name || "",
            id,
            barcode,
            group,
            `${this.itemsToday}` || "",
            `${this.itemsYesterday}` || "",
            `${this.itemsLast7Days}` || "",
            `${this.itemsLast90Days}` || "",
            this.stockCountForReport || "",
            itemValue,
            totalStockValue,
            daysUntilOutOfStock,
        ]
        return values.join(fieldDelimeter)
    }

    pdfContent(): object[] {
        const [barcode, id, group, itemValue, totalStockValue, daysUntilOutOfStock] = this.optionalValues()
        return [
            { text: this.name || "", style: "leftTableValue" },
            { text: id, style: "leftTableValue", noWrap: true },
            { text: barcode, style: "leftTableValue", noWrap: true },
            { text: group, style: "leftTableValue", noWrap: true },
            { text: this.itemsToday || "", style: "tableValue" },
            { text: this.itemsYesterday || "", style: "tableValue" },
            { text: this.itemsLast7Days || "", style: "tableValue" },
            { text: this.itemsLast90Days || "", style: "tableValue" },
            { text: this.stockCountForReport, style: "tableValue" },
            { text: itemValue, style: "tableValue" },
            { text: totalStockValue, style: "tableValue" },
            { text: daysUntilOutOfStock, style: "tableValue" }
        ]
    }

    private outputNumber(number: number | undefined, asData: boolean, decimalSeparator: string): string {
        if (isNil(number)) {
            return ""
        }
        if (asData) {
            const value = Numeral(number).format("0.00")
            if (decimalSeparator !== ".") {
                return value.replace(".", decimalSeparator)
            } else {
                return value
            }
        } else {
            return Numeral(number).format("0,0.00")
        }
    }

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // private optionalValues(asData: boolean = false, decimalSeparator: string = "."): [string, string, string, string, string, string] {
    private optionalValues(asData: boolean = false, decimalSeparator: string = ".") {
        let quantityInStockOrZero = this.quantityInStock || 0
        if (quantityInStockOrZero < 0) {
            quantityInStockOrZero = 0
        }

        const barcode = this.barcode || ""
        const id = this.productId || ""
        const group = this.product_group || ""
        const itemValue = this.outputNumber(this.costPrice, asData, decimalSeparator)
        const totalStockValue = this.costPrice && quantityInStockOrZero ? this.outputNumber(this.costPrice * quantityInStockOrZero, asData, decimalSeparator) : ""
        const daysUntilOutOfStock = quantityInStockOrZero > 0 && this.itemsLast7Days > 0 ? this.outputNumber((7 * quantityInStockOrZero / this.itemsLast7Days), asData, decimalSeparator) : ""
        return [barcode, id, group, itemValue, totalStockValue, daysUntilOutOfStock]
    }
}

export class ProductStatInventoryReportBuilder {

    private accountId: string
    private accountName?: string
    private shopId: string
    private shopName?: string

    constructor(accountId: string, shopId: string, accountName?: string, shopName?: string) {
        this.accountId = accountId
        this.accountName = accountName
        this.shopId = shopId
        this.shopName = shopName
    }

    buildCSVStatsAndInventoryReport = async (fieldDelimeter: string, decimalSeparator: string): Promise<string> => {
        const headerLine = [
            "Product", 
            "Product id", 
            "Barcode", 
            "Product group", 
            "Items sold today", 
            "Items sold yesterday", 
            "Items sold last 7 days", 
            "Items last 90 days", 
            "Stock", 
            "Item value", 
            "Total stock value", 
            "Est. days to out of stock"
        ].join(fieldDelimeter)
        const reportModels = await this.buildReportModels()
        let lines: string[] = [headerLine]
        lines = lines.concat(reportModels.map((model) => {
            return model.csvLine(fieldDelimeter, decimalSeparator)
        }))
        return lines.join("\n")
    }

    buildPDFStatsAndInventoryReport = async () => {
        Numeral.locale("da-dk")

        const title = this.buildDocumentName(false)
        const reportModels = await this.buildReportModels()
        const pdfRows: any[] = reportModels.map((model: ProductStatReportModel) => {
            return model.pdfContent()
        })

        const pdf = PDFMake as any // ugly workaround for bad typings
        pdf.vfs = PDFMakeFonts.pdfMake.vfs

        const autoColumnWidth = "auto"

        const documentDefinition: PDFMakeTypes.TDocumentDefinitions = {
            pageSize: "A4" as any, // Compensating for bad type declarations
            pageOrientation: "LANDSCAPE" as any, // Compensating for bad type declarations
            pageMargins: [10, 40, 10, 40],
            content: [
                { text: title, style: "title" },
                "\n",
                {
                    layout: {
                        hLineWidth: function (i: number, node: any) {
                            return 1
                        },
                        vLineWidth: function (i: number, node: any) {
                            return 1
                        },
                        hLineColor: function (i: number, node: any) {
                            return i === 1 ? "black" : "#EEEEEE"
                        },
                        vLineColor: function (i: number, node: any) {
                            return "#EEEEEE"
                        }
                    },
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        widths: ["*", autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth, autoColumnWidth],
                        body: [
                            [{ text: "Product", style: "leftTableHeader" },
                            { text: "Product id", style: "tableHeader" },
                            { text: "Barcode", style: "tableHeader" },
                            { text: "Product group", style: "tableHeader" },
                            { text: "Items sold today", style: "tableHeader" },
                            { text: "Items sold yesterday", style: "tableHeader" },
                            { text: "Items sold last 7 days", style: "tableHeader" },
                            { text: "Items sold last 90 days", style: "tableHeader" },
                            { text: "Stock", style: "tableHeader" },
                            { text: "Item value", style: "tableHeader" },
                            { text: "Total stock value", style: "tableHeader" },
                            { text: "Est. days to out of stock", style: "tableHeader" }],
                            ...pdfRows,
                        ]
                    }
                }
            ],
            styles: {
                title: {
                    fontSize: 12
                },
                tableHeader: {
                    fontSize: 10,
                    bold: true,
                    alignment: "left"
                },
                leftTableHeader: {
                    fontSize: 10,
                    bold: true,
                },
                tableValue: {
                    fontSize: 10,
                    alignment: "right"
                },
                leftTableValue: {
                    fontSize: 10
                },
            }
        }

        Numeral.locale(undefined)

        PDFMake.createPdf(documentDefinition).download(this.buildDocumentName(true) + ".pdf")
    }

    buildDocumentName(isFileName: boolean): string {
        const now = dayjs().format("MMMM Do YYYY, H:mm:ss")
        if (isFileName) {
            dayjs().format("MMMM Do YYYY, H_mm_ss")
        }
        let result = ""
        if (this.accountName) {
            result += this.accountName
            if (isFileName) {
                result += " - "
            } else {
                result += ": "
            }
        }
        result += "Sales report for "
        if (this.shopName) {
            result += this.shopName
            result += ", "
        }
        result += now
        return result
    }

    private async buildReportModels(): Promise<ProductStatReportModel[]> {
        let reportModels = await this.createProductStatReportModels()
        reportModels = await this.addStockValueToModels(reportModels)
        const sortedReportModels = Object.keys(reportModels).map(key => reportModels[key]).sort((a: ProductStatReportModel, b: ProductStatReportModel) => {
            return a.compare(b)
        })
        return sortedReportModels
    }

    private createProductStatReportModels = async (): Promise<Dictionary<ProductStatReportModel>> => {
        const stats = await this.dayStatsOfInterest()
        const models: Dictionary<ProductStatReportModel> = {}
        stats.forEach((statsOfGivenDay: DataSnapshot, index: number) => {
            const productStats: ProductLeaderboardStatsCollection | null = statsOfGivenDay ? statsOfGivenDay.val() : null
            if (productStats && productStats.products) {
                for (const key in productStats.products) {
                    const statsForProductOfGivenDay = productStats.products[key]
                    const name = this.productNameFromStats(statsForProductOfGivenDay)
                    const model = models[key] || new ProductStatReportModel(name)
                    model.aggregateValuesFromStats(statsForProductOfGivenDay, totalPeriodInDays - 1 - index)
                    models[key] = model
                }
            }
        })
        return models
    }

    private dayStatsOfInterest = async (): Promise<DataSnapshot[]> => {
        const baseRef: DatabaseReference = this.productDayStatsBaseRef()
        const promises: Promise<DataSnapshot>[] = this.generateDateKeysOfInterest().map((dateKey: string) => {
            return get(child(baseRef, dateKey))
        })
        return Promise.all(promises)
    }

    private generateDateKeysOfInterest = (): string[] => {
        const result: string[] = []

        const now = new Date()
        for (let offset = 0; offset < totalPeriodInDays; offset++) {
            const date = new Date()
            date.setDate(now.getDate() - offset)
            const dateKey = date.toISOString().substring(0, 10)
            result.push(dateKey)
        }

        return result.reverse()
    }

    private productDayStatsBaseRef = (): DatabaseReference => {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/stats/product_leaderboards/shop/${this.shopId}/day`)
    }

    private addStockValueToModels = async (models: Dictionary<ProductStatReportModel>): Promise<Dictionary<ProductStatReportModel>> => {
        const stockSnapshot = await get(this.stockBaseRef())
        if (!stockSnapshot || !stockSnapshot.val()) {
            return Promise.resolve(models)
        }

        const stockForShop: Dictionary<object | number> = stockSnapshot.val()
        for (const key in models) {
            const stock = stockForShop[key]
            const model = models[key]
            model.updateStockCount(stock)
        }

        return Promise.resolve(models)
    }

    private stockBaseRef = () => {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock`)
    }

    private productNameFromStats = (stats: ProductLeaderboardStats): string => {
        // TODO handle localization properly
        return stats.id ? localizedProperty(stats, "name", LanguageCode.da) : "Manual product"
    }
}
