//
//  ModalPicker.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 28/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Modal, Table } from "../components/wrappers"
import { Form, Stack, Button } from "react-bootstrap"

export interface ModalPickerElement {
    id: string
    name: string
    displayName?: JSX.Element
}

export interface ModalPickerProps<T> {
    elements: T[]
    onCancel(): void
    onSelect(element: T): void
    title: string
    alternativeButtonTitle?: string
    alternativeButtonAction?: () => void
    showSearch?: boolean
    entityName?: string
    excludeIds?: string[]
}

interface ModalPickerState {
    searchText: string
    showAll: boolean
}

export class ModalPicker<T extends ModalPickerElement> extends React.Component<ModalPickerProps<T>, ModalPickerState> {
    constructor(props: ModalPickerProps<T>) {
        super(props)
        this.state = {
            searchText: "",
            showAll: props.showSearch !== true
        }
    }

    elements() {
        const excludeIds = this.props.excludeIds ?? []
        if (this.state.showAll) {
            return this.props.elements.filter(element => {
                return !excludeIds.includes(element.id)
            })
        } else {
            if (this.state.searchText.length < 2) {
                return []
            }
            return this.props.elements.filter(element => {
                if (excludeIds.includes(element.id)) {
                    return false
                }
                return element.name.toLowerCase().includes(this.state.searchText.toLowerCase())
            })
        }
    }

    entityName() {
        return this.props.entityName ?? "product"
    }

    render() {
        return (
            <Modal
                size="lg"
                show={true}
                onHide={this.props.onCancel}
            >

                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {this.props.showSearch === true &&
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{`Enter ${this.entityName()} name to search`}</Form.Label>
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Control value={this.state.searchText} type="text" placeholder={`Search ${this.entityName()}s`} onChange={a => {
                                        this.setState({
                                            searchText: a.target.value,
                                            showAll: false
                                        })
                                    }} />
                                    <span> or </span>
                                    <Button onClick={() => { this.setState({ showAll: !this.state.showAll }) }}>{this.state.showAll ? <span>Hide&nbsp;all</span> : <span>Show&nbsp;all</span>}</Button>
                                </Stack>
                            </Form.Group>
                        </Form>
                    }
                    <Table bordered={true} condensed={true} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                        <tbody>
                            {this.elements().map((element) => {
                                return (
                                    <tr key={element.id} onClick={() => { this.props.onSelect(element) }}>
                                        <td>{element.displayName ?? element.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>

                <Modal.Footer>
                    {this.props.alternativeButtonTitle && <Button onClick={() => { this.props.alternativeButtonAction?.() }}>{this.props.alternativeButtonTitle}</Button>}
                    <Button variant="secondary" onClick={this.props.onCancel}>Cancel</Button>
                </Modal.Footer>

            </Modal >
        )
    }
}
