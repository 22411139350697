import * as React from "react"
import ImageDropControl from "../../ImageDropControl"
import {
    Alert,
    Button,
    Col,
    
    Form,
    FormControl,
    FormGroup,
    Card
} from "../../wrappers"
import { EmailConfigurationType } from "../../../models/EmailConfigurationType"
import { EmailValidation } from "../../../helpers/validation"
import LoadingButton, { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { stripEmptyValues } from "../../../helpers/stripEmptyValues"
import { RoleRouterProps, withRouter } from "../../../routes"
import { Row } from "react-bootstrap"
import { child, get, set } from "firebase/database"

interface ReceiptConfigurationState {
    logo_url: string | null
    email_from_address: string
    email_from_name: string
    email_subject: string
    footer: string
    header: string
    emailConfigType: EmailConfigurationType
    dirty: boolean
    loaded: boolean
    publishing: boolean
    sales_quote_email_subject: string
    sales_quote_admin_email?: string
    showSuccess: boolean
    valid: boolean
}

class ReceiptConfiguration extends React.Component<RoleRouterProps, ReceiptConfigurationState> {

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            logo_url: "",
            email_from_address: "",
            email_from_name: "",
            email_subject: "",
            sales_quote_email_subject: "",
            footer: "",
            header: "",
            emailConfigType: EmailConfigurationType.none,
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            valid: true,
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey

        const emailConfigTypeRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/email/config_type`)
        const emailConfigTypeSnapshot = await get(emailConfigTypeRef)
        const emailConfigType = emailConfigTypeSnapshot.exists() ? emailConfigTypeSnapshot.val() : EmailConfigurationType.none

        const receiptRef = child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/receipt`)
        const receiptSnapshot = await get(receiptRef)

        if (!receiptSnapshot || !receiptSnapshot.exists()) {
            this.setState({
                emailConfigType: emailConfigType,
                loaded: true
            })
            return
        }

        const value = receiptSnapshot.val()

        this.setState({
            logo_url: value.logo_url || "",
            email_from_address: value.email_from_address || "",
            email_from_name: value.email_from_name || "",
            email_subject: value.email_subject || "",
            sales_quote_email_subject: value.sales_quote_email_subject || "",
            emailConfigType: emailConfigType,
            footer: value.footer || "",
            header: value.header || "",
            loaded: true
        })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Receipt configuration">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"}>
                    <Card.Header>
                        {this.state.showSuccess ? "Receipt configuration updated successfully" : "Receipt configuration"}
                    </Card.Header>

                    <Card.Body>
                        <h4>Printed</h4>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Receipt logo (Maximum resolution 512×512)</Col>
                                <Col sm={10}>
                                    <ImageDropControl
                                        fileName="receipt_logo"
                                        filePath={`${this.props.role.account_id}/shops/${this.props.router.params.shopKey}/`}
                                        imageURL={this.state.logo_url ? this.state.logo_url : ""}
                                        dropTitle=""
                                        validateSize={(width: number, height: number) => {
                                            return width <= 512 && height <= 512
                                        }}
                                        deleteAction={() => { this.setReceiptLogo(null) }}
                                        uploadAction={(url) => { this.setReceiptLogo(url) }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Header</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="header"
                                        value={this.state.header}
                                        placeholder="Header shown on the printed receipt"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Footer</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="footer"
                                        value={this.state.footer}
                                        placeholder="Footer shown on the printed receipt"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>

                        <h4>Email</h4>
                        <Form>
                            {
                                this.state.emailConfigType === EmailConfigurationType.ownSMTP || this.state.emailConfigType === EmailConfigurationType.validatedDomain ? (
                                    <FormGroup className="mb-3" as={Row}>
                                        <Col sm={2}>Sender address</Col>
                                        <Col sm={10}>
                                            <FormControl
                                                type="text"
                                                name="email_from_address"
                                                value={this.state.email_from_address}
                                                placeholder="Enter address that receipt should be sent from"
                                                onChange={this.handleInputChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                ) : (
                                    <FormGroup className="mb-3" as={Row}>
                                        <Col sm={2} />
                                        <Col sm={10}>
                                            <Alert variant="warning">In order to send receipt emails originating from a domain other than ka-ching.dk, please go to the Email configuration found under advanced options.</Alert>
                                        </Col>
                                    </FormGroup>
                                )
                            }
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Sender name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="email_from_name"
                                        value={this.state.email_from_name}
                                        placeholder="Enter name seen as sender when sending email receipts"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Receipt email subject</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="email_subject"
                                        value={this.state.email_subject}
                                        placeholder="Enter subject shown when sending email receipts"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Sales quote email subject</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="sales_quote_email_subject"
                                        value={this.state.sales_quote_email_subject}
                                        placeholder="Enter subject shown when sending email sales quotes"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Sales quote admin email</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="sales_quote_admin_email"
                                        value={this.state.sales_quote_admin_email ?? ""}
                                        placeholder="Optionally enter an email address that will receive a copy of every sales quote generated in POS"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}/>
                        
                    </Card.Footer>
                </Card >

            </PageState>
        )
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        let valid = this.state.valid
        if (target.name === "email_from_address") {
            valid = !value || EmailValidation.valid(value)
        }

        const newState: any = {
            [name]: value,
            dirty: true,
            valid: valid,
        }
        this.setState(newState)
    }

    receiptLogoValidate = (width: number, height: number) => {
        return width <= 1024 && height <= 1024
    }

    setReceiptLogo = (url: string | null) => {
        this.setState({ logo_url: url, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey
        const shopConfigPath = `v1/accounts/${account}/shops/${shop}/configuration`
        const receiptRef = child(currentDatabaseRef(), `${shopConfigPath}/receipt`)

        this.setState({ publishing: true })

        const receiptConfig = {
            logo_url: this.state.logo_url,
            email_from_address: this.state.email_from_address,
            email_from_name: this.state.email_from_name,
            email_subject: this.state.email_subject,
            sales_quote_email_subject: this.state.sales_quote_email_subject,
            footer: this.state.footer,
            header: this.state.header
        }

        stripEmptyValues(receiptConfig)

        await set(receiptRef, receiptConfig)

        const salesQuoteAdminEmail = this.state.sales_quote_admin_email === undefined || this.state.sales_quote_admin_email === "" ? null : this.state.sales_quote_admin_email
        await set(child(currentDatabaseRef(), `${shopConfigPath}/sales_quote_email_address`), salesQuoteAdminEmail)

        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}

export default withRouter(ReceiptConfiguration)
