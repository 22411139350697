import React from "react"
import { DropzoneComponent, DropzoneComponentConfig } from "react-dropzone-component"
import * as _ from "lodash"
import Dropzone from "dropzone"
import { GiftcardConfig } from "./GiftcardTypeList/GiftcardTypeList"

export type AssetType = "image" | "font"
export interface GiftcardAssetDropzoneProps {
    giftcardConfig: GiftcardConfig,
    assetType: AssetType
    addedFileName: (name: string) => void
}

export function GiftcardAssetDropzone(props: GiftcardAssetDropzoneProps) {

    let componentConfig: DropzoneComponentConfig = {
        iconFiletypes: props.assetType == "image" ? ['.png'] : ['.otf'],
        showFiletypeIcon: true,
        postUrl: 'no-handler',
    }

    let djsconfig: Dropzone.DropzoneOptions = {
        autoProcessQueue: false,
        acceptedFiles: props.assetType == "image" ? "image/png" : "font/otf",
        maxFiles: 1,
        dictDefaultMessage: props.assetType == "image" ? "Upload image" : "Upload font",
        addRemoveLinks: true
    }

    let dropzone: Dropzone

    return (
        <DropzoneComponent config={componentConfig}
            eventHandlers={{
                // Pull out the dropzone object for customization
                init: (d: Dropzone) => {
                    dropzone = d
                },
                addedfile: async (file) => {
                    try {
                        await uploadFile(props, file)
                        dropzone.emit("uploadprogress", file, 100, 100)
                        dropzone.emit("complete", file)
                        dropzone.emit("success", file, "success")
                        props.addedFileName(file.name)
                    } catch {
                        dropzone.emit("complete", file)
                        dropzone.emit("error", file, "Error uploading file")
                    }
                }
            }}
            djsConfig={djsconfig} />

    )
}
async function uploadFile(props: GiftcardAssetDropzoneProps, file: Dropzone.DropzoneFile) {
    const assetPath = props.assetType == "image" ? `/api/v1/asset/images` : `/api/v1/asset/fonts`
    const url = props.giftcardConfig.baseURL + assetPath
    const formData = new FormData()
    formData.append(props.assetType, file)

    const headers = new Headers()

    headers.append("token", props.giftcardConfig.token)
    headers.append("uid", props.giftcardConfig.uid)

    const requestInit: RequestInit = {
        method: "POST",
        headers: headers,
        body: formData
    }

    const request = new Request(url, requestInit)
    const response = await fetch(request)
    if (!response.ok) {
        console.log(response.json())
        throw new Error()
    }
}

