import * as _ from "lodash"
import * as React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { FormGroup, Col, Modal, DescriptionCol, Row } from "../../wrappers"
import { LanguageCode, L10nString } from "../../../helpers/L10n"
import { L10nFormControl } from "../../L10nFormControl"
import { StripedTable } from "../../StripedTable"
import { allShortcuts, Shortcut, ShortcutItem, shortcutName, ShortcutsSection } from "./FrontPageSectionModels"
import { Dropdown, Button, Form, Stack, DropdownButton } from "react-bootstrap"
import { LabelledControl } from "../ProductEdit"
import { LanguagePicker } from "../../LanguagePicker"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { firestore } from "../../../config/constants"
import { collection, getDocs, query, where } from "firebase/firestore"

interface ShortcutsSectionEditProps {
    accountId: string
    section: ShortcutsSection
    currentLanguage: LanguageCode | null
    updateSection: (subSection: ShortcutsSection) => void
    setLanguage: (language: LanguageCode | null) => void
    removeLanguage: (language: LanguageCode) => void
    resolveLanguages: () => LanguageCode[]
}

interface ShortcutsSectionEditState {
    section: ShortcutsSection
    addShortcut: boolean
    selectedShortcut?: ShortcutItem
    shortcutLabel?: L10nString
    editedIndex?: number
    parameters?: _.Dictionary<any>
}

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

export class ShortcutsSectionEdit extends React.Component<ShortcutsSectionEditProps, ShortcutsSectionEditState> {
    constructor(props: ShortcutsSectionEditProps) {
        super(props)

        this.state = {
            section: props.section,
            addShortcut: false
        }
    }

    componentDidUpdate(prevProps: Readonly<ShortcutsSectionEditProps>, prevState: Readonly<ShortcutsSectionEditState>, snapshot?: any): void {
        if (prevProps.currentLanguage !== this.props.currentLanguage) {
            this.setLanguage(this.props.currentLanguage)
        }
        if (!_.isEqual(prevProps.section, this.props.section)) {
            this.setState({ section: this.props.section })
        }
    }

    componentDidMount(): void {
        this.setLanguage(this.props.currentLanguage)
    }

    onLocalizationChanged = (name: string, l10n: L10nString | null) => {
        const section = _.cloneDeep(this.state.section)

        if (l10n === null || l10n.value === "") {
            delete section[name]
        } else {
            section[name] = l10n
        }
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    // Signals to vm

    onRemove = (index: number) => {
        const name = this.state.section.shortcuts[index].title(this.props.currentLanguage)
        if (window.confirm(`Really remove the shortcut "${name}"?`) === true) {
            const section = _.cloneDeep(this.state.section)
            section.shortcuts.splice(index, 1)
            this.props.updateSection(section)
            this.setState({ section: section })
        }
    }

    onDragEnd = (reorder: any) => {
        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return
        }

        const section = _.cloneDeep(this.state.section)
        const [removed] = section.shortcuts.splice(reorder.source.index, 1)
        section.shortcuts.splice(reorder.destination.index, 0, removed)

        this.props.updateSection(section)
        this.setState({ section: section })
    }

    addShortcut = () => {
        if (_.isNil(this.state.selectedShortcut)) {
            return
        }
        const section = _.cloneDeep(this.state.section)
        const shortcut = new Shortcut(this.state.selectedShortcut)
        if (!_.isNil(this.state.shortcutLabel)) {
            shortcut.label = this.state.shortcutLabel
        }
        if (!_.isNil(this.state.parameters)) {
            if (Object.keys(this.state.parameters).length > 0) {
                shortcut.parameters = this.state.parameters
            }
        }

        if (!_.isNil(this.state.editedIndex)) {
            section.shortcuts[this.state.editedIndex] = shortcut
        } else {
            section.shortcuts.push(shortcut)
        }


        this.props.updateSection(section)
        this.setState({ section: section, addShortcut: false, selectedShortcut: undefined, shortcutLabel: undefined, editedIndex: undefined })
    }

    setLanguage = (language: LanguageCode | null) => {
        this.props.setLanguage(language)
        if (_.isNil(language)) { return }

        if (!_.isNil(this.state.shortcutLabel)) {
            if (!this.state.shortcutLabel.hasLocalizationFor(language)) {
                const clone = _.cloneDeep(this.state.shortcutLabel)
                clone.localizeTo(language)
                this.setState({ shortcutLabel: clone })
            }
        }
    }

    removeLanguage = (language: LanguageCode) => {
        if (!_.isNil(this.state.shortcutLabel)) {
            const clone = _.cloneDeep(this.state.shortcutLabel)
            clone.removeLocalization(language)
            this.setState({ shortcutLabel: clone })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        const resolved = this.props.resolveLanguages()
        if (!_.isNil(this.state.shortcutLabel)) {
            for (const l of this.state.shortcutLabel.localizations()) {
                if (!resolved.includes(l)) {
                    resolved.push(l)
                }
            }
        }
        return resolved
    }

    render() {
        return [
            (
                <Form key="form" onSubmit={e => e.preventDefault()}>
                    <FormGroup className="mb-3" as={Row}>
                        <DescriptionCol sm={2}>Name</DescriptionCol>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={this.state.section.title || null}
                                language={this.props.currentLanguage || null}
                                onLocalizationChanged={l10n => {
                                    this.onLocalizationChanged("title", l10n)
                                }
                                }
                            />
                        </Col>
                    </FormGroup>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(droppableProvided) => (
                                <div ref={droppableProvided.innerRef}>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th style={narrowStyle}>Order</th>
                                                <th style={nameStyle}>Name</th>
                                                <th style={narrowStyle}>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.section.shortcuts.map((shortcut, index) => (
                                                <Draggable key={`${shortcut.id}`} draggableId={`${shortcut.id}`} index={index}>
                                                    {(draggableProvided: any) => (
                                                        <tr
                                                            ref={draggableProvided.innerRef}
                                                            key={`${shortcut.id}`}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}
                                                            onClick={() => { this.setState({ addShortcut: true, selectedShortcut: shortcut.shortcutItem, shortcutLabel: shortcut.label, editedIndex: index, parameters: shortcut.parameters }) }}
                                                        >
                                                            <td style={narrowStyle}>{index + 1}</td>
                                                            <td style={nameStyle}>
                                                                {shortcut.title(this.props.currentLanguage)}
                                                            </td>
                                                            <td style={narrowStyle}>
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={(event) => {
                                                                        event.stopPropagation()
                                                                        this.onRemove(index)
                                                                    }}
                                                                >
                                                                    X
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </tbody>
                                    </StripedTable>
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <div style={{ textAlign: "right" }}>
                        <Button onClick={() => { this.setState({ addShortcut: true }) }}>Add</Button>
                    </div>
                </Form>
            ),
            this.state.addShortcut ? (
                <Modal show={true} key="add">
                    <Modal.Header>
                        <Modal.Title style={{ width: "100%" }}>
                            <Stack direction="horizontal" className="pb-2" gap={2}>
                                <span>{_.isNil(this.state.editedIndex) ? "Add short cut" : "Edit short cut"}</span>

                                <div className="ms-auto" />

                                <LanguagePicker
                                    typeName="section"
                                    initialLanguage={this.props.currentLanguage}
                                    resolveLanguages={() => { return this.resolveLanguages() }}
                                    onChange={(l) => { this.setLanguage(l) }}
                                    onRemove={(l) => { this.removeLanguage(l) }}
                                />
                            </Stack>

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <LabelledControl label="Short cut">
                            <Dropdown onSelect={(shortcut: any) => this.setState({ selectedShortcut: shortcut ?? undefined })}>
                                <Dropdown.Toggle variant="outline-primary" size="sm">{this.state.selectedShortcut ? shortcutName(this.state.selectedShortcut) : "Select a short cut"}</Dropdown.Toggle>
                                <Dropdown.Menu style={{ overflowY: "scroll", maxHeight: 400 }}>
                                    {allShortcuts.sort((a, b) => {
                                        return shortcutName(a).localeCompare(shortcutName(b))
                                    }).map((shortcut, index) => (
                                        <Dropdown.Item eventKey={shortcut} key={index}>
                                            {shortcutName(shortcut)}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </LabelledControl>

                        <LabelledControl label="Customize label">
                            <L10nFormControl
                                l10n={this.state.shortcutLabel ?? null}
                                type="text"
                                placeholder={!_.isNil(this.state.selectedShortcut) ? shortcutName(this.state.selectedShortcut) : ""}
                                language={this.props.currentLanguage}
                                style={{ resize: "vertical" }}
                                onLocalizationChanged={(l10n) => { this.setState({ shortcutLabel: l10n ?? undefined }) }}
                            />
                        </LabelledControl>

                        {this.state.selectedShortcut === "add_offer" && <OfferParameters accountId={this.props.accountId} parameters={this.state.parameters} onChange={p => {
                            this.setState({ parameters: p })
                        }} />}
                        {this.state.selectedShortcut === "basket_discount" && <BasketDiscountParameters accountId={this.props.accountId} parameters={this.state.parameters} onChange={p => {
                            this.setState({ parameters: p })
                        }} />}
                    </Modal.Body >

                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => this.setState({ addShortcut: false })}>Cancel</Button>
                        <Button size="sm" disabled={_.isNil(this.state.selectedShortcut)} onClick={() => this.addShortcut()}>Done</Button>
                    </Modal.Footer>

                </Modal >
            ) : null
        ]
    }
}

interface ParameterProps {
    accountId: string
    parameters?: _.Dictionary<any>
    onChange: (parameters: _.Dictionary<any>) => void
}

function BasketDiscountParameters(props: ParameterProps) {
    const [parametrize, setParametrize] = React.useState<boolean>(Object.keys(props.parameters ?? {}).length !== 0)
    const parameters = props.parameters ?? { type: "percentage", value: 0.1 }
    const type = parameters.type ?? "percentage"
    const value = parameters.value ?? 0
    const name: (key: string) => string = (key) => {
        switch (key) {
            case "percentage": return "Percentage"
            case "new_price": return "New price"
            case "amount_off": return "Amount off"
            default: return "Percentage"
        }
    }
    return <>
        <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Add parameters"
            checked={parametrize}
            onChange={v => {
                setParametrize(v.currentTarget.checked)
                if (!v.currentTarget.checked) {
                    props.onChange({})
                } else {
                    props.onChange(parameters)
                }
            }}
        />


        {parametrize && <LabelledControl label="Discount type">
            <DropdownButton title={name(type)} onSelect={key => {
                const clone = _.cloneDeep(parameters)
                clone.type = key
                props.onChange(clone)
            }} >
                <Dropdown.Item eventKey="percentage">Percentage</Dropdown.Item>
                <Dropdown.Item eventKey="new_price">New price</Dropdown.Item>
                <Dropdown.Item eventKey="amount_off">Amount off</Dropdown.Item>
            </DropdownButton>
        </LabelledControl>}

        {parametrize && <LabelledControl label="Value">
            <>
                {parameters.type === "percentage" && <DecimalValueFormControl
                    value={Math.round(value * 1000) / 10}
                    onValueChanged={value => {
                        let scaled: number | undefined = undefined
                        if (value !== null) {
                            scaled = value / 100
                        }
                        const clone = _.cloneDeep(parameters)
                        clone.value = scaled
                        props.onChange(clone)

                    }}
                    decimals={1}
                    prefixSymbol="%"
                    placeholder="Enter discount percentage"
                />
                }
                {parameters.type !== "percentage" && <DecimalValueFormControl
                    value={Math.round(value * 100) / 100}
                    onValueChanged={value => {
                        let scaled: number | undefined = undefined
                        if (value !== null) {
                            scaled = value
                        }
                        const clone = _.cloneDeep(parameters)
                        clone.value = scaled
                        props.onChange(clone)

                    }}
                    decimals={2}
                    prefixSymbol={name(type)}
                    placeholder="Enter amount"
                />
                }
            </>

        </LabelledControl>
        }
    </>
}
interface Rule {
    id: string
    name: string
}

function OfferParameters(props: ParameterProps) {
    const [rules, setRules] = React.useState<Rule[]>([])
    const [parametrize, setParametrize] = React.useState<boolean>(Object.keys(props.parameters ?? {}).length !== 0)
    const parameters = props.parameters ?? { rule_id: "" }
    const ruleId = parameters.rule_id ?? ""
    React.useEffect(() => {
        const fetch = async () => {
            const campaignRulesRef = collection(firestore, `accounts/${props.accountId}/inventory/all.markets.channels/campaign_rules`)
            const q = query(campaignRulesRef, where("type", "==", "offer"), where("archived", "==", false))
            const snap = await getDocs(q)
            const rules: Rule[] = []
            for (const document of snap.docs) {
                rules.push({ id: document.data().id, name: document.data().display_name })
            }
            setRules(rules.sort((a, b) => { return a.name.localeCompare(b.name) }))
        }
        fetch()
    }, [props.accountId])

    const name = (id: string) => {
        return rules.find(r => { return r.id === id })?.name ?? "Select a rule"
    }

    return <LabelledControl label="Offer">
        <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Add parameters"
            checked={parametrize}
            onChange={v => {
                setParametrize(v.currentTarget.checked)
                if (!v.currentTarget.checked) {
                    props.onChange({})
                } else if (parameters.rule_id ?? "" !== "") {
                    props.onChange(parameters)
                }
            }}
        />

        <>{parametrize && <DropdownButton title={name(ruleId)} onSelect={key => {
            const clone = _.cloneDeep(parameters)
            clone.rule_id = key
            props.onChange(clone)
        }} >
            {rules.map(rule => {
                return <Dropdown.Item key={rule.id} eventKey={rule.id}>{rule.name}</Dropdown.Item>
            })}
        </DropdownButton>}
        </>
    </LabelledControl>
}