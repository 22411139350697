import * as React from "react"
import {
    Button,
    Col,
    Grid,
    Card,
    Row
} from "../../wrappers"
import { PageState } from "../../PageState"
import { ProductCatalogService } from "../../../services/ProductCatalogService"
import { currentDatabaseRef } from "../../../config/constants"
import { StockReportBuilder } from "../../../services/StockReportBuilder"
import { RoleProps, RoleShopProps, withRole } from "../../../routes"
import * as _ from "lodash"
import { StockCountReportModal, StockReportFilter } from "./StockCountReportModal"
import { child, get } from "firebase/database"

const FileDownload = require("js-file-download")

interface StockCountReportsState {
    account_name?: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    focusedInput?: any
    reportMessage?: string
    date: Date
    showReportModal: boolean
}

class StockCountReports extends React.Component<RoleProps, StockCountReportsState> {

    // Props

    private productCatalogService = new ProductCatalogService()

    // Lifecycle

    constructor(props: RoleShopProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            date: new Date(),
            showReportModal: false
        }
    }

    // Component

    async componentDidMount() {
        this.setState({ loaded: false })

        const accountNameSnapshot = await get(child(currentDatabaseRef(), `v1/account_index/${this.props.role.account_id}/name`))

        const update: any = { loaded: true }
        if (accountNameSnapshot.exists()) {
            update.account_name = accountNameSnapshot.val()
        }

        this.setState(update)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} dirty={this.state.dirty} customMessage={this.state.reportMessage} typeName="reports">
                <Card className="my-4">
                    <Card.Header>
                        Stock count percentage report
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>This report shows the percentage of counted stock for each stock location from the selected date</Card.Title>
                        <Button variant="secondary" onClick={() => { this.setState({ showReportModal: true }) }}>Export CSV</Button>
                    </Card.Body>
                </Card>
                {
                    this.state.showReportModal ? (
                        <StockCountReportModal
                            mode="stock_count_percentage"
                            stockLocation={""}
                            role={this.props.role}
                            openNew={async (filter: StockReportFilter) => { await this.buildCountPercentageStockReport(filter);  }}
                            cancel={() => { this.setState({ showReportModal: false }) }}
                        />
                    ) : null
                }

            </PageState>
        )
    }

    buildCountPercentageStockReport = async (filter: StockReportFilter) => {
        this.setState({ showReportModal: false })
        const message = this.stockReportMessage(undefined, undefined)
        this.setState({ reportMessage: message })
        const builder = new StockReportBuilder(this.props.role.account_id, "", this.productCatalogService)
        const report = await builder.buildCountedPercentageStockReport(filter, (loadedProducts, countedStock) => {
            this.setState({ reportMessage: this.stockReportMessage(loadedProducts, countedStock) })
        })
        const fileName = report[1]
        FileDownload(report[0], `${fileName}.csv`)
        this.setState({ reportMessage: undefined })
    }

    stockReportMessage(loadedProducts?: number, countedStock?: number): string {
        let result: string = ""
        if (!_.isNil(countedStock)) {
            result = `Loaded ${countedStock} counted stock`
        } else if (!_.isNil(loadedProducts)) {
            result = `Loaded ${loadedProducts} products`
        }
        return result
    }
}

export default withRole(StockCountReports)
