import React, { useEffect, useState } from "react";
import { RoleRouterProps, withRoleRouterFunc } from "../../../routes";
import { Button, Card, Col, DescriptionCol, FormControl, FormGroup, Row, ToggleButtonGroup } from "../../wrappers";
import { Form } from "react-bootstrap";
import { GiftcardType } from "../../../models/GiftcardType";
import { FormattedTime } from "react-intl";
import { ToggleButton } from "../../ToggleButton";
import { ToggleButton  as ToggleButtonWrapper } from "../../wrappers";
import { Globals } from "../../../helpers/globals";
import { Currency } from "../../../models/MarketModels";
import * as _ from "lodash";
import { PageState } from "../../PageState";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "src/config/constants";
import { defaultPDFTemplate } from "./DefaultPDFTemplate";
import { defaultEmailTemplate } from "./DefaultEmailTemplate";

function GiftcardTypeDetail(props: RoleRouterProps) {
    const [giftcardType, setGiftcardType] = useState<GiftcardType | null>(null)
    const [currencies, setCurrencies] = useState<Currency[]>([{currencyCode: "DKK"}])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isPublishing, setIsPublishing] = useState<boolean>(false)
    const [allowPublishing, setAllowPublishing] = useState<boolean>(true)
    const [usedGiftcardTypeIds, setUsedGiftcardTypeIds] = useState<string[]>([])
    const accountId = props.role.account_id
    const [typeId, setTypeId] = useState<string>(props.router.params.typeId)
    const [nameNotEdited, setNameNotEdited] = useState<boolean>(typeId === "new" ? true : false)
    const [accountName, setAccountName] = useState<string>("")

    useEffect(() => {
        (async () => {
            if (typeId !== "new") {
                const promises: Promise<any>[] = [Globals.shared.getCurrencies(), fetchGiftcardType(accountId, typeId)()]
                const [currencies, value] = await Promise.all(promises)
                const data = (value.data as any)
                const giftcardType = GiftcardType.fromJSON(data)
                setGiftcardType(giftcardType)
                setCurrencies(currencies)
                setIsLoading(false)
            } else {
                const [currencies, typeIds] = await Promise.all([Globals.shared.getCurrencies(), getGiftcardTypesIds(props.role.account_id)])
                let accountSnapshot = await getDoc(doc(firestore, `accounts/${accountId}`))
                let name = ""
                if (accountSnapshot.exists()) {
                    let data = accountSnapshot.data()
                    name = data.name
                    setAccountName(name)
                }
                const defaultEmailAddress = `${name.toLowerCase()}@ka-ching.dk`
                const defaultEmailFromName = name
                const defaultEmailSubject = `Gavekort fra ${name}`
                const giftcardType = new GiftcardType(10000, "DKK", 1095, new Date(), "", "", defaultEmailAddress, defaultEmailSubject, defaultEmailFromName, "da", "Europe/Copenhagen", true, false, false)
                setUsedGiftcardTypeIds(typeIds)
                setCurrencies(currencies)
                setGiftcardType(giftcardType)
                setAllowPublishing(!missingRequiredValue(giftcardType))
                setIsLoading(false)
            }
        })()

        
    }, [props.role.account_id])

    
    return (
        <PageState loading={isLoading} publishing={isPublishing} dirty={isDirty && allowPublishing} typeName="giftcard type" submit_action={async () => { 
            await submit(accountId, giftcardType?.toJSON())
            if (typeId === "new") {
                setTypeId(giftcardType?.id ?? "")
                await Promise.all([publishDefaultTemplate(accountId, giftcardType?.id ?? "", defaultPDFTemplate, 'pdf'), publishDefaultTemplate(accountId, giftcardType?.id ?? "", defaultEmailTemplate(accountName), 'email')])
                let location = props.router.location.pathname.split("/")
                location[location.length - 1] = giftcardType?.id
                let newPath = location.join("/")
                props.router.navigate(newPath, { replace: true })
            }
        }}>
        { typeId !== "new" ?
            <div>
                <Button 
                onClick={() => { 
                    let path = `pdf`
                    props.router.navigate(path) 
                }} style={{marginRight: "10px"}}>PDF template</Button>
                <Button
                onClick={() => { 
                    let path = `email`
                    props.router.navigate(path) 
                }}>Email template</Button>
            </div>
            : null
        }
        
        <Card className="my-4">
        <Card.Header> Giftcard Type: {giftcardType?.name}</Card.Header>
        <Card.Body> 
            <Form>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Name*</Form.Label>
                    </DescriptionCol>
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="name"
                            style={{backgroundColor: getBackgrounColor((!_.isEmpty(giftcardType?.name)) || nameNotEdited)}}
                            value={giftcardType?.name ?? ""}
                            placeholder="Name"
                            onChange={(event) => {
                                setNameNotEdited(false)
                                handleEvent(event)
                            }}
                        />
                    </Col>
                    </FormGroup>
                {
                    
                    typeId === "new" ?  
                    <ValidatingIdEntryControl
                        isNew={true}
                        typeName="giftcard type"
                        descriptionName="Identifier*"
                        identifierSource={giftcardType?.name ?? ""}
                        existingIdentifier={giftcardType?.id ?? ""}
                        invalidIdentifiers={usedGiftcardTypeIds}
                        handleIdChange={(id, valid) => { 
                            
                            let giftcardTypeCopy = _.cloneDeep(giftcardType)
                            if (!_.isNil(giftcardTypeCopy)) {
                                giftcardTypeCopy.id = id
                                setIsDirty(true)
                                setAllowPublishing(valid && !missingRequiredValue(giftcardTypeCopy))
                                setGiftcardType(giftcardTypeCopy)
                            }
                        }}/>
                    :
                    <FormGroup className="mb-3" as={Row}> 
                        <DescriptionCol sm={2}>
                        <Form.Label>Identifier</Form.Label>
                        </DescriptionCol>
                        <Col sm={10}>
                        <FormControl
                            disabled={true}
                            type="text"
                            name="identifier"
                            value={giftcardType?.id  ?? ""}
                            placeholder="Identifier"
                        />
                     </Col>
                     </FormGroup>

                }
               
                <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                    <Form.Label>Email from address*</Form.Label>
                </DescriptionCol>
                
                <Col sm={10}>
                    <FormControl
                        disabled={false}
                        type="text"
                        style={{backgroundColor: getBackgrounColor(!_.isEmpty(giftcardType?.email_from_address))}}
                        name="email_from_address"
                        value={giftcardType?.email_from_address ?? ""}
                        placeholder="Email from address"
                        onChange={handleEvent}
                    />
                </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Email from name*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            style={{backgroundColor: getBackgrounColor(!_.isEmpty(giftcardType?.email_from_name))}}

                            name="email_from_name"
                            value={giftcardType?.email_from_name ?? ""}
                            placeholder="Email from name*"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Email subject*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="email_subject"
                            style={{backgroundColor: getBackgrounColor(!_.isEmpty(giftcardType?.email_subject))}}
                            value={giftcardType?.email_subject ?? ""}
                            placeholder="Email subject"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Max amount*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="number"
                            name="max_amount"
                            style={{backgroundColor: getBackgrounColor((giftcardType?.max_amount ?? 0) > 1)}}
                            value={giftcardType?.max_amount ?? ""}
                            placeholder="Max amount"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Expiry days*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="number"
                            name="expiry_days"
                            style={{backgroundColor: getBackgrounColor((giftcardType?.expiry_days ?? 0) > 1)}}
                            value={giftcardType?.expiry_days ?? ""}
                            placeholder="Expiry days"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Currency</DescriptionCol>
                    <Col sm={10}>
                        <ToggleButtonGroup
                            type="radio"
                            name="currency"
                            value={giftcardType?.currency ?? ""}
                            onChange={(value) => {
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.currency = value
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            }}
                        >
                            {
                                currencies.map((currency) => {
                                    return <ToggleButtonWrapper id={currency.currencyCode} key={currency.currencyCode} value={currency.currencyCode}>{currency.currencyCode}</ToggleButtonWrapper>
                                })
                            }
                        </ToggleButtonGroup>
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Created</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                            <FormattedTime
                                value={giftcardType?.created ?? ""}
                                day="numeric"
                                month="long"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Locale*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="locale"
                            style={{backgroundColor: getBackgrounColor(!_.isEmpty(giftcardType?.locale))}}
                            value={giftcardType?.locale ?? ""}
                            placeholder="Locale"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Timezone*</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            style={{backgroundColor: getBackgrounColor(!_.isEmpty(giftcardType?.timezone))}}
                            disabled={false}
                            type="text"
                            name="timezone"
                            value={giftcardType?.timezone ?? ""}
                            placeholder="Timezone"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>
                        <Form.Label>Set expiry on creation</Form.Label>
                    </DescriptionCol>
                    <Col sm={10}>
                        <ToggleButton
                            enabledTitle="True"
                            disabledTitle="False"
                            disabled={!giftcardType?.set_expiry_on_creation}
                            active={giftcardType?.set_expiry_on_creation ?? false}
                            performToggle={(() => {  
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.set_expiry_on_creation = !giftcardTypeCopy.set_expiry_on_creation
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            })}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                        <Form.Label>Use Wallet</Form.Label>
                    </DescriptionCol>
                    <Col sm={10}>
                        <ToggleButton
                            enabledTitle="True"
                            disabledTitle="False"
                            disabled={!giftcardType?.use_wallet}
                            active={giftcardType?.use_wallet ?? false}
                            performToggle={() => {  
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.use_wallet = !giftcardTypeCopy.use_wallet 
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            }}
                        />
                    </Col>
                </FormGroup>
                { giftcardType?.use_shopify ? 
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>
                        <Form.Label>Synchronize in Shopify</Form.Label>
                    </DescriptionCol>
                    <Col sm={10} style={{paddingTop: 3}}> This gift card type is configured to be synchronized in Shopify </Col>
                </FormGroup>
                : null
                }
                
            </Form>
        </Card.Body>
        </Card>
        </PageState>
    )

    function handleEvent(event: any) {
        let giftcardTypeCopy = _.cloneDeep(giftcardType);
        if (!_.isNil(giftcardTypeCopy)) {
            const name = event.target.name
            let value = event.target.value
            if (name === "max_amount" || name === "expiry_days") {
                value = Number(value)
                console.log("DINO NUMBER", value)
            }

            giftcardTypeCopy[event.target.name] = value
            setIsDirty(true)
            setGiftcardType(giftcardTypeCopy)
            setAllowPublishing(!missingRequiredValue(giftcardTypeCopy))
        }
    }

    async function submit(accountId: string, giftcardType: any) {
        const args: any = {
            action: "giftcard-type-create",
            account: accountId,
            type: giftcardType.id,
            data: giftcardType
        };
        setIsPublishing(true)
        const client = httpsCallable(getFunctions(), "clientApi")
        await client(args)
        setIsPublishing(false)
        setIsDirty(false)
    }
}

export default withRoleRouterFunc(GiftcardTypeDetail)

function missingRequiredValue(giftcardType: GiftcardType): boolean {
    if( _.isEmpty(giftcardType.name) ||
        giftcardType.max_amount < 1 ||
        giftcardType.expiry_days < 1 ||
        _.isEmpty(giftcardType.currency) ||
        _.isEmpty(giftcardType.id) ||
        _.isEmpty(giftcardType.name) ||
        _.isEmpty(giftcardType.email_from_address) ||
        _.isEmpty(giftcardType.email_subject) ||
        _.isEmpty(giftcardType.email_from_name) ||
        _.isEmpty(giftcardType.locale) ||
        _.isEmpty(giftcardType.timezone)) {
            console.log("MISSING", giftcardType)
            return true
        }
        return false
}

async function publishGiftcardScaffoldTemplate(accountId: string, type: string) {
    const args: any = {
        action: "giftcard-template-create",
        account: accountId,
        type: type,
        data: defaultPDFTemplate,
        template: "pdf"
    };
    const client = httpsCallable(getFunctions(), "clientApi")
    await client(args)
}

type TemplateType = "pdf" | "email"

async function publishDefaultTemplate(accountId: string, giftcardTypeId: string, data: any, templateType: TemplateType) {
    const args: any = {
        action: "giftcard-template-create",
        account: accountId,
        type: giftcardTypeId,
        data: data,
        template: templateType
    };
    const client = httpsCallable(getFunctions(), "clientApi")
    await client(args)
}

function getBackgrounColor(isValid: boolean): string {
    return isValid ? "white" : "#FFCCCC"
}

function fetchGiftcardType(accountId: string, type: string): () => Promise<HttpsCallableResult> {
    const args: any = {
        action: "giftcard-type-read",
        account: accountId,
        type: type
    };

    const fetchGiftcardType = async () => {
        const client = httpsCallable(getFunctions(), "clientApi");
        return await client(args);
    };
    return fetchGiftcardType
}

async function getGiftcardTypesIds(accountId: string): Promise<string[]> {
    const args: any = {
        action: "giftcard-type-list",
        account: accountId
    };

    const client = httpsCallable(getFunctions(), "clientApi");
    const value = await client(args);

    const data = (value.data as any[])
    const giftcardTypes: string[] = data.flatMap((type: any) => {
        const value = type.id
        return value ? [value] : []
    })
    return giftcardTypes
}