import dayjs from "dayjs"
import { StripedTable } from "../../StripedTable"
import { filterDescription } from "../StockCountList/StockCountOpenModal"
import { Modal } from "react-bootstrap"
import { StockCountRequest } from "../../../models/StockCountRequest"
import { Attribute } from "../../../models/Product"
import { useEffect, useState } from "react"
import React from "react"
import * as _ from "lodash"
import { firestore } from "../../../config/constants"
import { stockCountRequestStatus } from "./StockCountRequestList"
import { FormattedTime } from "react-intl"
import { collection, doc, onSnapshot } from "firebase/firestore"

interface Item {
    id: string
    name: string
}

interface StockCountRequestModalProps {
    accountId: string
    request: StockCountRequest
    requestId: string
    onComplete: () => void
    attributes?: _.Dictionary<Attribute>
    shops: Item[]
}

interface RequestStatus {
    shopName: string
    shopId: string
    status: "completed" | "cancelled" | "in_progress" | "created"
    closeDate?: Date
}

function requestCollectionRef(accountId: string) {
    return collection(firestore, `accounts/${accountId}/stock_count_requests`)
}

export function StockCountRequestStatusModal(props: StockCountRequestModalProps) {
    const thisMorning = dayjs().startOf("day").toDate()

    const [countStatuses, setCountStatuses] = useState<RequestStatus[]>([])

    useEffect(() => {
        const collectionRef = requestCollectionRef(props.accountId)
        const statusRef = collection(doc(collectionRef, props.requestId), "status")
        const unregister = onSnapshot(statusRef, snap => {
            const statuses: RequestStatus[] = []
            for (const document of snap.docs) {
                const shopName = props.shops.find(shop => { return shop.id === document.id })?.name ?? document.id
                const data = document.data() ?? {}
                const status = data.status
                const closeDate = data.close_date?.toDate()
                statuses.push({
                    shopName: shopName,
                    shopId: document.id,
                    status: status,
                    closeDate: closeDate
                })
            }
            setCountStatuses(statuses)
        })

        return () => {
            unregister()
        }
    }, [props.accountId])

    const completed = props.request.status !== "created" && props.request.status !== "in_progress"

    return <Modal size="lg" show={true} onHide={() => props.onComplete()}>
        <Modal.Header>
            <Modal.Title>
                {props.request.name}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <dl>
                <dt>Created</dt>
                <dd>
                    <FormattedTime
                        value={props.request.creationDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </dd>
            </dl>
            <dl>
                <dt>Due</dt>
                <dd style={{ color: (!completed && (props.request.dueDate < thisMorning)) ? "#FF0000" : "black" }}>
                    <FormattedTime
                        value={props.request.dueDate}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </dd>
            </dl>
            <dl>
                <dt>Stock count request description</dt>
                <dd className="mb-3" dangerouslySetInnerHTML={{ __html: filterDescription(props.request.filter, "stock_count_request", props.attributes).join("") }} />
            </dl>

            <StripedTable>
                <tbody>
                    {
                        countStatuses.sort((a, b) => { return a.shopName.localeCompare(b.shopName) }).map(status => {
                            return <tr key={status.shopId}>
                                <td>
                                    {status.shopName}
                                </td>
                                <td>
                                    {stockCountRequestStatus(status.status)}
                                </td>
                                <td>
                                    {!_.isNil(status.closeDate) ? dayjs(status.closeDate).format("YYYY-MM-DD HH:mm") : ""}
                                </td>
                            </tr>
                        })
                    }
                </tbody>

            </StripedTable>

        </Modal.Body>
    </Modal>
}

