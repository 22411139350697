import * as _ from "lodash"
import * as uuid from "uuid"

export class Cashier {

    static fromJSON(json: any): Cashier | null {
        if (_.isNil(json)) {
            return null
        }

        if ((!json.displayName && typeof json.displayName === "string") || (!json.identifier && typeof json.identifier === "string")) {
            return null
        }

        const cashier = new Cashier(json.identifier, json.display_name)
        cashier.fullName = json.full_name || null
        cashier.imageURL = json.image_url || null
        cashier.pinCode = json.pin_code || null
        cashier.cashierRoleId = json.cashier_role_id || null
        cashier.created = json.created || null
        cashier.lastUpdated = json.last_updated || null

        return cashier
    }

    static get blank(): Cashier {
        return new Cashier(uuid.v4().toUpperCase(), "")
    }

    id: string
    displayName: string
    fullName: string | null
    imageURL: string | null
    pinCode: string | null
    cashierRoleId: string | null
    created: number | null
    lastUpdated: number | null

    constructor(id: string, displayName: string) {
        this.id = id
        this.displayName = displayName
        this.fullName = null
        this.imageURL = null
        this.pinCode = null
        this.cashierRoleId = null
        this.created = null
        this.lastUpdated = null
    }

    toJSON(): any {
        const json: any = {
            identifier: this.id,
            display_name: this.displayName
        }

        if (!_.isNull(this.fullName) && this.fullName.length !== 0) {
            json.full_name = this.fullName
        }

        if (!_.isNull(this.imageURL) && this.imageURL.length !== 0) {
            json.image_url = this.imageURL
        }

        if (!_.isNull(this.pinCode) && this.pinCode.length !== 0) {
            json.pin_code = this.pinCode
        }
        if (!_.isNull(this.cashierRoleId) && this.cashierRoleId.length !== 0) {
            json.cashier_role_id = this.cashierRoleId
        }
        if (!_.isNull(this.created)) {
            json.created = this.created
        }
        if (!_.isNull(this.lastUpdated)) {
            json.last_updated = this.lastUpdated
        }

        return json
    }

}