//
//  ConfirmDeleteButton.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 31/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button } from "../components/wrappers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition, faTrash } from "@fortawesome/free-solid-svg-icons"

interface ConfirmDeleteButtonProps {
    message: string
    onDelete(): void
    className?: string
    deletionText?: string
    deletionIcon?: IconDefinition
    variant?: string
    disabled?: boolean
}

export class ConfirmDeleteButton extends React.Component<ConfirmDeleteButtonProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onDelete()
        }
    }

    render() {
        return <Button disabled={this.props.disabled ?? false} className={this.props.className} variant={this.props.variant ?? "danger"} onClick={(event) => { this.onClick(); event.stopPropagation() }}>{ this.props.deletionText ? <span>{this.props.deletionText}</span> : <FontAwesomeIcon icon={this.props.deletionIcon ?? faTrash}/> }</Button>
    }
}
