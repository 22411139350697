import { child, DataSnapshot, off, onValue } from "firebase/database"
import { currentDatabaseRef } from "../config/constants"
import { Attribute } from "../models/Product"
import * as _ from "lodash"

type AttributeType = "customer_attributes" | "product_attributes"

export class AttributeObserver {

    // Properties

    accountId: string
    attributesArray?: Attribute[]
    attributesDict?: _.Dictionary<Attribute>
    attributesChangedCallback: () => void = () => {}
    type: AttributeType

    // Lifecycle

    constructor(accountId: string, type: AttributeType = "product_attributes") {
        this.accountId = accountId
        this.attributesArray = undefined
        this.attributesDict = undefined
        this.type = type
    }

    // Public methods
    
    start() {
        this.observeAttributes()
    }
    
    stop() {
        off(this.attributesRef())
    }

    // Private methods

    private attributesRef() {
        if (this.type === "product_attributes") {
            return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/inventory/attributes`)
        } else {
            return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/inventory/customer_attributes`)
        }
    }
    
    private observeAttributes() {
        const attributesRef = this.attributesRef()
        if (!attributesRef) {
            return
        }
        onValue(attributesRef, (snapshot: DataSnapshot) => {
            const attributesDict = snapshot.val()
            if (!attributesDict) { 
                // Set tags state to empty array in case we've just deleted the last product
                this.attributesArray = []
                this.attributesDict = {}

                if (this.attributesChangedCallback) {
                    this.attributesChangedCallback()
                }
                return
            }
    
            const keys = Object.keys(attributesDict)
            const values: Attribute[] = keys.map(v => {
                return new Attribute(attributesDict[v])
            })
    
            const sorted = values.sort((a: Attribute, b: Attribute) => {
                return a.id < b.id ? -1 : 1
            })

            this.attributesArray = sorted
            this.attributesDict = _.reduce(values, (aggregated, value: Attribute, key) => {
                aggregated[value.id] = value
                return aggregated
            }, {})

            if (this.attributesChangedCallback) {
                this.attributesChangedCallback()
            }
        })
    }
}