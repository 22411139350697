import "react-dropzone-component/styles/filepicker.css" //tslint:disable-line
import "dropzone/dist/dropzone.css" //tslint:disable-line
import { DropzoneComponent, DropzoneComponentConfig, DropzoneComponentHandlers, DropzoneComponentProps } from "react-dropzone-component"
import * as Dropzone from "dropzone"
import { storage } from "../config/constants"
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import * as ReactDOM from "react-dom"
import React from "react"

interface FirebaseDropzoneConfig extends DropzoneComponentConfig {
    postPath: string
}

interface FirebaseStorageDropzoneComponentProps extends DropzoneComponentProps {
    isPublic?: boolean
    config: FirebaseDropzoneConfig
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post'ing.
// More fiddling may be necessary in the future.

export class FirebaseStorageDropzoneComponent extends DropzoneComponent<FirebaseStorageDropzoneComponentProps> {

    dropzone?: Dropzone
    componentDidMount() {

        if (super.componentDidMount) {
            super.componentDidMount()
        }

        const anyThis = this
        if (this.dropzone) {
            this.dropzone.uploadFiles = function (files: any[]) {
                const file = files[0]
                const filename = file.nameForUpload ? file.nameForUpload : file.name

                const fileRef = ref(storage, anyThis.props.config.postPath + filename.split(".")[0])
                const uploadTask = uploadBytesResumable(fileRef, file)

                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on("state_changed", (snapshot: any) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    console.log("PROGRESS, emitting", progress)
                    this.emit("uploadprogress", file, progress, file.upload.bytesSent)
                }, (error: any): any => {
                    // Handle unsuccessful uploads
                    console.log("ERROR", error)
                    return ""
                }, async () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    let downloadURL: string = await getDownloadURL(fileRef)

                    // Remove 'token' query parameter from URL if public
                    const isPublic = anyThis.props.isPublic || false
                    if (isPublic) {
                        const comps = downloadURL.split("?")
                        if (comps.length === 2) {
                            const params = comps[1].split("&")
                            const newParams: string[] = []
                            for (const param of params) {
                                if (!param.startsWith("token=")) {
                                    newParams.push(param)
                                }
                            }
                            comps[1] = newParams.join("&")
                            downloadURL = comps.join("?")
                        }
                    }

                     console.log('Upload is complete with dl URL ' + downloadURL);
                    (this as any)._finished(files, { message: "Upload complete", url: downloadURL })
                    return undefined as any
                })
            }

        }
    }
}
