import * as _ from "lodash"
import { CouponValues, Expiration, RuleModel, isFixedExpiration, isRelativeExpiration } from "../../../../models/RuleModels"

export function updateCouponValues<T extends keyof CouponValues>(key: T, value: CouponValues[T], currentCouponValues?: CouponValues): CouponValues {
    if (!_.isNil(currentCouponValues) && _.isEqual(currentCouponValues?.[key], value)) {
        return currentCouponValues
    }
    const couponValues = currentCouponValues ?? new CouponValues()
    couponValues[key] = value
    
    return couponValues
}


export function updateExpiration(clone: any, expiration: Expiration | undefined): any {
    if (!_.isNil(expiration) && isFixedExpiration(expiration)) {
        // Set the optional time limit to match the fixed expiration
        clone.start_date = expiration.start_date
        clone.end_date = expiration.end_date
    } else {
        delete clone.start_date
        delete clone.end_date
    }
    clone.coupon = updateCouponValues('expiration', expiration, clone.coupon)
    return clone
}

export function validateCoupon(isCoupon: boolean = false, coupon?: CouponValues): boolean {
    if (isCoupon === true) {
        if(
            _.isNil(coupon) || 
            _.isNil(coupon.title) || 
            _.isEmpty(coupon.title) || 
            _.isNil(coupon.template) || 
            isInvalid(coupon.expiration)
        ){
            return false
        }
    }
    return true
}

function isInvalid(expiration?: Expiration): boolean {
    if (_.isNil(expiration)) { 
        return true 
    }
    if (isFixedExpiration(expiration)) {
        if (
            _.isNil(expiration.end_date) || 
            _.isNaN(expiration.end_date.day) || 
            _.isNil(expiration.start_date) || 
            _.isNaN(expiration.start_date.day)) {
            return true
        }
        return false
    } else if (isRelativeExpiration(expiration)) {
        if (_.isNil(expiration.count) || _.isNil(expiration.date_component)) {
            return true
        }
        return false
    }
    return true
}