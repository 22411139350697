//
//  RuleEdit.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 28/05/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Form, Card } from "../../wrappers"
import { LanguagePicker } from "../../LanguagePicker"
import { RuleTemplateFormComponent } from "./RuleTemplateForm"
import { ProductObserver } from "../../../helpers/productObserver"
import { currentDatabaseRef } from "../../../config/constants"
import * as _ from "lodash"
import * as Models from "../../../models/RuleModels"
import * as React from "react"
import LoadingButton, { PageState } from "../../PageState"
import { ChannelSelector } from "../../ChannelSelector"
import { LanguageCode } from "../../../helpers/L10n"
import { Globals } from "../../../helpers/globals"
import { TagObserver } from "../../../helpers/tagObserver"
import { Market } from "../../../models/MarketModels"
import { CustomizableTemplate } from "./Customizable/Model"
import { BundleForm } from "./Bundle/BundleForm"
import { BundleTemplate } from "./Bundle/Model"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { BuyXGetYTemplate } from "./BuyXGetY/Model"
import { BuyXGetYForm } from "./BuyXGetY/BuyXGetYForm"
import { RoleRouterProps, withRoleRouter } from "../../../routes"
import { CustomizableForm } from "./Customizable/CustomizableForm"
import { MarketPicker } from "../../MarketPicker"
import { Stack } from "react-bootstrap"
import { child, get, push, set } from "firebase/database"

interface RuleEditState {
    key?: string
    type: Models.TemplateType
    metadata: Models.Metadata
    markets: Market[]
    shops: _.Dictionary<string>
    currentMarket: Market | null
    template?: Models.RuleModel
    currentLanguage?: LanguageCode
    dirty: boolean
    ruleAndMetadataLoaded: boolean
    productsLoaded: boolean
    tagsLoaded: boolean
    attributesLoaded: boolean
    customerAttributesLoaded: boolean
    publishing: boolean
    customerLookupEnabled: boolean
    selectedMarkets: string[]
    showId: boolean
    availableCouponTemplates?: Models.CouponTemplate[]
    selectedCouponTemplate?: Models.CouponTemplate
}

export type DiscountOverallType = "discount" | "coupon" | "offer"

interface RuleEditProps extends RoleRouterProps {
    type: DiscountOverallType
}

class RuleEdit extends React.Component<RuleEditProps, RuleEditState> {

    // Properties

    productObserver: ProductObserver
    tagObserver: TagObserver
    attributesObserver: AttributeObserver
    customerAttributesObserver: AttributeObserver

    // Lifecycle

    constructor(props: RuleEditProps) {
        super(props)

        this.productObserver = new ProductObserver(this.props.role.account_id)
        this.productObserver.productsChangedCallback = () => {
            this.setState({ productsLoaded: true })
        }
        this.tagObserver = new TagObserver(this.props.role.account_id)
        this.tagObserver.tagsChangedCallback = () => {
            this.setState({ tagsLoaded: true })
        }
        this.attributesObserver = new AttributeObserver(this.props.role.account_id)
        this.attributesObserver.attributesChangedCallback = () => {
            this.setState({ attributesLoaded: true })
        }

        this.customerAttributesObserver = new AttributeObserver(this.props.role.account_id, "customer_attributes")
        this.customerAttributesObserver.attributesChangedCallback = () => {
            this.setState({ customerAttributesLoaded: true })
        }

        const key = this.props.router.params.ruleKey !== "new" ? this.props.router.params.ruleKey : undefined
        const type = this.extractTypeFromQueryParameter()

        this.state = {
            key: key,
            type: type,
            metadata: new Models.Metadata({}, {}),
            currentLanguage: undefined,
            dirty: false,
            ruleAndMetadataLoaded: false,
            productsLoaded: false,
            tagsLoaded: false,
            publishing: false,
            customerLookupEnabled: false,
            markets: [],
            shops: {},
            currentMarket: null,
            selectedMarkets: [],
            attributesLoaded: false,
            customerAttributesLoaded: false,
            showId: false,
            availableCouponTemplates: [],
            selectedCouponTemplate: undefined
        }
    }

    // Component

    async componentDidMount() {
        this.productObserver.start()
        this.tagObserver.start()
        this.attributesObserver.start()
        this.customerAttributesObserver.start()
        await this.load()
    }

    componentWillUnmount() {
        this.productObserver.stop()
        this.tagObserver.stop()
        this.attributesObserver.stop()
        this.customerAttributesObserver.stop()
    }

    embedInCard(): boolean {
        return (this.state.type !== Models.TemplateType.Customizable) &&
            (this.state.type !== Models.TemplateType.Bundle) &&
            (this.state.type !== Models.TemplateType.BuyXGetY)
    }
    setMaxWidth() {
        return this.state.type !== (Models.TemplateType.Customizable || Models.TemplateType.Bundle || Models.TemplateType.BuyXGetY || Models.TemplateType.LoyaltyPoints) ? "none" : 625
    }

    render() {
        return (
            <PageState loading={!this.state.productsLoaded || !this.state.ruleAndMetadataLoaded || !this.state.tagsLoaded || !this.state.attributesLoaded || !this.state.customerAttributesLoaded} typeName="rule data" publishing={this.state.publishing} dirty={this.state.dirty} submit_action={async () => {
                this.publish()
            }} discard_action={() => {
                this.props.router.navigate(-1)
            }} submit_disabled={this.publishDisabled()}>

                <div style={{ maxWidth: this.setMaxWidth(), marginLeft: "auto", marginRight: "auto" }}>

                    <Form onSubmit={e => e.preventDefault()}>
                        <Stack direction="horizontal" className="pb-2" gap={2}>
                            <div className="ms-auto" />
                            <MarketPicker
                                role={this.props.role}
                                currentMarket={this.state.currentMarket}
                                resolveMarkets={() => { return Object.keys(this.state.metadata.markets ?? {}) }}
                                typeName="product"
                                onMarketSelect={market => { this.handleCurrentMarketChange(market) }}
                                addMarket={(market) => { this.addMarket(market) }}
                                removeMarket={(market) => { this.removeMarket(market) }}
                            />
                            <LanguagePicker
                                typeName="product"
                                initialLanguage={this.state.currentLanguage ?? null}
                                resolveLanguages={() => { return this.resolveLanguages() }}
                                onChange={(language) => { this.setLanguage(language) }}
                                onRemove={(language) => { this.removeLanguage(language) }}
                            />
                        </Stack>
                    </Form>

                    {this.embedInCard() ?
                        <Card className="my-4">
                            <Card.Header>{!this.state.key ? `New discount rule - ${Models.typeToNameMap[this.state.type]}` : `Editing rule - ${Models.typeToNameMap[this.state.type]}`}</Card.Header>
                            <Card.Body>
                                {this.renderBody()}
                            </Card.Body>
                        </Card>
                        : this.renderBody()}
                </div>
            </PageState>
        )
    }

    renderBody() {
        return <div>
            {this.ruleTemplateForm()}

            < br />
            <br />

            <Form onSubmit={e => e.preventDefault()}>
                <ChannelSelector
                    selectedChannels={Object.keys(this.state.metadata?.channels ?? {})}
                    onChange={this.handleChannelsChange}
                />
            </Form>
        </div>
    }

    // Methods

    async load() {
        const accountId = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${accountId}`)

        let ruleSnapshot: any
        if (this.state.key) {
            ruleSnapshot = await get(child(accountRef, `/inventory/campaign_template_repo/${this.state.key}`))
        }

        const showIdSnap = await get(child(accountRef, `configuration/backoffice/show_product_id`))
        const showId = showIdSnap.val() ?? false
        const shops = await Globals.shared.getShops()
        const markets = await Globals.shared.getMarkets()
        const channels = await Globals.shared.getChannels()
        await new Promise<void>(resolve => {
            this.setState({ markets: markets, shops: shops, showId: showId }, () => {
                resolve()
            })
        })

        const stateChange: any = { ruleAndMetadataLoaded: true, metadata: new Models.Metadata({}, {}) }
        if (markets.length === 1) {
            stateChange.metadata.markets = { [markets[0].id]: true }
        }
        if (channels.length === 1) {
            stateChange.metadata.channels = { [channels[0].id]: true }
        }

        const customerLookupEnabled = (await get(child(accountRef, "configuration/modules/customer_lookup/enabled"))).val() ? true : false
        stateChange.customerLookupEnabled = customerLookupEnabled

        if (this.props.type === "coupon") {
            await this.fetchAvailableCouponTypes()
        }

        if (ruleSnapshot && ruleSnapshot.exists()) {
            const rule = ruleSnapshot.val()
            const metadata = rule.metadata || new Models.Metadata({}, {})
            if (markets.length === 1) {
                metadata.markets = { [markets[0].id]: true }
            }
            if (channels.length === 1) {
                metadata.channels = { [channels[0].id]: true }
            }

            const type = this.extractTypeFromRuleTemplateObject(rule)
            if (type) {
                stateChange.metadata = metadata
                if (type === Models.TemplateType.Customizable) {
                    const template = new CustomizableTemplate(rule[type])
                    stateChange.template = template
                } else if (type === Models.TemplateType.Bundle) {
                    const template = new BundleTemplate(rule[type])
                    stateChange.template = template
                } else if (type === Models.TemplateType.BuyXGetY) {
                    const template = new BuyXGetYTemplate(rule[type])
                    stateChange.template = template
                } else if (type === Models.TemplateType.LoyaltyPoints) {
                    const template = new CustomizableTemplate(rule[type])
                    stateChange.template = template
                } else {
                    const template = new Models.LegacyRuleModel(type, rule[type])
                    stateChange.template = template
                }
                stateChange.type = type
            }
        }

        const selectedMarkets: string[] = Object.keys(stateChange.metadata?.markets ?? {})
        stateChange.selectedMarkets = selectedMarkets
        stateChange.currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState(stateChange)
    }

    extractTypeFromQueryParameter(): Models.TemplateType {
        const val = this.props.router.location.search.slice(5) //parsing shortcut
        const availableAsStrings: string[] = Models.allTemplateTypes
        const index = availableAsStrings.indexOf(val)
        return index > -1 ? Models.allTemplateTypes[index] : Models.allTemplateTypes[0]
    }

    extractTypeFromRuleTemplateObject(object: Object): Models.TemplateType | null {
        for (const type of Models.allTemplateTypes) {
            if (!_.isNil(object[type])) {
                return type
            }
        }
        return null
    }

    createRuleTemplateFromType(templateType: Models.TemplateType, type: DiscountOverallType): Models.RuleModel {
        if (templateType === Models.TemplateType.Customizable) {
            return new CustomizableTemplate(this.getCouponRelatedValues(type, true) ?? {})
        } else if (templateType === Models.TemplateType.Bundle) {
            return new BundleTemplate(this.getCouponRelatedValues(type, false) ?? {})
        } else if (templateType === Models.TemplateType.BuyXGetY) {
            return new BuyXGetYTemplate(this.getCouponRelatedValues(type, true) ?? {})
        } else if (templateType === Models.TemplateType.LoyaltyPoints) {
            return new CustomizableTemplate({ applies_to: { basket: true } })
        } else {
            return new Models.LegacyRuleModel(templateType, undefined)
        }
    }

    getCouponRelatedValues(type: DiscountOverallType, appliesToBasket: boolean): object | undefined {
        if (type !== "coupon") { return undefined }

        const values = {
            coupon: {
                expiration: {
                    date_component: "year",
                    count: 3
                }
            }
        }

        if (appliesToBasket) {
            values["applies_to"] = { basket: true }
        }

        return values
    }

    handleChannelsChange = (data: any) => {
        const channels: _.Dictionary<boolean> = {}

        for (const channel of data) {
            channels[channel] = true
        }

        const metadata = _.cloneDeep(this.state.metadata)
        metadata.channels = channels

        this.setState({ metadata: metadata, dirty: true })
    }

    addMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets ?? {}
        markets[market] = true
        metadata.markets = markets

        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    removeMarket = (market: string) => {
        const metadata = _.cloneDeep(this.state.metadata)
        const markets = metadata.markets || {}
        delete markets[market]
        metadata.markets = markets
        const selectedMarkets = Object.keys(metadata.markets)
        const currentMarket = this.updateCurrentMarket(selectedMarkets)
        this.setState({ metadata: metadata, currentMarket: currentMarket, selectedMarkets: selectedMarkets, dirty: true })
    }

    publishDisabled(): boolean {
        if (this.state.markets.length > 1 && this.state.currentMarket === null) {
            return true
        }
        if (!this.state.dirty) {
            return true
        }
        if (!this.state.template) {
            return true
        }
        if (!this.state.template.valid(this.state.selectedMarkets)) {
            return true
        }
        return false
    }

    publish = async () => {
        const type = this.state.type
        const template = this.state.template
        if (!type || !template) {
            return
        }

        let json = template.json()
        const rule = {
            metadata: this.state.metadata,
            [type]: json
        }

        this.setState({ publishing: true })

        const accountId = this.props.role.account_id
        let key = this.state.key
        if (key === undefined) {
            const aref = push(child(currentDatabaseRef(), `v1/accounts/${accountId}/inventory/campaign_template_repo`))
            key = aref.key || undefined
        }

        if (key !== undefined) {
            rule[type].id = key
            await set(child(currentDatabaseRef(), `v1/accounts/${accountId}/inventory/campaign_template_repo/${key}`), rule)
        }

        this.props.router.navigate("/rules")
    }

    availableCouponTemplatesRef() {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/modules/loyalty_club/coupons/available_types`)
    }

    async fetchAvailableCouponTypes() {
        const snapshot = await get(this.availableCouponTemplatesRef())
        if (snapshot.exists()) {
            const allCouponTemplates = snapshot.val()

            this.setState({
                availableCouponTemplates: allCouponTemplates
            })
        }
    }

    ruleTemplateForm() {
        const template = this.state.template ?? this.createRuleTemplateFromType(this.state.type, this.props.type)
        template.type = this.props.type
        if (Models.useLegacyEditor(this.state.type)) {
            return (
                <RuleTemplateFormComponent
                    role={this.props.role}
                    template={_.cloneDeep(template as Models.LegacyRuleModel)}
                    productObserver={this.productObserver}
                    tagsObserver={this.tagObserver}
                    currentMarket={this.resolvedMarket()}
                    selectedMarkets={this.state.selectedMarkets}
                    onTemplateChanged={(t) => {
                        this.setState({ template: t, dirty: true })
                    }}
                    currentLanguage={() => {
                        return this.state.currentLanguage || null
                    }}
                    resolvedCurrency={() => {
                        return this.resolvedCurrency()
                    }}
                    customerLookupEnabled={this.state.customerLookupEnabled}
                />
            )
        } else if (this.state.type === Models.TemplateType.Customizable) {
            return (
                <CustomizableForm
                    allShops={this.state.shops}
                    markets={Object.keys(this.state.metadata?.markets ?? {})}
                    currentLanguage={() => { return this.state.currentLanguage || null }}
                    template={template as CustomizableTemplate}
                    formType="discount"
                    onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }}
                    productObserver={this.productObserver}
                    tagObserver={this.tagObserver}
                    attributeObserver={this.attributesObserver}
                    customerAttributeObserver={this.customerAttributesObserver}
                    market={this.resolvedMarket()}
                    showId={this.state.showId}
                    type={this.props.type}
                    couponTemplates={this.state.availableCouponTemplates}
                />)
        } else if (this.state.type === Models.TemplateType.Bundle) {
            return (<BundleForm showId={this.state.showId} type={this.props.type} couponTemplates={this.state.availableCouponTemplates} allShops={this.state.shops} markets={Object.keys(this.state.metadata?.markets ?? {})} currentLanguage={() => { return this.state.currentLanguage || null }} template={template as BundleTemplate} onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }} productObserver={this.productObserver} tagObserver={this.tagObserver} attributeObserver={this.attributesObserver} customerAttributeObserver={this.customerAttributesObserver} market={this.resolvedMarket()} />)
        } else if (this.state.type === Models.TemplateType.BuyXGetY) {
            return (<BuyXGetYForm showId={this.state.showId} type={this.props.type} couponTemplates={this.state.availableCouponTemplates} allShops={this.state.shops} markets={Object.keys(this.state.metadata?.markets ?? {})} currentLanguage={() => { return this.state.currentLanguage || null }} template={template as BuyXGetYTemplate} onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }} productObserver={this.productObserver} tagObserver={this.tagObserver} attributeObserver={this.attributesObserver} customerAttributeObserver={this.customerAttributesObserver} market={this.resolvedMarket()} />)
        } else if (this.state.type === Models.TemplateType.LoyaltyPoints) {
            return (
                <CustomizableForm
                    allShops={this.state.shops}
                    markets={Object.keys(this.state.metadata?.markets ?? {})}
                    currentLanguage={() => { return this.state.currentLanguage || null }}
                    template={template as CustomizableTemplate}
                    formType="loyalty_points"
                    onTemplateChanged={(t) => { this.setState({ template: t, dirty: true }) }}
                    productObserver={this.productObserver}
                    tagObserver={this.tagObserver}
                    attributeObserver={this.attributesObserver}
                    customerAttributeObserver={this.customerAttributesObserver}
                    market={this.resolvedMarket()}
                    showId={this.state.showId}
                    type="discount"
                />)
        } else {
            return (<div>Unknown template type: {this.state.type}</div>)
        }
    }

    resolveLanguages(): LanguageCode[] {
        if (!this.state || !this.state.template) {
            return []
        }
        const localizations = new Set<LanguageCode>()
        if (this.state.template.display_name) {
            this.state.template.display_name.localizations().forEach(language => {
                localizations.add(language)
            })
        }
        return Array.from(localizations).sort()
    }

    setLanguage(language: LanguageCode | null) {
        this.setState({ currentLanguage: language || undefined })

        if (_.isNil(language)) { return }
        if (_.isNil(this.state.template)) { return }

        const languages = this.resolveLanguages()
        if (!languages.includes(language)) {
            const template = _.cloneDeep(this.state.template)
            if (!_.isNil(template.display_name)) {
                template.display_name.localizeTo(language)
                this.setState({ template: template })
            }
        }
    }

    removeLanguage(language: LanguageCode | null) {
        if (!language) { return }
        const template = this.state.template
        if (template && template.display_name) {
            template.display_name.removeLocalization(language)
        }
        this.setState({ template: template, currentLanguage: undefined, dirty: true })
    }

    updateCurrentMarket(selectedMarkets: string[]): Market | null {
        let currentMarket = this.state.currentMarket
        let currentMarketKey = currentMarket?.id ?? null

        if (selectedMarkets.length < 1) {
            return null
        }

        if (currentMarketKey && !selectedMarkets.includes[currentMarketKey]) {
            currentMarketKey = null
        }

        if (currentMarketKey === null) {
            currentMarketKey = selectedMarkets[0]
        }
        currentMarket = this.state.markets.find(market => { return (market.id === currentMarketKey) }) || null
        return currentMarket
    }

    resolvedCurrency(): string {
        const market = this.resolvedMarket()
        return market?.currency ?? ""
    }

    resolvedMarket(): Market | null {
        if (this.state.currentMarket) {
            return this.state.currentMarket
        }
        if (this.state.markets.length === 1) {
            return this.state.markets[0]
        }
        const selectedMarketKeys = Object.keys(this.state.metadata?.markets ?? {})
        if (selectedMarketKeys.length === 1) {
            return this.state.markets.find(market => { return market.id === selectedMarketKeys[0] }) || null
        }
        return null
    }
    handleCurrentMarketChange(market: Market | null) {
        this.setState({ currentMarket: market })
    }
}

export default withRoleRouter(RuleEdit)
