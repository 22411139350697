import React, { useState } from "react";
import { Button, Col, DescriptionCol, DropdownButton, FormControl, FormGroup, MenuItem, Row } from "../../../../../wrappers";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { Background } from "../../../../../../models/pdf_template/background";
import { Margins } from "../../../../../../models/pdf_template/margins";
import { TextValueEdit } from "./ValueEditComponents/TextValueEdit";
import { AlginmentValueEdit } from "./ValueEditComponents/AlginmentValueEdit";
import { ValueEditProps } from "../ComponentEdit";
import { FontButton } from "../../FontButton";

interface TextEditProps extends ValueEditProps {
    customFonts: string[]
}

export function TextEdit(props: TextEditProps) {
    return (
        <Form>
            <TextValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></TextValueEdit>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Text color</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Text color"
                        value={props.lineItem.textColor ?? ""}
                        placeholder="Enter text color"
                        onChange={(event) => {
                            props.updateLineItem((lineItem) => {
                                const newValue = event.currentTarget.value as string;
                                lineItem.textColor = newValue === "" ? undefined : newValue;
                                return lineItem;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Link</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Link"
                        value={props.lineItem.link ?? ""}
                        placeholder="Enter link"
                        onChange={(event) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.link = event.currentTarget.value as string;
                                return lineItem;
                            });
                        }} />
                </Col>
            </FormGroup>
            <AlginmentValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></AlginmentValueEdit>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>Font</DescriptionCol>
                <Col sm={9}>
                    <FontButton mode="pdf" selectedFont={props.lineItem.font} customFonts={props.customFonts} updatedFont={(font) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.font = font
                                return lineItem
                            })
                    }}></FontButton>
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Font size</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Font size"
                        min={0}
                        value={props.lineItem.fontSize ?? 0}
                        placeholder="Enter font size color"
                        onChange={(event) => {
                            props.updateLineItem((lineItem) => {
                                const value = new Number(event.currentTarget.value).valueOf()
                                if (value <= 0) {
                                    lineItem.fontSize = undefined
                                } else {
                                    lineItem.fontSize = value
                                }
                                
                                return lineItem
                            });
                        }} />
                </Col>
            </FormGroup>

            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background color</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Background"
                        value={props.lineItem.background?.color ?? ""}
                        placeholder="Enter background color"
                        onChange={(event) => {
                            updateBackground((background) => {
                                background.color = event.currentTarget.value as string;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background leading margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Background leading margin"
                        value={props.lineItem.background?.margins.leading ?? 0}
                        placeholder="Enter background color"
                        onChange={(event) => {
                            updateBackground((background) => {
                                background.margins.leading = new Number(event.currentTarget.value).valueOf();
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background trailing margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Background trailing margin"
                        value={props.lineItem.background?.margins.trailing ?? 0}
                        placeholder="Enter background trailing margin"
                        onChange={(event) => {
                            updateBackground((background) => {
                                background.margins.trailing = new Number(event.currentTarget.value).valueOf();
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background top margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Background top margin"
                        value={props.lineItem.background?.margins.top ?? 0}
                        placeholder="Enter background top margin"
                        onChange={(event) => {
                            updateBackground((background) => {
                                background.margins.top = new Number(event.currentTarget.value).valueOf();
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background bottom margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Background bottom margin"
                        value={props.lineItem.background?.margins.bottom ?? 0}
                        placeholder="Enter background bottom margin"
                        onChange={(event) => {
                            updateBackground((background) => {
                                background.margins.bottom = new Number(event.currentTarget.value).valueOf();
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
        </Form>
    );


    function updateBackground(callback: (background: Background) => (Background)) {
        let updatedBackground = _.cloneDeep(props.lineItem.background);
        
        if (_.isNil(updatedBackground)) {
            const margins = new Margins()
            updatedBackground = new Background("", margins)
        }

        updatedBackground = callback(updatedBackground);
        if (isBackgroundEmpty(updatedBackground)) {
            updatedBackground = undefined;
        }

        props.updateLineItem((lineItem) => {
            lineItem.background = updatedBackground
            return lineItem
        })
    }

    function isBackgroundEmpty(background: Background): boolean {
        if (background.color === "" && background.margins.leading === 0 && background.margins.trailing === 0 && background.margins.top === 0 && background.margins.bottom === 0) {
            return true;
        }
        return false;
    }
}
