import * as _ from "lodash"
import { L10nString } from "../../../helpers/L10n"
import { MarketAmount } from "../../../models/MarketAmount"

export enum ProductRuleType {
    CONTAINER_DEPOSIT = "container_deposit"
}

export const typeToNameMap = {
    container_deposit: "Container deposit"
}

export class ContainerDepositRule {
    displayName: L10nString = new L10nString("")
    id?: string
    name: string = ""
    price?: MarketAmount
    productId?: string
    productGroup?: string
    tag?: string

    static fromJSON(json: any): ContainerDepositRule {
        if (_.isNil(json) || _.isNil(json.display_name) || _.isNil(json.name)) {
            throw new Error(`Invalid container deposit json: ${json ? JSON.stringify(json) : json}`)
        }
        const result = new ContainerDepositRule()
        result.displayName = new L10nString(json.display_name)
        result.id = json.id
        result.name = json.name
        if (!_.isNil(json.price)) {
            result.price = new MarketAmount(json.price)
        }
        if (!_.isNil(json.product_id)) {
            result.productId = json.product_id
        }
        result.productGroup = json.product_group
        result.tag = json.tag
        return result
    }

    json(): any {
        if (_.isNil(this.tag)) {
            throw new Error("Missing tag on container deposit rule")
        }
        if (_.isNil(this.id)) {
            throw new Error("Missing id on container deposit rule")
        }
        const val: any = {
            display_name: this.displayName.json(),
            id: this.id,
            name: this.name,
            product_group: !_.isNil(this.productGroup) ? this.productGroup : null,
            tag: this.tag!
        }
        if (!_.isNil(this.productId)) {
            val.product_id = this.productId
        }
        if (!_.isNil(this.price)) {
            val.price = this.price.json()
        }
        return val
    }

    nameValid(): boolean {
        return !_.isNil(this.name)
    }

    displayNameValid(): boolean {
        return !_.isNil(this.displayName)
    }

    productIdValid(): boolean {
        return true // It's currently ok both to have an id and to have none
    }

    priceValid(): boolean {
        return !_.isNil(this.price)
    }

    valid(): boolean {
        const result = (
            this.displayNameValid() &&
            !_.isNil(this.id) &&
            this.id.length > 0 &&
            this.nameValid() &&
            this.productIdValid() &&
            this.priceValid() &&
            !_.isNil(this.tag) &&
            this.tag.length > 0
        )
        return result
    }
}

export class Metadata {
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>

    constructor() {
        this.channels = {}
        this.markets = {}
    }

    static fromJSON(json: any): Metadata {
        const result = new Metadata()
        if (!_.isNil(json) && !_.isNil(json.channels)) {
            result.channels = json.channels ?? {}
        }
        if (!_.isNil(json) && !_.isNil(json.markets)) {
            result.markets = json.markets ?? {}
        }
        return result
    }

    json(): any {
        return {
            channels: this.channels,
            markets: this.markets
        }
    }
}

export class ProductRule {
    containerDeposit?: ContainerDepositRule
    metadata: Metadata

    constructor() {
        this.metadata = new Metadata()
    }

    get displayName(): L10nString | null {
        if (!_.isNil(this.containerDeposit)) {
            return this.containerDeposit.displayName
        }
        return null
    }

    get id(): string | undefined {
        if (!_.isNil(this.containerDeposit)) {
            return this.containerDeposit.id
        }
        return undefined
    }

    static fromJSON(json: any): ProductRule {
        const result = new ProductRule()
        if (!_.isNil(json[ProductRuleType.CONTAINER_DEPOSIT])) {
            result.containerDeposit = ContainerDepositRule.fromJSON(json[ProductRuleType.CONTAINER_DEPOSIT])
        } else {
            throw new Error("Invalid product rule data")
        }
        result.metadata = Metadata.fromJSON(json.metadata)
        return result
    }

    json(): any {
        const result: any = {
            metadata: this.metadata ? this.metadata.json() : null            
        }
        if (!_.isNil(this.containerDeposit)) {
            result.container_deposit = this.containerDeposit.json()
        }
        return result
    }

    valid(): boolean {
        return this.containerDeposit ? this.containerDeposit.valid() : false
    }
}