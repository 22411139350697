//
//  background.ts
//  giftcard-service
//
//  Created by Flemming Pedersen on 03/04/2019.
//  Copyright © 2019 Ka-ching. All rights reserved.
//

import * as _ from "lodash"
import { Margins } from "./margins"

export class Background {

    public static fromJSON(json: any | null): Background | null {
        if (_.isNil(json) || _.isNil(json.color)) {
            return null
        }

        const background = new Background(
            json.color,
            Margins.fromJSON(json.margins) || new Margins(),
        )

        return background
    }

    public color: string
    public margins: Margins

    constructor(color: string, margins: Margins) {
        this.color = color
        this.margins = margins
    }

    public toJSON(): any {
        return {
            color: this.color,
            margins: this.margins.toJSON(),
        }
    }

}
