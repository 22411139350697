import { AppliesTo } from "../AppliesToSelector";
import { CustomerCondition } from "../CustomerConditionSelector";
import { DiscountType } from "../DiscountSelector";
import * as _ from "lodash";
import { CouponTemplate, CouponValues, DateComponents } from "../../../../models/RuleModels";
import { L10nString } from "../../../../helpers/L10n";
import { MarketAmount } from "../../../../models/MarketAmount";
import { validateCoupon } from "../Shared/Helpers";
import { DiscountOverallType } from "../RuleEdit";

interface Discount {
    type: DiscountType;
    value?: number | MarketAmount;
}
function parseDiscountType(value: any): Discount {
    if (value === undefined) {
        return { type: "percentage" };
    }
    if (!_.isNil(value.percentage)) {
        return { type: "percentage", value: value.percentage };
    } else if (!_.isNil(value.amount_off)) {
        return { type: "amount_off", value: new MarketAmount(value.amount_off) };
    } else if (!_.isNil(value.new_price)) {
        return { type: "new_price", value: new MarketAmount(value.new_price) };
    }
    return { type: "percentage" };
}
function parseAppliesTo(value: any): AppliesTo {
    if (value === undefined) {
        return { type: "all", products: [], tags: [], attributes: [] };
    } 
    if (!_.isNil(value.all)) {
        return { type: "all", products: [], tags: [], attributes: [] };
    } else if (!_.isNil(value.basket)) {
        return { type: "basket", products: [], tags: [], attributes: [] };
    } else if (!_.isNil(value.products)) {
        return { type: "products", products: value.products, tags: [], attributes: [] };
    } else if (!_.isNil(value.tags)) {
        return { type: "tags", products: [], tags: value.tags, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", products: [], tags: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }
    return { type: "all", products: [], tags: [], attributes: [] };
}
function parseCustomerCondition(value: any): CustomerCondition {
    if (value === undefined) {
        return { type: "all", customers: [], attributes: [] };
    }
    if (!_.isNil(value.all)) {
        return { type: "all", customers: [], attributes: [] };
    } else if (!_.isNil(value.members)) {
        return { type: "members", customers: [], attributes: [] };
    } else if (!_.isNil(value.customers)) {
        return { type: "customer_selection", customers: value.customers, attributes: [] };
    } else if (!_.isNil(value.attributes)) {
        return {
            type: "attributes", customers: [], attributes: value.attributes.map((json: any) => {
                return {
                    attributeId: json.attribute,
                    optionId: json.option
                };
            })
        };
    }

    return { type: "all", customers: [], attributes: [] };
}

export class BuyXGetYTemplate {
    discountType: DiscountType;
    discountAmount?: MarketAmount;
    discountPercentage?: number
    appliesTo: AppliesTo;
    customerCondition: CustomerCondition;
    priority?: number;
    continue_evaluation?: boolean;
    triggerCount?: number;
    applicationCount?: number;
    start_date?: DateComponents;
    end_date?: DateComponents;
    display_name?: L10nString;
    shop_ids?: string[]
    coupon?: CouponValues
    type: DiscountOverallType

    constructor(json: any) {
        if (!_.isNil(json.display_name)) {
            this.display_name = new L10nString(json.display_name);
        }
        this.customerCondition = parseCustomerCondition(json.customer_condition);
        this.appliesTo = parseAppliesTo(json.applies_to);
        const discount = parseDiscountType(json.discount)
        this.discountType = discount.type
        if (discount.type === "percentage") {
            this.discountPercentage = discount.value as number
        } else {
            this.discountAmount = discount.value as MarketAmount
        }
        this.priority = !_.isNil(json.priority) ? json.priority : undefined;
        if (json.continue_evaluation === true) {
            this.continue_evaluation = true;
        }
        if (!_.isNil(json.start_date)) {
            this.start_date = json.start_date;
        }
        if (!_.isNil(json.end_date)) {
            this.end_date = json.end_date;
        }
        if (!_.isNil(json.application_count)) {
            this.applicationCount = json.application_count
        }
        if (!_.isNil(json.trigger_count)) {
            this.triggerCount = json.trigger_count
        }
        if (!_.isNil(json.shop_ids)) {
            this.shop_ids = json.shop_ids
        }
        if (!_.isNil(json.coupon)) {
            this.coupon = CouponValues.fromJSON(json.coupon)
        }
        if (!_.isNil(json.type)) {
            this.type = json.type
        } else {
            // Backwards compatibility
            this.type = (json.is_coupon === true) ? "coupon" : "discount"            
        }
    }

    setMarkets(allMarkets: string[]): void {
        this.discountAmount?.setMarkets(allMarkets)
    }

    json(): any {
        const value: any = {
            display_name: this.display_name?.json() ?? new L10nString("-"),
            name: this.display_name?.localized(null) ?? "-",
            priority: this.priority ?? 0,
            customer_condition: this.customerConditionsJson(),
            type: this.type
        };
        const discount = {
            [this.discountType]: (this.discountType === "percentage") ? this.discountPercentage ?? 0 : (this.discountAmount ?? new MarketAmount(0)).json()
        };

        value.discount = discount;
        value.applies_to = this.appliesToJson(this.appliesTo);

        if (this.continue_evaluation === true) {
            value.continue_evaluation = true;
        }
        if (!_.isNil(this.start_date)) {
            value.start_date = this.start_date;
        }
        if (!_.isNil(this.end_date)) {
            value.end_date = this.end_date;
        }
        if (!_.isNil(this.applicationCount)) {
            value.application_count = this.applicationCount
        }
        if (!_.isNil(this.triggerCount)) {
            value.trigger_count = this.triggerCount
        }
        if (!_.isNil(this.shop_ids) && this.shop_ids.length > 0) {
            value.shop_ids = this.shop_ids
        }
        if (!_.isNil(this.coupon)) {
            value.coupon = this.coupon.toJSON()
        }

        return value;
    }

    appliesToJson(appliesTo: AppliesTo): any {
        if (appliesTo.type === "all") {
            return { all: true };
        } else if (appliesTo.type === "products") {
            return { products: appliesTo.products };
        } else if (appliesTo.type === "tags") {
            return { tags: appliesTo.tags };
        } else if (appliesTo.type === "attributes") {
            return { attributes: appliesTo.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    customerConditionsJson(): any {
        if (this.customerCondition.type === "all") {
            return { all: true };
        } else if (this.customerCondition.type === "members") {
            return { members: true };
        } else if (this.customerCondition.type === "customer_selection") {
            return { customers: this.customerCondition.customers };
        } else if (this.customerCondition.type === "attributes") {
            return { attributes: this.customerCondition.attributes.map(selection => { return { attribute: selection.attributeId, option: selection.optionId }; }) };
        }
    }

    valid(selectedMarkets: string[]): boolean {
        return this.validateCustomerCondition() &&
            this.validateAppliesTo(this.appliesTo) &&
            this.validateShared() &&
            this.validateDiscount() &&
            validateCoupon(this.type === "coupon", this.coupon)
    }

    validateAppliesTo(appliesTo: AppliesTo): boolean {
        if (appliesTo.type === "products") {
            return appliesTo.products.length !== 0;
        } else if (appliesTo.type === "tags") {
            return appliesTo.tags.length !== 0;
        } else if (appliesTo.type === "attributes") {
            return appliesTo.attributes.length !== 0;
        }
        return true;
    }

    validateCustomerCondition(): boolean {
        if (this.customerCondition.type === "attributes") {
            return this.customerCondition.attributes.length !== 0;
        } else if (this.customerCondition.type === "customer_selection") {
            return this.customerCondition.customers.length !== 0;
        }
        return true;
    }

    validateShared(): boolean {
        if (_.isNil(this.display_name)) {
            return false;
        }
        if (_.isNil(this.priority)) {
            return false;
        }

        return true;
    }

    validateXAndY(): boolean {
        if (_.isNil(this.triggerCount)) {
            return false;
        }
        if (_.isNil(this.applicationCount)) {
            return false;
        }
        if (this.applicationCount >= this.triggerCount) {
            return false
        }

        return true;
    }

    validateDiscount(): boolean {
        if (this.discountType === "percentage") {
            if (_.isNil(this.discountPercentage)) {
                return false
            }
        } else {
            if (_.isNil(this.discountAmount)) {
                return false
            }    
        }
        return true
    }
}
