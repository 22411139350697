//
//  TaxEdit.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 04/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import { Alert, Button, Col, Form, FormControl, FormGroup, Card, ToggleButton, ToggleButtonGroup } from "../wrappers"
import { currentDatabaseRef } from "../../config/constants"
import { TaxType, Tax, taxTypeName } from "../../models/MarketModels"
import * as React from "react"
import LoadingButton, { PageState } from "../PageState"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { Row } from "react-bootstrap"
import * as _ from "lodash"
import { child, get } from "firebase/database"

interface TaxEditState {
    tax: Tax
    new: boolean
    identifier: string
    error: string | null
    dirty: boolean
    publishing: boolean
    loading: boolean
}

class TaxEdit extends React.Component<RoleRouterProps, TaxEditState> {

    // Lifecycle

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            tax: new Tax({ id: "", name: "", rate: 0, type: TaxType.vat }),
            new: true,
            identifier: "",
            error: null,
            dirty: false,
            loading: true,
            publishing: false
        }
    }

    // Component

    async componentDidMount() {
        await this.load()
    }

    render() {
        return (
            <PageState loading={this.state.loading} typeName="tax" publishing={this.state.publishing} dirty={this.state.dirty}>

                <Card className="my-4">
                    <Card.Header>{this.state.new ? `New tax` : `Editing tax: ${this.state.tax.name}`}</Card.Header>
                    <Card.Body>

                        <Form onSubmit={e => e.preventDefault()}>

                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Name</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.tax.name}
                                        placeholder="Enter name"
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.taxesRef()}
                                isNew={this.state.new}
                                typeName="tax"
                                identifierSource={this.state.tax.name}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Tax rate</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="number"
                                        name="rate"
                                        value={this.state.tax.rate === 0 ? "" : Math.round(this.state.tax.rate * 100)}
                                        placeholder="Enter rate"
                                        onChange={this.handleInputChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Tax type</Col>
                                <Col sm={10}>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="currency"
                                        value={taxTypeName(this.state.tax.type)}
                                        onChange={this.handleTaxTypeChange}
                                    >
                                        <ToggleButton id="vat" key="vat" value="vat">VAT</ToggleButton>
                                        <ToggleButton id="sales_tax" key="sales_tax" value="sales_tax">Sales Tax</ToggleButton>
                                    </ToggleButtonGroup>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                    <LoadingButton onClick={this.publish} disabled={!this.state.dirty}/>
                    </Card.Footer>
                </Card>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing Tax:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }

    // Methods

    handleTaxTypeChange = (data: any) => {
        const tax = this.state.tax
        switch (data) {
            case "vat": {
                tax.type = TaxType.vat
                break
            }
            case "sales_tax": {
                tax.type = TaxType.salesTax
                break
            }
            default:
                return
        }
        this.setState({ tax: tax, dirty: true, error: null })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const tax = this.state.tax

        switch (name) {
            case "name": {
                tax.name = value
                break
            }
            case "rate": {
                const rate = Number(value) / 100
                tax.rate = rate
                break
            }
            default:
                return
        }

        this.setState({ tax: tax, dirty: true, error: null })
    }

    async load() {
        const accountId = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${accountId}`)

        const taxKey = this.props.router.params.taxKey !== "new" ? this.props.router.params.taxKey : null
        let isNewTax = true
        let tax = this.state.tax
        if (taxKey) {
            const taxSnap = await get(child(accountRef, `taxes/${taxKey}`))
            if (taxSnap && taxSnap.exists() && taxSnap.val()) {
                const value = taxSnap.val()
                let json = {
                    id: taxSnap.key,
                    name: value.name,
                    type: value.type,
                    rate: value.rate,
                    last_updated: value.last_updated,
                    created: value.created
                }
                tax = new Tax(json)
                isNewTax = false
            }
        }

        this.setState({ tax: tax, loading: false, new: isNewTax, identifier: tax.id })
    }

    taxesRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "taxes")
    }

    publish = async () => {
        const tax = this.state.tax
        const date = new Date().getTime()
        if (_.isNil(tax.created)) {
            tax.created = date
        }
        tax.lastUpdated = date

        const taxJson = tax.json()

        this.setState({ publishing: true })

        try {
            await publish(taxJson, "id", this.state.identifier, this.state.new, this.taxesRef(), true)
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.props.router.navigate("/markets")
    }
}

export default withRoleRouter(TaxEdit)
