import * as _ from "lodash"
import { Product } from "../models/Product"
import { currentDatabaseRef } from "../config/constants"
import { DataSnapshot, child, onValue, off } from "firebase/database"

// Can only observe product repo products at the dayjs

export class ProductObserver {

    // Properties

    accountId: string
    productsArray?: Product[]
    productsDict?: _.Dictionary<Product>
    productsChangedCallback: () => void = () => {}

    // Lifecycle

    constructor(accountId: string) {
        this.accountId = accountId
        this.productsArray = undefined
        this.productsDict = undefined
    }

    // Public methods
    
    start() {
        this.observeProducts()
    }
    
    stop() {
        off(this.productsRef())
    }

    // Private methods

    private productsRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/inventory/product_repo`)
    }
    
    private observeProducts() {
        const productsRef = this.productsRef()
        if (!productsRef) {
            return
        }
        onValue(productsRef, async (snapshot: DataSnapshot) => {
            const productsDict = snapshot.val()
            if (!productsDict) { 
                // Set products state to empty array in case we've just deleted the last product
                this.productsArray = []
                this.productsDict = {}
    
                if (this.productsChangedCallback) {
                    this.productsChangedCallback()
                }
                return
            }
    
            const keys = Object.keys(productsDict)
            const values = keys.map(v => {
                return new Product(productsDict[v].product)
            })
    
            const sorted = values.sort((a: Product, b: Product) => {
                if (a.barcode === b.barcode) {
                    return a.id < b.id ? -1 : 1
                }
                if (a.barcode && b.barcode) {
                    return a.barcode < b.barcode ? -1 : 1
                }
                return a.id < b.id ? -1 : 1
            })
    
            this.productsArray = sorted
            this.productsDict = _.reduce(values, (aggregated, value: Product, key) => {
                aggregated[value.id] = value
                return aggregated
            }, {})
    
            if (this.productsChangedCallback) {
                this.productsChangedCallback()
            }
        })
    }
}