import * as React from "react"

import { Button, Card, FormControl } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import LoadingButton, { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { child, get, set } from "firebase/database"

interface ReceiptBarcodeConfigurationProps {
    role: Role
}

interface ReceiptBarcodeConfigurationState {
    barcodeType: string
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
}

export default class ReceiptBarcodeConfiguration extends React.Component<ReceiptBarcodeConfigurationProps, ReceiptBarcodeConfigurationState> {
    constructor(props: ReceiptBarcodeConfigurationProps) {
        super(props)

        this.state = {
            barcodeType: "qr",
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/receipt/barcode_type`)
        const configSnap = await get(configRef)
        let barcodeType = this.state.barcodeType
        if (configSnap.exists()) {
            barcodeType = configSnap.val()
        }
        this.setState({ barcodeType: barcodeType, loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="contribution ratio">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"} >
                    <Card.Header>
                        {this.state.showSuccess ? "Receipt barcode type updated successsfully" : "Receipt barcode type"}
                    </Card.Header>

                    <Card.Body>
                        <h4>Please select the desired barcode format for the barcode that&apos;s printed on receipts for quick identification of a sale or offer.</h4>
                        <FormControl
                            as="select"
                            name="barcode_type"
                            value={this.state.barcodeType}
                            onChange={(e: any) => { this.barcodeTypeChanged(e.target.value) }}
                        >
                            <option value="qr">QR</option>
                            <option value="code128">Code 128</option>
                        </FormControl>
                    </Card.Body>

                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }

    barcodeTypeChanged = (value: string) => {
        this.setState({ barcodeType: value, dirty: true })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/receipt/barcode_type`)
        console.log("publish", this.state.barcodeType)
        this.setState({ publishing: true })
        await set(configRef, this.state.barcodeType)
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}
