import * as _ from "lodash"

export class EmailTemplate {

    public static fromJSON(json: any): EmailTemplate {
        const lines: EmailTemplateLine[] = []
        json.lines.forEach((lineJSON: any) => {
            const line = EmailTemplateLine.fromJSON(lineJSON)
            if (line) {
                lines.push(line)
            }
        })

        const template = new EmailTemplate(lines)
        template.backgroundColor = json.background_color
        template.title = json.title

        return template
    }

    public title?: string
    public backgroundColor?: string
    public lines: EmailTemplateLine[]

    constructor(lines: EmailTemplateLine[]) {
        this.lines = lines
    }

    public toJSON(): any {
        const json: any = {
            lines: this.lines.map((line) => line.toJSON()),
        }

        if (!_.isNil(this.title)) {
            json.title = this.title
        }

        if (!_.isNil(this.backgroundColor)) {
            json.background_color = this.backgroundColor
        }

        return json
    }
}

export class EmailTemplateLine {
    public static fromJSON(json: any): EmailTemplateLine | null {
        if (!json.text) {
            return null
        }

        const line = new EmailTemplateLine(json.text)
        if (!_.isNil(json.font_size)) {
            const fontSize = Number(json.font_size)
            if (isNaN(fontSize)) {
                return null
            }
            line.fontSize = fontSize
        }

        if (!_.isNil(json.wallet_url)) {
            line.walletURL = true
        }
        line.alignment = json.alignment
        line.textColor = json.text_color
        line.fontFamily = json.font_family
        line.fontWeight = json.font_weight
        line.fontStyle = json.font_style

        return line
    }

    public text: string
    public alignment?: string
    public textColor?: string 
    public fontFamily?: string
    public fontStyle?: string
    public fontWeight?: string
    public fontSize?: number
    public walletURL: boolean

    constructor(text: string) {
        this.text = text
        this.walletURL = false
    }

    public toJSON(): any {
        const json: any = {
            text: this.text,
        }

        if (this.walletURL) {
            json.wallet_url = true
        }

        if (!_.isNil(this.alignment)) {
            json.alignment = this.alignment
        }

        if (!_.isNil(this.fontFamily)) {
            json.font_family = this.fontFamily
        }

        if (!_.isNil(this.fontSize)) {
            json.font_size = this.fontSize
        }

        if (!_.isNil(this.fontStyle)) {
            json.font_style = this.fontStyle
        }

        if (!_.isNil(this.fontWeight)) {
            json.font_weight = this.fontWeight
        }

        if (!_.isNil(this.textColor)) {
            json.text_color = this.textColor
        }

        return json
    }

}