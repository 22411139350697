//
//  margins.ts
//  giftcard-service
//
//  Created by Flemming Pedersen on 03/04/2019.
//  Copyright © 2019 Ka-ching. All rights reserved.
//

import * as _ from "lodash"

export class Margins {

    public static fromJSON(json: any | null): Margins | null {
        if (_.isNil(json)) {
            return null
        }

        const margins = new Margins()

        if (!_.isNil(json.leading)) {
            margins.leading = json.leading
        }

        if (!_.isNil(json.top)) {
            margins.top = json.top
        }

        if (!_.isNil(json.trailing)) {
            margins.trailing = json.trailing
        }

        if (!_.isNil(json.bottom)) {
            margins.bottom = json.bottom
        }

        return margins
    }

    public leading: number = 0
    public top: number = 0
    public trailing: number = 0
    public bottom: number = 0

    public toJSON(): any {
        return {
            bottom: this.bottom,
            leading: this.leading,
            top: this.top,
            trailing: this.trailing,
        }
    }

}
