import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    Button,
    Col,
    DescriptionCol,
    DropdownButton,
    FormControl,
    FormControlStatic,
    FormGroup,
    MenuItem,
    Card,
    Row
} from "../wrappers"
import { Form } from "react-bootstrap"
import {
    allAttributeTypes,
    Attribute,
    AttributeOption,
    AttributeType,
    AttributeTypeKey,
    attributeTypeName
} from "../../models/Product"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { PageState } from "../PageState"
import { publish } from "../../helpers/ModelPublisher"
import { currentDatabaseRef } from "../../config/constants"
import { StripedTable } from "../StripedTable"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { AttributeMode } from "./AttributeList"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { ToggleButton } from "../ToggleButton"
import { child, DatabaseReference, get } from "firebase/database"

interface AttributeEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
    mode: AttributeMode
}

interface AttributeEditState {
    attribute: Attribute
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
    editingOption?: boolean
    editingOptionId?: string
    editingOptionIsNew?: boolean
    editingOptionName?: L10nString
    attributeTypeEditingCache: _.Dictionary<AttributeType>
}

class AttributeEdit extends React.Component<AttributeEditProps, AttributeEditState> {
    constructor(props: AttributeEditProps) {
        super(props)
        const optionsType = new AttributeType({ options: {} })
        const numberType = new AttributeType({ number: { suffix: "", scale: 0 } })
        const textType = new AttributeType({ text: { text: "" } })
        const textEntry = new AttributeType({ text_entry: { max_length: 1000, must_be_unique: true } })
        const dateType = new AttributeType({ date: { date: true } })
        const dateTimeType = new AttributeType({ date_time: { date_time: true } })
        const attributeTypeEditingCache = {
            options: optionsType,
            number: numberType,
            text: textType,
            text_entry: textEntry,
            date: dateType,
            date_time: dateTimeType
        }
        this.state = {
            attribute: new Attribute({
                id: "",
                name: "",
                type: optionsType
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null,
            attributeTypeEditingCache: attributeTypeEditingCache
        }
    }

    pop() {
        const path = this.props.mode === "product" ? `/attributes` : `/customer_attributes`
        this.props.router.navigate(path)
    }

    attributeKey() {
        return this.props.router.params.attributeKey
    }

    isNewAttribute() {
        return this.attributeKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.attribute.name.hasEmptyLocalizations()) {
            return false
        }

        let result = true
        switch (this.state.attribute.typeKey()) {
            case AttributeTypeKey.NUMBER:
                break

            case AttributeTypeKey.OPTIONS: {
                const options = this.state.attribute.type.options
                // We need at least one option
                if (!options) {
                    result = false
                } else if (Object.keys(options).length === 0) {
                    result = false
                } else {
                    for (const optionKey of Object.keys(options)) {
                        const option = options[optionKey]
                        if (option.name.hasEmptyLocalizations()) {
                            result = false
                        }
                    }
                }
                break
            }

            case AttributeTypeKey.TEXT:
                break
        }

        return result
    }

    attributesRef(): DatabaseReference {
        if (this.props.mode === "product") {
            return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/attributes")
        } else {
            return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/customer_attributes")
        }
    }

    async publish() {
        const json = this.state.attribute.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "id", this.state.identifier, this.isNewAttribute(), this.attributesRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewAttribute()) {
            const snapshot = await get(child(this.attributesRef(), this.attributeKey()))
            const attribute = new Attribute(snapshot.val())
            const attributeTypeEditingCache = _.cloneDeep(this.state.attributeTypeEditingCache)
            attributeTypeEditingCache[attribute.typeKey()] = _.cloneDeep(attribute.type)
            this.setState({ attribute: attribute, identifier: attribute.id, attributeTypeEditingCache: attributeTypeEditingCache, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        const languages: Set<LanguageCode> = new Set()
        for (const optionKey in this.state.attribute.type.options ?? {}) {
            const langs = this.state.attribute.type.options?.[optionKey].name.localizations() ?? []
            for (const language of langs) {
                languages.add(language)
            }
        }
        for (const language of this.state.attribute.name.localizations()) {
            languages.add(language)
        }
        return Array.from(languages)
    }

    setLanguage = (language: LanguageCode | null) => {
        if (!_.isNil(language)) {
            const attribute = _.cloneDeep(this.state.attribute)
            if (!attribute.name.hasLocalizationFor(language)) {
                attribute.name.localizeTo(language)
            }
            for (const optionKey in attribute.type.options ?? {}) {
                const option = attribute.type.options![optionKey]
                if (!option.name.hasLocalizationFor(language)) {
                    option.name.localizeTo(language)
                }
            }
            this.setState({ attribute: attribute, dirty: true, currentLanguage: language })
        }
    }

    removeLanguage = (language: LanguageCode) => {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.name.removeLocalization(language)
        for (const optionKey in attribute.type.options ?? {}) {
            const option = attribute.type.options![optionKey]
            option.name.removeLocalization(language)
        }
        this.setState({ attribute: attribute, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.name = l10n || new L10nString("")
        this.setState({ attribute: attribute, dirty: true })
    }

    handleIdChange(identifier: string) {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.id = identifier
        this.setState({ dirty: true, error: null, identifier: identifier, attribute: attribute })
    }

    handlePreserveOnLineItemChanged(e: any) {
        const attribute = _.cloneDeep(this.state.attribute)
        attribute.preserveOnLineItem = e.target.checked
        this.setState({ dirty: true, error: null, attribute: attribute })
    }

    handleTypeChange(type: string) {
        if (type === this.state.attribute.typeKey()) {
            return
        }

        const cachedAttributeType = this.state.attributeTypeEditingCache[type]
        if (!cachedAttributeType) {
            return
        }

        const attributeTypeEditingCache = _.cloneDeep(this.state.attributeTypeEditingCache)
        attributeTypeEditingCache[this.state.attribute.typeKey()] = _.cloneDeep(this.state.attribute.type)
        const newAttribute = _.cloneDeep(this.state.attribute)
        newAttribute.type = cachedAttributeType

        this.setState({ attributeTypeEditingCache: attributeTypeEditingCache, attribute: newAttribute, dirty: true })
    }

    editOption(key: string) {
        if (this.state.editingOption) {
            // Already editing
            return
        }
        // Remove the option so that a duplicate is not created when editing the id
        const isNew = this.state.attribute.type.options?.[key] === undefined
        this.removeOption(key)
        const option = (this.state.attribute.type.options || {})[key]
        this.setState({ editingOption: true, editingOptionId: key, editingOptionName: option.name, editingOptionIsNew: isNew })
    }

    removeOption(key: string) {
        const attribute = _.cloneDeep(this.state.attribute)
        delete attribute.type.options![key]
        this.setState({ attribute: attribute, dirty: true })
    }

    descriptionForAttributeTypeKey(key: AttributeTypeKey): JSX.Element {
        let result = <br />
        switch (key) {
            case AttributeTypeKey.NUMBER:
                result = (
                    <p>
                        With this type you can define number as the value for the attribute on the product.
                        <br />
                        You can specify a localized suffix here if needed. Fx. meters if the attribute is a length attribute as well as the number of decimals used.
                    </p>
                )
                break

            case AttributeTypeKey.OPTIONS:
                result = (
                    <p>
                        With this type you can define a set of options that the define all possible values for an attribute.
                        <br />
                        For instance you might define &quot;Pinot Noir&quot; and &quot;Cabernet Sauvignon&quot; for a &quot;Grape&quot; attribute.
                    </p>
                )
                break

            case AttributeTypeKey.TEXT:
                result = (
                    <p>
                        With this type you can specify some text for the attribute on the product.
                    </p>
                )
                break

            case AttributeTypeKey.TEXT_ENTRY:
                result = (
                    <p>
                        With this type you allow manual entry of the attribute on the product through POS.
                    </p>
                )
                break

            case AttributeTypeKey.DATE:
                result = (
                    <p>
                        With this type you can represent a date (without time).
                    </p>
                )
                break

            case AttributeTypeKey.DATE_TIME:
                result = (
                    <p>
                        With this type you can represent a date with time.
                    </p>
                )
                break

        }
        return result
    }

    renderDate() {
        // Currently we have no options for a date
        return <span />
    }

    renderDateTime() {
        // Currently we have no options for a date time
        return <span />
    }

    renderType(): JSX.Element {
        switch (this.state.attribute.typeKey()) {
            case AttributeTypeKey.NUMBER:
                return this.renderNumber()

            case AttributeTypeKey.OPTIONS:
                return this.renderOptions()

            case AttributeTypeKey.TEXT:
                return this.renderText()

            case AttributeTypeKey.TEXT_ENTRY:
                return this.renderTextEntry()

            case AttributeTypeKey.DATE:
                return this.renderDate()

            case AttributeTypeKey.DATE_TIME:
                return this.renderDateTime()
        }
    }

    renderText(): JSX.Element {
        return <br />
    }

    renderNumber(): JSX.Element {
        const number = this.state.attribute.type.number!
        return (
            <div>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Suffix</DescriptionCol>
                    <Col sm={10}>
                        <L10nFormControl
                            l10n={number.suffix}
                            placeholder="Enter localized suffix"
                            language={this.state.currentLanguage}
                            onLocalizationChanged={l10n => {
                                const newAttribute = _.cloneDeep(this.state.attribute)
                                newAttribute.type.number!.suffix = l10n || new L10nString("")
                                this.setState({ attribute: newAttribute, dirty: true })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Number of decimals</DescriptionCol>
                    <Col sm={10}>
                        <DropdownButton
                            title={`${number.scale}`}
                            key="attribute_type"
                            id={`dropdown-basic-options`}
                        >
                            {
                                Object.values(["0", "1", "2", "3"]).map((key: string) => {
                                    return (
                                        <MenuItem
                                            key={key}
                                            onClick={(event) => {
                                                const newValue = Number(key)
                                                const newAttribute = _.cloneDeep(this.state.attribute)
                                                newAttribute.type.number!.scale = newValue
                                                this.setState({ attribute: newAttribute, dirty: true })

                                                event.stopPropagation()
                                            }}
                                        >
                                            {key}
                                        </MenuItem>
                                    )
                                })
                            }
                        </DropdownButton>
                    </Col>
                </FormGroup>
            </div>
        )
    }

    renderTextEntry(): JSX.Element {
        const text_entry = this.state.attribute.type.text_entry!
        return (
            <div>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Max length</DescriptionCol>
                    <Col sm={10}>
                        <FormControl
                            type="number"
                            name="max_length"
                            min={0}
                            step={0}
                            value={text_entry.max_length ?? ""}
                            placeholder="Enter max length"
                            onChange={(e: any) => {
                                const newAttribute = _.cloneDeep(this.state.attribute)
                                const newValue = new Number(e.target.value).valueOf()
                                newAttribute.type.text_entry!.max_length = newValue
                                this.setState({ attribute: newAttribute, dirty: true })
                            }} />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Must be unique</DescriptionCol>
                    <Col sm={10}>
                        <ToggleButton
                            active={text_entry.must_be_unique}
                            enabledTitle="True"
                            disabledTitle="False"
                            keepActiveColor={true}
                            performToggle={async () => {
                                const newValue = !text_entry.must_be_unique
                                const newAttribute = _.cloneDeep(this.state.attribute)
                                newAttribute.type.text_entry!.must_be_unique = newValue
                                this.setState({ attribute: newAttribute, dirty: true })
                            }}
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }

    renderOptions(): JSX.Element {
        const options = this.state.attribute.type.options!
        return (
            <div>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Options</DescriptionCol>
                    <Col sm={10}>
                        <StripedTable>
                            <thead >
                                <tr>
                                    <th>Name</th>
                                    <th>Id</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.keys(options).map(optionKey => {
                                        const option = options[optionKey]
                                        return (
                                            <tr key={optionKey} onClick={() => { this.editOption(optionKey) }}>
                                                <td>{option.name.localized(this.state.currentLanguage) ?? option.name.value}</td>
                                                <td>{option.id}</td>
                                                <td className="narrow">
                                                    <ConfirmDeleteButton
                                                        message={`Really delete ${option.name.localized(this.state.currentLanguage)} option?`}
                                                        onDelete={() => {
                                                            this.removeOption(optionKey)
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                    </Col>
                </FormGroup>

                {this.state.editingOption ? (
                    <div>
                        <FormGroup className="mb-3" as={Row}>
                            <DescriptionCol sm={2}>Option name</DescriptionCol>
                            <Col sm={10}>
                                <L10nFormControl
                                    l10n={this.state.editingOptionName || null}
                                    placeholder="Enter localized name"
                                    language={this.state.currentLanguage}
                                    onLocalizationChanged={l10n => { this.setState({ editingOptionName: l10n || undefined }) }}
                                />
                            </Col>
                        </FormGroup>
                        <ValidatingIdEntryControl
                            isNew={this.state.editingOptionIsNew ?? false}
                            showExistingIdentifier={true}
                            typeName="option"
                            identifierSource={this.state.editingOptionName?.localized(this.state.currentLanguage) ?? ""}
                            existingIdentifier={this.state.editingOptionId ?? ""}
                            handleIdChange={(id, valid) => {
                                if (!valid) { return }
                                this.setState({ editingOptionId: id })
                            }}
                        />
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>&nbsp;</Col>
                            <Col sm={10}>
                                <Button
                                    onClick={() => {
                                        const name = this.state.editingOptionName
                                        let id = this.state.editingOptionId
                                        if (name === undefined || id === undefined || id.length === 0) {
                                            return
                                        }
                                        const attribute: Attribute = _.cloneDeep(this.state.attribute)
                                        const attrOptions = attribute.type.options || {}
                                        id = _.trim(id, ":")
                                        const option = new AttributeOption({ name: {} })
                                        option.id = id
                                        option.name = name
                                        attrOptions[id] = option
                                        attribute.type.options = attrOptions
                                        this.setState({
                                            editingOption: undefined,
                                            editingOptionId: undefined,
                                            editingOptionName: undefined,
                                            editingOptionIsNew: undefined,
                                            attribute: attribute,
                                            dirty: true
                                        })
                                    }}
                                >
                                    Done
                                </Button>
                            </Col>
                        </FormGroup>
                    </div>
                ) : (
                    <FormGroup className="mb-3" as={Row}>
                        <Col sm={2}>&nbsp;</Col>
                        <Col sm={10}>
                            <Button onClick={() => { this.setState({ editingOption: true, editingOptionIsNew: true }) }}>Add option</Button>
                        </Col>
                    </FormGroup>
                )
                }
            </div>
        )
    }

    newTypeName() {
        if (this.props.mode === "product") {
            return "Create product attribute"
        } else {
            return "Create customer attribute"
        }
    }

    editTypeName() {
        const name = this.state.attribute.name.localized(this.state.currentLanguage)
        if (this.props.mode === "product") {
            return `Edit product attribute: ${name}`
        } else {
            return `Edit customer attribute: ${name}`
        }
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="attribute"
            >
                <Form>

                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="attribute"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewAttribute() ? this.newTypeName() : this.editTypeName()}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Name</DescriptionCol>
                                    <Col sm={10}>
                                        <L10nFormControl
                                            l10n={this.state.attribute.name}
                                            placeholder="Enter localized name"
                                            language={this.state.currentLanguage}
                                            onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    !this.isNewAttribute()
                                        ? (
                                            <FormGroup className="mb-3" as={Row}>
                                                <DescriptionCol sm={2}>Identifier</DescriptionCol>
                                                <Col sm={10}>
                                                    <FormControlStatic>{this.state.attribute.id}</FormControlStatic>
                                                </Col>
                                            </FormGroup>
                                        ) : (
                                            <ValidatingIdEntryControl
                                                collectionRef={this.attributesRef()}
                                                isNew={this.isNewAttribute()}
                                                typeName="attribute"
                                                identifierSource={this.state.attribute.name.localized(this.state.currentLanguage)}
                                                existingIdentifier={this.state.identifier}
                                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                                            />
                                        )
                                }
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Type</DescriptionCol>
                                    <Col sm={10}>
                                        <DropdownButton
                                            title={attributeTypeName(this.state.attribute.typeKey())}
                                            key="attribute_type"
                                            id={`dropdown-basic-options`}
                                        >
                                            {
                                                allAttributeTypes.map((key) => {
                                                    return (
                                                        <MenuItem
                                                            key={key}
                                                            onClick={(event) => { this.handleTypeChange(key) }}
                                                        >
                                                            {attributeTypeName(key)}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                        <div>
                                            <br />
                                            {this.descriptionForAttributeTypeKey(this.state.attribute.typeKey())}
                                        </div>

                                    </Col>
                                </FormGroup>

                                {this.renderType()}

                                {this.props.mode === "product" &&
                                    <FormGroup className="mb-3" as={Row}>
                                        <DescriptionCol sm={2}>Preserve on line items</DescriptionCol>
                                        <Col sm={10}>
                                            <Form.Check
                                                type="checkbox"
                                                checked={this.state.attribute.preserveOnLineItem}
                                                label="Carry attribute information to line items"
                                                onChange={e => this.handlePreserveOnLineItemChanged(e)}
                                            />
                                        </Col>
                                    </FormGroup>
                                }
                            </span>
                        </Card.Body>
                        <Card.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Card.Footer>
                    </Card>
                </Form>
                {
                    this.state.error ? (
                        <Alert variant="danger">
                            <strong>Error publishing attribute:</strong>
                            {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }
}

export default withRoleRouter(AttributeEdit)
