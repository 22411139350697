//
//  MarketList.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 04/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card, Alert } from "../wrappers"
import { currentDatabaseRef } from "../../config/constants"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { PageState } from "../PageState"
import { StripedTable } from "../StripedTable"
import { Market, Tax, TaxType } from "../../models/MarketModels"
import { Globals } from "../../helpers/globals"
import { Dictionary, zip } from "lodash"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import * as _ from "lodash"
import { child, DataSnapshot, get, off, onValue, set } from "firebase/database"


interface MarketListState {
    error: string | null
    loaded: boolean
    markets: Market[]
    publishing: boolean
    taxes: Tax[]
}

class MarketList extends React.Component<RoleRouterProps, MarketListState> {

    // Properties

    initialState: MarketListState = {
        error: null,
        loaded: false,
        markets: [],
        publishing: false,
        taxes: [],
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    async componentDidMount() {
        await this.loadState()
        this.subscribe()
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    async loadState() {
        this.setState({ loaded: false })

        const promises: Promise<any>[] = [
            Globals.shared.getMarkets(),
            Globals.shared.getTaxes()
        ]
        const [markets, taxes] = await Promise.all(promises)

        this.setState({ markets: markets, taxes: taxes, loaded: true, error: null })
    }

    marketsSubscription: ((a: DataSnapshot, b?: string | undefined | null) => any) | undefined
    taxesSubscription: ((a: DataSnapshot, b?: string | undefined | null) => any) | undefined

    subscribe() {
        this.marketsSubscription = onValue(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/markets`), snapshot => {
            if (!snapshot) {
                return
            }
            const markets: Market[] = []
            const marketsDict = snapshot.val() || {}
            for (const marketKey in marketsDict) {
                const value = marketsDict[marketKey]
                let productGroupTaxesMap: Map<string, string[]> = new Map()
                if (!_.isNil(value.product_group_taxes)) {
                    for (const key of Object.keys(value.product_group_taxes)) {
                        productGroupTaxesMap.set(key, Object.keys(value.product_group_taxes[key]))
                    }
                }
                markets.push({
                    id: marketKey,
                    name: value.name,
                    currency: value.currency,
                    taxes: new Set(Object.keys(value.taxes || {})),
                    product_group_taxes: productGroupTaxesMap
                })
            }
            this.setState({ markets: markets })
        })

        this.taxesSubscription = onValue(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/taxes`), snapshot => {
            if (!snapshot) {
                return
            }
            let taxes: Tax[] = []
            const taxesDict = snapshot.val() || {}
            for (const taxKey in taxesDict) {
                const value = taxesDict[taxKey]
                taxes.push(new Tax({
                    id: taxKey,
                    rate: value.rate,
                    name: value.name,
                    type: (value.type === "sales_tax") ? TaxType.salesTax : TaxType.vat
                }))
            }

            taxes = taxes.sort((a: Tax, b: Tax) => {
                return a.name.localeCompare(b.name)
            })
            this.setState({ taxes: taxes })
        })
    }

    unsubscribe() {
        if (this.marketsSubscription) {
            off(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/markets`), "value", this.marketsSubscription)
            this.marketsSubscription = undefined
        }
        if (this.taxesSubscription) {
            off(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/taxes`), "value", this.taxesSubscription)
            this.taxesSubscription = undefined
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="markets" publishing={this.state.publishing}>
                <div>
                    <Card className="my-4">
                        <Card.Header>
                            Markets
                        </Card.Header>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.markets.map((market) => {
                                    return (
                                        <tr key={market.id} onClick={() => { this.editMarket(market.id) }} >
                                            <td>{market.name}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Are you sure you wish to delete the market '${market.name}'?`}
                                                    onDelete={async () => { await this.removeMarket(market.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                        <Card.Footer>
                            <Button onClick={() => { this.createMarket() }}>New market</Button>
                        </Card.Footer>
                    </Card>

                    {this.state.error ? (
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    ) : []}

                    <Card className="my-4">
                        <Card.Header>
                            Taxes
                        </Card.Header>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.taxes.map((tax) => {
                                    return (
                                        <tr key={tax.id} onClick={() => { this.editTax(tax.id) }} >
                                            <td>{tax.name}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Are you sure you wish to delete the tax definition '${tax.name}'?`}
                                                    onDelete={async () => { await this.removeTax(tax.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                        <Card.Footer>
                            <Button onClick={() => { this.createTax() }}>New tax definition</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </PageState>
        )
    }

    // Methods

    // edit market /market/market_key
    editMarket(key: string) {
        this.props.router.navigate(`/market/${key}`)
    }

    //new market /market/new
    createMarket() {
        const path = "/market/new"
        this.props.router.navigate(path)
    }

    async removeMarket(key: string) {
        this.setState({ error: null })

        if (this.state.markets.length === 1) {
            this.setState({ error: "You must always have at least one market defined." })
            return
        }

        this.setState({ publishing: true })

        const accountId = this.props.role.account_id

        const shopsSnap = await get(child(currentDatabaseRef(),`v1/accounts/${accountId}/shop_index`))

        if (!shopsSnap || !shopsSnap.exists()) {
            console.log("No shops found. Internal error.")
            this.setState({ publishing: false })
            return
        }

        const shopKeys: string[] = []
        const shopNames: Dictionary<string> = {}
        const shopsDict = shopsSnap.val()
        for (const shopKey in shopsDict) {
            const shopMeta = shopsDict[shopKey]
            if (shopMeta.deactivated) {
                continue
            }
            shopKeys.push(shopKey)
            shopNames[shopKey] = shopMeta.name || "-"
        }

        const refs = shopKeys.map(shopKey => {
            return child(currentDatabaseRef(), `v1/accounts/${accountId}/shops/${shopKey}/market`)
        })

        const promises = refs.map(aref => { return get(aref) })
        const marketsInUseSnaps = await Promise.all(promises)
        const marketsInUse: Dictionary<string[]> = {}
        for (const [snap, shopKey] of zip(marketsInUseSnaps, shopKeys)) {
            if (!shopKey) { continue }
            if (snap && snap.exists() && typeof snap.val() === "string") {
                const market = snap.val() as string
                const existing = (marketsInUse[market] || [])
                existing.push(shopKey)
                marketsInUse[market] = existing
            }
        }
        if (marketsInUse[key]) {
            const names = marketsInUse[key].map(shopKey => { return `'${shopNames[shopKey] || "-"}'` })
            this.setState({ error: `This market is in use by the following shops: ${names.join(", ")}, and must not be deleted.`, publishing: false })
            return
        }

        const removeMarket = set(child(currentDatabaseRef(), `v1/accounts/${accountId}/markets/${key}`), null)
        const fiscalRuleForMarket = child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules/fiscal_rules/countries/${key}`)
        const removeFiscalRuleForMarket = set(fiscalRuleForMarket, null)

        await Promise.all([removeMarket, removeFiscalRuleForMarket])

        this.setState({ publishing: false })
    }

    // edit market /market/market_key
    editTax(key: string) {
        this.props.router.navigate(`/tax/${key}`)
    }

    //new market /market/new
    createTax() {
        const path = "/tax/new"
        this.props.router.navigate(path)
    }

    async removeTax(key: string) {
        this.setState({ publishing: true })

        const accountId = this.props.role.account_id

        await set(child(currentDatabaseRef(), `v1/accounts/${accountId}/taxes/${key}`), null)

        this.setState({ publishing: false })
    }

}

export default withRoleRouter(MarketList)
