import * as React from "react"
import { currentDatabaseRef } from "../../config/constants"
import { Button, Col, Form, FormGroup, Card, Alert, Row, FormControlStatic, DescriptionCol } from "../wrappers"
import { PageState } from "../PageState"
import { Tag } from "../../models/Product"
import { L10nFormControl } from "../L10nFormControl"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { Role } from "../../config/role"
import { LanguagePicker } from "../LanguagePicker"
import { cloneDeep } from "lodash"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { publish } from "../../helpers/ModelPublisher"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { child, get, off } from "firebase/database"

interface TagEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
}

interface TagEditState {
    tag: Tag
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
}

class TagEdit extends React.Component<TagEditProps, TagEditState> {
    constructor(props: TagEditProps) {
        super(props)
        this.state = {
            tag: new Tag({
                tag: "",
                name: ""
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null
        }
    }

    pop() {
        const path = `/tags`
        this.props.router.navigate(path)
    }

    tagKey() {
        return this.props.router.params.tagKey
    }

    isNewTag() {
        return this.tagKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.tag.name.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    tagsRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/tags")
    }

    async publish() {
        const json = this.state.tag.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "tag", this.state.identifier, this.isNewTag(), this.tagsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    componentWillUnmount() {
        const key = this.tagKey()
        const account = this.props.role.account_id
        off(child(currentDatabaseRef(), `v1/accounts/${account}/inventory/tags/${key}`))
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewTag()) {
            const snapshot = await get(child(this.tagsRef(), this.tagKey()))
            const tag = new Tag(snapshot.val())
            this.setState({ tag: tag, identifier: tag.tag, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.tag.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const tag = cloneDeep(this.state.tag)
        tag.name.removeLocalization(language)
        this.setState({ tag: tag, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const tag = cloneDeep(this.state.tag)
        tag.name = l10n || new L10nString("")
        this.setState({ tag: tag, dirty: true })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="tag"
            >
                <Form>
                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="tag"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewTag() ? "Create new tag" : `Edit tag '${this.state.tag.name.localized(this.state.currentLanguage)}'`}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <div>
                                    <FormGroup className="mb-3" as={Row}>
                                        <DescriptionCol sm={2}>Name</DescriptionCol>
                                        <Col sm={10}>
                                            <L10nFormControl
                                                l10n={this.state.tag.name}
                                                placeholder="Enter localized name"
                                                language={this.state.currentLanguage}
                                                onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {
                                        !this.isNewTag()
                                            ? (
                                                <FormGroup className="mb-3" as={Row}>
                                                    <DescriptionCol sm={2}>Identifier</DescriptionCol>
                                                    <Col sm={10}>
                                                        <FormControlStatic>{this.state.tag.tag}</FormControlStatic>
                                                    </Col>
                                                </FormGroup>
                                            ) : null
                                    }
                                </div>
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.tagsRef()}
                                isNew={this.isNewTag()}
                                typeName="tag"
                                identifierSource={this.state.tag.name.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                        </Card.Body>
                        <Card.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Card.Footer>
                    </Card>
                </Form>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing tag:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }
}

export default withRoleRouter(TagEdit)
