import { cloneDeep, update } from "lodash"
import * as React from "react"
import { Button, Form, InputGroup } from "react-bootstrap"


interface SearchWordsListProps {
    searchWords: string[]
    onChange: (searchWords: string[]) => void
}

interface SearchWordsListState {
    searchWord: string
    showSearchWordField: boolean
}

export class SearchWordsList extends React.Component<SearchWordsListProps, SearchWordsListState> {
    constructor(props: SearchWordsListProps) {
        super(props)

        this.state = {
            searchWord: "",
            showSearchWordField: false
        }
    }

    removeSearchWord = (index: number) => {
        const searchWords = cloneDeep(this.props.searchWords)
        searchWords.splice(index, 1)
        this.props.onChange(searchWords)
    }

    handleInputChange = (value: any) => {
        let input: string
        input = value as string
        this.setState({ searchWord: input.replace(/\s/g, "") })
    }
    
    addSearchWord = () => {
        const searchWord = this.state.searchWord
        const searchWords = cloneDeep(this.props.searchWords)
        
        if (!searchWords.includes(searchWord)) {
           searchWords.push(searchWord)
        }
        this.props.onChange(searchWords)
        this.setState({searchWord: ""})
    }
    showSearchWordTextField = () => {
        let bool = this.state.showSearchWordField
        this.setState({ showSearchWordField: !bool })
    }

    searchWordTextField() {
        return <>
            
            <Form.Control 
            size="sm"
            name="search_word"
            value={this.state.searchWord}
            placeholder="Enter search word"
            onChange={(event: any) => this.handleInputChange(event.target.value)} /> 

            <Button size="sm" onClick={this.addSearchWord} disabled={this.state.searchWord == ""}>Add</Button>
           
          
        </>
    }

    render() {
        return <>
            <InputGroup className="mb-0">
            {this.state.showSearchWordField ? this.searchWordTextField() : <Button size="sm" variant="outline-primary" onClick={this.showSearchWordTextField}> Add search word</Button>}
            </InputGroup> 
            {
            this.props.searchWords && this.props.searchWords.length > 0 ? (
                this.props.searchWords.map((searchWord, index) => {
                    return (
                        
                        
                        <span onClick={() => {
                            this.removeSearchWord(index)
                        }} key={index}><span style={{ display: "inline-block", fontSize: "small", marginBottom: 4, backgroundColor: "#DDDDDD", borderRadius: 5, whiteSpace: "nowrap", padding: "0px 6px" }}>
                        {`${searchWord}`}&nbsp;<b>✕</b>
                    </span> </span>
                    
                    )
                })

            ) : null
           }
           
        </>
    }


}