import React from "react";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { SpacingValueEdit } from "./ValueEditComponents/SpacingValueEdit";
import { ValueEditProps } from "../ComponentEdit";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "../../../../../../components/wrappers";

export function ImageEdit(props: ValueEditProps) {
    return (
        <Form>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Image</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="image"
                        value={props.lineItem.image ?? ""}
                        placeholder="Enter image"
                        onChange={(event) => {
                            props.updateLineItem((lineItem) => {
                                lineItem.image = event.currentTarget.value as string
                                return lineItem;
                            });
                        } } />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Image width</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Image width"
                        value={props.lineItem.imageWidth ?? ""}
                        placeholder="Enter image width"
                        onChange={(event) => {
                            props.updateLineItem((lineItem) => {
                                const value = new Number(event.currentTarget.value).valueOf()
                                lineItem.imageWidth = value == 0 ? undefined : value
                                return lineItem;
                            });
                        } } />
                    </Col>
            </FormGroup>
            <SpacingValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></SpacingValueEdit>
        </Form>
    );
}