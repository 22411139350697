import * as Actions from "./StockListActions"
import * as Models from "./StockListModels"
import * as React from "react"
import * as ReactRedux from "react-redux"
import * as Reducers from "./StockListReducers"
import * as Redux from "redux"
import promise from "redux-promise-middleware"
import { PageState } from "../../PageState"
import { Card } from "../../wrappers"
import { StockObserver } from "../../../helpers/stockObserver"
import { StripedTable } from "../../StripedTable"
import { RoleRouterProps, withRoleRouter } from "../../../routes"

// Keeping middleware and store definitions here in this file for now. Seems like normally people have global store and the use convenience setup to filter out the parts that you want to look at.

const middleware = Redux.applyMiddleware(promise)
const store = Redux.createStore(Reducers.stockReducer, middleware)

interface StockListProps extends RoleRouterProps {
    loaded?: boolean
    stockLocation: string
    stockListItems?: Models.StockListItem[]
    hasReserved?: boolean
}

class StockList extends React.Component<StockListProps> {

    stockObserver: StockObserver

    constructor(props: StockListProps) {
        super(props)
        this.stockObserver = new StockObserver(props.role.account_id, props.stockLocation)
    }

    componentDidMount() {
        this.stockObserver.stockChangedCallback = () => {
            store.dispatch(Actions.stockDataChanged(this.stockObserver.stockDict, this.stockObserver.reservedDict))
        }
        this.stockObserver.start()

        const marketCompletion = (marketId: string) => {
            store.dispatch(Actions.fetchAccountProductsAction(this.props.role.account_id, marketId!))
            store.dispatch(Actions.fetchShopProductsAction(this.props.role.account_id, this.props.stockLocation))
        }

        store.dispatch(Actions.fetchMarketAction(this.props.role.account_id, this.props.stockLocation, marketCompletion))
    }

    componentWillUnmount() {
        this.stockObserver.stop()
    }

    render() {
        const stockListItems = this.props.stockListItems ?? []
        return (
            <PageState loading={!(this.props.loaded ?? false)} typeName="stock">
                <Card className="my-4">
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Count</th>
                                {this.props.hasReserved === true &&
                                    <th>Reserved</th>
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {stockListItems.map(listItem => {
                                const result = (
                                    <tr key={listItem.productId + (listItem.variantId ?? "")}>
                                        {
                                            !listItem.variantId
                                                ?
                                                <td style={{ color: listItem.archived ? "#AA0000" : "black" }}>{listItem.name + (listItem.archived ? " (deleted)" : "")}</td>
                                                :
                                                <td style={{ color: listItem.archived ? "#AA0000" : "black" }}>↳ {listItem.name + (listItem.archived ? " (deleted)" : "")}</td>
                                        }
                                        {
                                            typeof listItem.count !== "number"
                                                ?
                                                <td className="narrow" />
                                                :
                                                <td className="narrow">{listItem.count}</td>
                                        }
                                        {
                                            this.props.hasReserved && (typeof listItem.reserved !== "number"
                                                ?
                                                <td className="narrow" />
                                                :
                                                <td className="narrow">{listItem.reserved}</td>
                                            )
                                        }
                                    </tr>
                                )
                                return result
                            })}
                        </tbody>
                    </StripedTable>
                </Card>
            </PageState>
        )
    }
}

// BG: I tried to generalize this into some higher order component functions stuff, but my js skills are not up to the task
const mapStateToProps = (state: Reducers.StockListState) => {
    return {
        loaded: state.loaded,
        stockListItems: state.stockListItems,
        hasReserved: Object.keys(state.reservedEntries ?? {}).length > 0
    }
}
// BG: In js this is done with a decorator instead so it's a bit more elegant, but alas, typescript is not easy to satisfy
const ConnectedStock = ReactRedux.connect(mapStateToProps)(StockList)

class ReduxStock extends React.Component<StockListProps> {
    render() {
        return (
            <ReactRedux.Provider store={store as any}>
                <ConnectedStock {...this.props} />
            </ReactRedux.Provider>
        )
    }
}

export default withRoleRouter(ReduxStock)
