import * as React from "react"
// import { Route /*, Redirect */ } from "react-router-dom"
import { AccountOwnerRequirement, Role, RoleAccessRequirement, ShopOwnerRequirement } from "./config/role"
import { /*Navigate,*/ Navigate, NavigateFunction, Outlet, useOutletContext, /*RouteProps  , RouteComponentProps, Omit */ } from "react-router"
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { useAppContext } from "./AppLayout";
// import * as H from 'history';

export function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}

export interface RoleProps {
    role: Role
    id?: string
}

export interface RoleRouterProps extends RoleProps {
    router: { navigate: NavigateFunction, location: any, params: any }
}

export interface RoleShopProps {
    role: Role
    shop: string
    id?: string
}

export interface RoleShopRouterProps {
    role: Role
    shop: string
    id?: string
    router: { navigate: NavigateFunction, location: any, params: any }
}

export interface RoleStockLocationProps {
    role: Role
    stockLocation: string
    id?: string,
    router: { navigate: NavigateFunction, location: any, params: any }
}

export function withRoleFunc<C extends RoleProps>(Component: (props: C) => JSX.Element): (props: Omit<C, "role" | "id">) => JSX.Element {
    type WithoutRole = Omit<C, "role" | "id">
    function ComponentWithRouterProp(props: WithoutRole) {
        const [role, id] = useOutletContext<[Role, string?]>();
        const AnyComponent: any = Component
        return (
            <AnyComponent role={role} id={id} {...props} />
        );
    }

    return ComponentWithRouterProp;
}

export function withRoleRouterFunc<C extends RoleProps>(Component: (props: C) => JSX.Element): (props: Omit<C, "role" | "id" | "router">) => JSX.Element {
    type WithoutRole = Omit<C, "role" | "id" | "router">

    function ComponentWithRouterProp(props: WithoutRole) {
        const [role, id] = useOutletContext<[Role, string?]>();
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const AnyComponent: any = Component
        return (
            <AnyComponent
                role={role} id={id}
                router={{ location, navigate, params }}
                {...props}
            />
        );
    }

    return ComponentWithRouterProp;
}

export function withRoleRouter<C extends RoleRouterProps>(Component: typeof React.Component<C, any>) {
    type WithoutRole = Omit<C, "role" | "id" | "router">

    function ComponentWithRouterProp(props: WithoutRole) {
        const [role, id] = useOutletContext<[Role, string?]>();
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const AnyComponent: any = Component
        return (
            <AnyComponent
                role={role} id={id}
                router={{ location, navigate, params }}
                {...props}
            />
        );
    }

    return ComponentWithRouterProp;
}


export function withRole<C extends RoleProps>(Component: typeof React.Component<C, any>) {
    type WithoutRole = Omit<C, "role" | "id">
    function ComponentWithRouterProp(props: WithoutRole) {
        const [role, id] = useOutletContext<[Role, string?]>();
        const AnyComponent: any = Component
        return (
            <AnyComponent role={role} id={id} {...props} />
        );
    }

    return ComponentWithRouterProp;
}

export function withShop<C extends RoleShopProps>(Component: typeof React.Component<C, any>) {
    type WithoutRole = Omit<C, "role" | "shop" | "id">
    function ComponentWithRouterProp(props: WithoutRole) {
        const [role, shop, id] = useOutletContext<[Role, string, string?]>();
        const AnyComponent: any = Component
        return (
            <AnyComponent role={role} id={id} shop={shop} {...props} />
        );
    }

    return ComponentWithRouterProp;
}

export function withShopRouter<C extends RoleShopRouterProps>(Component: typeof React.Component<C, any>) {
    type WithoutRole = Omit<C, "role" | "shop" | "id" | "router">
    function ComponentWithRouterProp(props: WithoutRole) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const [role, shop, id] = useOutletContext<[Role, string, string?]>();
        const AnyComponent: any = Component
        return (
            <AnyComponent role={role} router={{ location, navigate, params }} id={id} shop={shop} {...props} />
        );
    }

    return ComponentWithRouterProp;
}


export function withStockLocationRouter<C extends RoleStockLocationProps>(Component: typeof React.Component<C, any>) {
    type WithoutRole = Omit<C, "role" | "stockLocation" | "id" | "router">
    function ComponentWithRouterProp(props: WithoutRole) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        const [role, stock_location, id] = useOutletContext<[Role, string, string?]>();
        const AnyComponent: any = Component
        return (
            <AnyComponent
                role={role}
                id={id}
                router={{ location, navigate, params }}
                stockLocation={stock_location}
                {...props} />
        );
    }

    return ComponentWithRouterProp;
}

// export interface RouteComponentProps<Params extends { [K in keyof Params]?: string }> {
//     match: match<Params>;
//     location: H.Location;
//     history: H.History;
//     staticContext?: any;
// }

// export interface match<Params extends { [K in keyof Params]?: string } = {}> {
//     params: Params;
//     isExact: boolean;
//     path: string;
//     url: string;
//   }


export interface Resolver {
    authenticated: boolean
    role?: Role
}

// interface AccessRouteProps extends RouteProps {
//     resolver: Resolver
// }

// This is a hack in order to let Omit know that it is ok to omit "role" from generic P.
// interface Roleable {
//     role: Role
// }

// // BG: This is just to satisfy the compiler. I don't really understand all of that type jazz but it seems to work
// type ReducedRouteComponentProps = Omit<RouteComponentProps<{}>, "history" | "match" | "location">

// interface RoleRouteComponentProps extends ReducedRouteComponentProps {
//     role?: Role
// }

// type RouteComponent = React.FunctionComponent<RoleRouteComponentProps> | React.ComponentClass<any>

// export const accessRouted = <P extends object>(component: React.ComponentType<P>, roleRequirement: RoleAccessRequirement): React.FunctionComponent<Omit<P & Roleable, "role"> & AccessRouteProps> => ({ path, resolver, ...rest }: AccessRouteProps) => {
//     const renderFn = (MyComponent?: RouteComponent) => (props: RouteProps) => {
//         if (!MyComponent) {
//             return null
//         }
//         const role = resolver.role
//         const authenticated = resolver.authenticated
//         let redirectPath: string | undefined

//         // BG: not liking this at all but I don"t have a prettier solution ready
//         if (!role) {
//             if (authenticated) {
//                 redirectPath = path === "/roles" ? undefined : "/roles"
//             } else {
//                 redirectPath = path === "/login" ? undefined : "/login"
//             }
//         } else if (!roleRequirement.isSatisfied(role)) {
//             redirectPath = path === "/no_access" ? undefined : "/no_access"
//         } else if (path === "/roles" || path === "/login") {
//             redirectPath = "/"
//         }

//         if (!redirectPath) {
//             return <MyComponent {...props} {...rest} role={role} />
//         }

//         return <Navigate to={redirectPath} />
//     }
//     return <Route path={path} element={renderFn(component as any)} />
// }

type Props = {
    requirement: RoleAccessRequirement,
    resolver: Resolver
}

type Props2 = {
    requirement: RoleAccessRequirement,
    resolver: Resolver,
    children: any
}

export const ProtectedRoute2 = ({ requirement, resolver, children }: Props2) => {
    // return <Outlet/>
    // let redirectPath: string | undefined
    // const role = resolver.role
    // const authenticated = resolver.authenticated
    // if (!role) {
    //     if (authenticated) {
    //         redirectPath = "/roles"
    //     } else {
    //         redirectPath = "/login"
    //     }
    // } else if (!requirement.isSatisfied(role)) {
    //     redirectPath = "/no_access"
    // } else {
    //     redirectPath = "/"
    // }
    return children

    // if (!redirectPath) {
    //     return <Outlet/>;
    // }
    // return <Navigate to={redirectPath} replace />;
};

export const AccountRoute = () => {
    const appContext = useAppContext()
    const resolver = appContext.resolver
    let params = useParams();
    let redirectPath: string | undefined
    const role = resolver.role
    const requirement = AccountOwnerRequirement()
    const authenticated = resolver.authenticated
    if (!role) {
        if (authenticated) {
            redirectPath = "/roles"
        } else {
            redirectPath = "/login"
        }
    } else if (!requirement.isSatisfied(role)) {
        redirectPath = "/no_access"
    } else {
        return <Outlet context={[role, params.id, appContext.selectStockLocation, appContext.deselectStockLocation]} />;
    }

    return <Navigate to={redirectPath} replace />;
};

export const ShopRoute = () => {
    let params = useParams();
    let redirectPath: string | undefined
    const appContext = useAppContext()
    const resolver = appContext.resolver
    const role = resolver.role
    const requirement = ShopOwnerRequirement()
    const authenticated = resolver.authenticated
    if (!role) {
        if (authenticated) {
            redirectPath = "/roles"
        } else {
            redirectPath = "/login"
        }
    } else if (!requirement.isSatisfied(role)) {
        redirectPath = "/no_access"
    } else {
        return <Outlet context={[role, params.shopKey, params.id]} />;
    }

    return <Navigate to={redirectPath} replace />;
};

export const StockLocationRoute = () => {
    let params = useParams();
    let redirectPath: string | undefined
    const appContext = useAppContext()
    const resolver = appContext.resolver
    const role = resolver.role
    const requirement = ShopOwnerRequirement()
    const authenticated = resolver.authenticated
    if (!role) {
        if (authenticated) {
            redirectPath = "/roles"
        } else {
            redirectPath = "/login"
        }
    } else if (!requirement.isSatisfied(role)) {
        redirectPath = "/no_access"
    } else {
        return <Outlet context={[role, params.stockLocationKey, params.id]} />;
    }
    return <Navigate to={redirectPath} replace />;
};


export const ProtectedRoute = ({ requirement, resolver }: Props) => {
    return <Outlet />
    // let redirectPath: string | undefined
    // const role = resolver.role
    // const authenticated = resolver.authenticated
    // if (!role) {
    //     if (authenticated) {
    //         redirectPath = "/roles"
    //     } else {
    //         redirectPath = "/login"
    //     }
    // } else if (!requirement.isSatisfied(role)) {
    //     redirectPath = "/no_access"
    // } else {
    //     redirectPath = "/"
    // }

    // if (!redirectPath) {
    //     return <Outlet/>;
    // }
    // return <Navigate to={redirectPath} replace />;
};

