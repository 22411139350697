//
//  pdf_template_line.ts
//  giftcard-service
//
//  Created by Flemming Pedersen on 13/03/2019.
//  Copyright © 2019 Ka-ching. All rights reserved.
//

import * as _ from "lodash"
import { Background } from "./background"

export enum PDFTemplateLineType {
    text = "text",
    barcode = "barcode",
    image = "image",
    spacer = "spacer",
    wallet_url = "wallet_url"
}

export class PDFTemplateLine {
    public static fromJSON(json: any | null): PDFTemplateLine | null {
        if (_.isNil(json) || _.isNil(json.type)) {
            return null
        }

        if (!Object.values(PDFTemplateLineType).includes(json.type)) {
            return null
        }

        const pdfTemplateLine = new PDFTemplateLine(json.type)
        pdfTemplateLine.text = json.text
        pdfTemplateLine.alignment = json.alignment
        pdfTemplateLine.font = json.font
        pdfTemplateLine.textColor = json.text_color
        pdfTemplateLine.fontSize = json.font_size
        pdfTemplateLine.link = json.link
        pdfTemplateLine.spacing = json.spacing
        pdfTemplateLine.image = json.image
        pdfTemplateLine.imageWidth = json.image_width
        if (!_.isNil(json.barcode_type)) {
            pdfTemplateLine.barcodeType = json.barcode_type
        }

        const background = Background.fromJSON(json.background)
        if (!_.isNil(background)) {
            pdfTemplateLine.background = background
        }

        return pdfTemplateLine
    }

    public text?: string
    public textColor?: string
    public font?: string
    public alignment?: string
    public fontSize?: number
    public lineType: PDFTemplateLineType
    public barcodeType?: string
    public background?: Background
    public link?: string
    public spacing?: number
    public image?: string
    public imageWidth?: number

    constructor(lineType: PDFTemplateLineType) {
        this.lineType = lineType
    }

    public toJSON(): any {
        const json: any = {
            type: this.lineType,
        }

        if (!_.isNil(this.alignment)) {
            json.alignment = this.alignment
        }

        if (!_.isNil(this.background)) {
            json.background = this.background.toJSON()
        }

        if (!_.isNil(this.font)) {
            json.font = this.font
        }

        if (!_.isNil(this.fontSize)) {
            json.font_size = this.fontSize
        }

        if (!_.isNil(this.link)) {
            json.link = this.link
        }

        if (!_.isNil(this.spacing)) {
            json.spacing = this.spacing
        }

        if (!_.isNil(this.text)) {
            json.text = this.text
        }

        if (!_.isNil(this.textColor)) {
            json.text_color = this.textColor
        }

        if (!_.isNil(this.image)) {
            json.image = this.image
        }

        if (!_.isNil(this.imageWidth)) {
            json.image_width = this.imageWidth
        }

        if (!_.isNil(this.barcodeType)) {
            json.barcode_type = this.barcodeType
        }

        return json
    }
}
