import * as _ from "lodash"
import * as React from "react"
import {
    Button,
    Modal,
    Pager
    } from "../../wrappers"
import { Cashier } from "../../../models/Cashier"
import { FormattedTime } from "react-intl"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { StockCountEvent } from "../../../models/StockCountModels"
import { StripedTable } from "../../StripedTable"
import { Pagination } from "react-bootstrap"
import { child, DatabaseReference, DataSnapshot, endAt, off, onValue, orderByChild, query, Query, startAt } from "firebase/database"
 
interface StockCountLineEventListModalProps {
    completed: () => void
    productId: string
    productName?: string
    role: Role
    stockLocation: string
    stockCountId: string
    variantId?: string
}

interface StockCountLineEventListModalState {
    cashiers: _.Dictionary<Cashier>
    cashiersLoaded: boolean
    events: StockCountEvent[]
    eventsLoaded: boolean
    pageIndex: number
}

const pageSize = 20

export class StockCountLineEventListModal extends React.Component<StockCountLineEventListModalProps, StockCountLineEventListModalState> {

    // Properties

    eventsObserverHandle: any
    cashiersObserverHandle: any

    // Constructor

    constructor(props: StockCountLineEventListModalProps) {
        super(props)

        this.state = {
            cashiers: {},
            cashiersLoaded: false,
            events: [],
            eventsLoaded: false,
            pageIndex: 0
        }
    }    

    // Methods

    eventsQuery(): Query {
        let indexValue = `${this.props.stockCountId}*${this.props.productId}`
        if (!_.isNil(this.props.variantId)) {
            indexValue += `*${this.props.variantId}`
        }
        const dbRef = child(currentDatabaseRef(), `/v1/accounts/${this.props.role.account_id}/stock_locations/${this.props.stockLocation}/inventory/stock_counts/events`)
        const q = query(dbRef, orderByChild("indexes/stock_count_and_product"),
            startAt(indexValue),
            endAt(indexValue))
        return q
    }

    cashiersRef(): DatabaseReference {
        return child(currentDatabaseRef(), `/v1/accounts/${this.props.role.account_id}/shops/${this.props.stockLocation}/cashiers`)
    }

    closeButtonClicked() {
        this.props.completed()
    }

    isPreviousDisabled(): boolean {
        return this.state.pageIndex === 0
    }

    isNextDisabled(): boolean {
        const pages = this.state.events.length / pageSize
        return pages <= this.state.pageIndex + 1
    }

    loadPrevious() {
        this.setState({ pageIndex: this.state.pageIndex - 1 })
    }

    loadNext() {
        this.setState({ pageIndex: this.state.pageIndex + 1 })
    }

    // Component

    async componentDidMount() {
        this.eventsObserverHandle = onValue(this.eventsQuery(), (snapshot: DataSnapshot) => {
            let events: StockCountEvent[] = []
            if (snapshot.exists()) {
                const value: _.Dictionary<any> = snapshot.val()
                events = Object.keys(value).map(eventKey => { return new StockCountEvent(value[eventKey]) })
            }
            events.reverse()
            this.setState({ eventsLoaded: true, events: events })
        })
        this.cashiersObserverHandle = onValue(this.cashiersRef(), (snapshot: DataSnapshot) => {
            let cashiers: _.Dictionary<Cashier> = {}
            if (snapshot.exists()) {
                const value: _.Dictionary<any> = snapshot.val()
                cashiers =_.mapValues(value, (v => {
                    return Cashier.fromJSON(v)!
                }))
            }
            this.setState({ cashiersLoaded: true, cashiers: cashiers })
        })
    }

    componentWillUnmount() {
        off(this.cashiersRef())
        off(this.eventsQuery())
    }

    render() {
        const offset = this.state.pageIndex * pageSize
        const events = this.state.events.slice(offset, Math.min(offset + pageSize, this.state.events.length))
        return (
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>
                        Events for product: {this.props.productName || ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageState loading={!this.state.eventsLoaded && !this.state.cashiersLoaded} typeName="stock count events">
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Time</th>
                                    <th>By</th>
                                    <th>Adjustment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    events.map((event: StockCountEvent) => {
                                        const userName = event.userName(this.state.cashiers)
                                        const rowKey = `${event.product.productId}*${event.timestamp}`
                                        return (
                                            <tr key={rowKey}>
                                                <td>
                                                    <FormattedTime 
                                                        value={new Date((event.timestamp || 0) * 1000)}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                        hour="numeric"
                                                        minute="numeric"
                                                        second="numeric"
                                                    />
                                                </td>
                                                <td>{userName}</td>
                                                <td>{event.adjustment > 0 ? `+${event.adjustment}` : event.adjustment}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                        <Pagination>
                            <Pagination.Prev onClick={() => { this.loadPrevious() }} disabled={this.isPreviousDisabled()}>&larr; Previous Page</Pagination.Prev>
                            <Pagination.Next onClick={() => { this.loadNext() }} disabled={this.isNextDisabled()}>Next Page &rarr;</Pagination.Next>
                        </Pagination>
                    </PageState>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.closeButtonClicked() }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

}