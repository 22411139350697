import * as React from "react"
import {
    Col,
    
    Form,
    FormControl,
    FormGroup,
} from "../../wrappers"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"

export class MobilePayModule extends React.Component<ModuleProps, {}> {

    // Helpers

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] || false
    }

    handleInputChange = (event: any) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.props.updateConfiguration(config => {
            if (value === undefined || value === null || value.length === 0) {
                delete config[name]
            } else {
                config[name] = value
            }
        })
    }

    // Component

    render() {
        return (
            <Form>
            
        </Form>
        )
    }
}
