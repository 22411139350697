import * as _ from "lodash"
import * as SharedModels from "../ProductOrganizationModels"
import { LanguageCode, L10nString } from "../../../../helpers/L10n"
import { Tab } from "./ProductTabsModels"
import { Tag } from "../../../../models/Product"
import { TagObserver } from "../../../../helpers/tagObserver"
import { currentDatabaseRef } from "../../../../config/constants"
import { DatabaseReference, child, get, set } from "firebase/database"

export default class ProductTabsViewModel {

    // Properties

    private _accountId: string
    private _panes?: Tab[]
    private _tagObserver: TagObserver
    private _tags?: Tag[]

    // Constructor

    constructor(accountId: string) {
        this._accountId = accountId
        this._tagObserver = new TagObserver(accountId)
        this._tagObserver.tagsChangedCallback = this.handleTagsChanged.bind(this)
    }

    // Signals In

    start() {
        this._tagObserver.start()
    }

    stop() {
        this._tagObserver.stop()
    }

    handleSingleReordering(reorder: any): boolean {
        if (!this._panes || !this._tags) {
            return false
        }

        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return false
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return false
        }

        const newOrdering = Array.from(this._panes)
        const [removed] = newOrdering.splice(reorder.source.index, 1)
        newOrdering.splice(reorder.destination.index, 0, removed)
        newOrdering.forEach((pane, i) => { pane.order = i + 1 })

        this._panes = newOrdering

        if (this.paneAndTagListsUpdated) {
            this.paneAndTagListsUpdated(this._panes, this._tags)
        }

        return true
    }

    handleRemovePane(index: number) {
        if (!this._panes || !this._tags) {
            return
        }

        const newOrdering = Array.from(this._panes)
        newOrdering.splice(index, 1)
        newOrdering.forEach((pane, i) => { pane.order = i + 1 })
        this._panes = newOrdering
        this._tags = this.remainingTags(newOrdering, this._tagObserver.tagsArray ?? [])

        if (this.paneAndTagListsUpdated) {
            this.paneAndTagListsUpdated(this._panes, this._tags)
        }
    }

    handleTagSelect(index: number) {
        if (!this._panes || !this._tags) {
            return
        }

        const tag = this._tags[index]
        if (!tag) {
            return
        }

        const newOrdering = Array.from(this._panes)
        newOrdering.push(new Tab(tag.name, this._panes.length + 1, new SharedModels.Filter(SharedModels.FilterType.tag, tag.tag)))
        this._panes = newOrdering
        this._tags = this.remainingTags(newOrdering, this._tagObserver.tagsArray ?? [])

        if (this.paneAndTagListsUpdated) {
            this.paneAndTagListsUpdated(this._panes, this._tags)
        }
    }

    nameOfPaneAtIndex(index: number): string {
        if (!this._panes) {
            return ""
        }
        if (!this._tagObserver.tagsDict) {
            return ""
        }
        const filter = this._panes[index].filter
        let result = ""
        switch (filter.type) {
            case SharedModels.FilterType.tag: {
                const tag = this._tagObserver.tagsDict[filter.id]
                const name = _.isNil(tag) ? new L10nString(filter.id) : tag.name
                result = name.localized(LanguageCode.da)
                break
            }
            default: {
                console.error(`Unsupported filter type: ${filter.type}`)
                break
            }
        }
        return result
    }

    async publish() {
        if (!this._panes) {
            return
        }
        const filters = this._panes.map((pane) => { return pane.toJson() })
        await set(this.panesRef(), filters)
    }

    // Signals Out   

    public paneAndTagListsUpdated?: (panes: Tab[], tags: Tag[]) => void

    // Helpers

    private panesRef(): DatabaseReference {
        return child(currentDatabaseRef(), `v1/accounts/${this._accountId}/app_data/pos/product_organization/tabs`)
    }

    private async handleTagsChanged() {
        const filters = new SharedModels.Filters(this._tagObserver.tagsDict ?? {})
        if (!this._panes) {
            this._panes = await this.loadPanesFromDB(filters)
        }

        this._panes = this.panesFromFilters(this._panes.map(pane => pane.toJson()), filters)
        this._tags = this.remainingTags(this._panes, this._tagObserver.tagsArray ?? [])

        if (this.paneAndTagListsUpdated) {
            this.paneAndTagListsUpdated(this._panes, this._tags)
        }
    }

    private async loadPanesFromDB(filters: SharedModels.Filters): Promise<Tab[]> {
        const panesSnapshot = await get(this.panesRef())
        if (!panesSnapshot.exists()) {
            return []
        } else {
            return this.panesFromFilters(panesSnapshot.val(), filters)
        }
    }

    private panesFromFilters(panesJson: any[], filters: SharedModels.Filters): Tab[] {
        let order = 1
        const optionalPanes: (Tab | undefined)[] = panesJson.map((paneJson: any) => {
            const filterType = Object.keys(paneJson.filter)[0]
            switch (SharedModels.FilterType[filterType]) {
                case SharedModels.FilterType.tag: {
                    const tag = filters.tag[paneJson.filter.tag]
                    let tagName = new L10nString(paneJson.filter.tag)
                    if (!_.isNil(tag)) {
                        tagName = tag.name
                    }
                    const result = new Tab(tagName, order, SharedModels.Filter.fromJson(paneJson.filter))
                    order += 1
                    return result
                }
                default:
                    return undefined
            }
        })
        return optionalPanes.filter(p => p) as Tab[]
    }

    private remainingTags(panes: Tab[], tags: Tag[]): Tag[] {
        const tagPanes = this.tagPanes(panes)
        const ids = new Set<string>(tagPanes.map(pane => pane.filter.id))
        return tags.filter(tag => !ids.has(tag.tag))
    }

    private tagPanes(panes: Tab[]): Tab[] {
        return panes.filter(pane => pane.filter.type === SharedModels.FilterType.tag)
    }
}