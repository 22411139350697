export class GiftcardType {
    public static fromJSON(json: any): GiftcardType | null {
        if (!json.max_amount
            || !json.expiry_days
            || !json.currency
            || !json.created
            || !json.id
            || !json.name
            || !json.email_from_address
            || !json.email_subject
            || !json.email_from_name
            || !json.locale
            || !json.timezone) {
            return null
        }

        let date: Date
        if (json.created){
            date = new Date(json.created * 1000)
        } else {
            return null
        }
        

        const giftcardType = new GiftcardType(
            json.max_amount,
            json.currency,
            json.expiry_days,
            date,
            json.id,
            json.name,
            json.email_from_address,
            json.email_subject,
            json.email_from_name,
            json.locale,
            json.timezone,
            json.set_expiry_on_creation,
            json.use_wallet ?? false,
            json.use_shopify ?? false
        )

        return giftcardType
    }

    public max_amount: number
    public expiry_days: number
    public currency: string
    public created: Date
    public id: string
    public name: string
    public email_from_address: string
    public email_subject: string
    public email_from_name: string
    public locale: string
    public timezone: string
    public set_expiry_on_creation: boolean
    public use_wallet: boolean
    public use_shopify: boolean

    constructor(maxAmount: number, currency: string, expiry_days: number, created: Date, id: string, name: string, email_from_address: string, email_subject: string, email_from_name: string, locale: string, timezone: string, set_expiry_on_creation: boolean, use_wallet: boolean, use_shopify: boolean) {
        this.max_amount = maxAmount
        this.currency = currency
        this.expiry_days = expiry_days
        this.created = created
        this.id = id
        this.name = name
        this.email_from_address = email_from_address
        this.email_subject = email_subject
        this.email_from_name = email_from_name
        this.locale = locale
        this.timezone = timezone
        this.set_expiry_on_creation = set_expiry_on_creation
        this.use_wallet = use_wallet,
        this.use_shopify = use_shopify
    }

    public toJSON(): any {
        const json: any = {}

        json.max_amount = this.max_amount
        json.currency = this.currency
        json.expiry_days = this.expiry_days
        json.created = this.created
        json.id = this.id
        json.name = this.name
        json.email_from_address = this.email_from_address
        json.email_subject = this.email_subject
        json.email_from_name = this.email_from_name
        json.locale = this.locale
        json.timezone = this.timezone
        json.set_expiry_on_creation = this.set_expiry_on_creation
        if (this.use_wallet === true) {
            json.use_wallet = true
        }
        if (this.use_shopify === true) {
            json.use_shopify = this.use_shopify
        }
        
        return json
    }
}

export function unixTimestampToiso8601DateString(timestamp: number): string {
    let timestampInMiliseconds = timestamp * 1000
    let dateString = new Date(timestampInMiliseconds).toISOString()
    return dateString
}