import * as _ from "lodash"
import * as React from "react"

import { Alert, Button, Col, Form, FormControl, FormGroup, HelpBlock, Card, DescriptionCol } from "../wrappers"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import LoadingButton, { PageState } from "../PageState"
import { RecommendationCategory } from "../../models/RecommendationCategory"
import { currentDatabaseRef } from "../../config/constants"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { FormText, Row } from "react-bootstrap"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { child, get, set } from "firebase/database"

interface RecommendationCategoryEditState {
    categories: RecommendationCategory[]
    dirty: boolean
    editingCategory: RecommendationCategory
    error: string | null
    loading: boolean
    new: boolean
    publishing: boolean,
    validId: boolean,
    validName: boolean
}

class RecommendationCategoryEdit extends React.Component<RoleRouterProps, RecommendationCategoryEditState> {

    // Lifecycle

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            categories: [],
            dirty: false,
            editingCategory: new RecommendationCategory({ id: "", name: "" }, 0),
            error: null,
            loading: true,
            new: true,
            publishing: false,
            validId: false,
            validName: false
        }
    }

    // Component

    async componentDidMount() {
        await this.load()
    }

    render() {
        return (
            <PageState loading={this.state.loading} typeName="recommendation category" publishing={this.state.publishing} dirty={this.state.dirty}>
                <Card className="my-4">
                    <Card.Header>{this.state.new ? `New category` : `Editing category: ${this.state.editingCategory.name.localized(LanguageCode.da)}`}</Card.Header>
                    <Card.Body>
                        <Form onSubmit={e => e.preventDefault()}>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Name</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.editingCategory.name.localized(LanguageCode.da)}
                                        placeholder="Enter name"
                                        onChange={this.handleNameChange}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>

                            <ValidatingIdEntryControl
                                isNew={this.state.new ?? false}
                                showExistingIdentifier={true}
                                typeName="recommendation category"
                                identifierSource={this.state.editingCategory?.name.localized(null) ?? ""}
                                existingIdentifier={this.state.editingCategory?.id ?? ""}
                                handleIdChange={(id, valid) => {
                                    if (!valid) { return }
                                    this.handleIdChange(id)
                                }}
                            />
                            {
                                !this.state.validId
                                    ?
                                    <Row><Col sm={2}/><Col sm={10}><HelpBlock>Identifier is not valid</HelpBlock></Col></Row>
                                    :
                                    []
                            }
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={this.publishDisabled()} />

                    </Card.Footer>
                </Card>
                {
                    this.state.error ? (
                        <Alert variant="danger">
                            <strong>Error publishing recommendation category</strong> {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }

    // Methods

    publishDisabled(): boolean {
        return !this.state.dirty || !this.state.validId || !this.state.validName
    }

    generatedIdentifier(input: string): string {
        const trimmedInput = input.trim()
        if (!trimmedInput) {
            return ""
        }
        return trimmedInput
            .toLowerCase()
            .replace(/ø/g, "oe")
            .replace(/æ/g, "ae")
            .replace(/å/g, "aa")
            .replace(/\W/g, "_") || ""
    }

    isValidIdentifier(identifier: string): boolean {
        const index = _.find((this.state.categories || []), category => {
            return category.id === identifier
        })
        return index === undefined && identifier !== ""
    }

    isValidName(name: L10nString): boolean {
        return !name.hasEmptyLocalizations()
    }

    handleNameChange = (event: any) => {
        const target = event.target
        const value = target.value

        const category = this.state.editingCategory
        category.name = new L10nString(value)

        this.setState({ dirty: true, error: null, validName: this.isValidName(category.name) })
    }

    handleIdChange = (id: string) => {
        const valid = this.isValidIdentifier(id)
        const category = this.state.editingCategory
        category.id = id

        this.setState({ dirty: true, error: null, validId: valid })
    }


    async load() {
        const accountId = this.props.role.account_id

        const categoryKey = this.props.router.params.categoryKey !== "new" ? this.props.router.params.categoryKey : null
        let editingCategory = this.state.editingCategory
        const categories: RecommendationCategory[] = []

        const recommendationCategoriesSnap = await get(child(currentDatabaseRef(), `/v1/accounts/${accountId}/inventory/recommendation_categories`))
        if (recommendationCategoriesSnap && recommendationCategoriesSnap.exists()) {
            const value = recommendationCategoriesSnap.val()
            let index = 0
            for (const key in value) {
                const categoryJson = value[key]
                const category = new RecommendationCategory(categoryJson, index + 1)
                if (categoryJson.id === categoryKey) {
                    editingCategory = category
                }
                index += 1
                categories.push(category)
            }
        }

        const state = {
            categories: categories,
            loading: false,
            editingCategory: editingCategory,
            validId: this.isValidIdentifier(editingCategory.id),
            validName: this.isValidName(editingCategory.name)
        }

        this.setState(state)
    }

    recommendationCategoriesRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/inventory/recommendation_categories`)
    }

    publish = async () => {
        const category = this.state.editingCategory
        let rank = category.rank
        if (rank === 0) {
            rank = this.state.categories.length + 1
        }

        const categoryJson = category.json()

        this.setState({ publishing: true })

        try {
            await set(child(this.recommendationCategoriesRef(), `${rank - 1}`), categoryJson)
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.props.router.navigate("/product_recommendations")
    }
}

export default withRoleRouter(RecommendationCategoryEdit)
