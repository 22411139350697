import * as React from "react"
import { Table } from "react-bootstrap"

export function StripedTable(props: any) {
    return (
        /* 'fill' contains workaround for weird React bootstrap warning in the console */
        <Table className="table-borderless" striped={true} bordered={false} hover={true} style={{ cursor: "pointer", ...props.style }} /*fill={true}*/ >
            {props.children}
        </Table>
    ) 
}
