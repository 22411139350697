import React, { useState } from "react";
import { PDFTemplateLine, PDFTemplateLineType } from "../../../../../models/pdf_template/pdf_template_line";
import { Button, Modal } from "../../../../wrappers";
import * as _ from "lodash";
import { TextEdit } from "./BodyEditComponents/TextEdit";
import { BarcodeEdit } from "./BodyEditComponents/BarcodeEdit";
import { SpacingEdit } from "./BodyEditComponents/SpacingEdit";
import { WalletEdit } from "./BodyEditComponents/WalletEdit";
import { ImageEdit } from "./BodyEditComponents/ImageEdit";

interface ComponentEditProps {
    lineItem: PDFTemplateLine;
    customFonts: string[]
    index: number;
    onOK: (index: number, line: PDFTemplateLine) => void;
    onCancel: () => void;
}
export function ComponentEdit(props: ComponentEditProps) {
    const [lineItem, setLineItem] = useState<PDFTemplateLine>(props.lineItem);

    return (
        <Modal show={true} size="lg">
            <Modal.Header>
                <Modal.Title> {getEditTitle(lineItem.lineType)} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <EditBody updateLineItem={updateLineItem} lineItem={lineItem} customFonts={props.customFonts}></EditBody>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="primary" disabled={false} onClick={() => { props.onOK(props.index, lineItem); }}> OK </Button>
            </Modal.Footer>

        </Modal>
    );

    function updateLineItem(callback: UpdateLineItemCallback) {
        let updatedLine = _.cloneDeep(lineItem);
        updatedLine = callback(updatedLine);
        setLineItem(updatedLine);
    }
}

function getEditTitle(type: PDFTemplateLineType) {
    let title = "Edit "
    switch (type) {
        case PDFTemplateLineType.text:
            return title + "text component"
        case PDFTemplateLineType.wallet_url:
            return title + "Wallet component";
        case PDFTemplateLineType.barcode:
            return title + "barcode component";
        case PDFTemplateLineType.image:
            return title + "image component";
        case PDFTemplateLineType.spacer:
            return title + "spacer component";
    }
}

export interface EditBodyProps {
    lineItem: PDFTemplateLine;
    updateLineItem: (callback: UpdateLineItemCallback) => void;
    customFonts: string[]
}

export interface ValueEditProps {
    lineItem: PDFTemplateLine;
    updateLineItem: (callback: UpdateLineItemCallback) => void;
}

export type UpdateLineItemCallback = (lineItem: PDFTemplateLine) => PDFTemplateLine;
export function EditBody(props: EditBodyProps) {

    switch (props.lineItem.lineType) {
        case PDFTemplateLineType.text:
            return <TextEdit updateLineItem={props.updateLineItem} customFonts={props.customFonts} lineItem={props.lineItem}></TextEdit>;
        case PDFTemplateLineType.spacer:
            return <SpacingEdit updateLineItem={props.updateLineItem} lineItem={props.lineItem}></SpacingEdit>;
        case PDFTemplateLineType.barcode:
            return <BarcodeEdit updateLineItem={props.updateLineItem} lineItem={props.lineItem}></BarcodeEdit>;
        case PDFTemplateLineType.wallet_url:
            return <WalletEdit updateLineItem={props.updateLineItem} lineItem={props.lineItem}></WalletEdit>;
        case PDFTemplateLineType.image:
            return <ImageEdit updateLineItem={props.updateLineItem} lineItem={props.lineItem}></ImageEdit>;
        default:
            return <TextEdit updateLineItem={props.updateLineItem} customFonts={props.customFonts} lineItem={props.lineItem}></TextEdit>;
    }
}
