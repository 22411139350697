import * as React from "react"
import { Button, ButtonGroup } from "./wrappers"

interface ToggleButtonProps {
    performToggle: () => void
    active: boolean
    enabledTitle?: string
    disabledTitle?: string
    disabled?: boolean
    keepActiveColor?: boolean
}

export class ToggleButton extends React.Component<ToggleButtonProps, any> {
    render() {
        return (
            <ButtonGroup>
                <Button
                    variant={this.props.active ? "success" : "light"}
                    active={this.props.active}
                    // disabled={!this.props.isEnabled}
                    onClick={() => { if (!this.props.active) { this.props.performToggle() } }}
                >
                    {this.props.enabledTitle ?? "Enabled"}
                </Button>
                <Button
                    variant={this.getSecondButtonVariant()}
                    // disabled={this.props.isEnabled}
                    active={!this.props.active}
                    onClick={() => { if (this.props.active) { this.props.performToggle() } }}
                >
                    {this.props.disabledTitle ?? "Disabled"}
                </Button>
            </ButtonGroup>
        )
    }
    
    private getSecondButtonVariant(): string | undefined {
        if (this.props.keepActiveColor) {
            return !this.props.active ? "success" : "light"
        } else {
            return this.props.disabled ? "success" : "light"
        }
        
    }
}
