import * as React from "react"
import {
    Attribute,
    AttributeOption,
    AttributeTypeKey,
    AttributeValue
} from "../../models/Product"
import {
    clamp,
    cloneDeep,
    Dictionary,
    isNil,
} from "lodash"
import {
    FormControl,
    InputGroup,
    InputGroupAddon,
} from "../wrappers"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { currentDatabaseRef } from "../../config/constants"
import { StripedTable } from "../StripedTable"
import { Button, Form, Overlay, Dropdown, Card } from "react-bootstrap"
import { Calendar } from "react-date-range"
import dayjs from "dayjs"
import * as utc from "dayjs/plugin/utc"
import * as dayjstimezone from "dayjs/plugin/timezone"
import { FormattedDate, FormattedTime } from "react-intl"
import { useRef } from "react"
import * as _ from "lodash"
import TimeField from "react-simple-timefield"
import { DeleteButtonSymbol } from "./DiscountRules/AppliesToSelector"
import { child, get } from "firebase/database"
dayjs.extend(utc as any)
dayjs.extend(dayjstimezone as any)

interface AttributeSelectionProps {
    style?: React.CSSProperties
    attributeType: AttributeType
    account: string
    selectedAttributes: Dictionary<AttributeValue>
    currentLanguage: LanguageCode | null
    disabled?: boolean
    onChange: (attributes: Dictionary<AttributeValue>) => void
}

type AttributeType = "customer" | "product" | "shop"

interface AttributeSelectionState {
    attributes: Dictionary<Attribute>
    tappedAttribute?: string
}

interface DateEditorProps {
    value: string
    show: boolean
    onShow: (shown: boolean) => void
    onChanged: (value: string) => void
    placeholder?: string
}


export function DateEditor(props: DateEditorProps) {
    const target = useRef(null)

    let date: Date | undefined
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (props.value.length > 0) {
        date = dayjs.tz(props.value, timezone).toDate()
    }

    return <span>
        <Button ref={target} onClick={() => { props.onShow(!props.show) }} style={{ width: "100%" }} variant="outline-dark">

            {date ? <FormattedDate
                value={date}
                day="numeric"
                month="long"
                year="numeric"
            /> : <span style={{ color: "#888888" }}>{props.placeholder ?? "Select a date"}&nbsp;</span>}

        </Button>
        <Overlay rootClose={true} onHide={() => props.onShow(false)} target={target.current} show={props.show} placement="bottom-start">
            <div style={{
                zIndex: 2000,
                position: 'absolute',
                backgroundColor: 'white',
                padding: '2px 2px',
                color: 'white',
                borderRadius: "0.375rem",
                borderWidth: 1,
                borderColor: "#dddddd",
                borderStyle: "solid"
            }}>
                <Calendar date={date} onChange={(date) => {
                    const dateString = dayjs(date).local().format("YYYY-MM-DD")
                    props.onChanged(dateString)
                    props.onShow(false)
                }} />
            </div>
        </Overlay>
    </span>
}

export function DateTimeEditor(props: DateEditorProps) {
    const target = useRef(null)

    // Show an empty value until expanding
    const initialEmpty = props.value.length === 0 && !props.show

    const timeString = props.value.length > 0 ? props.value : dayjs().set("second", 0).set("millisecond", 0).toISOString()

    const timestamp = dayjs(timeString)
    const date = timestamp.toDate()
    const hours = timestamp.hour()
    const minutes = timestamp.minute()
    const formatted = _.padStart(`${hours}`, 2, "0") + ":" + _.padStart(`${minutes}`, 2, "0")

    return <><Button ref={target} onClick={() => {
        // Set the value initially, if it was empty
        if (initialEmpty) {
            props.onChanged(timeString)
        }
        props.onShow(!props.show)
    }} style={{ width: "100%" }} variant="outline-dark">

        {initialEmpty ? <span style={{ color: "#888888" }}>{props.placeholder ?? "Select a date and time"}&nbsp;</span> : <FormattedTime
            value={date}
            day="numeric"
            month="long"
            year="numeric"
            hour12={false}
        />}

    </Button>
        <Overlay rootClose={true} onHide={() => props.onShow(false)} target={target.current} show={props.show} placement="bottom-start">
            <div style={{
                zIndex: 2000,
                position: 'absolute',
                backgroundColor: 'white',
                padding: '2px 2px',
                color: 'white',
                borderRadius: "0.375rem",
                borderWidth: 1,
                borderColor: "#dddddd",
                borderStyle: "solid",
                textAlign: "center"
            }}>
                <TimeField
                    input={<Form.Control type="text" />}
                    style={{ textAlign: "center" }}
                    value={formatted} onChange={event => {
                        const newValue = event.target.value
                        const components = newValue.split(":")
                        const newTimestamp = timestamp
                            .set("hour", parseInt(components[0]))
                            .set("minute", parseInt(components[1]))
                            .set("second", 0)
                            .set("millisecond", 0)

                        props.onChanged(newTimestamp.toISOString())
                    }} />
                <Calendar date={date} onChange={(date) => {
                    const existing = timeString.split("T")
                    const datePart = dayjs(date).local().format("YYYY-MM-DD")

                    props.onChanged([datePart, existing[1]].join("T"))
                }} />
            </div>
        </Overlay>
    </>
}


export class AttributeSelection extends React.Component<AttributeSelectionProps, AttributeSelectionState> {
    constructor(props: AttributeSelectionProps) {
        super(props)

        this.state = {
            // selectedAttributes: {},
            attributes: {}
        }
    }

    onChange(data: any) {
        const tags = {}
        for (const key in data) {
            tags[data[key]] = true
        }

        this.props.onChange(tags)
    }

    attributesRef() {
        const account = this.props.account
        switch (this.props.attributeType) {
            case "customer": return child(currentDatabaseRef(), `v1/accounts/${account}/inventory/customer_attributes`)
            case "product": return child(currentDatabaseRef(), `v1/accounts/${account}/inventory/attributes`)
            case "shop": return child(currentDatabaseRef(), `v1/accounts/${account}/inventory/shop_attributes`)
        }
    }

    async componentDidMount() {

        const snapshot = await get(this.attributesRef())
        if (!snapshot.exists()) {
            this.setState({ attributes: {} })
            return
        }

        const rawAttributes = snapshot.val()
        const attributes: Dictionary<Attribute> = {}
        for (const key in rawAttributes) {
            if (Object.prototype.hasOwnProperty.call(rawAttributes, key)) {
                attributes[key] = new Attribute(rawAttributes[key])
            }
        }

        this.setState({ attributes: attributes })
    }

    componentWillUnmount() {
    }

    removeAttribute(attributeKey: string) {
        const selected = cloneDeep(this.props.selectedAttributes)
        delete selected[attributeKey]
        this.props.onChange(selected)
    }

    setAttribute(attributeKey: string, value: AttributeValue) {
        const selected = cloneDeep(this.props.selectedAttributes)
        selected[attributeKey] = value
        this.props.onChange(selected)
    }

    addAttribute(attributeKey: string, defaultValue: AttributeValue) {
        const selected = cloneDeep(this.props.selectedAttributes)
        selected[attributeKey] = defaultValue
        this.props.onChange(selected)
    }

    unusedAttributes() {
        const attributeKeys = Object.keys(this.state.attributes)
        const selected = Object.keys(this.props.selectedAttributes)
        const diff = attributeKeys.filter(x => !selected.includes(x))
            .sort((a, b) => {
                const aName = this.state.attributes[a]?.name.localized(null) ?? a
                const bName = this.state.attributes[b]?.name.localized(null) ?? b
                return aName.localeCompare(bName)
            })
        return diff
    }

    renderNumberValue(value: AttributeValue, selectedAttribute: string, attribute: Attribute): JSX.Element {
        const scale = clamp(attribute.type.number!.scale, 0, 3)
        const step = 1 / Math.pow(10, scale)

        let numberValue: number | undefined = isNil(value.number) ? undefined : value.numberValue()
        if (numberValue === undefined || isNaN(numberValue)) {
            numberValue = undefined
        }
        return (
            <td>
                <InputGroup
                    style={{ borderStyle: "solid", borderWidth: 1, borderRadius: "0.375rem", borderColor: "black", width: "100%" }}
                >
                    <FormControl
                        type="number"
                        name="value"
                        step={step}
                        value={numberValue ?? ""}
                        style={{ borderWidth: 0, textAlign: "end" }}
                        placeholder="Enter value"
                        onChange={(e: any) => {
                            const newValue = Number(e.target.value)
                            const newAttr = new AttributeValue(undefined)
                            if (!isNil(newValue) && e.target.value !== "") {
                                newAttr.number = newValue
                            }
                            this.setAttribute(selectedAttribute, newAttr)
                        }}
                        onBlur={(e: any) => {
                            const fixedStringValue = value.numberValue().toFixed(scale)
                            const newAttr = new AttributeValue(undefined)
                            newAttr.number = Number(fixedStringValue)
                            this.setAttribute(selectedAttribute, newAttr)
                        }}
                        autoComplete="off"
                    />
                    <InputGroupAddon style={{ alignContent: "center" }}>{attribute.type.number!.suffix.localized(this.props.currentLanguage)}</InputGroupAddon>
                </InputGroup>
            </td>
        )
    }

    renderDateValue(value: AttributeValue, selectedAttribute: string): JSX.Element {
        return <td>
            <DateEditor show={this.state.tappedAttribute === selectedAttribute} value={value.stringValue()} onChanged={(value) => {
                const newAttr = new AttributeValue(undefined)
                newAttr.string = value
                this.setAttribute(selectedAttribute, newAttr)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: selectedAttribute })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderDateTimeValue(value: AttributeValue, selectedAttribute: string): JSX.Element {
        return <td>
            <DateTimeEditor show={this.state.tappedAttribute === selectedAttribute} value={value.stringValue()} onChanged={(value) => {
                const newAttr = new AttributeValue(undefined)
                newAttr.string = value
                this.setAttribute(selectedAttribute, newAttr)
            }} onShow={(shown) => {
                if (shown) {
                    this.setState({ tappedAttribute: selectedAttribute })
                } else {
                    this.setState({ tappedAttribute: undefined })
                }
            }} />
        </td>
    }

    renderOptionsValue(options: Dictionary<AttributeOption>, value: AttributeValue, selectedAttribute: string): JSX.Element {
        const stringValue: string = value.stringValue()
        const selectedOption = options[stringValue]
        let displayValue = stringValue
        if (!isNil(selectedOption)) {
            displayValue = selectedOption.name.localized(this.props.currentLanguage || null)
        } else {
            displayValue = "Select a value"
        }
        return (
            <td>
                <Dropdown
                    onSelect={(selectedValue: any) => {
                        const newAttr = new AttributeValue(undefined)
                        newAttr.string = selectedValue
                        this.setAttribute(selectedAttribute, newAttr)
                    }}>
                    <Dropdown.Toggle
                        style={{ width: "100%", color: isNil(selectedOption) ? "#888888" : "black" }}
                        variant="outline-dark"
                        id="dropdown-attribute-value"
                    >
                        {displayValue}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "100%" }}>
                        {Object.keys(options).map((optionKey) => {
                            const option = options[optionKey]
                            return <Dropdown.Item key={optionKey} eventKey={optionKey}>{option.name.localized(this.props.currentLanguage || null)}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        )
    }

    renderTextValue(value: AttributeValue, selectedAttribute: string): JSX.Element {
        const l10nString = value.localizedValue()
        return (
            <td>
                <L10nFormControl
                    placeholder="Enter a value"
                    l10n={l10nString}
                    type="textarea"
                    as="textarea"
                    language={this.props.currentLanguage || null}
                    style={{ resize: "vertical", width: "100%", borderColor: "black", borderRadius: "0.375rem" }}
                    onLocalizationChanged={l10n => {
                        if (isNil(l10n)) {
                            const newAttr = new AttributeValue(undefined)
                            newAttr.string = ""
                            this.setAttribute(selectedAttribute, newAttr)
                        } else {
                            const newAttr = new AttributeValue(undefined)
                            newAttr.localized = l10n
                            this.setAttribute(selectedAttribute, newAttr)
                        }
                    }}
                />
            </td>
        )
    }

    renderTextEntryValue(value: AttributeValue, selectedAttribute: string): JSX.Element {
        const l10nString = value.booleanValue()
        return (
            <td>
                <div style={{ color: "#888888", padding: "4px 8px", borderStyle: "solid", borderWidth: 1, borderRadius: "0.375rem", borderColor: "#dddddd", width: "100%" }}> This attribute is input through POS when the product is added to the basket. </div>
            </td>
        )
    }

    render() {
        return (
            <section style={this.props.style}>
                {
                    this.state.attributes
                        ? (
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Attributes</Card.Title>
                                    {
                                        Object.keys(this.props.selectedAttributes).length > 0 ? (
                                            <StripedTable>
                                                <thead>
                                                    <tr>
                                                        <th>Attribute</th>
                                                        <th>Value</th>
                                                        {this.props.disabled !== true &&
                                                            <th>Delete</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.keys(this.props.selectedAttributes).map((selectedAttribute) => {
                                                            const value = this.props.selectedAttributes[selectedAttribute]
                                                            if (isNil(value)) {
                                                                return null
                                                            }

                                                            const attribute = this.state.attributes[selectedAttribute]
                                                            let attributeName = selectedAttribute
                                                            if (!isNil(attribute)) {
                                                                attributeName = attribute.name.localized(this.props.currentLanguage || null)
                                                            }
                                                            let type: AttributeTypeKey | undefined = undefined
                                                            if (!isNil(attribute)) {
                                                                type = attribute.typeKey()
                                                            }
                                                            if (!isNil(attribute) && !isNil(type)) {
                                                                let renderedValue: JSX.Element = <br />
                                                                switch (type) {
                                                                    case AttributeTypeKey.NUMBER:
                                                                        renderedValue = this.renderNumberValue(value, selectedAttribute, attribute)
                                                                        break

                                                                    case AttributeTypeKey.OPTIONS:
                                                                        renderedValue = this.renderOptionsValue(attribute.type.options!, value, selectedAttribute)
                                                                        break

                                                                    case AttributeTypeKey.TEXT:
                                                                        renderedValue = this.renderTextValue(value, selectedAttribute)
                                                                        break

                                                                    case AttributeTypeKey.TEXT_ENTRY:
                                                                        renderedValue = this.renderTextEntryValue(value, selectedAttribute)
                                                                        break

                                                                    case AttributeTypeKey.DATE:
                                                                        renderedValue = this.renderDateValue(value, selectedAttribute)
                                                                        break

                                                                    case AttributeTypeKey.DATE_TIME:
                                                                        renderedValue = this.renderDateTimeValue(value, selectedAttribute)
                                                                        break
                                                                }

                                                                return (
                                                                    <tr key={selectedAttribute}>
                                                                        <td>{attributeName}</td>
                                                                        {renderedValue}
                                                                        {this.props.disabled !== true &&
                                                                            <td className="narrow">
                                                                                <Button variant="link" onClick={() => { this.removeAttribute(selectedAttribute) }}><DeleteButtonSymbol /></Button>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            } else {
                                                                return (
                                                                    <tr key={selectedAttribute}>
                                                                        <td>{attributeName} (undefined)</td>
                                                                        <td>{value.stringValue()}</td>
                                                                        {this.props.disabled !== true &&
                                                                            <td className="narrow">
                                                                                <Button variant="link" onClick={() => { this.removeAttribute(selectedAttribute) }}><DeleteButtonSymbol /></Button>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </StripedTable>
                                        ) : null
                                    }

                                    {
                                        this.unusedAttributes().length > 0 ? (
                                            <Dropdown
                                                id="dropdown-add-attribute"
                                                onSelect={(selectedAttribute: any) => {
                                                    const attribute = this.state.attributes[selectedAttribute]
                                                    const defaultValue = new AttributeValue(undefined)
                                                    switch (attribute.typeKey()) {
                                                        case AttributeTypeKey.NUMBER:
                                                            defaultValue.number = 0
                                                            break

                                                        case AttributeTypeKey.TEXT:
                                                            defaultValue.localized = new L10nString("")
                                                            break

                                                        case AttributeTypeKey.OPTIONS:
                                                            defaultValue.string = ""
                                                            break

                                                        case AttributeTypeKey.TEXT_ENTRY:
                                                            defaultValue.boolean = true
                                                            break

                                                        default:
                                                            break
                                                    }
                                                    this.addAttribute(selectedAttribute, defaultValue)
                                                }}
                                            >
                                                <Dropdown.Toggle
                                                    variant="outline-primary"
                                                    size="sm"
                                                    disabled={this.props.disabled}
                                                >
                                                    Add attribute
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    style={{ overflowY: "scroll", maxHeight: 400 }}>
                                                    {

                                                        this.unusedAttributes().map((attributeKey) => {
                                                            const attribute = this.state.attributes[attributeKey]
                                                            return <Dropdown.Item key={attributeKey} eventKey={attributeKey}>{attribute.name.localized(this.props.currentLanguage || null)}</Dropdown.Item>
                                                        })}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            // <DropdownButton
                                            //     style={{ overflowY: "scroll", maxHeight: 400 }}
                                            //     title="Add attribute"

                                            // >

                                            // </DropdownButton>

                                        ) : null
                                    }


                                </Card.Body>
                            </Card>
                        ) : <div>Loading..</div>
                }
            </section>
        )
    }
}
