import * as React from "react"
import {
    Col,
    
    Form,
    FormGroup,
} from "../../wrappers"
import { ToggleButton } from "../../ToggleButton"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"

export class CustomerLookupModule extends React.Component<ModuleProps, {}> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
        }
    }

    // Component

    render() {
        const config = this.props.moduleData
        const customerSignupEnabled: boolean = config["customer_signup_enabled"] || false
        const useCustomerAccount: boolean = config["use_customer_account"] || false
        const useCustomerDiscountLookup: boolean = config["use_customer_discount_lookup"] || false

        return (
            <Form>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Use Customer Signup</Col>
                    <Col sm={10}>
                        {"If enabled, it will be possible to sign up customers in Ka-ching. This functionality requires an integration that can push customer data to a CRM system"}
                        <br />
                        <ToggleButton
                            active={customerSignupEnabled}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["customer_signup_enabled"] = !customerSignupEnabled
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Use Customer Account</Col>
                    <Col sm={10}>
                        {"If enabled, it will be possible to add deposits to a customer account from the customer details screen"}
                        <br />
                        <ToggleButton
                            active={useCustomerAccount}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["use_customer_account"] = !useCustomerAccount
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Use Customer Specific Discount Lookup</Col>
                    <Col sm={10}>
                        {"If enabled, customer specific discounts will be looked up using an integration after adding a customer to a sale"}
                        <br />
                        <ToggleButton
                            active={useCustomerDiscountLookup}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["use_customer_discount_lookup"] = !useCustomerDiscountLookup
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
