import * as React from "react"

import ReceiptConfiguration from "./ShopConfiguration/ReceiptConfiguration"
import OrderHandlingConfiguration from "./ShopConfiguration/OrderHandlingConfiguration"

import { currentDatabaseRef } from "../../config/constants"
import { RoleProps, withRole } from "../../routes"
import VippsMobilePayConfiguration from "./ShopConfiguration/VippsMobilePayConfiguration"
import { child, get } from "firebase/database"
import SharedConfigurationEdit from "./ShopConfiguration/SharedConfigurationEdit"
import ContributionRatioConfigurationShop from "./ShopConfiguration/ContributionRatioConfiguration"

interface ShopConfigurationState {
    mobile_pay_module_enabled: boolean
    orders_module_enabled: boolean
}

class ShopConfiguration extends React.Component<RoleProps, ShopConfigurationState> {

    constructor(props: RoleProps) {
        super(props)
        this.state = {
            mobile_pay_module_enabled: false,
            orders_module_enabled: false
        }
    }

    mobilePayEnabledRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules/mobile_pay/enabled`)
    }

    ordersEnabledRef(accountId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules/orders/enabled`)
    }

    async componentDidMount() {
        const mobilePaySnap = await get(this.mobilePayEnabledRef(this.props.role.account_id))
        const ordersEnabledSnap = await get(this.ordersEnabledRef(this.props.role.account_id))
        this.setState({
            mobile_pay_module_enabled: mobilePaySnap.val() === true,
            orders_module_enabled: ordersEnabledSnap.val() === true
        })
    }

    render() {
        return (
            <div>
                <ReceiptConfiguration
                    key="receipt_configuration"
                    role={this.props.role}
                />
                {this.state.mobile_pay_module_enabled ? [
                    <VippsMobilePayConfiguration
                        key="vipps_mobile_pay_configuration"
                        role={this.props.role}
                    />]
                    : null}
                {this.state.orders_module_enabled ?
                    (
                        <OrderHandlingConfiguration
                            role={this.props.role}
                            mode="shop"
                        />
                    )
                    : null}
                {/** maximum stock displayed */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"maximum_stock_displayed"}
                    name={"maximum stock displayed"}
                    defaultValue={99}
                    description={<><p>By default, Ka-ching POS only shows the actual stock value up to 99. After that it shows 99+.</p><p>You can override this behavior by setting the maximum stock displayed to a different value.<br /></p></>}
                    typeValue="number"
                    minValue={1}
                    mode="shop"
                />

                {/** ignore online stock availability */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"ignore_online_stock_availability"}
                    name={"ignore online stock availability"}
                    defaultValue={false}
                    description={<><p>By default, POS will not allow online sales if the stock count for the remote stock location is 0</p><p>You can override this behavior by ignoring the online stock availability.</p></>}
                    typeValue="boolean"
                    mode="shop"
                />
                {/** show item in basket */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"show_item_price_in_basket"}
                    name={"show item price in basket"}
                    defaultValue={false}
                    description={<><p>Enabling this will show the final discounted price <i>per item</i> in the basket.</p></>}
                    typeValue="boolean"
                    mode="shop"
                />
                {/** pin code reset period */}
                <SharedConfigurationEdit
                    role={this.props.role}
                    path={"cashier_selection/pin_code_reset_period_months"}
                    name={"pin code reset period (in months)"}
                    defaultValue={undefined}
                    description={`Setting a value here, will force cashiers to choose a new PIN code every x months`}
                    typeValue="number"
                    minValue={1}
                    mode="shop"
                />
                <ContributionRatioConfigurationShop
                    role={this.props.role}
                    mode="shop"
                />
            </div>
        )
    }
}

export default withRole(ShopConfiguration)
