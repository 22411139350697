import React, { useState, useEffect } from "react"
import { Button, FormControl, InputGroup } from "../../wrappers"
import { MarketAmount } from "../../../models/MarketAmount"
import { Market } from "../../../models/MarketModels"
import { FormType } from "../../../models/RuleModels"
import { DecimalValueFormControl } from "../../DecimalValueFormControl"
import { MarketAmountFormControl } from "../../MarketAmountFormControl"
import { DeleteButtonSymbol } from "./AppliesToSelector"
import { DiscountType } from "./DiscountSelector"
import { RequirementsType } from "./MinimumRequirementsSelector"

interface StairStepProps {
    discountType: DiscountType
    requirementsType: RequirementsType
    formType: FormType
    market: Market | null
    markets: string[]
    row: RowData
    rowChanged: (row: RowData) => void
    onRowDeleted?: (row: RowData) => void
}

export interface RowData {
    id: number
    count?: number
    amount?: MarketAmount
    discountAmount?: MarketAmount
    discountPercentage?: number
}

function rowWithCount(row: RowData, event: any): RowData {
    const value = event.target.value
    const count = Number(value)
    return {
        id: row.id,
        count: value === "" ? undefined : count,
        discountAmount: row.discountAmount,
        discountPercentage: row.discountPercentage
    }
}

function rowWithAmount(row: RowData, amount: MarketAmount | null): RowData {
    return {
        id: row.id,
        amount: amount ?? undefined,
        discountAmount: row.discountAmount,
        discountPercentage: row.discountPercentage
    }
}

function rowWithDiscountPercentage(row: RowData, value: number | null): RowData {
    let scaled: number | undefined = undefined
    if (value !== null) {
        scaled = value / 100
    }
    return {
        id: row.id,
        count: row.count,
        amount: row.amount,
        discountPercentage: scaled
    }
}

function rowWithDiscountAmount(row: RowData, amount: MarketAmount | null): RowData {
    return {
        id: row.id,
        count: row.count,
        amount: row.amount,
        discountAmount: amount ?? undefined
    }
}

export function StairStep(props: StairStepProps) {
    const [row, setRow] = useState<RowData>(props.row);
    useEffect(() => {
        props.rowChanged(row)
    }, [row, props])

    return <tr>
        {props.requirementsType === "count" ? [
            <td key="a" style={{ width: "1%", whiteSpace: "nowrap" }}>When buying</td>,
            <td key="b">
                <InputGroup>
                    <FormControl
                        type="number"
                        name="count"
                        // min={0}
                        step={1}
                        value={row.count ?? ""}
                        placeholder="Enter quantity"
                        onChange={(event: any) => { setRow(rowWithCount(row, event)) }}
                    />
                </InputGroup>
            </td>] : (props.requirementsType === "amount" && [
                <td key="c" style={{ width: "1%", whiteSpace: "nowrap" }}>
                    When the basket total is
                </td>,
                <td key="d">
                    <MarketAmountFormControl
                        allMarkets={props.markets}
                        amount={row.amount ?? null}
                        market={props.market}
                        onAmountChanged={amount => {
                            setRow(rowWithAmount(row, amount))
                        }}
                        placeholder="Enter required amount"
                        currency={props.market?.currency ?? ""}
                    />
                </td>
            ])}

        {props.discountType === "percentage" ? [
            <td key="e" style={{ width: "1%", whiteSpace: "nowrap" }}>
                {props.formType === "discount" ? "Reduce price by" : "Point earning percentage"}
            </td>,
            <td key="f">
                <DecimalValueFormControl
                    value={row.discountPercentage !== undefined ? Math.round(row.discountPercentage * 1000) / 10 : null}
                    onValueChanged={value => { setRow(rowWithDiscountPercentage(row, value)) }}
                    decimals={1}
                    prefixSymbol="%"
                    placeholder={props.formType === "discount" ? "Enter discount percentage" : "Enter point earning percentage"}
                />
            </td>
        ] : [
            props.discountType === "amount_off" ? <td key="g" style={{ width: "1%", whiteSpace: "nowrap" }}>{props.formType === "discount" ? "Reduce price by" : "Point earning amount"}</td> : <td key="h" style={{ width: "1%", whiteSpace: "nowrap" }}>Set price to</td>,
            <td key="i">
                <MarketAmountFormControl
                    allMarkets={props.markets}
                    amount={row.discountAmount ?? null}
                    market={props.market}
                    onAmountChanged={amount => {
                        setRow(rowWithDiscountAmount(row, amount))
                    }}
                    placeholder={props.formType === "discount" ? "Enter discount amount" : "Enter point earning amount"}
                    currency={props.market?.currency ?? ""}
                />
            </td>
        ]
        }
        {props.onRowDeleted && <td><Button variant="link" onClick={() => { props.onRowDeleted?.(row) }}><DeleteButtonSymbol /></Button></td>}
    </tr >
}

