import * as _ from "lodash"
import { currentDatabaseRef } from "../../../config/constants"
import { FrontPageSection, FrontPageSectionType } from "./FrontPageSectionModels"
import { child, DatabaseReference, get, set } from "firebase/database"

export default class FrontPageSectionsViewModel {

    // Properties

    private _accountId: string
    private _sections?: FrontPageSection[]

    // Constructor

    constructor(accountId: string) {
        this._accountId = accountId
    }

    // Signals In

    async start() {
        this._sections = await this.loadSectionsFromDB()
        if (this.sectionsUpdated) {
            this.sectionsUpdated(this._sections)
        }
    }

    stop() {
    }

    handleSingleReordering(reorder: any): boolean {
        if (!this._sections) {
            return false
        }

        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return false
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return false
        }

        const newOrdering = Array.from(this._sections)
        const [removed] = newOrdering.splice(reorder.source.index, 1)
        newOrdering.splice(reorder.destination.index, 0, removed)

        this._sections = newOrdering

        if (this.sectionsUpdated) {
            this.sectionsUpdated(this._sections)
        }

        return true
    }

    handleRemoveSection(index: number) {
        if (!this._sections) {
            return
        }

        const newOrdering = Array.from(this._sections)
        newOrdering.splice(index, 1)
        this._sections = newOrdering

        if (this.sectionsUpdated) {
            this.sectionsUpdated(this._sections)
        }
    }

    handleSectionUpdate(index: number, section: FrontPageSection) {
        if (!this._sections) {
            return
        }

        const sections = _.cloneDeep(this._sections)
        sections[index] = section
        this._sections = sections
        if (this.sectionsUpdated) {
            this.sectionsUpdated(this._sections)
        }
    }

    handleSectionAdd(type: FrontPageSectionType) {
        if (!this._sections) {
            return
        }

        const sections = _.cloneDeep(this._sections)
        sections.push(FrontPageSection.withType(type))
        this._sections = sections
        if (this.sectionsUpdated) {
            this.sectionsUpdated(this._sections)
        }
    }

    nameOfSectionAtIndex(index: number): string {
        if (!this._sections) {
            return ""
        }

        return this._sections[index].title()
    }

    async publish() {
        if (!this._sections) {
            return
        }
        const sections = this._sections.map((section) => { return section.toJSON() })
        await set(this.frontPageRef(), { sections: sections })
    }

    // Signals Out   

    public sectionsUpdated?: (sections: FrontPageSection[]) => void

    // Helpers

    private frontPageRef(): DatabaseReference {
        return child(currentDatabaseRef(), `v1/accounts/${this._accountId}/app_data/pos/configurable_front_page`)
    }

    private async loadSectionsFromDB(): Promise<FrontPageSection[]> {
        const frontPageSnapshot = await get(this.frontPageRef())
        if (!frontPageSnapshot.exists()) {
            return []
        } else {
            return this.sectionsFromSnapshot(frontPageSnapshot.val())
        }
    }

    private sectionsFromSnapshot(json: any): FrontPageSection[] {
        return json.sections.map((sectionJson: any) => {
            return FrontPageSection.fromJSON(sectionJson)
        })
    }
}