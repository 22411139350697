import { Dictionary, values, flatMap } from "lodash"
import { BaseReport, formatTime, dataFormatCurrencyAmount, formatTwoDigits, Payment, ReportLine, ReportType, Sale, StyleField } from "../models/ReportModels"
import { DataSnapshot } from "firebase/database"

class PaymentTypeSalesReportLine implements ReportLine {
    private sale_id: string
    private type: string
    private sequence_number: number
    private time: number
    private cashier_name: string
    private register_name: string
    private shop_name: string
    private payment_type: string
    private amount: number
    private payment_status: string
    private sale_status: string
    private card_type: string

    constructor(sale_id: string, type: string, sequence_number: number, time: number, cashier_name: string, register_name: string, shop_name: string, payment_type: string, amount: number, payment_status: string, sale_status: string, card_type: string) {
        this.sale_id = sale_id
        this.type = type
        this.sequence_number = sequence_number
        this.time = time
        this.cashier_name = cashier_name
        this.register_name = register_name
        this.shop_name = shop_name
        this.payment_type = payment_type
        this.amount = amount
        this.payment_status = payment_status
        this.sale_status = sale_status
        this.card_type = card_type
    }

    forCSV(decimalSeparator: string): string[] {
        return [
            `"${this.sale_id}"`,
            `"${this.type}"`,
            `"${this.sequence_number}"`,
            `"${formatTime(this.time)}"`,
            `"${this.cashier_name}"`,
            `"${this.register_name}"`,
            `"${this.shop_name}"`,
            `"${this.payment_type}"`,
            `"${this.card_type}"`,
            `"${dataFormatCurrencyAmount(this.amount, decimalSeparator)}"`,
            `"${this.payment_status}"`,
            `"${this.sale_status}"`,
        ]
    }

    forPDF(): object[] {
        return [
            { text: this.sale_id, style: `${StyleField.leftTableValue}` },
            { text: this.type, style: `${StyleField.leftTableValue}` },
            { text: this.sequence_number, style: `${StyleField.rightTableValue}` },
            { text: formatTime(this.time), style: `${StyleField.leftTableValue}` },
            { text: this.cashier_name, style: `${StyleField.leftTableValue}` },
            { text: this.register_name, style: `${StyleField.leftTableValue}` },
            { text: this.shop_name, style: `${StyleField.leftTableValue}` },
            { text: this.payment_type, style: `${StyleField.leftTableValue}` },
            { text: this.card_type, style: `${StyleField.leftTableValue}` },
            { text: formatTwoDigits(this.amount), style: `${StyleField.rightTableValue}` },
            { text: this.payment_status, style: `${StyleField.leftTableValue}` },
            { text: this.sale_status, style: `${StyleField.rightTableValue}` }
        ]
    }
}

export class PaymentTypeSalesReport extends BaseReport implements ReportType {
    title(): string {
        return "Sales report, payments" //TODO: change to localization key
    }

    description(type: "csv" | "pdf"): string {
        return `This report has a line per payment type in a given sale. The following columns are exported: ${this.headers(type).join(", ")}.` //TODO: change to localization key
    }

    async prepare(): Promise<void> {
    }

    buildLines(salesSnapshot: DataSnapshot): ReportLine[] {
        if (!salesSnapshot.exists) {
            return []
        }

        const salesDict: Dictionary<Sale> = salesSnapshot.val()
        const lines = values(salesDict).map((sale: Sale) => {
            const payments = sale.payments || []
            return payments.map((payment: Payment) => {
                let cardType: string = ""
                if (payment.payment_type.split(".")[0] === "card") {
                    cardType = (payment.metadata || {}).card_type || ""
                }
                return new PaymentTypeSalesReportLine(
                    sale.identifier,
                    sale.summary.is_return ? "return" : "sale", //TODO: change to localization keys
                    sale.sequence_number,
                    sale.timing.timestamp,
                    sale.source.cashier_name,
                    sale.source.register_name,
                    sale.source.shop_name,
                    payment.payment_type,
                    payment.amount,
                    payment.success ? "success" : "failure",
                    sale.voided ? "voided" : "completed",
                    cardType
                )
            })
        })

        return flatMap(lines)
    }

    headers(type: "csv" | "pdf"): string[] { //TODO: localization
        return [
            "Sale Id",
            "Type",
            "Sequence number",
            "Time",
            "Cashier",
            "Register",
            "Shop",
            "Payment Type",
            "Card Type",
            "Amount",
            "Payment Status",
            "Sale Status"
        ]
    }
}
