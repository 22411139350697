import * as React from "react"
import {
    Alert,
    Button,
    Col,
    
    Form,
    FormControl,
    FormGroup,
    Modal
} from "../../wrappers"
import { getExistingApiKey } from "../../../helpers/existingApiKey"
import { ImportIntegrationType, getIntegrationName } from "./ImportIntegrations"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import { v4 as uuid } from "uuid"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { Row } from "react-bootstrap"
import { child, DatabaseReference, get, set } from "firebase/database"

interface ImportIntegrationEditProps {
    role: Role
    integrationType: ImportIntegrationType
    editComplete: () => (void)
    existingIntegration?: any
    existingIntegrationId?: string
}

interface ImportIntegrationEditState {
    addIntegrationName?: string
    addIntegrationId?: string
    errorDescription?: string
    isNew: boolean
}

export class ImportIntegrationEdit extends React.Component<ImportIntegrationEditProps, ImportIntegrationEditState> {
    constructor(props: ImportIntegrationEditProps) {
        super(props)

        let isNew: boolean
        if (this.props.existingIntegration === undefined) {
            isNew = true
        } else {
            isNew = false
        }
        const integration = this.props.existingIntegration || {}

        this.state = {
            addIntegrationName: integration.name,
            addIntegrationId: this.props.existingIntegrationId,
            isNew: isNew
        }
    }

    integrationRef(): DatabaseReference {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        return child(accountRef, `api_keys/import_integrations/${this.props.integrationType}`)
    }

    addIntegrationEnabled(): boolean {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        for (const param of [id, name]) {
            if (param === undefined || param.length === 0) {
                return false
            }
        }
        return true
    }

    async addIntegration() {
        const id = this.state.addIntegrationId
        const name = this.state.addIntegrationName
        const integrationType = this.props.integrationType
        if (id === undefined) { return }
        if (name === undefined) { return }
        this.setState({ errorDescription: undefined })
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)

        const existingIntegrationRef = child(accountRef, `api_keys/import_integrations/${integrationType}/${id}`)
        if (this.state.isNew) {
            if ((await get(existingIntegrationRef)).exists()) {
                const errorDescription = `Integration with type ${integrationType} and id ${id} already exists`
                console.error(errorDescription)
                this.setState({ errorDescription: errorDescription })
                return
            }
        }

        // 1: See if any integrations already exist
        let existingAPIKey = await getExistingApiKey(account)

        // 2: If so, use key - if not, generate 
        if (existingAPIKey === undefined) {
            existingAPIKey = uuid()
        }

        // 3: lookup service user uid
        const serviceUserUID = (await get(child(child(accountRef, "api_keys"), "service_user_uid"))).val()
        if (!serviceUserUID) {
            const errorDescription = "No service user UID found on the account"
            console.error(errorDescription)
            this.setState({ errorDescription: errorDescription })
            return
        }

        // 4: Add integration entry and api key
        const apiKeyRef = child(accountRef, `api_keys/import_integrations/${integrationType}/${id}/${existingAPIKey}`)
        await set(apiKeyRef, { metadata: { uid: serviceUserUID, active: true } })

        // 5: And set the integration name
        const integrationConfigRef = child(accountRef, `configuration/import_integrations/${integrationType}/${id}`)
        await set(integrationConfigRef, { name: name })

        this.setState({ addIntegrationName: undefined, addIntegrationId: undefined })
        this.props.editComplete()
    }

    render() {
        return (
            <Modal size="lg" show={true} onHide={() => { this.props.editComplete() }} >
                <Modal.Header closeButton={true}>
                    <Modal.Title>{this.state.isNew ? "Add new import integration" : "Edit import integration"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errorDescription ? <Alert variant="warning">{this.state.errorDescription}</Alert> : null}

                    Please enter an id and a name for the {getIntegrationName(this.props.integrationType).toLowerCase()} import integration.
                        <br /><br />
                    <Form onSubmit={e => e.preventDefault()}>
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={2}>Integration name</Col>
                            <Col sm={10}>
                                <FormControl
                                    type="text"
                                    name="integrationname"
                                    value={this.state.addIntegrationName || ""}
                                    placeholder="Enter integration name"
                                    onChange={(event: any) => {
                                        this.setState({ addIntegrationName: event.target.value })
                                    }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                        <ValidatingIdEntryControl
                            collectionRef={this.integrationRef()}
                            isNew={this.state.isNew}
                            typeName="import integration"
                            identifierSource={this.state.addIntegrationName || ""}
                            existingIdentifier={this.props.existingIntegrationId || ""}
                            handleIdChange={(id, valid) => {
                                this.setState({ addIntegrationId: id })
                            }}
                        />
                    </Form>

                    <Button variant="success" onClick={async () => { await this.addIntegration() }} disabled={!this.addIntegrationEnabled()}>{this.state.isNew ? "Add integration" : "Update integration"}</Button>

                </Modal.Body>
            </Modal>
        )
    }
}