//
//  Overview.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 17/08/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Alert } from "../wrappers"
import { PageState } from "../PageState"
import { Role } from "../../config/role"
import { Globals } from "../../helpers/globals"
import { Market } from "../../models/MarketModels"
import { currentDatabaseRef, firestore } from "../../config/constants"
import { Dictionary, cloneDeep, last, take } from "lodash"
import { sortLikeFirebaseOrderByKey } from "../../helpers/sorting"
import { RoleShopProps, RoleProps } from '../../routes';
import dayjs from "dayjs"
import { child, DatabaseReference, DataSnapshot, get, limitToFirst, off, onValue, orderByKey, query, startAt } from "firebase/database"
import { collection, count, getCountFromServer } from "firebase/firestore"

interface OverviewProps extends RoleProps {
    role: Role
    shop?: string
}

interface OverviewState {
    markets: Market[]
    loaded: boolean
    error: string | null
    stats: Dictionary<any>
    stats_yesterday: Dictionary<any>
    keys: string[]
    titles: Dictionary<string>
    shopMarket?: string
    paths: string[],
    shopCount: Dictionary<number>
    marketProductCount: Dictionary<number>
    loadedShopsAndProducts: boolean
}

class ShopOverview extends React.Component<RoleShopProps, any> {
    render() {
        return <Overview shop={this.props.shop} role={this.props.role} />
    }
}

class Overview extends React.Component<OverviewProps, OverviewState> {

    // Properties

    initialState: OverviewState = {
        markets: [],
        loaded: false,
        error: null,
        stats: {},
        stats_yesterday: {},
        keys: [],
        titles: {},
        paths: [],
        shopCount: {},
        marketProductCount: {},
        loadedShopsAndProducts: false
    }

    // Component

    constructor(props: OverviewProps) {
        super(props)

        this.state = this.initialState
    }

    async componentDidMount() {
        await this.loadState()
    }

    async componentDidUpdate(prevProps: OverviewProps) {
        if (prevProps.shop !== this.props.shop) {
            await this.loadState()
        }
    }

    componentWillUnmount() {
        this.unsubscribe()
    }

    async loadState() {
        this.setState({ loaded: false })

        const now = dayjs()

        const dateString = now.format("YYYY-MM-DD")
        const yesterday = now.subtract(1, "day")
        const yesterdayString = yesterday.format("YYYY-MM-DD")
        const markets = await Globals.shared.getMarkets()
        const stats: Dictionary<any> = {}
        const statsYesterday: Dictionary<any> = {}
        const keys: string[] = []
        const titles: Dictionary<string> = {}
        const paths: string[] = []
        let shopMarket: string | undefined
        const shopCount: Dictionary<number> = {}

        if (this.props.shop) {
            const shopMarketRef = child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/shops/${this.props.shop}/market`)
            const marketSnap = await get(shopMarketRef)
            shopMarket = marketSnap.val()
            const path = `v1/accounts/${this.props.role.account_id}/stats/summary/shop/${this.props.shop}/all/day/${dateString}`
            const statsRef = child(currentDatabaseRef(), path)
            const snap = await get(statsRef)

            const pathYesterday = `v1/accounts/${this.props.role.account_id}/stats/summary/shop/${this.props.shop}/all/day/${yesterdayString}`
            const statsRefYesterday = child(currentDatabaseRef(), pathYesterday)
            const snapYesterday = await get(statsRefYesterday)

            paths.push(path)
            stats[this.props.shop] = snap.val()
            statsYesterday[this.props.shop] = snapYesterday.val()
            keys.push(this.props.shop)
            titles[this.props.shop] = "Shop"
            shopCount[this.props.shop] = 1
        } else {
            const promises: Promise<DataSnapshot>[] = []
            const promisesYesterday: Promise<DataSnapshot>[] = []
            for (const market of markets) {
                const marketId = market.id
                const path = `v1/accounts/${this.props.role.account_id}/stats/summary/market/${marketId}/day/${dateString}`
                const statsRef = child(currentDatabaseRef(), path)
                paths.push(path)
                promises.push(get(statsRef))
                keys.push(marketId)
                titles[marketId] = market.name

                const pathYesterday = `v1/accounts/${this.props.role.account_id}/stats/summary/market/${marketId}/day/${yesterdayString}`
                const statsRefYesterday = child(currentDatabaseRef(), pathYesterday)
                promisesYesterday.push(get(statsRefYesterday))
            }

            const snaps = await Promise.all(promises)
            snaps.forEach((snapshot, index) => {
                const market = markets[index]
                stats[market.id] = snapshot.val()
            })

            const snapYesterday = await Promise.all(promisesYesterday)
            snapYesterday.forEach((snapshot, index) => {
                const market = markets[index]
                statsYesterday[market.id] = snapshot.val()
            })
        }

        this.setState({
            markets: markets,
            loaded: true,
            error: null,
            stats: stats,
            stats_yesterday: statsYesterday,
            keys: keys,
            titles: titles,
            shopMarket: shopMarket,
            paths: paths,
            shopCount: shopCount
        }, async () => {
            this.subscribe(paths, markets)
            await this.loadShopAndProductInfo()
        })
    }

    async loadShopAndProductInfo() {
        const shopCount: Dictionary<number> = {}
        const productCount: Dictionary<number> = {}

        if (this.props.shop) {
            shopCount[this.props.shop] = 1
        } else {
            const promises: Promise<DataSnapshot>[] = []

            const shopIndexPath = `v1/accounts/${this.props.role.account_id}/shop_index`
            const shopIndexSnap = await get(child(currentDatabaseRef(), shopIndexPath))
            if (shopIndexSnap && shopIndexSnap.val()) {
                const shopIndices = shopIndexSnap.val()
                for (const shopKey of Object.keys(shopIndices)) {
                    const shopIndex = shopIndices[shopKey]
                    if (shopIndex.deactivated) { continue }
                    const marketPromise = get(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/shops/${shopKey}/market`))
                    promises.push(marketPromise)
                }
            }
            const snaps = await Promise.all(promises)
            for (const snap of snaps) {
                if (snap && snap.val()) {
                    const market = snap.val()
                    shopCount[market] = (shopCount[market] || 0) + 1
                }
            }
        }

        if (!this.props.shop) {
            for (const market of this.state.markets) {
                const marketProductCount = await this.countProductsForMarket(market.id)
                productCount[market.id] = marketProductCount
            }
        }
        this.setState({ shopCount: shopCount, marketProductCount: productCount, loadedShopsAndProducts: true })
    }

    async countProductsForMarket(marketId: string): Promise<number> {
        const productsCollection = collection(firestore, `accounts/${this.props.role.account_id}/inventory/${marketId}.pos/product_search_index`)
        const snap = await getCountFromServer(productsCollection)
        return snap.data().count
    }

    subscribe(paths: string[], markets: Market[]) {
        paths.forEach((path, index) => {
            const market = markets[index]
            const statsRef = child(currentDatabaseRef(), path)
            onValue(statsRef, snapshot => {
                const stats = cloneDeep(this.state.stats)
                if (snapshot) {
                    stats[market.id] = snapshot.val()
                    this.setState({ stats: stats })
                }
            })
        })
    }

    unsubscribe() {
        this.state.paths.forEach(path => {
            const statsRef = child(currentDatabaseRef(), path)
            off(statsRef)
        })
    }

    salesHeader(key: string, yesterday: boolean = false): string {
        if (!yesterday) {
            if (this.props.shop || this.state.markets.length === 1) {
                return "Sales Today"
            }
            return "Sales Today for market: " + this.state.titles[key]    
        } else {
            if (this.props.shop || this.state.markets.length === 1) {
                return "Sales Yesterday"
            }
            return "Sales Yesterday for market: " + this.state.titles[key]    
        }
    }

    shopStatHeader(key: string): string {
        if (this.props.shop || this.state.markets.length === 1) {
            return "Shop Statistics"
        }
        return "Shop Statistics for market: " + this.state.titles[key]
    }

    shopStatCount(key: string): string {
        if (this.props.shop) {
            return ""
        }
        let shopCount: string = ""
        if (this.state.loadedShopsAndProducts) {
            shopCount = `${(this.state.shopCount[key] || 0)}`
        }
        return "Number of shops: " + shopCount
    }

    productStatCount(key: string): string {
        let productCount: string = ""
        if (this.state.loadedShopsAndProducts) {
            productCount = `${(this.state.marketProductCount[key] || 0)}`
        }
        return "Number of products: " + productCount
    }

    numerOfSales(key: string, yesterday: boolean = false): string | undefined {
        const stats = yesterday ? this.state.stats_yesterday[key] : this.state.stats[key]
        if (!stats) {
            return undefined
        }
        const all = stats.all
        if (!all) {
            return undefined
        }

        if (!all.count) {
            return undefined
        }

        return `Number of sales: ${all.count}`
    }

    numerOfItemsSold(key: string, yesterday: boolean = false): string | undefined {
        const stats = yesterday ? this.state.stats_yesterday[key] : this.state.stats[key]
        if (!stats) {
            return undefined
        }
        const all = stats.all
        if (!all) {
            return undefined
        }

        if (!all.item_count) {
            return undefined
        }

        return `Number of items sold: ${all.item_count}`
    }


    basketSize(key: string, yesterday: boolean = false): string | undefined {
        const stats = yesterday ? this.state.stats_yesterday[key] : this.state.stats[key]
        if (!stats) {
            return undefined
        }
        const all = stats.all
        if (!all) {
            return undefined
        }
        if (all.item_count !== undefined && all.count !== undefined && all.count > 0) {
            const options = { minimumIntegerDigits: 1, maximumFractionDigits: 2 }
            const size = all.item_count / all.count
            const sizeString = size.toLocaleString("da", options)
            return `Average basket size: ${sizeString}`
        }
        return undefined
    }

    currency(key: string): string {
        let marketId: string = key
        if (this.state.shopMarket) {
            marketId = this.state.shopMarket
        }
        const m = this.state.markets.find((market) => { return market.id === marketId })
        if (m) {
            return m.currency
        } else {
            return ""
        }
    }

    basketValue(key: string, yesterday: boolean = false): string | undefined {
        const stats = yesterday ? this.state.stats_yesterday[key] : this.state.stats[key]
        if (!stats) {
            return undefined
        }
        const sales = stats.all
        if (!sales) {
            return undefined
        }
        const currency = this.currency(key)
        if (sales.total !== undefined && sales.count !== undefined && sales.count > 0) {
            const size = sales.total / sales.count
            const options = { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
            const sizeString = size.toLocaleString("da", options)
            return `Average basket value: ${sizeString} ${currency}`
        }
        return undefined
    }

    turnover(key: string, yesterday: boolean = false): string | undefined {
        const stats = yesterday ? this.state.stats_yesterday[key] : this.state.stats[key]
        if (!stats) {
            return undefined
        }
        const all = stats.all
        if (!all) {
            return undefined
        }
        const currency = this.currency(key)
        if (all.total !== undefined) {
            const options = { minimumIntegerDigits: 1, minimumFractionDigits: 2, maximumFractionDigits: 2 }
            const sizeString = all.total.toLocaleString("da", options)
            return `Turnover: ${sizeString} ${currency}`
        }
        return undefined
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="overview">
                <div style={{ fontSize: "20px" }}>
                    <div> {this.state.keys.map(key => {
                        return (
                            <div key={key}>
                                <br /><h2>{this.shopStatHeader(key)}</h2>
                                <p>
                                    {this.shopStatCount(key)}
                                    {
                                        !this.state.loadedShopsAndProducts && !this.props.shop
                                            ?
                                            <span className="loader" />
                                            :
                                            null
                                    }
                                </p>
                                {
                                    !this.props.shop ?
                                    <div><p>
                                    {this.productStatCount(key)}
                                    {
                                        !this.state.loadedShopsAndProducts
                                            ?
                                            <span className="loader" />
                                            :
                                            null
                                    }
                                </p> <br /> </div>
                                : null
                                }

                                <h3>{this.salesHeader(key)}</h3>
                                {this.state.stats[key] ? (
                                    <div>
                                        <p>
                                            {this.numerOfSales(key)}
                                        </p>
                                        <p>
                                            {this.numerOfItemsSold(key)}
                                        </p>
                                        <p>
                                            {this.basketSize(key)}
                                        </p>
                                        <p>
                                            {this.basketValue(key)}
                                        </p>
                                        <p>
                                            {this.turnover(key)}
                                        </p>
                                    </div>
                                ) : <div>No sales for today</div>
                                }

                                <br /><h3>{this.salesHeader(key, true)}</h3>
                                {this.state.stats_yesterday[key] ? (
                                    <div>
                                        <p>
                                            {this.numerOfSales(key, true)}
                                        </p>
                                        <p>
                                            {this.numerOfItemsSold(key, true)}
                                        </p>
                                        <p>
                                            {this.basketSize(key, true)}
                                        </p>
                                        <p>
                                            {this.basketValue(key, true)}
                                        </p>
                                        <p>
                                            {this.turnover(key, true)}
                                        </p>
                                    </div>
                                ) : <div>No sales for yesterday</div>
                                }

                            </div>
                        )
                    })}
                        <br /><br /><br /><br />
                    </div>
                </div>
                {
                    this.state.error ? (
                        <Alert variant="danger">
                            {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState >
        )
    }
}

export {
    Overview, ShopOverview
}
