import * as React from "react"

import { Button, Card, FormControl } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import LoadingButton, { PageState } from "../../PageState"
import { RoleRouterProps, withRouter } from "../../../routes"
import { Form } from "react-bootstrap"
import { child, get, remove, set } from "firebase/database"

interface ContributionRatioConfigurationProps extends RoleRouterProps {
    mode: "account" | "shop"
}
interface ContributionRatioConfigurationShopState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    ratio: number
    selectedRatio: number
    override: boolean
}

class ContributionRatioConfigurationShop extends React.Component<ContributionRatioConfigurationProps, ContributionRatioConfigurationShopState> {
    constructor(props: ContributionRatioConfigurationProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            showSuccess: false,
            ratio: 0.1,
            override: false,
            selectedRatio: 0.1
        }
    }

    async componentDidMount() {
        const capabilitiesRef = this.capabilitiesRef()
        const supportedSnapshot = await get(capabilitiesRef)
        let ratio: number = 0.1
        if (this.props.mode === "shop") {
            const accountShop = await get(this.accountCapabilitiesRef())
            ratio = accountShop.val() ?? 0.1
        }
        this.setState({
            loaded: true,
            selectedRatio: (supportedSnapshot.val() ?? 0.1),
            override: this.props.mode === "account" ? true : supportedSnapshot.exists(),
            ratio: ratio
        })
    }

    handleSwitchChange = (value: boolean) => {
        this.setState({override: value, dirty: true})
    }
    
    overrideDefaultsLabel() {
        
        return `Override default value: (${(this.state.ratio ?? 0.1) * 100}%)`

    }
    
    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="contribution ratio">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"} >
                    <Card.Header>
                        {this.state.showSuccess ? "Contribution ratio published successsfully" : "Contribution ratio"}
                    </Card.Header>

                    <Card.Body>
                        {this.props.mode === "shop" &&
                            <>
                            
                                <Form.Switch
                                    id="custom-switch"
                                    label={this.overrideDefaultsLabel()}
                                    checked={this.state.override}
                                    onChange={(e) => this.handleSwitchChange(e.target.checked)}
                                 />
                                <br />
                                </>
                            }
                        { 
                        (this.state.override && <>
                            <p>The <i>contribution ratio</i> of the items in the basket can be calculated in case the <i>cost price</i> of all items in the basket is known. The formula for calculating the ratio is:</p>
                            <p>First find the <i>base price</i> of all items. The <i>base price</i> is the price of the item after all discounts and taxes have been subtracted.</p>
                            <p>Find the <i>margin</i> of all items. The <i>margin</i> is the <i>base price</i> minus the <i>cost price</i>. In other words, the <i>margin</i> represents the earnings (or losses) from selling an item.</p>
                            <p>Finally, the <i>contribution ratio</i> can be calculated as the <i>margin</i> divided by the <i>base price</i>. The <i>contribution ratio</i> of an entire basket is naturally the sum of margins for all line items divided by the sum of base prices for all line items.</p>
                            <br />
                            
                            <p>If you specify a <i>desired contribution ratio</i> - then the action button in the POS will indicate if this <i>contribution ratio</i> is met for the current basket. The color of the indicator line may be interpreted as follows:</p>
                            <ul>
                                <li><b>Green</b> The contribution ratio of the basket is greater than or equal to the desired ratio</li>
                                <li><b>Orange</b> The contribution ratio of the basket is less than the desired ratio, but the margin is still positive</li>
                                <li><b>Red</b> The margin is negative - in other words you are losing money for this sale. Consider lowering any manual discounts?</li>
                                <li><b>Gray</b> The margin cannot be calculated. One or more items in the basket do not have a specified <i>cost price</i></li>
                            </ul>
                            <br />
                            <h4>Please enter the desired contribution ratio in whole percentages.</h4>
                            <FormControl
                                type="number"
                                name="rate"
                                min={0}
                                // Floating point precision error sometimes occurs, 
                                // such as 7 being 7.000...01, so we round it to the nearest integer
                                value={this.props.mode === "account" ? Math.round(this.state.selectedRatio * 100) : undefined}
                                placeholder={`Enter rate ${this.props.mode == "shop" ? `(${this.state.ratio * 100})`: ``}`}
                                onChange={(e: any) => { this.handleChange(e.target.value) }}
                                autoComplete="off"
                            />  
                        </>)
                        }    
                          
                    </Card.Body>

                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }

    handleChange = (value: string) => {
        const numberValue = Number(value)
        this.setState({ selectedRatio: numberValue / 100, dirty: true })
    }

    accountCapabilitiesRef() {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/kpi_thresholds/contribution_ratio`)
    }

    capabilitiesRef() {
        const account = this.props.role.account_id
        switch (this.props.mode) {
            case "account": {
                return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/kpi_thresholds/contribution_ratio`)
            }
            case "shop": {
                const shop = this.props.router.params.shopKey
                return child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/kpi_thresholds/contribution_ratio`)
            }
        }
    }

    publish = async () => {

        this.setState({ publishing: true })
        console.log(this.state.ratio)
        if(this.state.override || this.state.ratio === this.state.selectedRatio) {
            await set(this.capabilitiesRef(), this.state.selectedRatio)
        } else {
            await remove(this.capabilitiesRef())
        }
        const account = this.props.role.account_id
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
        
}
export default withRouter(ContributionRatioConfigurationShop)
