import * as React from "react"
import { currentDatabaseRef } from "../../../config/constants"
import { Button, DropdownButton, FormControl, MenuItem, Modal } from "../../wrappers"
import ImageDropControl from "../.././ImageDropControl"
import { Cashier } from "../../../models/Cashier"
import { LanguageCode } from "../../../helpers/L10n"
import { CashierRole } from "../Configuration/CashierRoles"
import * as _ from "lodash"
import { child, set } from "firebase/database"

interface CashierDialogProps {
    shop: string
    account: string
    cashier: Cashier | null
    onDone(): void
    cashierRoles: CashierRole[]
}

interface CashierDialogState {
    cashier: Cashier
}

export default class CashierDialog extends React.Component<CashierDialogProps, CashierDialogState> {

    private cashierPath(cashier: Cashier) {
        return `v1/accounts/${this.props.account}/shops/${this.props.shop}/cashiers/${cashier.id}`
    }

    private get imageURLPath() {
        return `${this.props.account}/shops/${this.props.shop}/cashiers/`
    }

    constructor(props: CashierDialogProps) {
        super(props)

        this.state = {
            cashier: this.props.cashier || Cashier.blank
        }
    }

    isNew(): boolean {
        return _.isNil(this.props.cashier)
    }

    validateImage = (width: number, height: number) => {
        return width <= 512 && height <= 512
    }

    setImageURL = (url: string | null) => {
        const cashier = _.cloneDeep(this.state.cashier)
        cashier.imageURL = url

        this.setState({ cashier: cashier })
    }

    async publish() {
        const cashierRef = child(currentDatabaseRef(), this.cashierPath(this.state.cashier))
        
        const cashier = _.cloneDeep(this.state.cashier)
        const date = new Date().getTime()
        if (_.isNil(cashier.created)) {
            cashier.created = date
        }
        cashier.lastUpdated = date
        await set(cashierRef, cashier.toJSON())

        this.props.onDone()
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        const cashier = _.cloneDeep(this.state.cashier)
        cashier[name] = value

        this.setState({
            cashier: cashier
        })
    }

    handleChange = (roleId: string | null) => {
        const cashier = _.cloneDeep(this.state.cashier)
        cashier.cashierRoleId = roleId

        this.setState({
            cashier: cashier
        })
    }

    render() {
        const publishDisabled = !this.state.cashier.displayName

        return (
            <Modal show={true} key="gdflgn34">
                <Modal.Header>{this.isNew() ? "New cashier" : "Edit cashier"}</Modal.Header>
                <Modal.Body>
                    <strong>Full name</strong>
                    <FormControl
                        type="text"
                        name="fullName"
                        value={this.state.cashier.fullName || ""}
                        placeholder="Enter cashier full name"
                        onChange={this.handleInputChange}
                    />
                    <br />
                    <strong>Initials</strong>(*required)
                    <FormControl
                        type="text"
                        name="displayName"
                        value={this.state.cashier.displayName}
                        placeholder="Enter cashier initials"
                        onChange={this.handleInputChange}
                    />

                    {this.props.cashierRoles.length > 0 && (
                        <div>
                            <br />
                            <strong>Cashier role</strong>
                            <br />
                            <DropdownButton
                                title={this.props.cashierRoles.find(role => { return role.id === (this.state.cashier.cashierRoleId ?? "_") })?.name.localized(LanguageCode.en) ?? "Default"}
                                id={`dropdown-basic`}
                            >
                                {
                                    this.props.cashierRoles.map((role) => {
                                        return (
                                            <MenuItem
                                                key={role.id}
                                                onClick={() => { this.handleChange(role.id) }}
                                            >
                                                {role.name.localized(LanguageCode.en)}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </DropdownButton>
                        </div>
                    )}

                    <br />
                    <div style={{ height: "256px", width: "256px", border: "1px solid lightgray" }}>
                        <ImageDropControl
                            fileName={this.state.cashier.id}
                            filePath={this.imageURLPath}
                            imageURL={this.state.cashier.imageURL || undefined}
                            dropTitle="Cashier image (max size 512×512 2MB)"
                            validateSize={this.validateImage}
                            deleteAction={() => { this.setImageURL(null) }}
                            uploadAction={(url) => { this.setImageURL(url) }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="mx-4 px-4" onClick={() => { this.props.onDone() }}>Cancel</Button>
                    <Button className="px-4"
                        variant="success"
                        disabled={publishDisabled}
                        onClick={async () => { await this.publish() }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
