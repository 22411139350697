import React, { useState } from "react";
import * as _ from "lodash";
import { DividerMenuItem, FormControl, DropdownButton, FormGroup, HeaderMenuItem, MenuItem, ButtonToolbar, ButtonGroup } from "../../../../components/wrappers";
import { builtInFonts } from "./shared";
import { Dropdown, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

export interface FontButtonProps  {
    customFonts: string[]
    selectedFont: string | undefined
    updatedFont: (font: string | undefined) => void
    mode: "email" | "pdf"
}

export interface FontStyleProps  {
    selectedStyle: string | undefined
    updatedStyle: (style: string | undefined) => void
}

export interface FontWeightProps  {
    selectedWeight: string | undefined
    updatedWeight: (weight: string | undefined) => void
}

export function FontStyleButton(props: FontStyleProps) {
    
    return  <ToggleButtonGroup type="radio" name="style" value={props.selectedStyle ?? "normal"} onChange={ (value) => props.updatedStyle(value)}>                
                <ToggleButton id="italic" value={"Oblique"} style={{fontStyle: "Italic"}}> Italic </ToggleButton>
                <ToggleButton id="normal" value={"Normal"} style={{fontStyle: "Normal"}}> Normal </ToggleButton>
            </ToggleButtonGroup>
}

export function FontButton(props: FontButtonProps) {
    return <DropdownButton id="dropdown-basic-button"
            title={props.selectedFont ?? "No font selected"}
            key="attribute_type"
        >
            { props.mode === "email" ? getEmailItems(props) : getPDFItems(props)}
    </DropdownButton>
} 

export function FontWeightButton(props: FontWeightProps) {
    return <>
    
    <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
        <ButtonGroup className="me-2" aria-label="First group">
            <ToggleButtonGroup type="radio" name="weight" value={props.selectedWeight ?? "normal1"} onChange={(value) => props.updatedWeight(value)}>
                <ToggleButton id="extra thin" value="100" style={{fontWeight: "100"}}> Extra Thin </ToggleButton>
                <ToggleButton id="thin" value="200" style={{fontWeight:"200"}}> Thin </ToggleButton>
                <ToggleButton id="light" value="300" style={{fontWeight:"300"}}> Light </ToggleButton>
                <ToggleButton id="normal" value="400" style={{fontWeight: "400"}}> Normal </ToggleButton>
                <ToggleButton id="medium" value="500" style={{fontWeight: "500"}}> Medium </ToggleButton>
            </ToggleButtonGroup>
        </ButtonGroup>
    </ButtonToolbar>
    
            <ToggleButtonGroup type="radio" name="weight" value={props.selectedWeight ?? "normal1"} onChange={(value) => props.updatedWeight(value)}>
                <ToggleButton id="semi_bold" value="600" style={{fontWeight: "600"}}> Semi Bold </ToggleButton>
                <ToggleButton id="bold" value="700" style={{fontWeight: "700"}}> Bold </ToggleButton>
                <ToggleButton id="extra_bold" value="800" style={{fontWeight: "800"}}> Extra Bold </ToggleButton>
                <ToggleButton id="black" value="900" style={{fontWeight: "900"}}> Black </ToggleButton>
                <ToggleButton id="extra black" value="950" style={{fontWeight: "950"}}> Extra Black </ToggleButton>
            </ToggleButtonGroup>
    
    </>
} 

function getPDFItems(props: FontButtonProps): React.ReactNode[] {
    const menuItems: React.ReactNode[] = []
    menuItems.push(...getNoFontMenuItem(props))
    
    if (props.customFonts.length > 0) {
        menuItems.push(...getCustomPDFFonts(props))
    }

    menuItems.push(...getBuiltInPDFFonts(props))
    return menuItems
}


function getEmailItems(props: FontButtonProps): React.ReactNode[] {
    const menuItems: React.ReactNode[] = []
    menuItems.push(...getNoFontMenuItem(props))

    menuItems.push(...getEmailFonts(props))
    return menuItems
}

function getNoFontMenuItem(props:FontButtonProps): React.ReactNode[] {
    return [
        <MenuItem
            key={"no_font"}
            onClick={() => {
                props.updatedFont(undefined)
            } }>
            No font selected
        </MenuItem>,
        <DividerMenuItem key={"divider1"}></DividerMenuItem>
        ]
}

function getEmailFonts(props: FontButtonProps): React.ReactNode[] {
    const menuItems: React.ReactNode[] = []
    menuItems.push(menuHeader("Email fonts:"));
    menuItems.push(...getMenuItemsForFonts(props, emailFonts));
    return menuItems
}

function getCustomPDFFonts(props: FontButtonProps): React.ReactNode[] {
    const menuItems: React.ReactNode[] = []
    menuItems.push(menuHeader("Uploaded fonts:"));
    menuItems.push(...getMenuItemsForFonts(props, props.customFonts));
    return menuItems
}

function getBuiltInPDFFonts(props: FontButtonProps): React.ReactNode[] {
    const menuItems: React.ReactNode[] = []
    menuItems.push(menuHeader("Built-in fonts:"));
    menuItems.push(...getMenuItemsForFonts(props, builtInFonts));
    return menuItems
}

function getMenuItemsForFonts(props:FontButtonProps, fonts: string[]): React.ReactNode[] {
    return fonts.map((value: string) => {
        return (
            <MenuItem
                key={value}
                onClick={() => {
                    props.updatedFont(value);
                } }
            >
            {value}
            </MenuItem>
        );
    })
}

function menuHeader(title: string) {
    return <HeaderMenuItem key={title}> {title} </HeaderMenuItem>
}

export const emailFonts = [
    'Arial',
    'Courier New',
    'Georgia',
    'Helvetica',
    'Trebuchet',
    'Times New Roman',
    'Verdana',
];