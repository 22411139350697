//
//  BudgetEdit.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 08/11/2021.
//  Copyright © 2021 Ka-ching. All rights reserved.
//

import * as React from "react"
import { DropdownButton, MenuItem, FormGroup, Row, Col, Card } from "../wrappers"
import { currentDatabaseRef } from "../../config/constants"
import { PageState } from "../PageState"
//import { SingleDatePicker } from "react-dates"
import dayjs from "dayjs"
import { Cashier } from "../../models/Cashier"
import { CashierRole } from "./Configuration/CashierRoles"
import { L10nString } from "../../helpers/L10n"
import { ascendingByCashierFullName } from "./CashierList/helpers"
import * as _ from "lodash"
import Numeral from "numeral"
/* tslint:disable */
import "firebase/database"
import { MarketAmountFormControl } from "../MarketAmountFormControl"
import { Globals } from "../../helpers/globals"
import { Market } from "../../models/MarketModels"
import { MarketAmount } from "../../models/MarketAmount"
import { StripedTable } from "../StripedTable"
import { DeleteButton } from "../DeleteButton"
import { RoleShopProps, withShop } from "../../routes"
import { Calendar } from "react-date-range"
import { Dropdown, Form } from "react-bootstrap"
import { CallableDropzoneComponent, FunctionName } from "../CallableDropzoneComponent"
import { child, DatabaseReference, endAt, get, off, onValue, orderByKey, query, remove, set, startAt } from "firebase/database"
/* tslint:enable */

require("numeral/locales/da-dk") // register da-dk locale settings

function CenteredBlock(props: any) {
    return (
        <Row>
            <Col sm={0} md={1} />
            <Col sm={12} md={10}>{props.children}</Col>
            <Col sm={0} md={1} />
        </Row>
    )
}

type BudgetType = "shop" | "cashier" | "cashier_role"

interface BudgetEditState {
    loaded: boolean
    dirty: boolean
    publishing: boolean
    date: dayjs.Dayjs | null
    focus: boolean | null
    cashiers: Cashier[]
    cashierRoles: CashierRole[]
    selectedName?: string
    selectedCashierId?: string
    selectedRoleId?: string
    budgetType: BudgetType
    currentMonth: string
    currentBudget: _.Dictionary<any>
    newBudget: _.Dictionary<any>
    currentMarket: Market | null
}

class BudgetEdit extends React.Component<RoleShopProps, BudgetEditState> {

    // Component

    constructor(props: RoleShopProps) {
        super(props)

        const now = dayjs()

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            focus: null,
            date: null,
            cashiers: [],
            cashierRoles: [],
            currentMonth: now.format("YYYY-MM"),
            budgetType: "shop",
            currentBudget: {},
            newBudget: {},
            currentMarket: null
        }
    }

    async componentDidMount() {
        await this.loadState()
        this.setupObservers()
    }

    componentWillUnmount() {
        this.teardownObservers()
    }

    setupObservers() {

        onValue(child(currentDatabaseRef(), this.cashierRolesPath), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ loaded: true })
                return
            }

            const roles: CashierRole[] = []
            if (snapshot && snapshot.exists()) {
                const rolesDict = snapshot.val()
                for (const key in rolesDict) {
                    const roleJson = rolesDict[key]
                    if (roleJson.id && roleJson.name) {
                        roles.push({
                            id: roleJson.id,
                            name: new L10nString(roleJson.name),
                            functionalityBlocklist: roleJson.functionality_blocklist ?? []
                        })
                    }
                }
            }
            this.setState({ cashierRoles: roles, loaded: true })
        })

        onValue(child(currentDatabaseRef(), this.cashiersPath), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const cashiersMap = snapshot.val()

            let cashiers: Cashier[] = []
            for (const key in cashiersMap) {
                const cashier = Cashier.fromJSON(cashiersMap[key])
                if (!_.isNull(cashier)) {
                    cashiers.push(cashier)
                }
            }

            cashiers = cashiers.sort(ascendingByCashierFullName)

            this.setState({ cashiers: cashiers })
        })
    }

    private get cashierRolesPath(): string {
        return `v1/accounts/${this.accountKey}/inventory/cashier_roles`
    }
    teardownObservers() {
        off(child(currentDatabaseRef(), this.cashiersPath))
        off(child(currentDatabaseRef(), this.cashierRolesPath))
    }
    private get shopKey(): string {
        return this.props.shop
    }

    private get accountKey(): string {
        return this.props.role.account_id
    }

    private get cashiersPath(): string {
        return `v1/accounts/${this.accountKey}/shops/${this.shopKey}/cashiers`
    }

    async loadState() {
        this.setState({ loaded: false })
        const markets = await Globals.shared.getMarkets()
        const shopMarketRef = child(currentDatabaseRef(), `v1/accounts/${this.accountKey}/shops/${this.shopKey}/market`)
        const shopMarket = await get(shopMarketRef)
        if (shopMarket.exists()) {
            const market = markets.find(m => { return m.id === shopMarket.val() })
            if (market) {
                this.setState({ currentMarket: market })
            }
        }

        // Set shop as initially selected subject
        await this.loadBudget(this.state.currentMonth, undefined, undefined)
        this.setState({
            selectedName: "Shop",
            selectedRoleId: undefined,
            selectedCashierId: undefined,
            budgetType: "shop"
        })
    }

    async selectSubject(subject: string) {
        const comps = subject.split(".")
        const type = comps[0]
        const id = comps[1]
        if (type === "cashier") {
            const selected = this.state.cashiers.find(c => { return c.id === id })
            if (selected !== undefined) {
                await this.loadBudget(this.state.currentMonth, id, undefined)
                this.setState({
                    selectedName: "Cashier: " + (selected.displayName ?? selected.fullName ?? selected.id),
                    selectedCashierId: id,
                    selectedRoleId: undefined,
                    budgetType: "cashier"
                })
            }
        } else if (type === "role") {
            const cashierRoles = this.getCashierRoles()
            const selected = cashierRoles.find(c => { return c.id === id })
            if (selected !== undefined) {
                await this.loadBudget(this.state.currentMonth, undefined, id)
                this.setState({
                    selectedName: "Cashier role: " + selected.name.localized(null),
                    selectedRoleId: id,
                    selectedCashierId: undefined,
                    budgetType: "cashier_role"
                })
            }
        } else if (type === "shop") {
            await this.loadBudget(this.state.currentMonth, undefined, undefined)
            this.setState({
                selectedName: "Shop",
                selectedRoleId: undefined,
                selectedCashierId: undefined,
                budgetType: "shop"
            })
        }
    }

    shopBudgetRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountKey}/budgets/summary/shop/${this.shopKey}/all/day`)
    }

    cashierBudgetRef(cashierId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountKey}/budgets/summary/shop/${this.shopKey}/cashier/${cashierId}/day`)
    }
    cashierRoleBudgetRef(cashierRoleId: string) {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountKey}/budgets/summary/shop/${this.shopKey}/cashier_role/${cashierRoleId}/day`)
    }

    async updateCurrentMonth(newMonth: dayjs.Dayjs) {
        const currentMonth = newMonth.format("YYYY-MM")
        this.setState({ currentMonth: currentMonth })
        await this.loadBudget(currentMonth, this.state.selectedCashierId, this.state.selectedRoleId)
    }

    async loadBudget(currentMonth: string, cashierId?: string, roleId?: string) {
        // Get dates of visible months

        const current = dayjs(currentMonth, "YYYY-MM")
        const startOfCurrentMonth = current.startOf("month")
        const endOfNextMonth = startOfCurrentMonth.clone().add(1, "months").endOf("month")
        const firstDay = startOfCurrentMonth.format("YYYY-MM-DD")
        const lastDay = endOfNextMonth.format("YYYY-MM-DD")

        let budgetRef: DatabaseReference | undefined = undefined
        if (cashierId !== undefined) {
            budgetRef = this.cashierBudgetRef(cashierId)
        } else if (roleId) {
            budgetRef = this.cashierRoleBudgetRef(roleId)
        } else {
            budgetRef = this.shopBudgetRef()
        }

        let budget: _.Dictionary<any> = {}
        if (budgetRef !== undefined) {
            const q = query(budgetRef, orderByKey(), startAt(firstDay), endAt(lastDay))
            const snapshot = await get(q)
            if (snapshot.exists()) {
                budget = snapshot.val()
            }
        }
        this.setState({ currentBudget: budget })
    }

    async submit() {
        this.setState({ publishing: true })
        let budgetRef: DatabaseReference | undefined = undefined
        if (this.state.selectedCashierId !== undefined) {
            budgetRef = this.cashierBudgetRef(this.state.selectedCashierId)
        } else if (this.state.selectedRoleId) {
            budgetRef = this.cashierRoleBudgetRef(this.state.selectedRoleId)
        } else {
            budgetRef = this.shopBudgetRef()
        }

        for (const key in this.state.newBudget) {
            const budgetDayRef = child(budgetRef, key)
            if (this.state.newBudget[key] === "KX_DELETE") {
                await remove(budgetDayRef)
            } else {
                await set(budgetDayRef, this.state.newBudget[key])
            }
        }
        this.setState({ dirty: false, publishing: false, newBudget: {} })
        await this.loadBudget(this.state.currentMonth, this.state.selectedCashierId, this.state.selectedRoleId)
    }

    renderUnsaved() {
        if (Object.keys(this.state.newBudget).length === 0) { return }
        return (
            <div>
                <br /><br />
                Unsaved values
                <StripedTable>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th style={{ textAlign: "right" }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(this.state.newBudget).sort().map(key => {
                            return (
                                <tr key={key}>
                                    <td>
                                        {key}
                                    </td>
                                    <td style={{ textAlign: "right" }}>{this.state.newBudget[key] === "KX_DELETE" ? "Deleted" : this.getUnsavedTotal(this.state.newBudget[key])}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </StripedTable>
            </div>
        )
    }

    getUnsavedTotal(budget: _.Dictionary<any>) {
        let total: any
        if (this.state.budgetType == "cashier" || this.state.budgetType == "cashier_role") {
            total = budget?.sales?.total
        } else {
            total = budget?.all?.total
        }
        return Numeral(total ?? 0).format("0,0")
    }

    renderAmountInput(date: dayjs.Dayjs, type: BudgetType) {
        const key = date.format("YYYY-MM-DD")
        let entry = this.state.newBudget[key] ?? this.state.currentBudget[key]
        if (entry === "KX_DELETE") { entry = undefined }
        let total: MarketAmount | null
        if (type == "cashier" || type == "cashier_role") {
            total = _.isNil(entry?.sales?.total) ? null : new MarketAmount(entry?.sales?.total)
        } else {
            total = _.isNil(entry?.all?.total) ? null : new MarketAmount(entry?.all?.total)
        }
        return (
            <Form.Group as={Row}>
                <Form.Label column sm={2}>Budget value</Form.Label>
                <Col sm={10}>
                    <MarketAmountFormControl
                        amount={total}
                        market={this.state.currentMarket}
                        allMarkets={this.state.currentMarket ? [this.state.currentMarket.id] : []}
                        onAmountChanged={amount => {
                            const clone = _.cloneDeep(this.state.newBudget)
                            if (type == "cashier" || type == "cashier_role") {
                                clone[key] = { sales: { total: amount?.amount(this.state.currentMarket?.id ?? null) ?? 0 } }
                            } else {
                                clone[key] = { all: { total: amount?.amount(this.state.currentMarket?.id ?? null) ?? 0 } }
                            }
                            this.setState({ newBudget: clone, dirty: true })
                        }}
                        placeholder="Enter budget value"
                        currency={this.state.currentMarket?.currency ?? ""}
                    >
                        <DeleteButton
                            onDelete={() => {
                                const clone = _.cloneDeep(this.state.newBudget)
                                clone[key] = "KX_DELETE"
                                this.setState({ newBudget: clone, dirty: true })
                            }}
                            disabled={entry === undefined}
                        />
                    </MarketAmountFormControl>
                </Col>
            </Form.Group>
        )
    }

    render() {
        return (
            <CenteredBlock>
                <PageState
                    loading={!this.state.loaded}
                    dirty={this.state.dirty}
                    typeName="budgets"
                    publishing={this.state.publishing}
                    title="Unsaved changes"
                    submit_title="Save"
                    submit_action={async () => { await this.submit() }}
                >
                    <div>
                        <p>
                            Create a budget by selecting a cashier, cashier role or shop below, and start editing the relevant dates.
                        </p><br /><br />
                        <Form onSubmit={(event) => {
                            event.preventDefault()
                        }}>
                            <Form.Group as={Row}>
                                <Form.Label size="sm" column sm="2">Select subject</Form.Label>
                                <Col sm={10}>
                                    <DropdownButton
                                        variant="primary"
                                        title={this.state.selectedName ?? "Subject"}
                                        id="dropdown-attribute-value"
                                        onSelect={async (selectedValue: any) => {
                                            await this.selectSubject(selectedValue)
                                        }}
                                    >
                                        <Dropdown.Item key={"shop"} eventKey={"shop." + this.shopKey}> Shop </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Header key=".cashiersHeader">Cashiers</Dropdown.Header>

                                        {Object.keys(this.state.cashiers).map((optionKey) => {
                                            const option: Cashier = this.state.cashiers[optionKey]
                                            return <Dropdown.Item key={".cashier_" + optionKey} eventKey={"cashier." + option.id}>{option.displayName}</Dropdown.Item>
                                        })}
                                        {this.renderCashierRoles()}
                                    </DropdownButton>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Select date</Form.Label>
                                <Col sm={10}>
                                    <Calendar
                                        onShownDateChange={async date => {
                                            const dayjsDate = dayjs(date)
                                            await this.updateCurrentMonth(dayjsDate)
                                        }}
                                        date={this.state.date ? this.state.date.toDate() : undefined} onChange={date => {
                                            this.setState({ date: dayjs(date) })
                                        }}
                                        dayContentRenderer={day => {
                                            const date = dayjs(day)
                                            const key = date.format("YYYY-MM-DD")
                                            const budgetEntry = this.state.newBudget[key] ?? this.state.currentBudget[key]
                                            if (budgetEntry !== undefined) {
                                                let total: Numeral.Numeral | undefined = undefined
                                                if (budgetEntry.sales?.total !== undefined) {
                                                    total = Numeral(budgetEntry.sales?.total)
                                                } else if (budgetEntry.all?.total !== undefined) {
                                                    total = Numeral(budgetEntry.all?.total)
                                                }
                                                return <span style={{ fontSize: "10px", lineHeight: "normal" }}><b>{date.format("D")}</b><br /> {total?.format("0,0")}</span>
                                            } else {
                                                return <span>{date.format("D")}</span>
                                            }

                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <br></br>
                            {this.state.date !== null && this.renderAmountInput(this.state.date, this.state.budgetType)}
                        </Form>
                        {this.renderUnsaved()}
                        <Card className="my-4">
                            <Card.Header>
                                Budget import
                            </Card.Header>
                            <Card.Body>
                                <strong>Upload budget through CSV</strong>
                                <CallableDropzoneComponent
                                    key="stock-csv-drop-zone"
                                    config={this.componentConfig()}
                                    eventHandlers={this.eventHandlers()}
                                    djsConfig={this.djsConfig()}
                                    account={this.props.role.account_id}
                                    shop={this.props.shop}
                                    functionName={FunctionName.BudgetCSVImporter}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </PageState>
            </CenteredBlock>
        )
    }

    djsConfig = () => {
        return {
            autoProcessQueue: true,
            dictDefaultMessage: "Add a CSV file containing budgets here.",
            url: "https://fakeurl.com" // not used, but DropzoneComponent complains if it's not set
        }
    }

    componentConfig = () => {
        return {
            iconFiletypes: [".csv"],
            showFiletypeIcon: true
        }
    }

    eventHandlers = () => {
        return {
            error: (error: any) => {
                console.error(`Error: ${JSON.stringify(error)}`)
            }
        }
    }
    private getCashierRoles(): CashierRole[] {
        let roles: CashierRole[] = []
        const defaultCashier = this.state.cashierRoles.find(role => role.id === "_")
        if (defaultCashier === undefined) {
            const newRoles: CashierRole = {
                id: "_",
                name: new L10nString("Default"),
                functionalityBlocklist: []
            }
            roles.push(newRoles)
            this.state.cashierRoles.forEach(role => {
                roles.push(role)
            })
        } else {
            roles = this.state.cashierRoles

        }
        return roles
    }
    private renderCashierRoles(): React.ReactNode {
        let roles = this.getCashierRoles()
        const cashierRoles = Object.keys(roles).map((optionKey) => {
            const option: CashierRole = roles[optionKey]
            return <Dropdown.Item key={".role_" + optionKey} eventKey={"role." + option.id}>{option.name.localized(null)}</Dropdown.Item>
        })

        return [
            <Dropdown.Divider key=".divider" />,
            <Dropdown.Header key=".cashierRolesHeader">Cashier roles</Dropdown.Header>,

            cashierRoles
        ]
    }
}

export default withShop(BudgetEdit)
