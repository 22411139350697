import React from "react";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { SpacingValueEdit } from "./ValueEditComponents/SpacingValueEdit";
import { ValueEditProps } from "../ComponentEdit";

export function SpacingEdit(props: ValueEditProps) {
    return (
        <Form>
            <SpacingValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></SpacingValueEdit>
        </Form>
    );
}