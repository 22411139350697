import * as React from "react"
import {
    Button,
    Col,
    
    DescriptionCol,
    
    Form,
    FormControl,
    FormGroup,
    Modal,
} from "../../wrappers"
import { ToggleButton } from "../../ToggleButton"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"
import { StripedTable } from "../../../components/StripedTable"
import { ConfirmDeleteButton } from "../../../components/ConfirmDeleteButton"
import { ValidatingIdEntryControl } from "../../../components/ValidatingIdEntryControl"
import * as _ from "lodash"

type TemplateAdditionState = "done" | "add_template"

interface LoyaltyClubModuleState {
    templateName: string
    templateId: string
    duplicateIdAlert: boolean
    availableTypes: any[]
    currentEditingTemplateId: string | undefined
    templateAdditionState: TemplateAdditionState
}

export class LoyaltyClubModule extends React.Component<ModuleProps, LoyaltyClubModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            duplicateIdAlert: false,
            availableTypes: [],
            currentEditingTemplateId: undefined,
            templateName: "",
            templateId: "",
            templateAdditionState: "add_template"
        }
    }

    // Component

    componentDidMount(): void {
        const config = this.props.moduleData
        this.setState ({
            availableTypes: this.getTemplateFromConfig(config)
        })
    }

    private getTemplateFromConfig(config: any) {
        const coupons = config["coupons"] || undefined
        let templates: any[] = []
        if (!_.isUndefined(coupons)) {
            templates = coupons["available_types"] || []
        }
        return templates
    }

    render() {
        const config = this.props.moduleData
        const couponsEnabled: boolean = config["enable_coupons"] || false
        const enableLoyaltyCards: boolean = config["enable_loyalty_cards"] || false
        const enableOffers: boolean = config["enable_offers"] || false

        return (
            <Form>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Use loyalty cards</DescriptionCol>
                    <Col sm={10}>
                        {"If enabled, it will be possible for customers to add loyalty cards to their Google and Apple wallets."}
                        <br />
                        <ToggleButton
                            active={enableLoyaltyCards}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["enable_loyalty_cards"] = !enableLoyaltyCards
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Use coupons</DescriptionCol>
                    <Col sm={10}>
                        {"If enabled, you will be able to issue coupons to customers."}
                        <br />
                        <ToggleButton
                            active={couponsEnabled}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["enable_coupons"] = !couponsEnabled
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Use offers</DescriptionCol>
                    <Col sm={10}>
                        {"If enabled, you will be able add offers to the basket."}
                        <br />
                        <ToggleButton
                            active={enableOffers}
                            performToggle={async () => {
                                this.props.updateConfiguration(data => {
                                    data["enable_offers"] = !enableOffers
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
                
                {this.renderTemplates(this.props)}
            </Form>
        )
    }

    renderTemplates(props: ModuleProps): JSX.Element {
        return (
            <div>
                {!_.isEmpty(this.state.availableTypes) ? this.availableCouponTemplatesTable(this.state.availableTypes, props) : null}
                { this.state.templateAdditionState === "done" ? this.renderTemplateAddition() : this.renderAddTemplateButton() }               
                {this.showDuplicateIdAlert()}
            </div>
        )
    }

    private availableCouponTemplatesTable(templates: any[], props: ModuleProps) {
        let templatesWithFilter = templates
        if (!_.isUndefined(this.state.currentEditingTemplateId)) {
            templatesWithFilter = templates.filter((value) => value.identifier !== this.state.currentEditingTemplateId)
        }
        return <FormGroup className="mb-3" as={Row}>
            <DescriptionCol sm={2} style={{fontWeight: "bold", fontSize:"small", paddingTop:"20px", textAlign:"right"}}> Coupon templates</DescriptionCol>
            <Col sm={10}>
                <StripedTable>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(templatesWithFilter).map(optionKey => {
                            const template = templatesWithFilter[optionKey]
                            return (
                                <tr key={optionKey} onClick={() => {
                                    this.setState({
                                        currentEditingTemplateId: template.identifier,
                                        templateId: template.identifier,
                                        templateName: template.name,
                                        templateAdditionState: "done"
                                    })
                                } }>
                                    <td>{template.name}</td>
                                    <td>{template.identifier}</td>
                                    <td className="narrow">
                                        <ConfirmDeleteButton
                                            message={`Really delete ${template.name} option?`}
                                            onDelete={() => {
                                                props.updateConfiguration(data => {
                                                    const availableTypes = this.state.availableTypes
                                                    const index = availableTypes.findIndex((value) => {
                                                        return value.identifier == template.identifier
                                                    })
                                                    availableTypes.splice(index, 1)
                                                    this.setState({availableTypes: availableTypes, currentEditingTemplateId: undefined})
                                                    this.updateCouponTypes(data, availableTypes)
                                                })
                                            } } />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </StripedTable>
            </Col>
        </FormGroup>
    }

    private renderTemplateAddition() {
        return <div>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={2}>Option name</DescriptionCol>
                <Col sm={10}>

                    <FormControl
                        type="text"
                        name="name"
                        value={this.state.templateName}
                        placeholder="Enter template name"
                        onChange={(event: any) => {
                            const value = event.target.value
                            this.setState({ templateName: value })
                        } } />
                </Col>
            </FormGroup>
            <ValidatingIdEntryControl
                isNew={(_.isUndefined(this.state.currentEditingTemplateId) ? true : false)}
                typeName="template"
                identifierSource={this.state.templateName ?? ""}
                existingIdentifier={this.state.templateId ?? ""}
                handleIdChange={(id, valid) => {
                    if (!valid) { return} 
                    this.setState({ templateId: id })
                } } />
            <FormGroup className="mb-3" as={Row}>
                <Col sm={2}>&nbsp;</Col>
                <Col sm={10}>
                    <Button
                        onClick={() => {
                            this.props.updateConfiguration(data => {
                                if (!_.isEmpty(this.state.templateId) && !_.isEmpty(this.state.templateName)) {
                                    const couponTemplate = {
                                        identifier: this.state.templateId,
                                        name: this.state.templateName
                                    }
                                    const availableTypes = this.state.availableTypes
                                    const notDuplicate = this.isNotDuplicateId(this.state.availableTypes, couponTemplate.identifier)
                                    if (notDuplicate) {
                                        const updatedTypes = this.updateAvailableTypes(availableTypes, couponTemplate)
                                        this.updateCouponTypes(data, updatedTypes)
                                        this.setState({
                                            availableTypes: updatedTypes,
                                            templateId: "",
                                            templateName: "",
                                            currentEditingTemplateId: undefined,
                                            templateAdditionState: "add_template"
                                        })
                                    } else {
                                        this.setState({ duplicateIdAlert: true })
                                    }
                                }
                            })
                        } }
                    >
                        Done
                    </Button>
                </Col>
            </FormGroup>
        </div>
    }

    showDuplicateIdAlert()  {
        return (
        <Modal show={this.state.duplicateIdAlert} key="b">
            <Modal.Header>
                <Modal.Title>Duplicate template id</Modal.Title>
            </Modal.Header>

            <Modal.Body>You cannot add multiple templates with the same id.</Modal.Body>

            <Modal.Footer>
                <Button onClick={() => { this.setState({ duplicateIdAlert: false }) }}>Ok</Button>
            </Modal.Footer>
        </Modal>
        )
    }

    private renderAddTemplateButton() {
        return (
        <FormGroup className="mb-3" as={Row}>
                <Col sm={2}>&nbsp;</Col>
                <Col sm={10}>
                    <Button
                        onClick={() => {
                            this.setState({templateAdditionState: "done"})
                        } }
                    >
                        Add template
                    </Button>
                </Col>
            </FormGroup>
            )
    }

    private isNotDuplicateId(availableTypes: any[], templateId: string): boolean {
        let types = availableTypes
        if (this.state.currentEditingTemplateId) {
            types = types.filter((value) => value.identifier != this.state.currentEditingTemplateId)
        }
        const index = types.findIndex((value) => {
            return value.identifier == templateId
        })
        return index < 0
    }

    private updateAvailableTypes(availableTypes: any[], couponTemplate: { identifier: string; name: string }) {
        if (this.state.currentEditingTemplateId) {
            const index = availableTypes.findIndex((value) => value.identifier == this.state.currentEditingTemplateId)
            availableTypes[index] = couponTemplate
        } else {
            availableTypes.push(couponTemplate)
        }
        return availableTypes
    }

    private updateCouponTypes(data: any, availableTypes: any[]) {
        if (_.isUndefined(data["coupons"])) {
            data["coupons"] = {
                available_types: availableTypes
            }
        } else {
            data["coupons"]["available_types"] = availableTypes
        }
    }
}
