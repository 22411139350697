import * as React from "react"
import {
    Nav as XNav,
    NavItem as XNavItem,
    NavDropdown as XNavDropdown,
    Dropdown as XDropdown,
    Col as XCol,
    Container as XGrid,
    Row as XRow,
    Button as XButton,
    FormGroup as XFormGroup,
    ToggleButton as XToggleButton,
    ButtonGroup as XButtonGroup,
    DropdownButton as XDropdownButton,
    FormControl as XFormControl,
    InputGroup as XInputGroup,
    ToggleButtonGroup as XToggleButtonGroup,
    Image as XImage,
    Form as XForm,
    Badge as XLabel,
    FormCheck as XCheckbox,
    // HelpBlock as XHelpBlock,
    Table as XTable,
    Modal as XModal,
    Navbar as XNavbar,
    Card as XCard,
    // Pager as XPager,
    Alert as XAlert,
    // PageHeader as XPageHeader,
    // FormCheck as XRadio,
    ButtonToolbar as XButtonToolbar,
    ProgressBar as XProgressBar,
} from "react-bootstrap"

// import { Sizes, SelectCallback } from 'react-bootstrap';
// import { Omit } from 'react-bootstrap';
import { DropdownBaseProps } from 'react-bootstrap/lib/Dropdown';
// import { TransitionCallbacks } from 'react-bootstrap'

declare namespace ProgressBarX {
    export interface ProgressBarProps extends Omit<React.HTMLProps<ProgressBarProps>, "label"> {
        // Optional
        active?: boolean;
        bsSize?: string;
        variant?: string;
        interpolatedClass?: any; // TODO: Add more specific type
        max?: number;
        min?: number;
        now?: number;
        srOnly?: boolean;
        striped?: boolean;
        label?: React.ReactNode;
    }
}
export function ProgressBar(props: ProgressBarX.ProgressBarProps) {
    return <XProgressBar {...props as any}>
        {(props as any).children}
    </XProgressBar>
}
declare namespace ButtonToolbarX {
    export interface ButtonToolbarProps extends React.HTMLProps<ButtonToolbarProps> {
        block?: boolean;
        bsSize?: string;
        variant?: string;
        justified?: boolean;
        vertical?: boolean;
    }
}

export function ButtonToolbar(props: ButtonToolbarX.ButtonToolbarProps) {
    return <XButtonToolbar {...props as any}>
        {props.children}
    </XButtonToolbar>
}
declare namespace CheckboxX {
    export interface CheckboxProps extends React.HTMLProps<CheckboxProps> {
        bsClass?: string;
        disabled?: boolean;
        inline?: boolean;
        inputRef?: (instance: HTMLInputElement) => void;
        validationState?: "success" | "warning" | "error";
    }
}

export const Alert = XAlert
export const Modal = XModal
export const Navbar = XNavbar;
export const Card = XCard;
export const FormGroup = XFormGroup;
// export const Pager = XPager;
// export const PageHeader = XPageHeader;

export function PageHeader(props: CheckboxX.CheckboxProps) {
    return <div {...props as any}>
        {props.children}
    </div>
}

export class Pager extends React.Component<any, any> {
    render() {
        return <div {...this.props as any}>
            {this.props.children}
        </div>
    }
}


// export function Pager(props: CheckboxX.CheckboxProps) {
//     return <div {...props as any}>
//         {props.children}
//     </div>
// }


export function Checkbox(props: CheckboxX.CheckboxProps) {
    return <XCheckbox {...props as any}>
        {props.children}
    </XCheckbox>
}

declare namespace TableX {
    export interface TableProps extends React.HTMLProps<TableProps> {
        bordered?: boolean;
        condensed?: boolean;
        hover?: boolean;
        responsive?: boolean;
        striped?: boolean;
        fill?: boolean;
        bsClass?: string;
    }
}

export function Table(props: TableX.TableProps) {
    return <XTable {...props as any}>
        {props.children}
    </XTable>
}

declare namespace HelpBlockX {
    export interface HelpBlockProps extends React.HTMLProps<HelpBlockProps> {
        bsClass?: string;
    }
}

export function HelpBlock(props: HelpBlockX.HelpBlockProps) {
    return <div {...props as any}>
        {props.children}
    </div>
}

declare namespace LabelX {
    export interface LabelProps extends React.HTMLProps<LabelProps> {
        bsSize?: string;
        variant?: string;
    }
}

export function Label(props: LabelX.LabelProps) {
    return <XLabel {...props as any}>
        {props.children}
    </XLabel>
}

declare namespace FormX {
    export interface FormProps extends React.HTMLProps<FormProps> {
        bsClass?: string;
        componentClass?: React.ComponentClass;
        horizontal?: boolean;
        inline?: boolean;
    }
}

export function Form(props: FormX.FormProps) {
    return <XForm {...props as any}>
        {props.children}
    </XForm>
}

declare namespace ImageX {
    export interface ImageProps extends React.HTMLProps<ImageProps> {
        circle?: boolean;
        responsive?: boolean;
        rounded?: boolean;
        thumbnail?: boolean;
    }
}
export function Image(props: ImageX.ImageProps) {
    return <XImage {...props as any}>
        {props.children}
    </XImage>
}

declare namespace ToggleButtonGroupX {
    interface BaseProps {
        /**
         * You'll usually want to use string|number|string[]|number[] here,
         * but you can technically use any|any[].
         */
        defaultValue?: any;
        /**
         * You'll usually want to use string|number|string[]|number[] here,
         * but you can technically use any|any[].
         */
        value?: any;
    }

    interface RadioProps {
        /** Required if `type` is set to "radio" */
        name: string;
        type: "radio";
        onChange?(value: any): void;
    }

    interface CheckboxProps {
        name?: string;
        type: "checkbox";
        onChange?(values: any[]): void;
    }

    export type ToggleButtonGroupProps = BaseProps
        & (RadioProps | CheckboxProps)
        & Omit<ButtonGroupX.ButtonGroupProps, "onChange">
        & Omit<React.HTMLProps<BaseProps>, "defaultValue" | "type" | "value" | "onChange">;
}

export function ToggleButtonGroup(props: ToggleButtonGroupX.ToggleButtonGroupProps) {
    return <XToggleButtonGroup size="sm" {...props as any}>
        {props.children}
    </XToggleButtonGroup>
}

declare namespace InputGroupX {
    export interface InputGroupProps extends React.HTMLProps<InputGroupProps> {
        bsClass?: string;
        bsSize?: string;
    }
}

export function InputGroup(props: InputGroupX.InputGroupProps) {
    return <XInputGroup {...props as any}>
        {props.children}
    </XInputGroup>
}

declare namespace InputGroupAddonX {
    type InputGroupAddonProps = React.HTMLProps<InputGroupAddonProps>;
}

export function InputGroupAddon(props: InputGroupAddonX.InputGroupAddonProps) {
    return <div {...props as any}>
        {props.children}
    </div>
}

declare namespace FormControlX {
    export interface FormControlProps extends React.HTMLProps<FormControlProps> {
        bsClass?: string;
        bsSize?: string;
        componentClass?: React.ComponentClass;
        id?: string;
        inputRef?: (instance: HTMLInputElement) => void;
        type?: string;
    }
}

export function FormControl(props: FormControlX.FormControlProps) {
    return <XFormControl size="sm" {...props as any}>
        {props.children}
    </XFormControl>
}

declare namespace FormControlStaticX {
    export interface FormControlStaticProps extends React.HTMLProps<FormControlStaticProps> {
        bsClass?: string;
        componentClass?: React.ComponentClass;
    }
}

export function FormControlStatic(props: FormControlStaticX.FormControlStaticProps) {
    return <div {...props as any}>
        {props.children}
    </div>
}

declare namespace DropdownButtonX {
    export interface DropdownButtonBaseProps extends DropdownBaseProps {
        block?: boolean;
        bsSize?: string;
        variant?: string;
        navItem?: boolean;
        noCaret?: boolean;
        pullRight?: boolean;
        title: React.ReactNode;
    }

    export type DropdownButtonProps = DropdownButtonBaseProps & Omit<React.HTMLProps<DropdownButtonBaseProps>, 'title'>;
}

export function DropdownButton(props: DropdownButtonX.DropdownButtonProps) {
    // TODO: Dicuss general sizing of buttons
    return <XDropdownButton size="sm" {...props as any}>
        {props.children}
    </XDropdownButton>
}

declare namespace ButtonGroupX {
    export interface ButtonGroupProps extends React.HTMLProps<ButtonGroupProps> {
        block?: boolean;
        bsSize?: string;
        variant?: string;
        justified?: boolean;
        vertical?: boolean;
    }
}

export function ButtonGroup(props: ButtonGroupX.ButtonGroupProps) {
    return <XButtonGroup {...props as any}>
        {props.children}
    </XButtonGroup>
}

declare namespace ToggleButtonX {
    export interface ToggleButtonProps extends React.HTMLProps<ToggleButtonProps> {
        checked?: boolean;
        name?: string;
        value: number | string;
    }
}

export function ToggleButton(props: ToggleButtonX.ToggleButtonProps) {
    return <XToggleButton {...props as any}>
        {props.children}
    </XToggleButton>
}

// declare namespace FormGroupX {
//     export interface FormGroupProps extends React.HTMLProps<FormGroupProps> {
//         bsClass?: string;
//         bsSize?: string;
//         controlId?: string;
//         validationState?: "success" | "warning" | "error" | null;
//     }
// }

// export function FormGroupRow(props: FormGroupX.FormGroupProps) {
//     return <XFormGroup className="mb-3" as={Row} {...props as any}>
//         {props.children}
//     </XFormGroup>
// }

// export FormGroup 

// export function FormGroup(props: FormGroupX.FormGroupProps) {
//     return <XFormGroup {...props as any}>
//         {props.children}
//     </XFormGroup>
// }

declare namespace ButtonX {
    export interface ButtonProps extends React.HTMLProps<ButtonProps> {
        bsClass?: string;
        active?: boolean;
        block?: boolean;
        variant?: string;

        bsSize?: string;
        componentClass?: React.ComponentClass;
        disabled?: boolean;
    }
}

export function Button(props: ButtonX.ButtonProps) {
    return <XButton size="sm" {...props as any}>
        {props.children}
    </XButton>
}

declare namespace NavX {
    export interface NavProps extends React.HTMLProps<NavProps> {
        // Optional
        activeHref?: string;
        activeKey?: any;
        bsSize?: string;
        variant?: string;

        collapsible?: boolean;
        eventKey?: any;
        expanded?: boolean;
        justified?: boolean;
        navbar?: boolean;
        pullRight?: boolean;
        right?: boolean;
        stacked?: boolean;
        ulClassName?: string;
        ulId?: string;
    }
}

export function Nav(props: NavX.NavProps) {
    return <XNav {...props as any}>
        {props.children}
    </XNav>
}

declare namespace NavItemX {
    export interface NavItemProps extends React.HTMLProps<NavItemProps> {
        active?: boolean;
        brand?: any; // TODO: Add more specific type
        bsSize?: string;
        variant?: string;

        componentClass?: React.ComponentClass;
        defaultNavExpanded?: boolean;
        eventKey?: any;
        fixedBottom?: boolean;
        fixedTop?: boolean;
        fluid?: boolean;
        inverse?: boolean;
        linkId?: string;
        navExpanded?: boolean;
        onSelect?: any;
        onToggle?: Function;
        staticTop?: boolean;
        toggleButton?: any; // TODO: Add more specific type
        toggleNavKey?: string | number;
    }
}

export function NavItem(props: NavItemX.NavItemProps) {
    return <XNavItem {...props as any}>
        <XNav.Link>
            {props.children}
        </XNav.Link>
    </XNavItem>
}


declare namespace NavDropdownX {
    export interface NavDropdownBaseProps extends DropdownBaseProps {
        active?: boolean;
        noCaret?: boolean;
        eventKey?: any;
        title: React.ReactNode;
    }

    export type NavDropdownProps = NavDropdownBaseProps & Omit<React.HTMLProps<NavDropdownBaseProps>, 'title'>;
}

export function NavDropdown(props: NavDropdownX.NavDropdownProps) {
    return <XNavDropdown {...props as any}>
        {props.children}
    </XNavDropdown>
}

declare namespace MenuItemX {
    export interface MenuItemProps extends React.HTMLProps<MenuItemProps> {
        active?: boolean;
        bsClass?: string;
        disabled?: boolean;
        divider?: boolean;
        eventKey?: any;
        header?: boolean;
        onClick?: React.MouseEventHandler<{}>;
        onSelect?: any;
        target?: string;
        title?: string;
    }
}

export function MenuItem(props: MenuItemX.MenuItemProps) {
    return <XDropdown.Item {...props as any}>
        {props.children}
    </XDropdown.Item>
}

export function DividerMenuItem(props: MenuItemX.MenuItemProps) {
    return <XDropdown.Divider {...props as any}>
        {props.children}
    </XDropdown.Divider>
}

export function HeaderMenuItem(props: MenuItemX.MenuItemProps) {
    return <XDropdown.Header {...props as any}>
        {props.children}
    </XDropdown.Header>
}

declare namespace ColX {
    export interface ColProps extends React.HTMLProps<ColProps> {
        componentClass?: React.ComponentClass;
        lg?: number;
        lgHidden?: boolean;
        lgOffset?: number;
        lgPull?: number;
        lgPush?: number;
        md?: number;
        mdHidden?: boolean;
        mdOffset?: number;
        mdPull?: number;
        mdPush?: number;
        sm?: number;
        smHidden?: boolean;
        smOffset?: number;
        smPull?: number;
        smPush?: number;
        xs?: number;
        xsHidden?: boolean;
        xsOffset?: number;
        xsPull?: number;
        xsPush?: number;
    }
}
export function Col(props: ColX.ColProps) {
    return <XCol {...props as any}>
        {props.children}
    </XCol>
}

export function DescriptionCol(props: ColX.ColProps) {
    return <XCol style={{fontWeight: "bold", fontSize:"small", paddingTop:"5px", textAlign:"right"}} {...props as any}>
        {props.children}
    </XCol>
}

declare namespace GridX {
    export interface GridProps extends React.HTMLProps<GridProps> {
        componentClass?: React.ComponentClass;
        fluid?: boolean;
        bsClass?: string;
    }
}

export function Grid(props: GridX.GridProps) {
    return <XGrid {...props as any}>
        {props.children}
    </XGrid>
}

declare namespace RowX {
    export interface RowProps extends React.HTMLProps<RowProps> {
        componentClass?: React.ComponentClass;
    }
}

export function Row(props: RowX.RowProps) {
    return <XRow {...props as any}>
        {props.children}
    </XRow>
}
