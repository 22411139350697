//
//  TagList.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 12/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card } from "../wrappers"
import { forEach } from "lodash"
import { currentDatabaseRef } from "../../config/constants"
import { PageState } from "../PageState"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { Tag } from "../../models/Product"
import { LanguageCode } from "../../helpers/L10n"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { child, off, onValue, set } from "firebase/database"

interface TagListState {
    tags: Tag[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class TagList extends React.Component<RoleRouterProps, TagListState> {

    // Properties

    initialState: TagListState = {
        tags: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        off(this.tagsRef())
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="tags">
                <Card className="my-4">
                    <Card.Header>
                        <Button onClick={this.newTag}>New tag</Button>
                    </Card.Header>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tags.map(tag => {
                                return (
                                    <tr key={tag.tag} onClick={() => { this.editTag(tag.tag) }}>
                                        <td>{tag.name.localized(this.state.currentLanguage) ?? tag.name.value}</td>
                                        <td className="narrow">
                                            <ConfirmDeleteButton
                                                message={`Really delete ${tag.name.localized(this.state.currentLanguage)} tag name? This operation will not modify any products using the tag. Only the display name will be removed.`}
                                                onDelete={async () => { await this.removeTag(tag.tag) }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </StripedTable>
                    <Card.Footer>
                        <Button onClick={this.newTag}>New tag</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }

    // State

    loadState() {
        onValue(this.tagsRef(), snapshot => {
            if (!snapshot || (snapshot && !snapshot.exists())) {
                this.setState({ tags: [], loaded: true })
                return
            }

            const tags: Tag[] = []
            forEach(snapshot.val(), (value) => {
                const tag = new Tag(value)
                tags.push(tag)
            })

            const sorted = tags.sort((lhs, rhs) => {
                return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
            })

            this.setState({
                tags: sorted,
                loaded: true
            })
        })
    }

    // Tag

    newTag = () => {
        this.props.router.navigate("/tag/new")
    }

    editTag = (key: string) => {
        this.props.router.navigate(`/tag/${key}`)
    }

    async removeTag(key: string) {
        await set(child(this.tagsRef(), key), null)
    }

    // Helpers

    tagsRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "inventory/tags")
    }
}

export default withRoleRouter(TagList)
