import * as React from "react"
import { FrontPageSectionsCard } from "./FrontPageSectionsPanel"
import { RoleProps, withRole } from "../../../routes"

export class ConfigurableFrontPage extends React.Component<RoleProps> {
    
    constructor(props: RoleProps) {
        super(props)
        this.state = {}
    }

    // Component

    render() {
        return (
            <div>
                <FrontPageSectionsCard role={this.props.role}/>
            </div>
        )
    }
}

export default withRole(ConfigurableFrontPage)