import React from "react"
import { Form, FormControl, Card, FormLabel, Row } from "react-bootstrap"
import { FormGroup, HelpBlock } from "../../../wrappers"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { AppliesTo, AppliesToSelectorCard } from "../AppliesToSelector";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { Description } from "./BuyXGetYFormDescription";
import * as _ from "lodash"
import { CouponTemplate, DateComponents, Expiration, RuleModel, isFixedExpiration } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { BuyXGetYTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";
import { MarketAmount } from "../../../../models/MarketAmount";
import { CouponTemplateSelector } from "../CouponTemplateSelector";
import { updateCouponValues, updateExpiration } from "../Shared/Helpers";
import { DiscountOverallType } from "../RuleEdit";

export interface BuyXGetYFormProps {
    market: Market | null
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    template: BuyXGetYTemplate
    allShops: _.Dictionary<string>
    showId: boolean
    type: DiscountOverallType
    couponTemplates?: CouponTemplate[]
    onTemplateChanged: (template: RuleModel) => void
}

export function BuyXGetYForm(props: BuyXGetYFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType, percentage?: number, amount?: MarketAmount) {
        if (props.template.discountType === type && percentage === props.template.discountPercentage && amount === props.template.discountAmount) {
            return
        }
        const clone = _.clone(props.template)
        clone.discountType = type
        clone.discountPercentage = percentage
        clone.discountAmount = amount
        props.onTemplateChanged(clone)
    }

    function setAppliesTo(appliesTo: AppliesTo) {
        if (_.isEqual(props.template.appliesTo, appliesTo)) {
            return
        }
        const clone = _.clone(props.template)
        clone.appliesTo = appliesTo
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setAdvancedProps(startDate?: DateComponents, endDate?: DateComponents, continueEvaluation?: boolean, shopIds?: string[]) {
        const clone = _.clone(props.template)
        clone.shop_ids = shopIds
        clone.start_date = startDate
        clone.end_date = endDate
        clone.continue_evaluation = continueEvaluation
        if (_.isEqual(clone, props.template)) {
            return
        }
        props.onTemplateChanged(clone)
    }

    function setXAndY(triggerCount?: number, applicationCount?: number) {
        if (props.template.triggerCount === triggerCount && props.template.applicationCount === applicationCount) {
            return
        }
        const clone = _.clone(props.template)
        clone.triggerCount = triggerCount
        clone.applicationCount = applicationCount
        props.onTemplateChanged(clone)
    }

    function setSelectedCouponTemplate(couponTemplate: CouponTemplate | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('template', couponTemplate, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponTemplateTitle(couponTitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('title', couponTitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponSubtitle(subtitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('subtitle', subtitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponExpiration(expiration: Expiration | undefined) {
        const clone = _.clone(props.template)
        const updatedClone = updateExpiration(clone, expiration)
        props.onTemplateChanged(updatedClone)
    }

    return <Form onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        {props.type === "coupon" ?
            <CouponTemplateSelector
                currentLanguage={props.currentLanguage}
                couponTemplates={props.couponTemplates}
                title={props.template.coupon?.title}
                expiration={props.template.coupon?.expiration}
                subtitle={props.template.coupon?.subtitle}
                selectedCoupon={props.template.coupon?.template}
                templateChanged={(selectedCouponTemplate) => { setSelectedCouponTemplate(selectedCouponTemplate) }}
                titleChanged={(title) => { setCouponTemplateTitle(title) }}
                subtitleChanged={(subtitle) => { setCouponSubtitle(subtitle) }}
                expirationChanged={expiration => { setCouponExpiration(expiration) }}
            ></CouponTemplateSelector> : null}
        <BuyXGetYCard validation={props.template.validateXAndY()} triggerCount={props.template.triggerCount} applicationCount={props.template.applicationCount} onChange={(triggerCount, applicationCount) => { setXAndY(triggerCount, applicationCount) }} />
        <DiscountSelector markets={props.markets} validation={props.template.validateDiscount()} showDiscountValue={true} market={props.market ?? undefined} discountAmount={props.template.discountAmount} discountPercentage={props.template.discountPercentage} type={props.template.discountType} formType="discount" typeChanged={(type, percentage, amount) => { setDiscountType(type, percentage, amount) }} />
        <AppliesToSelectorCard showId={props.showId} validation={props.template.validateAppliesTo(props.template.appliesTo)} type={props.type} productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} appliesTo={props.template.appliesTo} appliesToChanged={(appliesTo) => { setAppliesTo(appliesTo) }} />
        <CustomerConditionSelector type={props.type} validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector allShops={props.allShops} type={props.type} selectedShops={props.template.shop_ids} continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation, shopIds) => { setAdvancedProps(startDate, endDate, continueEvaluation, shopIds) }} />
        <Description allShops={props.allShops} validation={props.template.valid([])} formProps={props} customerCondition={props.template.customerCondition} market={props.market} discountType={props.template.discountType} appliesTo={props.template.appliesTo} />
    </Form>
}

interface BuyXGetYCardProps {
    triggerCount?: number
    applicationCount?: number
    onChange: (triggerCount?: number, applicationCount?: number) => void
    validation: boolean
}

export function BuyXGetYCard(props: BuyXGetYCardProps) {
    return <Card className="my-4" border="primary">
        <Card.Body>
            <Card.Title>
                Buy X get Y
            </Card.Title>
            <HelpBlock>When buying X of the matching products apply a discount to the Y cheapest</HelpBlock>
            <FormGroup className="mb-3" as={Row} style={{ marginLeft: "0px", marginRight: "0px" }}
                validationState={props.validation ? null : "error"}
            >
                <FormLabel>Trigger count (X)</FormLabel>
                <FormControl
                    type="number"
                    name="triggerCount"
                    min={0}
                    step={1}
                    value={props.triggerCount ?? ""}
                    placeholder="Enter trigger count (X)"
                    onChange={(e: any) => {
                        const triggerCount = e.target.value === "" ? undefined : Number(e.target.value)
                        const applicationCount = getApplicationCount(props.applicationCount, triggerCount)
                        props.onChange(triggerCount, applicationCount);
                    }} />
                <FormLabel>Application count (Y)</FormLabel>
                <FormControl
                    type="number"
                    name="applicationCount"
                    min={0}
                    max={props.triggerCount}
                    step={1}
                    value={props.applicationCount ?? ""}
                    placeholder="Enter application count (Y)"
                    onChange={(e: any) => {
                        const value = e.target.value === "" ? undefined : Number(e.target.value)
                        const applicationCount = getApplicationCount(value, props.triggerCount)
                        props.onChange(props.triggerCount, applicationCount);
                    }} />
            </FormGroup>
        </Card.Body>
    </Card>

    function getApplicationCount(triggerCount: number | undefined, applicationCount: number | undefined): number | undefined {
        if (_.isNil(triggerCount) || _.isNil(applicationCount)) { return applicationCount }
        const maxAllowed = triggerCount
        if (applicationCount > maxAllowed) {
            return maxAllowed
        } else {
            return applicationCount
        }
    }
}