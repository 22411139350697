import React from "react";
import { Form } from "react-bootstrap";
import * as _ from "lodash";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "../../../../../components/wrappers";
import { Margins } from "../../../../../models/pdf_template/margins";
import { Background } from "../../../../../models/pdf_template/background";
import { GlobalEditProps } from "./GlobalModalEdit";
import { isMarginEmpty } from "./EditGlobalMargins";

export function EditGlobalBackground(props: GlobalEditProps) {
    return (
        <Form>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background color</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Leading margin"
                        value={props.pdfTemplate.background?.color ?? ""}
                        placeholder="Enter background color"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string;
                            updateBackground((background) => {
                                background.color = value;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background leading margin</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Leading margin"
                        value={props.pdfTemplate.background?.margins?.leading ?? 0}
                        placeholder="Enter leading margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateBackground((background) => {
                                background.margins.leading = value;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background trailing margin</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="trailing margin"
                        value={props.pdfTemplate.background?.margins?.trailing ?? 0}
                        placeholder="Enter trailing margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateBackground((background) => {
                                background.margins.trailing = value;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background top margin</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="top margin"
                        value={props.pdfTemplate.background?.margins?.top ?? 0}
                        placeholder="Enter trailing margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateBackground((background) => {
                                background.margins.top = value;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Background bottom margin</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="bottom margin"
                        value={props.pdfTemplate.background?.margins?.bottom ?? 0}
                        placeholder="Enter trailing margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateBackground((background) => {
                                background.margins.bottom = value;
                                return background;
                            });
                        }} />
                </Col>
            </FormGroup>
        </Form>
    );

    function updateBackground(callback: (background: Background) => Background) {
        let background = _.cloneDeep(props.pdfTemplate.background);
        if (_.isNil(background)) {
            const margins = new Margins();
            background = new Background("", margins);
        }
        background = callback(background);
        props.updatePDFTemplate((template) => {
            if (isBackgroundEmpty(background)) {
                background = undefined;
            }
            template.background = background;
            return template;
        });
    }
}
function isBackgroundEmpty(background: Background | undefined) {
    return isMarginEmpty(background?.margins) && background?.color === "";
}
