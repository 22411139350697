import * as React from "react"
import { currentDatabaseRef } from "../../config/constants"
import { Col, FormGroup, DropdownButton, MenuItem, DescriptionCol } from "../wrappers"
import { Tag } from "../../models/Product"
import { Dictionary, isNil, cloneDeep } from "lodash"
import { LanguageCode } from "../../helpers/L10n"
import { StripedTable } from "../StripedTable"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { Card, Row } from "react-bootstrap"
import { child, get, off } from "firebase/database"

interface TagSelectionProps {
    account: string
    selectedTags: Dictionary<boolean>
    currentLanguage: LanguageCode | null
    onChange: (tags: Dictionary<boolean>) => void
}

interface TagSelectionState {
    tags: Dictionary<Tag>
    selectedTags: Dictionary<boolean>
}

export class TagSelection extends React.Component<TagSelectionProps, TagSelectionState> {
    constructor(props: TagSelectionProps) {
        super(props)

        this.state = {
            selectedTags: {},
            tags: {}
        }
    }

    onChange(data: any) {
        const tags = {}
        for (const key in data) {
            tags[data[key]] = true
        }

        this.props.onChange(tags)
    }

    UNSAFE_componentWillReceiveProps(nextProps: TagSelectionProps) {
        this.setState({ selectedTags: nextProps.selectedTags || [] })
    }

    async componentDidMount() {
        this.setState({ selectedTags: this.props.selectedTags || {} })

        const account = this.props.account
        const tagRef = child(currentDatabaseRef(), `v1/accounts/${account}/inventory/tags`)
        const snapshot = await get(tagRef)
        if (!snapshot.exists()) {
            this.setState({ tags: {} })
            return
        }

        const rawTags = snapshot.val()
        const tags: Dictionary<Tag> = {}
        for (const key in rawTags) {
            if (Object.prototype.hasOwnProperty.call(rawTags, key)) {
                tags[key] = new Tag(rawTags[key])
            }
        }

        this.setState({ tags: tags })
    }

    componentWillUnmount() {
        const account = this.props.account
        const tagRef = child(currentDatabaseRef(), `v1/accounts/${account}/inventory/tags`)
        off(tagRef)
    }

    removeTag(tagKey: string) {
        const selected = cloneDeep(this.state.selectedTags)
        delete selected[tagKey]
        this.props.onChange(selected)
    }

    addTag(tagKey: string) {
        const selected = cloneDeep(this.state.selectedTags)
        selected[tagKey] = true
        this.props.onChange(selected)
    }

    unusedTags() {
        const tagKeys = Object.keys(this.state.tags)
        const selected = Object.keys(this.state.selectedTags)
        const diff = tagKeys.filter(x => !selected.includes(x))
        return diff
    }

    render() {
        return <>
            {this.unusedTags().length > 0 ? (
                <DropdownButton
                    title="Add tag"
                    id="dropdown-add-tag"
                    variant="outline-primary"
                    onSelect={(selectedTag: any) => {
                        this.addTag(selectedTag)
                    }}
                >
                    {
                        this.unusedTags().map((tagKey) => {
                            const tag = this.state.tags[tagKey]
                            return <MenuItem key={tagKey} eventKey={tagKey}>{tag.name.localized(this.props.currentLanguage || null)}</MenuItem>
                        })
                    }
                </DropdownButton>
            ) : null
            }

            {this.state.tags && Object.keys(this.state.selectedTags).length > 0 ? (
                Object.keys(this.state.selectedTags).map((selectedTag) => {
                    const tag = this.state.tags[selectedTag]
                    let tagName = selectedTag
                    if (!isNil(tag)) {
                        tagName = tag.name.localized(this.props.currentLanguage || null)
                    }

                    return (
                        <span onClick={() => {
                            this.removeTag(selectedTag)
                        }} key={selectedTag}><span style={{ display: "inline-block", fontSize: "small", marginBottom: 4, backgroundColor: "#DDDDDD", borderRadius: 5, whiteSpace: "nowrap", padding: "0px 6px" }}>
                            {tagName}&nbsp;<b>✕</b>
                            {/* <ConfirmDeleteButton
                                message={`Really delete tag: ${tagName}?`}
                                onDelete={() => {
                                    this.removeTag(selectedTag)
                                }}
                            /> */}
                        </span> </span>
                    )
                })

            ) : null
            }


            {!this.state.tags && <div>Loading..</div>}
        </>
    }
}
