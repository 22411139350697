import * as _ from "lodash"

export enum CashierSelectionMode {
    manual = "manual",
    beforeEachSale = "before_each_sale",
}

export class CashierSelection {

    static fromJSON(json: any): CashierSelection {
        if (_.isNil(json)) {
            return new CashierSelection(false, CashierSelectionMode.manual)
        }

        const usePINCode = json.use_pin_code || false
        const selectionPoint = json.selection_point === CashierSelectionMode.beforeEachSale || json.selection_point === CashierSelectionMode.manual ? json.selection_point : CashierSelectionMode.manual

        return new CashierSelection(usePINCode, selectionPoint)
    }

    static get default(): CashierSelection {
        return new CashierSelection(false, CashierSelectionMode.beforeEachSale)
    }

    usePINCode: boolean
    selectionPoint: CashierSelectionMode

    constructor(usePINCode: boolean, selectionPoint: CashierSelectionMode) {
        this.usePINCode = usePINCode
        this.selectionPoint = selectionPoint
    }

    toJSON(): any {
        return {
            use_pin_code: this.usePINCode,
            selection_point: this.selectionPoint
        }
    }
}