import * as React from "react"
import { FormattedNumber } from "react-intl"

interface FormattedPercentProps {
    value: number
}

export class FormattedPercent extends React.Component<FormattedPercentProps> {
    render() {
        return (
            <span>
                <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={1} value={this.props.value * 100} />
                %
        </span>
        )
    }
}
