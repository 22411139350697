import * as React from "react"
import ProductFoldersViewModel from "./ProductFoldersViewModel"
import SortableTree, {
    getNodeAtPath,
    getVisibleNodeCount,
    removeNodeAtPath
} from "@nosferatu500/react-sortable-tree"
import {
    FormGroup,
    Card
} from "../../../wrappers"
import {
    Button,
    ButtonToolbar,
    Row,
} from "react-bootstrap"
import { FilterTreeItem } from "./ProductFoldersModels"
import { LanguageCode } from "../../../../helpers/L10n"
import { PageState } from "../../../PageState"
import { Role } from "../../../../config/role"
import { Tag } from "../../../../models/Product"

interface ProductFoldersCardProps {
    role: Role
}

interface ProductFoldersCardState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    tags: Tag[]
    treeData: FilterTreeItem[]
    treeHeight: number
}

export class ProductFoldersCard extends React.Component<ProductFoldersCardProps, ProductFoldersCardState> {

    private viewModel: ProductFoldersViewModel

    // Constructor

    constructor(props: ProductFoldersCardProps) {
        super(props)

        this.viewModel = new ProductFoldersViewModel(props.role.account_id)
        this.viewModel.foldersAndTagsUpdated = this.foldersAndTagsUpdated.bind(this)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            tags: [],
            treeData: [],
            treeHeight: 0
        }
    }

    // Signals from vm

    foldersAndTagsUpdated = (treeItems: FilterTreeItem[], tags: Tag[]) => {
        const height = getVisibleNodeCount({
            treeData: treeItems
        })
        // const getNodeKey = ({treeIndex}: any) => treeIndex
        // walk({
        //     treeData: treeItems as TreeItem[],
        //     getNodeKey,
        //     callback: (t: any) => { height++ },
        //     ignoreCollapsed: true
        // })
        this.setState({
            loaded: true,
            tags: tags,
            treeData: treeItems,
            treeHeight: height
        })
    }

    // Signals to vm

    onTagSelect = (index: number) => {
        this.viewModel.handleTagSelect(index)
        this.setState({ dirty: true })
    }

    onRemoveNode = (path: number[]) => {
        const getNodeKey = ({ treeIndex }: any) => treeIndex
        const nodeInfo = getNodeAtPath({
            treeData: this.state.treeData,
            path,
            getNodeKey
        })
        const name = nodeInfo ? nodeInfo.node.title : ""
        if (window.confirm(`Really remove the folder "${name}"?`) === true) {
            const parameters = {
                treeData: this.state.treeData,
                path,
                getNodeKey
            }
            const treeData = removeNodeAtPath(parameters)
            this.viewModel.handleTreeDataChanged(treeData as FilterTreeItem[])
            this.setState({ dirty: true })
        }
    }

    onChange = (treeData: FilterTreeItem[]) => {
        this.viewModel.handleTreeDataChanged(treeData)
        this.setState({ treeData: treeData as any, dirty: true })
    }

    onPublish = async () => {
        this.setState({ publishing: true })
        await this.viewModel.publish()
        this.setState({ dirty: false, publishing: false })
    }

    // Component

    componentDidMount() {
        this.viewModel.start()
    }

    componentWillUnmount() {
        this.viewModel.stop()
    }

    render() {
        return (
            <PageState dirty={this.state.dirty} loading={!this.state.loaded} typeName="folders" publishing={this.state.publishing}>
                <Card className="my-4" key="product_folders">
                    <Card.Header>
                        Folders
                    </Card.Header>
                    <Card.Body>
                        <h4>Concept</h4>
                        <p>Folders are an optional filtering and structuring method used in POS. It provides a way of organizing products in a hierachical manner which can be navigated in POS.</p>
                        <p>A folder is created by selecting a tag in the list below. A tag can be used as many times as you wish for as many folders as you wish.</p>
                        <p>The folder hierarchy can be rearranged by dragging and dropping the folders in the list</p>
                        <p>For more info take a look at our <a href="https://ka-ching.zendesk.com/hc/en-us/search?utf8=✓&query=mapper" target="_blank" rel="noopener noreferrer">guides in Zendesk</a></p>
                        {/* Somehow the buttons beneath the SortableTree was not clickable with height 0, 1 or 10. I'm clapping the min height of the container div to 65 */}
                        <div style={{ height: Math.max(this.state.treeHeight, 1) * 62, background: "#f5f5f5", borderRadius: "3px" }}>
                            <SortableTree
                                treeData={this.state.treeData}
                                onChange={treeData => { this.onChange(treeData as FilterTreeItem[]) }}
                                generateNodeProps={({ node, path }) => ({
                                    buttons: [(
                                        <Button
                                            key={`${node["filter"]["type"] - node["filter"]["id"]}`}
                                            onClick={() =>
                                                this.onRemoveNode(path)
                                            }
                                        >
                                            X
                                        </Button>
                                    )]
                                })}
                            />
                        </div>
                        <br />
                        <FormGroup className="mb-3" as={Row}>
                            {
                                this.state.tags.length > 0 ? (
                                    <ButtonToolbar>
                                        {
                                            this.state.tags.map((tag, index) => {
                                                return (
                                                    <Button
                                                        className="ms-2 mb-2"
                                                        key={tag.tag}
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            this.onTagSelect(index)
                                                        }}
                                                    >
                                                        {tag.name.localized(LanguageCode.da)}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </ButtonToolbar>
                                ) : "No tags defined"
                            }
                        </FormGroup>
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={this.onPublish} disabled={!this.state.dirty}>Publish</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
}