import * as Models from "../../../models/RuleModels"
import * as React from "react"
import dayjs from "dayjs"
import {
    Button,
    Card,
    Table
} from "../../wrappers"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { ModalPicker } from "../../ModalPicker"
import { dayjsFromDateComponents } from "./RuleTemplateForm"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { RoleRouterProps, withRoleRouter } from "../../../routes"
import * as _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoins, faGifts, faPercentage, faTicket, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { DiscountOverallType } from "./RuleEdit"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { child, off, onValue, set } from "firebase/database"

interface RuleElement {
    id: string
    name: string
    priority: number
    startDate?: string
    endDate?: string
    expired: boolean
    upcoming: boolean
    templateType: string
    templateTypeId: string,
    type: "discount" | "coupon" | "offer"
}

interface AvailableElement {
    id: string
    name: string
}

interface RuleListState {
    rules: RuleElement[]
    availableRules: AvailableElement[]
    isAvailableRulesPickerVisible: boolean
    isAvailableCouponRulesPickerVisible: boolean
    isAvailableOfferRulesPickerVisible: boolean
    couponRules: AvailableElement[]
    offerRules: AvailableElement[]
    loaded: boolean
    showLegacyTemplates: boolean,
    isLoyaltyPointsEnabled: boolean
    isCouponsEnabled: boolean
    isOffersEnabled: boolean
}

const availableRules: AvailableElement[] = Models.availableTemplateTypes.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

const couponRules: AvailableElement[] = Models.couponRules.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

const offerRules: AvailableElement[] = Models.offerRules.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

const allRules: AvailableElement[] = Models.allTemplateTypes.map(type => {
    return {
        id: type,
        name: Models.typeToNameMap[type]
    }
})

class RuleList extends React.Component<RoleRouterProps, RuleListState> {

    // Properties

    initialState: RuleListState = {
        rules: [],
        availableRules: availableRules,
        isAvailableRulesPickerVisible: false,
        isAvailableCouponRulesPickerVisible: false,
        isAvailableOfferRulesPickerVisible: false,
        couponRules: couponRules,
        offerRules: offerRules,
        loaded: false,
        showLegacyTemplates: false,
        isLoyaltyPointsEnabled: false,
        isCouponsEnabled: false,
        isOffersEnabled: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    async componentDidMount() {
        onValue(this.templateRepoRef(), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ rules: [], loaded: true })
                return
            }

            const dict = snapshot.val() ?? {}
            let rules: RuleElement[] = []
            const today = dayjs().startOf("day").format("YYYY-MM-DD")

            Object.keys(dict).forEach(key => {
                const val = dict[key]
                for (const templateType of Models.allTemplateTypes) {
                    const t = val[templateType]
                    if (t != null && t["name"] != null && t["priority"] != null) { //tslint:disable-line
                        let expired = false
                        let upcoming = false
                        let startDate: string | undefined = undefined
                        if (t["start_date"]) {
                            startDate = dayjsFromDateComponents(t["start_date"]).format("YYYY-MM-DD")
                            if (today < startDate) {
                                upcoming = true
                            }
                        }
                        let endDate: string | undefined = undefined
                        if (t["end_date"]) {
                            endDate = dayjsFromDateComponents(t["end_date"]).format("YYYY-MM-DD")
                            if (today > endDate) {
                                expired = true
                            }
                        }
                        let isCoupon = false

                        if (!(_.isNil(t.is_coupon))) {
                            isCoupon = t.is_coupon
                        }

                        const type: DiscountOverallType = t.type ?? (isCoupon ? "coupon" : "discount")

                        rules.push({
                            id: key,
                            name: t.name,
                            priority: t.priority,
                            endDate: endDate,
                            startDate: startDate,
                            expired: expired,
                            upcoming: upcoming,
                            templateType: Models.typeToNameMapShort[templateType],
                            templateTypeId: templateType,
                            type: type
                        })
                        break
                    }
                }
            })
            rules = rules.sort((a: RuleElement, b: RuleElement) => {
                return b.priority - a.priority
            })
            this.setState({ rules: rules, loaded: true })
        })

        onValue(this.loyaltyClubModuleRef(), snapshot => {
            if (!snapshot || !snapshot.exists()) {
                this.setState({ rules: [], loaded: true })
                return
            }

            const loyaltyClubModule = snapshot.val() ?? {}
            const isLoyaltyClubModuleEnabled = loyaltyClubModule.enabled ?? false
            const isLoyaltyPointsEnabled = isLoyaltyClubModuleEnabled && (loyaltyClubModule.enable_loyalty_cards ?? false)
            const isCouponsEnabled = isLoyaltyClubModuleEnabled && (loyaltyClubModule.enable_coupons ?? false)
            const isOffersEnabled = isLoyaltyClubModuleEnabled && (loyaltyClubModule.enable_offers ?? false)
            this.setState({ isLoyaltyPointsEnabled: isLoyaltyPointsEnabled, isOffersEnabled: isOffersEnabled, isCouponsEnabled: isCouponsEnabled })
            this.setDiscountRules(this.state.showLegacyTemplates, isLoyaltyClubModuleEnabled)
        })
    }

    componentWillUnmount() {
        off(this.templateRepoRef())
        off(this.loyaltyClubModuleRef())
    }

    setDiscountRules(showLegacyTemplate: boolean, isLoyaltyClubModuleEnabled: boolean) {
        const rules = showLegacyTemplate ? allRules : availableRules
        const filteredRules = rules.filter(rule => {
            if (rule.id === Models.TemplateType.LoyaltyPoints) { return false }
            else { return true }
        })
        this.setState({ availableRules: filteredRules, showLegacyTemplates: showLegacyTemplate })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="discounts">
                <div>
                    {this.state.isAvailableRulesPickerVisible && <ModalPicker elements={this.state.availableRules} alternativeButtonTitle={this.state.showLegacyTemplates ? "Hide legacy templates" : "Show legacy templates"} alternativeButtonAction={() => { this.setDiscountRules(!this.state.showLegacyTemplates, this.state.isLoyaltyPointsEnabled) }} onCancel={this.cancelAvailableRulesPicker} onSelect={(element: AvailableElement) => { this.createDiscountRule(element.id) }} title="Add discount rule" />}
                    {this.state.isAvailableCouponRulesPickerVisible && <ModalPicker elements={this.state.couponRules} onCancel={this.cancelCouponRulesPicker} onSelect={(element: AvailableElement) => { this.createCouponRule(element.id) }} title="Add coupon rule" />}
                    {this.state.isAvailableOfferRulesPickerVisible && <ModalPicker elements={this.state.offerRules} onCancel={this.cancelOfferRulesPicker} onSelect={(element: AvailableElement) => { this.createOfferRule(element.id) }} title="Add offer rule" />}

                    <Card className="my-4">
                        <Card.Header>
                            <div>
                                <Button className="me-2" onClick={this.showAvailableRulesPicker}>Add discount rule</Button>
                                {this.state.isCouponsEnabled && <Button className="me-2" onClick={this.showCouponRulesPicker}>Add coupon rule</Button>}
                                {this.state.isOffersEnabled && <Button className="me-2" onClick={this.showOfferRulesPicker}>Add offer</Button>}
                                {this.state.isLoyaltyPointsEnabled && <Button className="me-2" onClick={() => { this.createDiscountRule(Models.TemplateType.LoyaltyPoints) }}> Add loyalty points rule</Button>}
                            </div>
                        </Card.Header>
                        <Table striped={true} bordered={true} condensed={true} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Priority</th>
                                    <th>Status</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rules.map((rule) => {
                                    return (
                                        <tr style={rule.expired ? { backgroundColor: "#FFF0F0" } : (rule.upcoming ? { backgroundColor: "#FFF8E8" } : {})} key={rule.id} onClick={() => { this.editRule(rule.id, rule.type) }} >
                                            <td align="center"> {this.getRuleIcon(rule)}</td>
                                            <td>{rule.name}</td>
                                            <td>{rule.templateType}</td>
                                            <td>{rule.priority}</td>
                                            <td>{rule.expired ? `Expired (${rule.endDate})` : (rule.upcoming ? `Upcoming (${rule.startDate})` : "Active")}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${rule.name}???`}
                                                    onDelete={() => { this.removeRule(rule.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Card.Footer>
                            <div>
                                <Button className="me-2" onClick={this.showAvailableRulesPicker}>Add discount rule</Button>
                                {this.state.isCouponsEnabled && <Button className="me-2" onClick={this.showCouponRulesPicker}>Add coupon rule</Button>}
                                {this.state.isOffersEnabled && <Button className="me-2" onClick={this.showOfferRulesPicker}>Add offer</Button>}
                                {this.state.isLoyaltyPointsEnabled && <Button className="me-2" onClick={() => { this.createDiscountRule(Models.TemplateType.LoyaltyPoints) }}> Add loyalty points rule</Button>}
                            </div>
                        </Card.Footer>
                    </Card>
                </div>
            </PageState>
        )
    }

    // Methods

    templateRepoRef() {
        const accountId = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/inventory/campaign_template_repo`)
    }

    loyaltyClubModuleRef() {
        const accountId = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${accountId}/configuration/modules/loyalty_club`)
    }

    // edit rule /rules/rule_key
    editRule(key: string, type: "discount" | "coupon" | "offer") {
        this.props.router.navigate(`/rule/${type}/${key}`)
    }

    //new rule /rules/new?new=ruletype
    createDiscountRule(type: string) {
        this.props.router.navigate(`/rule/discount/new?new=${type}`)
    }

    createCouponRule(type: string) {
        this.props.router.navigate(`/rule/coupon/new?new=${type}`)
    }

    createOfferRule(type: string) {
        this.props.router.navigate(`/rule/offer/new?new=${type}`)
    }

    removeRule(key: string) {
        const accountId = this.props.role.account_id

        set(child(currentDatabaseRef(), `v1/accounts/${accountId}/inventory/campaign_template_repo/${key}`), null)
    }

    showAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: true })
    }

    cancelAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: false })
    }

    showCouponRulesPicker = () => {
        this.setState({ isAvailableCouponRulesPickerVisible: true })
    }

    cancelCouponRulesPicker = () => {
        this.setState({ isAvailableCouponRulesPickerVisible: false })
    }

    showOfferRulesPicker = () => {
        this.setState({ isAvailableOfferRulesPickerVisible: true })
    }

    cancelOfferRulesPicker = () => {
        this.setState({ isAvailableOfferRulesPickerVisible: false })
    }

    getRuleIcon(rule: RuleElement) {
        if (rule.type === "coupon") {
            return <RuleTooltip icon={faTicket} tooltip="Coupon" />
        } else if (rule.type === "offer") {
            return <RuleTooltip icon={faGifts} tooltip="Offer" />
        } else if (rule.templateTypeId === "loyalty_points") {
            return <RuleTooltip icon={faCoins} tooltip="Loyalty points" />
        }
        return <RuleTooltip icon={faPercentage} tooltip="Discount" />
    }
}

function RuleTooltip(props: { icon: IconDefinition, tooltip: string }) {
    return <OverlayTrigger overlay={<Tooltip>{props.tooltip}</Tooltip>}>
        <FontAwesomeIcon icon={props.icon}></FontAwesomeIcon></OverlayTrigger>

}

export default withRoleRouter(RuleList)
