import React from "react"
import { Form } from "react-bootstrap"
import { AttributeObserver } from "../../../../helpers/attributeObserver";
import { ProductObserver } from "../../../../helpers/productObserver";
import { TagObserver } from "../../../../helpers/tagObserver";
import { Market } from "../../../../models/MarketModels";
import { CustomerCondition, CustomerConditionSelector } from "../CustomerConditionSelector";
import { DiscountSelector, DiscountType } from "../DiscountSelector";
import { BundleStair } from "../BundleStair";
import { BundleRowData } from "../BundleStairStep";
import { Description } from "./BundleFormDescription";
import * as _ from "lodash"
import { CouponTemplate, DateComponents, Expiration, RuleModel } from "../../../../models/RuleModels";
import { L10nString, LanguageCode } from "../../../../helpers/L10n";
import { BundleTemplate } from "./Model";
import { SharedPropertiesSelector } from "../SharedProperties";
import { AdvancedPropertiesSelector } from "../AdvancedProperties";
import { MarketAmount } from "../../../../models/MarketAmount";
import { CouponTemplateSelector } from "../CouponTemplateSelector";
import { updateCouponValues, updateExpiration } from "../Shared/Helpers";
import { DiscountOverallType } from "../RuleEdit";

export interface BundleFormProps {
    market: Market | null
    markets: string[]
    currentLanguage: () => LanguageCode | null
    productObserver: ProductObserver
    tagObserver: TagObserver
    attributeObserver: AttributeObserver
    customerAttributeObserver: AttributeObserver
    allShops: _.Dictionary<string>
    template: BundleTemplate
    showId: boolean
    type: DiscountOverallType
    couponTemplates?: CouponTemplate[]
    onTemplateChanged: (template: RuleModel) => void
}

export function BundleForm(props: BundleFormProps) {

    function setDisplayName(name?: L10nString) {
        if (_.isEqual(props.template.display_name, name)) {
            return
        }
        const clone = _.clone(props.template)
        clone.display_name = name
        props.onTemplateChanged(clone)
    }

    function setDiscountType(type: DiscountType, percentage?: number, amount?: MarketAmount) {
        if (props.template.discountType === type && percentage === props.template.discountPercentage && amount === props.template.discountAmount) {
            return
        }
        const clone = _.clone(props.template)
        clone.discountType = type
        clone.discountPercentage = percentage
        clone.discountAmount = amount
        props.onTemplateChanged(clone)
    }

    function setSteps(steps: BundleRowData[]) {
        if (_.isEqual(props.template.steps, steps)) {
            return
        }
        const clone = _.clone(props.template)
        clone.steps = steps
        props.onTemplateChanged(clone)
    }

    function setCustomerCondition(condition: CustomerCondition) {
        if (_.isEqual(props.template.customerCondition, condition)) {
            return
        }
        const clone = _.clone(props.template)
        clone.customerCondition = condition
        props.onTemplateChanged(clone)
    }

    function setPriority(priority: number | undefined) {
        if (props.template.priority === priority) {
            return
        }
        const clone = _.clone(props.template)
        clone.priority = priority
        props.onTemplateChanged(clone)
    }

    function setAdvancedProps(startDate?: DateComponents, endDate?: DateComponents, continueEvaluation?: boolean, shopIds?: string[]) {
        const clone = _.clone(props.template)
        clone.shop_ids = shopIds
        clone.start_date = startDate
        clone.end_date = endDate
        clone.continue_evaluation = continueEvaluation
        if (_.isEqual(clone, props.template)) {
            return
        }
        props.onTemplateChanged(clone)
    }

    function setSelectedCouponTemplate(couponTemplate: CouponTemplate | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('template', couponTemplate, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponTemplateTitle(couponTitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('title', couponTitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponSubtitle(subtitle: L10nString | undefined) {
        const clone = _.clone(props.template)
        clone.coupon = updateCouponValues('subtitle', subtitle, clone.coupon)
        props.onTemplateChanged(clone)
    }

    function setCouponExpiration(expiration: Expiration | undefined) {
        const clone = _.clone(props.template)
        const updatedClone = updateExpiration(clone, expiration)
        props.onTemplateChanged(updatedClone)
    }

    return <Form onSubmit={e => e.preventDefault()}>
        <SharedPropertiesSelector validation={props.template.validateShared()} currentLanguage={props.currentLanguage} displayName={props.template.display_name} priority={props.template.priority} updateSharedProps={(displayName, priority) => { setDisplayName(displayName); setPriority(priority) }} />
        {props.type === "coupon" ? 
            <CouponTemplateSelector 
            currentLanguage={props.currentLanguage} 
            couponTemplates={props.couponTemplates} 
            title={props.template.coupon?.title} 
            subtitle={props.template.coupon?.subtitle}  
            selectedCoupon={props.template.coupon?.template}
            expiration={props.template.coupon?.expiration}
            templateChanged={(template) => {setSelectedCouponTemplate(template)}}
            titleChanged={(title) => {setCouponTemplateTitle(title) }}
            subtitleChanged={(subtitle) => {setCouponSubtitle(subtitle) }}
            expirationChanged={expiration => {setCouponExpiration(expiration)}}
            ></CouponTemplateSelector> : null}
        <DiscountSelector markets={props.markets} validation={props.template.validateDiscount()} showDiscountValue={true} market={props.market ?? undefined} discountAmount={props.template.discountAmount} discountPercentage={props.template.discountPercentage} type={props.template.discountType} formType="discount" typeChanged={(type, percentage, amount) => { setDiscountType(type, percentage, amount) }} />
        <BundleStair type={props.type} showId={props.showId} productObserver={props.productObserver} tagsObserver={props.tagObserver} attributesObserver={props.attributeObserver} validation={props.template.validateSteps()} market={props.market} rows={props.template.steps} rowsChanged={(rows) => { setSteps(rows) }} />
        <CustomerConditionSelector type={props.type} validation={props.template.validateCustomerCondition()} attributesObserver={props.customerAttributeObserver} condition={props.template.customerCondition} conditionChanged={(condition) => { setCustomerCondition(condition) }} />
        <AdvancedPropertiesSelector allShops={props.allShops} type={props.type} selectedShops={props.template.shop_ids} continueEvaluation={props.template.continue_evaluation} startDate={props.template.start_date} endDate={props.template.end_date} updateAdvancedProps={(startDate, endDate, continueEvaluation, shopIds) => { setAdvancedProps(startDate, endDate, continueEvaluation, shopIds) }} />
        <Description allShops={props.allShops} validation={props.template.valid([])} formProps={props} market={props.market} />
    </Form>
}


