import * as React from "react"
import { ToggleButton } from "../../ToggleButton"
import {
    Button,
    Col,
    FormGroup,
    Card,
} from "../../wrappers"
import { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { RoleRouterProps, withRouter } from "../../../routes"
import { child, get, remove, set } from "firebase/database"
import { Form, Row } from "react-bootstrap"


interface OrderHandlingConfigurationProps extends RoleRouterProps {
    mode: "account" | "shop"
}

interface OrderHandlingConfigurationState {
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    selectedSupported: OrderHandlingType[]
    defaults: OrderHandlingType[]
    override: boolean
    dirty: boolean
}

type OrderHandlingType = "click_and_collect" | "reserve_and_collect" | "ship_from_store" | "inventory_transfer"
const supported: OrderHandlingType[] = ["click_and_collect", "reserve_and_collect", "ship_from_store", "inventory_transfer"]

function getTitle(value: OrderHandlingType): string {
    switch (value) {
        case "click_and_collect": return "Click & Collect"
        case "reserve_and_collect": return "Reserve & Collect"
        case "ship_from_store": return "Ship from Store"
        case "inventory_transfer": return "Inventory transfer"
    }
}

class OrderHandlingConfiguration extends React.Component<OrderHandlingConfigurationProps, OrderHandlingConfigurationState> {

    constructor(props: OrderHandlingConfigurationProps) {
        super(props)

        this.state = {
            loaded: false,
            publishing: false,
            showSuccess: false,
            selectedSupported: [],
            defaults: [],
            override: false,
            dirty: false
        }
    }

    async componentDidMount() {
        const capabilitiesRef = this.capabilitiesRef()
        const supportedSnapshot = await get(capabilitiesRef)
        let defaults: OrderHandlingType[] = []
        if (this.props.mode === "shop") {
            const accountSnap = await get(this.accountCapabilitiesRef())
            defaults = (accountSnap.val() ?? {}).supported ?? []
        }
        this.setState({
            loaded: true,
            selectedSupported: (supportedSnapshot.val() ?? {}).supported ?? [],
            override: this.props.mode === "account" ? true : supportedSnapshot.exists(),
            defaults: defaults
        })
    }

    handleSwitchChange = (value: boolean) => {
        if (value === true) {
            this.setState({ override: true, dirty: true })
        } else {
            this.setState({ override: false, selectedSupported: [], dirty: true })
        }
    }

    overrideDefaultsLabel() {
        return `Override default value: (${this.state.defaults.map(val => { return getTitle(val) }).join(", ")})`
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} typeName="Order handling configuration">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"}>
                    <Card.Header>
                        {this.state.showSuccess ? "Order handling configuration updated successfully" : "Order handling configuration"}
                    </Card.Header>
                    <Card.Body>
                        <div />
                        <Form>
                            {this.state.defaults.length > 0 &&
                                <>
                                    <Form.Switch
                                        id="custom-switch"
                                        label={this.overrideDefaultsLabel()}
                                        checked={this.state.override}
                                        onChange={(e) => this.handleSwitchChange(e.target.checked)}
                                    />
                                    <br />
                                </>
                            }

                            { // If we are overriding, or no defaults have been set, then we allow configuring the values
                                (this.state.override || this.state.defaults.length === 0) && <Form.Group className="mb-3" as={Row}>
                                    {supported.map((value) => {
                                        const currentEnabledState = this.setEnabledState(value)
                                        return (
                                            <div key={value}>
                                                <Col sm={2}>{getTitle(value)}</Col>
                                                <Col sm={10}>
                                                    <ToggleButton active={currentEnabledState} performToggle={async () => { this.updateSelectedSupported(value, currentEnabledState) }} />
                                                    <br /><br />
                                                </Col>
                                            </div>
                                        )
                                    }
                                    )}
                                </Form.Group>
                            }
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <Button disabled={!this.state.dirty} onClick={this.publish} >Publish</Button>
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }

    private setEnabledState(value: OrderHandlingType): boolean {
        return this.state.selectedSupported.includes(value)
    }

    private updateSelectedSupported(value: OrderHandlingType, currentEnabledState: boolean) {
        var selectedSupported = this.state.selectedSupported

        if (currentEnabledState) {
            const index = selectedSupported.findIndex((element: string) => element === value)
            if (index !== -1) {
                selectedSupported.splice(index, 1)
            }
        } else {
            selectedSupported.push(value)
        }

        this.setState({
            selectedSupported: selectedSupported,
            dirty: true
        })
    }

    accountCapabilitiesRef() {
        const account = this.props.role.account_id
        return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/order_handling`)
    }

    capabilitiesRef() {
        const account = this.props.role.account_id
        switch (this.props.mode) {
            case "account": {
                return child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/order_handling`)
            }
            case "shop": {
                const shop = this.props.router.params.shopKey
                return child(currentDatabaseRef(), `v1/accounts/${account}/shops/${shop}/configuration/order_handling`)
            }
        }
    }

    publish = async () => {
        this.setState({ publishing: true })
        // If we are overriding, or no defaults have been set, then we allow configuring the values
        if (this.state.override || this.state.defaults.length === 0) {
            await set(this.capabilitiesRef(), { supported: this.state.selectedSupported, dummy: true })
        } else {
            await remove(this.capabilitiesRef())
        }
        this.setState({ showSuccess: true, publishing: false, dirty: false })
    }
}

export default withRouter(OrderHandlingConfiguration)
