import React, { useState } from "react"
import { Button, DropdownButton, FormGroup, Dropdown, Card, Form } from "react-bootstrap"
import { LanguageCode } from "../../../helpers/L10n"
import { ProductObserver } from "../../../helpers/productObserver"
import { TagObserver } from "../../../helpers/tagObserver"
import { AttributeObserver } from "../../../helpers/attributeObserver"
import { Attribute, Product, Tag } from "../../../models/Product"
import { Thumbnail } from "../../../components/Thumbnail"
import { StripedTable } from "../../../components/StripedTable"
import * as _ from "lodash"
import { ModalPicker } from "../../ModalPicker"
import { ProductElement } from "../ConfigurableFrontPage/ProductsSectionEdit"
import { Calendar } from "react-date-range"
import dayjs from "dayjs"
import TimeField from "react-simple-timefield"
import { DiscountOverallType } from "./RuleEdit"

interface ProductListElementProps {
    product?: Product
    showId: boolean
    remove: (key: string) => void
}

function ProductListElement(props: ProductListElementProps) {
    if (!props.product) {
        return <span />
    }
    if (props.showId) {
        return <tr><td className="narrow"><Thumbnail src={props.product.image_url} /></td><td><span>{props.product.localizedName(LanguageCode.da)}</span><span className="text-muted">{` (${props.product?.id ?? ""})`}</span><Button className="float-sm-end" variant="link" onClick={() => { props.remove(props.product!.id) }}><DeleteButtonSymbol /></Button></td></tr>
    } else {
        return <tr><td className="narrow"><Thumbnail src={props.product.image_url} /></td><td>{props.product.localizedName(LanguageCode.da)}<Button className="float-sm-end" variant="link" onClick={() => { props.remove(props.product!.id) }}><DeleteButtonSymbol /></Button></td></tr>
    }
}

interface TagElementProps {
    tag?: Tag
    remove: (key: string) => void
}
function TagElement(props: TagElementProps) {
    if (!props.tag) {
        return <span />
    }
    return <tr><td>{props.tag.name.localized(LanguageCode.da)}<Button className="float-sm-end" variant="link" onClick={() => { props.remove(props.tag!.tag) }}><DeleteButtonSymbol /></Button></td></tr>
}

export function DeleteButtonSymbol() {
    return <span role="img" aria-label="delete button">✖️</span>
}

interface AttributeElementProps {
    attribute?: Attribute
    option: string
    comparison?: Comparison
    remove: (key: string) => void
}

export function AttributeElement(props: AttributeElementProps) {
    if (!props.attribute) {
        return <span />
    }
    let value = props.option
    if (props.attribute?.type.options !== undefined) {
        const option = (props.attribute!.type.options ?? {})[props.option]
        if (option !== undefined) {
            value = option.name.localized(LanguageCode.da)
        }
    }
    if (props.attribute?.type.date_time !== undefined) {
        const d = dayjs(value)
        value = d.format("YYYY-MM-DD HH:mm")
    }
    const comp = (props.comparison === undefined) ? ": " : ` ${props.comparison} `
    return <tr><td>{props.attribute.name.localized(LanguageCode.da)}{comp}{value}<Button style={{ float: "right" }} variant="link" onClick={() => { props.remove(props.attribute!.id) }}><DeleteButtonSymbol /></Button></td></tr>
}

interface SelectAttributeOptionProps {
    attribute?: Attribute
    disabled?: boolean
    optionSelected: (attributeId: string, optionId: string) => void
}

export function SelectAttributeOption(props: SelectAttributeOptionProps) {
    const options = (props.attribute!.type.options ?? {})
    return <DropdownButton disabled={props.disabled} className="d-inline" size="sm" title={`Select option for ${props.attribute!.name.localized(LanguageCode.da)}`} id="a">
        {Object.keys(options).map(key => {
            const option = options[key]

            return <Dropdown.Item key={option.id} onClick={() => {
                props.optionSelected(props.attribute!.id, key)
            }} eventKey={option.id}>{option.name.localized(LanguageCode.da)}</Dropdown.Item>
        })}
    </DropdownButton>
}

interface SelectAttributeTextProps {
    attribute?: Attribute
    textSelected: (attributeId: string, value: string) => void
    textUpdated?: (attributeId: string, value: string) => void
}

export function SelectAttributeText(props: SelectAttributeTextProps) {
    const [value, setValue] = useState("")
    return <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter a value for {props.attribute?.name.localized(null) ?? "selected attribute"}</Form.Label>
        <Form.Control type="text" placeholder="Enter value" onKeyDown={event => {
            if (event.code === "Enter") {
                event.preventDefault()
                if (props.attribute?.id !== undefined) {
                    props.textSelected(props.attribute.id, value)
                }
            }
        }} onChange={a => {
            const newValue = a.target?.value ?? ""
            setValue(newValue)
            if (props.attribute?.id !== undefined && props.textUpdated !== undefined) {
                props.textUpdated(props.attribute.id, newValue)
            }
        }} />
        <Form.Text className="text-muted">
            Enter the value that you want to match against.
        </Form.Text>
    </Form.Group>
}

interface SelectAttributeDateProps {
    attribute: Attribute
    onChange: (value: string, comparison: Comparison) => void
    onCancel: () => void
}

export type Comparison = "==" | "<" | ">" | "<=" | ">="
export function SelectAttributeDate(props: SelectAttributeDateProps) {
    const [value, setValue] = useState<Date | undefined>()
    const [comparison, setComparison] = useState<Comparison>("==")
    const [showCalendar, setShowCalendar] = useState<boolean>(false)

    const comparisonDescription: (comp: Comparison) => string = (comp) => {
        switch (comp) {
            case "==": return "is"
            case "<": return "is older than"
            case ">": return "is newer than"
            case "<=": return "is older than or same as"
            case ">=": return "is newer than or same as"
        }
    }
    const allComps: Comparison[] = ["==", "<", ">", "<=", ">="]
    const formattedDate: () => string = () => {
        if (value === undefined) {
            return "selected date"
        } else {
            const dateString = dayjs(value).local().format("YYYY-MM-DD")
            return dateString
        }
    }

    return <Form.Group className="mb-3" controlId="formBasicEmail">
        <div>
            {!showCalendar && <Button className="my-3" variant="outline-primary" size="sm" onClick={() => {
                setShowCalendar(true)
            }}>Select a date</Button>}

            {showCalendar && <Calendar date={value} onChange={date => {
                setValue(date)
                setShowCalendar(false)
            }}
            />
            }
        </div>
        <Dropdown onSelect={(value) => { setComparison(value ?? undefined as any) }}>
            <Dropdown.Toggle size="sm" variant="outline-primary">Select comparison</Dropdown.Toggle>
            <Dropdown.Menu>
                {allComps.map(comp => {
                    return <Dropdown.Item key={comp} eventKey={comp}>{comparisonDescription(comp)}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
        <Form.Text className="text-muted">
            Include products with a <b>{props.attribute?.name.localized(null) ?? "selected attribute"}</b> that <b>{comparisonDescription(comparison)}</b> {formattedDate()}
        </Form.Text>
        <div>
            <Button className="me-3" variant="outline-secondary" size="sm" onClick={() => { props.onCancel() }}>Cancel</Button>
            <Button disabled={value === undefined} variant="outline-primary" size="sm" onClick={() => {
                if (value === undefined) { return }
                const dateString = dayjs(value).local().format("YYYY-MM-DD")
                props.onChange(dateString, comparison)
            }}>Add filter</Button>
        </div>
    </Form.Group>
}

export function SelectAttributeDateTime(props: SelectAttributeDateProps) {
    const [value, setValue] = useState<Date | undefined>()
    const [comparison, setComparison] = useState<Comparison>("==")
    const [showCalendar, setShowCalendar] = useState<boolean>(false)

    const timestamp = dayjs(value)
    const hours = timestamp.hour()
    const minutes = timestamp.minute()
    const formatted = _.padStart(`${hours}`, 2, "0") + ":" + _.padStart(`${minutes}`, 2, "0")

    const comparisonDescription: (comp: Comparison) => string = (comp) => {
        switch (comp) {
            case "==": return "is"
            case "<": return "is older than"
            case ">": return "is newer than"
            case "<=": return "is older than or same as"
            case ">=": return "is newer than or same as"
        }
    }
    const allComps: Comparison[] = ["==", "<", ">", "<=", ">="]
    const formattedDate: () => string = () => {
        if (value === undefined) {
            return "selected date"
        } else {
            const dateString = dayjs(value).local().format("YYYY-MM-DD HH:mm")
            return dateString
        }
    }

    return <Form.Group className="mb-3" controlId="formBasicEmail">
        <div className="mt-3">
            <Form.Text className="text-muted">
                Enter a time
            </Form.Text>
            <TimeField
                input={<Form.Control type="text" />}
                style={{ textAlign: "center" }}
                value={formatted} onChange={event => {
                    const newValue = event.target.value
                    const components = newValue.split(":")
                    const newTimestamp = dayjs(value ?? new Date())
                        .set("hour", parseInt(components[0]))
                        .set("minute", parseInt(components[1]))
                    setValue(newTimestamp.toDate())
                }} />

            {!showCalendar && <Button className="my-3" variant="outline-primary" size="sm" onClick={() => {
                setShowCalendar(true)
            }}>Select a date</Button>}

            {showCalendar && <Calendar date={value} onChange={date => {
                const existing = dayjs(value ?? new Date()).toISOString().split("T")

                const datePart = dayjs(date).local().format("YYYY-MM-DD")

                const newISOstring = [datePart, existing[1]].join("T")

                setValue(dayjs(newISOstring).toDate())
                setShowCalendar(false)
            }}
            />
            }
        </div>
        <Dropdown onSelect={(value) => { setComparison(value ?? undefined as any) }}>
            <Dropdown.Toggle size="sm" variant="outline-primary">Select comparison</Dropdown.Toggle>
            <Dropdown.Menu>
                {allComps.map(comp => {
                    return <Dropdown.Item key={comp} eventKey={comp}>{comparisonDescription(comp)}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
        <Form.Text className="text-muted">
            Include products with a <b>{props.attribute?.name.localized(null) ?? "selected attribute"}</b> that <b>{comparisonDescription(comparison)}</b> {formattedDate()}
        </Form.Text>
        <div>
            <Button className="me-3" variant="outline-secondary" size="sm" onClick={() => { props.onCancel() }}>Cancel</Button>
            <Button disabled={value === undefined} variant="outline-primary" size="sm" onClick={() => {
                if (value === undefined) { return }
                const dateString = dayjs(value).toISOString()
                props.onChange(dateString, comparison)
            }}>Add filter</Button>
        </div>
    </Form.Group>
}

export type LoyaltyPointMode = "item_based" | "basket_based"

interface AppliesToSelectorProps {
    appliesTo: AppliesTo
    appliesToChanged: (appliesTo: AppliesTo) => void
    productObserver: ProductObserver
    tagsObserver: TagObserver
    attributesObserver: AttributeObserver
    validation: boolean
    bundle?: boolean
    loyaltyPointMode?: LoyaltyPointMode
    type: DiscountOverallType
    showId: boolean
}

export interface AppliesTo {
    type: AppliesToType
    products: string[]
    tags: string[]
    attributes: AttributeSelection[]
}

export interface AttributeSelection {
    attributeId: string
    optionId: string
    comparison?: Comparison
}

export type AppliesToType = "basket" | "all" | "products" | "tags" | "attributes"

export function AppliesToSelectorCard(props: AppliesToSelectorProps) {
    return <Card className="my-4" border="primary">
        <Card.Body>
            <Card.Title>
                Applies to
            </Card.Title>
            <AppliesToSelector showId={true} type={props.type} validation={props.validation} loyaltyPointMode={props.loyaltyPointMode} appliesTo={props.appliesTo} appliesToChanged={props.appliesToChanged} productObserver={props.productObserver} tagsObserver={props.tagsObserver} attributesObserver={props.attributesObserver} />
        </Card.Body>
    </Card>
}

export function AppliesToSelector(props: AppliesToSelectorProps) {
    const [selectAttributeOption, setSelectAttributeOption] = useState<string | undefined>(undefined)
    const [showProductPickerModal, setShowProductPickerModal] = useState(false)

    function setProducts(value: string[]) {
        if (_.isEqual(value, props.appliesTo.products)) {
            return
        }
        const clone = _.clone(props.appliesTo)
        clone.products = value
        props.appliesToChanged(clone)
    }

    function setType(type: AppliesToType) {
        if (type === props.appliesTo.type) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.type = type
        props.appliesToChanged(clone)
    }

    function setTags(tags: string[]) {
        if (_.isEqual(tags, props.appliesTo.tags)) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.tags = tags
        props.appliesToChanged(clone)
    }

    function setAttributes(attributes: AttributeSelection[]) {
        if (_.isEqual(attributes, props.appliesTo.attributes)) {
            return
        }
        const clone = _.cloneDeep(props.appliesTo)
        clone.attributes = attributes
        props.appliesToChanged(clone)
    }

    return <Form validated={props.validation}>

        <FormGroup
            style={{ marginLeft: "0px", marginRight: "0px" }}
        >

            <div>
                {props.bundle !== true && props.loyaltyPointMode === "basket_based" && <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="Basket"
                    checked={props.appliesTo.type === "basket"}
                    onChange={() => { setType("basket") }}
                >

                </Form.Check>
                }
                {props.type === "coupon" && <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="Basket"
                    checked={props.appliesTo.type === "basket"}
                    onChange={() => { setType("basket") }}
                >

                </Form.Check>
                }
                {props.bundle !== true && (props.loyaltyPointMode === undefined && props.type !== "coupon") && <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="All products"
                    checked={props.appliesTo.type === "all"}
                    onChange={() => { setType("all") }}
                >

                </Form.Check>
                }
                <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="Specific products"
                    checked={props.appliesTo.type === "products"}
                    onChange={() => { setType("products") }}
                >

                </Form.Check>
                <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="Products with specific tags"
                    checked={props.appliesTo.type === "tags"}
                    onChange={() => { setType("tags") }}
                >

                </Form.Check>
                <Form.Check
                    type="radio"
                    id="appliesToSelector"
                    label="Products with specific attribute values"
                    checked={props.appliesTo.type === "attributes"}
                    onChange={() => { setType("attributes") }}
                >

                </Form.Check>
            </div>

            {(props.appliesTo.type !== "all" && props.appliesTo.type !== "basket") && <hr />}
            {props.appliesTo.type === "products" &&
                <div>
                    {props.appliesTo.products.length > 0 &&
                        <StripedTable>
                            <thead><tr><td colSpan={2}>
                                {props.bundle === true ? "Bundle must contain some of the products below" : "Apply discount for any of the products below"}
                            </td></tr></thead>
                            <tbody>
                                {props.appliesTo.products.map(key => {
                                    return <ProductListElement key={key} remove={key => {
                                        const clone = props.appliesTo.products.filter(element => { return element !== key })
                                        setProducts(clone)
                                    }} showId={props.showId} product={(props.productObserver.productsDict ?? {})[key]} />
                                })}
                            </tbody>
                        </StripedTable>
                    }
                    <Button onClick={() => setShowProductPickerModal(true)}>Select one or more products</Button>
                    {showProductPickerModal &&
                        <ModalPicker
                            elements={elementsForPicker(props)}
                            onCancel={() => setShowProductPickerModal(false)}
                            onSelect={(element) => {
                                const clone = [...props.appliesTo.products]
                                clone.push(element.id)
                                setProducts(clone)
                                setShowProductPickerModal(false)
                            }}
                            title="Select product"
                            entityName="product"
                            showSearch={true}
                            excludeIds={props.appliesTo.products}
                        />
                    }
                </div>
            }
            {props.appliesTo.type === "tags" &&
                <div>
                    {props.appliesTo.tags.length > 0 &&
                        <StripedTable>
                            <thead><tr><td>
                                {props.bundle === true ? "Bundle must contain products that has any of the tags below" : "Apply discount if the product has any of the tags below"}
                            </td></tr></thead>
                            <tbody>
                                {props.appliesTo.tags.map(key => {
                                    return <TagElement key={key} remove={key => {
                                        const clone = props.appliesTo.tags.filter(element => { return element !== key })
                                        setTags(clone)
                                    }} tag={(props.tagsObserver.tagsDict ?? {})[key]} />
                                })}
                            </tbody>
                        </StripedTable>
                    }
                    <DropdownButton title="Select one or more tags" id="a">
                        {(props.tagsObserver.tagsArray ?? []).map(tag => {
                            if (props.appliesTo.tags.includes(tag.tag)) {
                                return undefined
                            }

                            return <Dropdown.Item key={tag.tag} onClick={() => {
                                const clone = [...props.appliesTo.tags]
                                clone.push(tag.tag)
                                setTags(clone)
                            }} eventKey={tag.tag}>{tag.name.localized(LanguageCode.da)}</Dropdown.Item>
                        })}
                    </DropdownButton>
                </div>
            }
            {props.appliesTo.type === "attributes" &&
                <div>
                    {props.appliesTo.attributes.length > 0 &&
                        <StripedTable>
                            <thead><tr><td>
                                {props.bundle === true ? "Bundle must contain products that has the specified value for any of the attributes below" : "Apply discount if the product has the specified value for any of the attributes below"}
                            </td></tr></thead>
                            <tbody>
                                {props.appliesTo.attributes.map(key => {
                                    return <AttributeElement key={key.attributeId} remove={key => {
                                        const clone = props.appliesTo.attributes.filter(element => { return element.attributeId !== key })
                                        setAttributes(clone)
                                    }} attribute={(props.attributesObserver.attributesDict ?? {})[key.attributeId]} option={key.optionId} />
                                })}
                            </tbody>
                        </StripedTable>
                    }
                    {selectAttributeOption !== undefined ? (
                        (props.attributesObserver.attributesDict ?? {})[selectAttributeOption].type.options !== undefined ?
                            <SelectAttributeOption attribute={(props.attributesObserver.attributesDict ?? {})[selectAttributeOption]} optionSelected={(attributeId, optionId) => {
                                const clone = [...props.appliesTo.attributes]
                                clone.push({ attributeId: attributeId, optionId: optionId })
                                setAttributes(clone)
                                setSelectAttributeOption(undefined)
                            }} />
                            :
                            <SelectAttributeText attribute={(props.attributesObserver.attributesDict ?? {})[selectAttributeOption]} textSelected={(attributeId, optionId) => {
                                const clone = [...props.appliesTo.attributes]
                                clone.push({ attributeId: attributeId, optionId: optionId })
                                setAttributes(clone)
                                setSelectAttributeOption(undefined)
                            }} />
                    ) :
                        <div>
                            <DropdownButton title="Select one or more attributes" id="a">
                                {(props.attributesObserver.attributesArray ?? []).map(attribute => {
                                    // if (selectedAttributes.find(entry => { return entry.attributeId === attribute.id }) !== undefined) {
                                    //     return undefined
                                    // }
                                    if (attribute.type.options === undefined && attribute.type.text === undefined) {
                                        return undefined
                                    }

                                    return <Dropdown.Item disabled={attribute.preserveOnLineItem !== true} key={attribute.id} onClick={() => {
                                        setSelectAttributeOption(attribute.id)
                                    }} eventKey={attribute.id}>{`${attribute.name.localized(LanguageCode.da)}${(attribute.preserveOnLineItem !== true) ? " (enable 'Preserve on line items' to use this attribute)" : ""}`}</Dropdown.Item>
                                })}
                            </DropdownButton>
                            <br /><br />
                            <p>(Note that attributes must be preserved on line items in order to trigger discount rules.)</p>
                        </div>
                    }
                </div>
            }
        </FormGroup>
    </Form>
}

function elementsForPicker(props: AppliesToSelectorProps) {
    return (props.productObserver.productsArray ?? []).map(product => {
        return new ProductElement(product, props.showId)
    })
}
