import { isNil, isString } from "lodash"
import { L10nString, LanguageCode } from "../helpers/L10n"

export enum ProductAssetMimeType {
    documentPDF = "document/pdf",
    threeSixty = "application/orbitvu",
    imageJPEG = "image/jpeg",
    imageJPG = "image/jpg",
    imagePNG = "image/png",
    videoVimeo = "video/vimeo",
    videoYoutube = "video/youtube",
}

// Inspired by https://stackoverflow.com/questions/22544625/javascript-convert-youtube-vimeo-urls-into-embed-versions-for-use-on-a-forum
function convert_youtube(input: string): string {
    let result = input
    const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?(\S+)/g
    if (pattern.test(input)) {
        const replacement = `http://www.youtube.com/embed/$1`
        let output = input.replace(pattern, replacement)
        const indexOfQuery = output.indexOf("&") || output.indexOf("&amp;")
        let queryString: string | undefined = undefined
        if (indexOfQuery >= 0) {
            queryString = output.substring(indexOfQuery)
            output = output.substring(0, indexOfQuery)
        }

        // For start time, turn get param & into ?
        if (!isNil(queryString) && queryString.includes("t=")) {
            const tPattern = /t=\d+/g
            const match = queryString.match(tPattern)
            output += `?${match}`
        }
        result = output
    }
    return result
}

function convert_vimeo(input: string): string {
    const pattern = /(?:http?s?:\/\/)?(?!player\.)(?:www\.)?(?:vimeo\.com)\/(?!video)(\S+)/g
    if (pattern.test(input)) {
        const replacement = `https://player.vimeo.com/video/$1`
        return input.replace(pattern, replacement)
    }
    return input
}

export function convertToEmbedURL(input: string): string {
    let output = convert_youtube(input)
    output = convert_vimeo(output)
    return output
}

export function videoAssetMimeTypeFromURL(string: string): ProductAssetMimeType | null {
    const normalized = string.toLowerCase()
    if (normalized.includes("youtube")) {
        return ProductAssetMimeType.videoYoutube
    } else if (normalized.includes("vimeo")) {
        return ProductAssetMimeType.videoVimeo
    }

    return null
}

export function imageAssetMimeTypeFromFile(file: any): ProductAssetMimeType | null {
    const normalized = file.type

    switch (normalized) {
        case "image/jpeg":
            return ProductAssetMimeType.imageJPEG

        case "image/jpg":
            return ProductAssetMimeType.imageJPG

        case "image/png":
            return ProductAssetMimeType.imagePNG
    }

    return null
}

export function documentAssetMimeTypeFromFile(file: any): ProductAssetMimeType | null {
    const normalized = file.type

    switch (normalized) {
        case "application/pdf":
            return ProductAssetMimeType.documentPDF
    }

    return null
}

export class ProductAsset {
    public static fromJSON(json: any): ProductAsset | null {
        if (isNil(json)) {
            return null
        }

        if (isNil(json.url)) {
            return null
        }

        if (!isString(json.mime_type)) {
            return null
        }

        if (!enumerationContains(ProductAssetMimeType, json.mime_type)) {
            return null
        }

        const asset = new ProductAsset(json.url, json.mime_type)

        if (!isNil(json.name)) {
            asset.name = new L10nString(json.name)
        }

        return asset
    }

    url: string
    mimeType: ProductAssetMimeType
    name?: L10nString

    constructor(url: string, mimeType: ProductAssetMimeType) {
        this.url = url
        this.mimeType = mimeType
    }

    removeLocalization(language: LanguageCode) {
        if (!isNil(this.name)) {
            this.name.removeLocalization(language)
        }
    }

    isDocumentAsset(): boolean {
        switch (this.mimeType) {
            case ProductAssetMimeType.documentPDF:
                return true

            case ProductAssetMimeType.imageJPG:
            case ProductAssetMimeType.imageJPEG:
            case ProductAssetMimeType.imagePNG:
            case ProductAssetMimeType.videoVimeo:
            case ProductAssetMimeType.videoYoutube:
            case ProductAssetMimeType.threeSixty:
                return false
        }
    }

    isImageAsset(): boolean {
        switch (this.mimeType) {
            case ProductAssetMimeType.imageJPG:
            case ProductAssetMimeType.imageJPEG:
            case ProductAssetMimeType.imagePNG:
                return true

            case ProductAssetMimeType.documentPDF:
            case ProductAssetMimeType.videoVimeo:
            case ProductAssetMimeType.videoYoutube:
            case ProductAssetMimeType.threeSixty:
                return false
        }
    }

    isVideoAsset(): boolean {
        switch (this.mimeType) {
            case ProductAssetMimeType.documentPDF:
            case ProductAssetMimeType.imageJPG:
            case ProductAssetMimeType.imageJPEG:
            case ProductAssetMimeType.imagePNG:
            case ProductAssetMimeType.threeSixty:
                return false

            case ProductAssetMimeType.videoVimeo:
            case ProductAssetMimeType.videoYoutube:
                return true
        }
    }

    is360ImageAsset(): boolean {
        switch (this.mimeType) {
            case ProductAssetMimeType.documentPDF:
            case ProductAssetMimeType.imageJPG:
            case ProductAssetMimeType.imageJPEG:
            case ProductAssetMimeType.imagePNG:
            case ProductAssetMimeType.videoVimeo:
            case ProductAssetMimeType.videoYoutube:
                return false

            case ProductAssetMimeType.threeSixty:
                return true
        }
    }


    toJSON(): any {
        const json: any = {
            url: this.url,
            mime_type: this.mimeType
        }

        if (!isNil(this.name)) {
            json.name = this.name.json()
        }

        return json
    }
}

function enumerationContains(enumeration: any, value: any): boolean {
    return Object.keys(enumeration).filter(key => !isFinite(Number(key))).filter(key => enumeration[key] === value).length > 0
}
