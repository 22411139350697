import * as React from "react"
import { isNil } from "lodash"
import { Col, Row } from "./wrappers"
import DocumentDropControl from "./DocumentDropControl"
import { Role } from "../config/role"
import { ProductAsset, documentAssetMimeTypeFromFile, ProductAssetMimeType } from "../models/ProductAsset"
import { L10nFormControl } from "./L10nFormControl"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { Button } from "../components/wrappers"
import { LabelledControl } from "./protected/ProductEdit"
import { Form } from "react-bootstrap"

interface URLAssetEditComponentProps {
    productId: string,
    role: Role,
    setLanguage: (language: LanguageCode | null) => void,
    currentLanguage: () => LanguageCode | null,
    resolveLanguages: () => LanguageCode[]
    addAsset: (asset: ProductAsset) => void
    mimeType: ProductAssetMimeType
}

interface URLAssetEditComponentState {
    name: L10nString | null
    url: string | null
}

const pushid = require("pushid")

export class URLAssetEditComponent extends React.Component<URLAssetEditComponentProps, URLAssetEditComponentState> {

    initialState: URLAssetEditComponentState = {
        name: null,
        url: null
    }

    // BG: Couldn't find a way to reset the dropzone component.
    // Incremented on succesfull image upload to make react think that we have a new dropzone component.
    dropzoneComponentKey = 1

    constructor(props: URLAssetEditComponentProps) {
        super(props)

        this.state = this.initialState
    }

    isAddDisabled(): boolean {
        return isNil(this.state.name) || isNil(this.state.url)
    }

    handleNameChange(name: L10nString | null) {
        this.setState({ name: name })
    }

    handleURLChange(url: string) {
        this.setState({ url: url })
    }

    addAsset() {
        if (isNil(this.state.name) || isNil(this.state.url)) {
            return
        }

        this.dropzoneComponentKey += 1
        this.setState(this.initialState)

        const asset = new ProductAsset(this.state.url, this.props.mimeType)
        asset.name = this.state.name

        this.props.addAsset(asset)
    }

    render() {
        return (
            <div>

                <LabelledControl label="Name">
                    <L10nFormControl
                        l10n={this.state.name}
                        type="text"
                        language={this.props.currentLanguage()}
                        style={{ resize: "vertical" }}
                        onLocalizationChanged={(l10n) => { this.handleNameChange(l10n) }}
                    />

                </LabelledControl>

                <LabelledControl label="URL">
                    <Form.Control
                        value={this.state.url ?? ""}
                        type="text"
                        style={{ resize: "vertical" }}
                        onChange={event => { this.handleURLChange(event.target.value) }}
                    />

                </LabelledControl>

                <LabelledControl>
                    <Button
                        variant="success"
                        disabled={this.isAddDisabled()}
                        onClick={() => { this.addAsset() }}
                    >Add
                    </Button>

                </LabelledControl>
            </div>
        )
    }
}
