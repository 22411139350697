import * as React from "react"
import {
    auth,
    logout,
    signInWithApple,
    signInWithMicrosoft
} from "../helpers/auth"
import { useLocation, useNavigate } from "react-router"
import { firestore } from "../config/constants"
import { Col, Container, Row } from "react-bootstrap"
import * as _ from "lodash"
import { useAppContext } from "../AppLayout"
import { UserCredential, User } from "firebase/auth"
import { doc, onSnapshot } from "firebase/firestore"

const useQuery = () => new URLSearchParams(useLocation().search)

function setErrorMsg(error: Error) {
    return {
        registerError: error.message,
        loading: false
    }
}

export interface RegisterProps {
    resolveRole(userId: String): Promise<void>
}

async function verifyInvite(account: string, token: string) {
    const url = process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE + `/verifyPendingInvite?account_id=${encodeURIComponent(account)}&token=${encodeURIComponent(token)}`
    const response = await window.fetch(url)
    switch (response.status) {
        case 204:
            return
        case 410:
            throw new Error(`Invite with token ${token} has expired`)
        default:
            throw new Error(`No pending invite with token ${token} found`)
    }
}

async function acceptInvite(account: string, token: string, user: User) {
    const url = process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE + `/verifyPendingInvite?account_id=${encodeURIComponent(account)}&token=${encodeURIComponent(token)}&accept=true&uid=${encodeURIComponent(user.uid)}`
    const response = await window.fetch(url)
    switch (response.status) {
        case 204:
            return
        case 410:
            throw new Error(`Invite with token ${token} has expired`)
        default:
            throw new Error(`No pending invite with token ${token} found`)
    }
}

type SignUpMethods = "email" | "apple" | "microsoft"

interface RegisterState {
    loading: boolean
    existingEmail?: string
    email?: string
    token?: string
    password?: string
    registerError: string | null
    allowedMethods: SignUpMethods[]
}

function Register() {
    const query = useQuery()
    const appContext = useAppContext()
    const navigate = useNavigate()
    return <WrappedRegister resolveRole={appContext.resolveRole} email={query.get("email")} token={query.get("token")} methods={query.get("methods")} accountId={query.get("account_id")} navigateToRoot={() => navigate("/")} />
}

interface WrappedRegisterProps extends RegisterProps {
    email: string | null
    token: string | null
    methods: string | null
    accountId: string | null
    navigateToRoot: () => void
}

class WrappedRegister extends React.Component<WrappedRegisterProps, RegisterState> {
    constructor(props: WrappedRegisterProps) {
        super(props)
        this.state = {
            registerError: null,
            loading: false,
            allowedMethods: ["email", "apple", "microsoft"]
        }
    }

    async componentDidMount() {
        await logout()

        this.extractQueryParams()
    }

    extractQueryParams = () => {
        const state: any = {}

        if (this.props.email) {
            state["existingEmail"] = decodeURIComponent(this.props.email)
        }
        if (this.props.token) {
            state["token"] = this.props.token
        }

        if (this.props.methods) {
            const methods: SignUpMethods[] = []
            for (const method of this.props.methods.split(",")) {
                if (method === "email") {
                    methods.push("email")
                } else if (method === "apple") {
                    methods.push("apple")
                } else if (method === "microsoft") {
                    methods.push("microsoft")
                }
            }
            state["allowedMethods"] = methods
        } else {
            state["allowedMethods"] = ["email", "apple", "microsoft"]
        }

        this.setState(state)
    }

    signUpWithMicrosoft = async (e: any) => {
        e.preventDefault()
        await this.signIn("microsoft")
    }

    signUpWithApple = async (e: any) => {
        e.preventDefault()
        await this.signIn("apple")
    }

    signIn = async (type: SignUpMethods) => {
        const account = this.props.accountId
        if (typeof account === "undefined" || account === null || account === "") {
            this.setState(setErrorMsg(new Error("Missing account id in URL")))
            return
        }

        const email = this.state.email || this.state.existingEmail
        const password = this.state.password
        const token = this.state.token

        try {
            if (type === "email") {
                if (!email) { throw new Error("Missing e-mail address") }
                if (!password) { throw new Error("Missing password") }
            }
            if (!token) { throw new Error("Missing token") }
        } catch (error: any) {
            this.setState(setErrorMsg(error))
            return
        }

        this.setState({ loading: true })

        try {
            await verifyInvite(account, token)

            let credential: UserCredential | undefined
            if (type === "email") {
                credential = await auth(email!, password!)
            } else if (type === "microsoft") {
                credential = await signInWithMicrosoft()
            } else if (type === "apple") {
                credential = await signInWithApple()
            }
            if (!credential) {
                throw new Error("Internal error when creating user. Please try again.")
            }
            const user = credential.user
            if (user === null) {
                throw new Error("Internal error when creating user. Please try again.")
            }

            // Then kindly accept the invitation
            await acceptInvite(account, token, user)

            // Wait until the invitation is accepted by awaiting the existence
            // of the account id in either 'accounts' or 'shops' for the profile.
            this.awaitAcceptance(user, account)
        } catch (error: any) {
            this.setState(setErrorMsg(error))
        }
    }

    handleSubmit = async (e: any) => {
        e.preventDefault()
        await this.signIn("email")
    }

    private awaitAcceptance(user: User, acceptAccountId: string) {
        let unsubscribe: () => void = () => {}
        unsubscribe = onSnapshot(doc(firestore, `profiles/${user.uid}`), async snapshot => {
            const data = snapshot.data() ?? {}
            const accounts: string[] = data.accounts ?? []
            const shops = data.shops ?? {}
            let done = false
            if (accounts.includes(acceptAccountId)) {
                done = true
            }
            if (!_.isNil(shops[acceptAccountId])) {
                done = true
            }
            if (done) {
                unsubscribe()
                await this.props.resolveRole(user.uid)

                this.props.navigateToRoot()    
            }
        })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <h1>Sign up</h1>
                        {
                            this.state.loading
                                ?
                                <div> Creating user, accepting invitation and assigning access rights. Please be patient as it may take a while... </div>
                                : (
                                    <div>
                                        {this.state.allowedMethods.includes("email") ? (
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    {
                                                        this.state.existingEmail
                                                            ?
                                                            <input className="form-control" placeholder="Email" value={this.state.existingEmail} disabled={true} />
                                                            :
                                                            (
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                    value={this.state.email || ""}
                                                                    onChange={(e: any) => { this.setState({ email: e.target.value }) }}
                                                                    type="email"
                                                                    autoComplete="off"
                                                                    autoCorrect="off"
                                                                    autoCapitalize="off"
                                                                    spellCheck="false"
                                                                />
                                                            )
                                                    }
                                                </div>
                                                <br />
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" className="form-control" placeholder="Password" value={this.state.password || ""} onChange={(e: any) => { this.setState({ password: e.target.value }) }} />
                                                </div>
                                                <br />
                                                <button type="submit" className="btn btn-primary">Sign up</button>
                                            </form>
                                        )
                                            : null
                                        }

                                        {(this.state.allowedMethods.includes("email") && this.state.allowedMethods.length > 1) ?
                                            (
                                                <div>
                                                    <br />
                                                    --- or ---
                                                    <br />
                                                </div>
                                            )
                                            : null
                                        }

                                        {this.state.allowedMethods.includes("apple") ?
                                            (
                                                <div>
                                                    <br />
                                                    <button className="btn btn-default" onClick={this.signUpWithApple} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px" }}><img alt="Apple logo" src="/backoffice/apple.svg" style={{ height: "44px" }} /> Sign up with Apple&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                                                    <br />
                                                </div>
                                            ) : null}

                                        {this.state.allowedMethods.includes("microsoft") ? (
                                            <div>
                                                <br />
                                                <button className="btn btn-default" onClick={this.signUpWithMicrosoft} style={{ width: "300px", background: "#000000", color: "#FFFFFF", textShadow: "inherit", fontSize: "19px", padding: "0px", border: "0px", height: "44px" }}><img alt="Microsoft logo" src="/backoffice/microsoft.svg" style={{ height: "20px" }} />&nbsp;&nbsp;&nbsp;Sign up with Microsoft</button>
                                                <br />
                                            </div>
                                        ) : null}

                                        {
                                            this.state.registerError && (
                                                <div>
                                                    <br />
                                                    <br />
                                                    <div className="alert alert-danger" role="alert">
                                                        <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true" />
                                                        <span className="sr-only">Error:</span>
                                                        &nbsp;{this.state.registerError}
                                                    </div>
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Register
