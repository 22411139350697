import { firebaseAuth } from "../config/constants"
import { OAuthProvider, UserCredential, User, signInWithEmailAndPassword, sendPasswordResetEmail, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth"

export function auth(email: string, pw: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(firebaseAuth(), email, pw)
}

export async function token(): Promise<string | null> {
    const user = firebaseAuth().currentUser
    if (user) {
        return await user.getIdToken()
    }
    return null
}

export function userId(): string | null {
    const user = firebaseAuth().currentUser
    if (user) {
        return user.uid
    }
    return null
}

export function currentUser(): User | null {
    return firebaseAuth().currentUser || null
}

export function logout() {
    return firebaseAuth().signOut()
}

export function login(email: string, pw: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(firebaseAuth(), email, pw)
}

export function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(firebaseAuth(), email)
}

export function signInWithMicrosoft(): Promise<UserCredential> {
    const provider = new OAuthProvider("microsoft.com")
    provider.setCustomParameters({
        // Ask user to select an account to use.
        prompt: 'select_account',
    })

    return signInWithPopup(firebaseAuth(), provider)
}

export function signInWithApple(): Promise<UserCredential> {
    const provider = new OAuthProvider("apple.com")
    return signInWithPopup(firebaseAuth(), provider)
}
