//
//  GroupSelection.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 13/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { ProductGroup } from "../models/Product"
import { LanguageCode } from "../helpers/L10n"
import { Dropdown } from "react-bootstrap"

interface GroupSelectionProps {
    account: string
    selectedGroup: string | null
    currentLanguage: LanguageCode | null
    groups: ProductGroup[]
    onChange: (group: ProductGroup | null) => void
}
export class GroupSelection extends React.Component<GroupSelectionProps, any> {

    // Properties

    // Component

    constructor(props: GroupSelectionProps) {
        super(props)
    }

    render() {
        return (
            <>
                {
                    this.props.groups ? (
                        <Dropdown
                            onSelect={a => {
                                const group = this.props.groups.find(g => { return g.group === a })
                                this.props.onChange(group ?? null)
                            }}
                        >
                            <Dropdown.Toggle variant="outline-primary" size="sm">
                                {this.props.selectedGroup ? (this.props.groups.find(g => { return g.group === this.props.selectedGroup })?.name.localized(null) ?? this.props.selectedGroup) : "Select a product group"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {this.props.groups.map((group) => {
                                    return <Dropdown.Item active={group.group === this.props.selectedGroup} id={group.group} key={group.group} eventKey={group.group}>{group.name.localized(this.props.currentLanguage)}</Dropdown.Item>
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : <div>Loading..</div>
                }
            </>
        )
    }
}
