import * as React from "react"
import { Globals } from "../helpers/globals"
import { Language, LanguageCode } from "../helpers/L10n"
import { DropdownButton, InputGroup } from "./wrappers"
import { Dictionary, isNil } from "lodash"
import { Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCircleMinus } from "@fortawesome/free-solid-svg-icons"
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons"

export function localize(l10nString: any, language: any) {
    if (!l10nString) { return "" }
    if (typeof (l10nString) !== "object") {
        return l10nString
    }
    return l10nString[language] || l10nString["en"] || l10nString[Object.keys(l10nString)[0]]
}

interface ConfirmDeleteIconProps {
    className?: string
    message: string
    onDelete(): void
    deletionText?: string
    variant?: string
}

export class ConfirmDeleteIcon extends React.Component<ConfirmDeleteIconProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onDelete()
        }
    }

    render() {
        return <FontAwesomeIcon style={{ color: "#dc3545" }} icon={faCircleMinus} className={this.props.className} onClick={(event) => { event.preventDefault(); event.stopPropagation(); this.onClick() }}></FontAwesomeIcon>
    }
}


interface LanguagePickerProps {
    resolveLanguages: () => LanguageCode[]
    initialLanguage: LanguageCode | null
    hideRemove?: any
    onChange?: (language: LanguageCode | null) => void
    onRemove?: (language: LanguageCode) => void
    typeName: string
    className?: string
}

interface LanguagePickerState {
    currentLanguage: LanguageCode | null
    languages: Dictionary<Language>
    localizedLanguages: LanguageCode[]
    showAlert: boolean
    hideRemove: boolean
}

export class LanguagePicker extends React.Component<LanguagePickerProps, LanguagePickerState> {
    _mounted: boolean = false
    constructor(props: LanguagePickerProps) {
        super(props)

        this.state = {
            currentLanguage: props.initialLanguage || null,
            languages: {},
            localizedLanguages: [],
            showAlert: false,
            hideRemove: props.hideRemove || false
        }
    }

    unlocalized = (): LanguageCode[] => {
        const unloc: LanguageCode[] = []
        for (const key in this.state.languages) {
            const languageCode: LanguageCode = LanguageCode[key]
            if (this.state.localizedLanguages.includes(languageCode)) { continue }
            unloc.push(languageCode)
        }
        return unloc.sort()
    }

    updateLanguage(props: LanguagePickerProps) {
        const localizedLanguages = props.resolveLanguages()
        const initialLanguage = props.initialLanguage
        const currentLanguage = this.state.currentLanguage
        if (!isNil(initialLanguage)) {
            this.selectLanguage(initialLanguage)
        } else if (localizedLanguages.length > 0 && (isNil(currentLanguage) || !localizedLanguages.includes(currentLanguage))) {
            this.selectLanguage(localizedLanguages[0])
        } else if (localizedLanguages.length === 0 && !isNil(currentLanguage)) {
            this.selectLanguage(null)
        }
        this.setState({ localizedLanguages: localizedLanguages })
    }

    UNSAFE_componentWillReceiveProps(nextProps: LanguagePickerProps) {
        this.updateLanguage(nextProps)
    }

    async componentDidMount() {
        this._mounted = true
        const languages = await Globals.shared.getLanguages()
        if (!this._mounted) {
            return
        }
        this.updateLanguage(this.props)
        this.setState({ languages: languages })
    }
    componentWillUnmount() {
        this._mounted = false
    }

    selectLanguage = (language: LanguageCode | null) => {
        if (language === this.state.currentLanguage) { return }
        this.setState({ currentLanguage: language })
        if (this.props.onChange) {
            this.props.onChange(language)
        }
    }

    removeLanguage = (language: LanguageCode | null) => {
        if (!language) { return }
        if (this.props.onRemove) {
            this.props.onRemove(language)
        }
    }

    title(): string {
        const defaultTitle = "Localize"
        if (!this.state.languages) {
            return defaultTitle
        }
        if (this.state.currentLanguage === null) {
            return defaultTitle
        }
        const language = this.state.currentLanguage
        if (this.state.languages[language]) {
            return this.state.languages[language].name.localized(LanguageCode.en)
        }
        return defaultTitle
    }

    render() {
        return <DropdownButton
            key="a"
            className={this.props.className}
            variant="outline-primary"
            title={this.title()}
            id="localize"
            onSelect={(event: any) => { this.selectLanguage(LanguageCode[event as string]) }}
        >
            {this.state.localizedLanguages.length > 0 && <span>
                <Dropdown.Header
                    style={{ minWidth: "14em" }}

                >Localized</Dropdown.Header>
                {this.state.localizedLanguages.map(language => {
                    if (this.state.languages && this.state.languages[language]) {
                        const languageName = this.state.languages[language].name.localized(LanguageCode.en)
                        const selected = this.state.currentLanguage === language
                        return <Dropdown.Item style={{minWidth: "14em"}} className="triggerHover" key={language} eventKey={language}><FontAwesomeIcon style={{ visibility: selected ? "visible" : "hidden", paddingRight: 5 }} icon={faCheck} />{languageName}&nbsp;<span className="float-sm-end"><ConfirmDeleteIcon className="hovers" message={`Are you sure you wish to remove the localization for: ${languageName}`} onDelete={() => { this.removeLanguage(language) }}></ConfirmDeleteIcon></span></Dropdown.Item>
                    }
                    return null
                })}
                < Dropdown.Divider />
            </span>}
            < Dropdown.Header >{this.state.localizedLanguages.length > 0 ? "Not Localized" : "Add Localization"} </Dropdown.Header>
            {
                this.unlocalized().map(language => {
                    if (this.state.languages && this.state.languages[language]) {
                        return <Dropdown.Item  style={{minWidth: "14em"}} className="triggerHover" key={language} eventKey={language}><FontAwesomeIcon style={{ visibility: "hidden", paddingRight: 5 }} icon={faCheck} /> {this.state.languages[language].name.localized(LanguageCode.en)}&nbsp;<span className="float-sm-end"><FontAwesomeIcon style={{ verticalAlign: "revert-layer", color: "#0d6efd" }} className="hovers" icon={faPlusSquare} /></span></Dropdown.Item>
                    }
                    return null
                })
            }
        </DropdownButton>
    }
}
