import { Cashier } from "../../../models/Cashier"

export function ascendingByCashierFullName(lhs: Cashier, rhs: Cashier) {
    if (lhs.fullName === null || rhs.fullName === null) {
        return 0
    }

    if (lhs.fullName > rhs.fullName) {
        return 1
    }

    if (lhs.fullName < rhs.fullName) {
        return -1
    }

    return 0
}
