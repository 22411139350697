export class EmailValidation {

    static valid(text: string): boolean {
        // https://en.wikipedia.org/wiki/Email_address
        const containsAtChar = text.indexOf("@") !== -1
        // local part does not allow certain special characters that are only allowed in certain conditions "(),:;<>@[\] - see wiki page for details
        const localPart = text.substr(0, text.indexOf("@"))
        const localPartValid = containsAtChar && localPart.match(/^[a-z0-9!#$%&'*+-/=?^_`{|}~.]+$/i) !== null
        // domain part does not allow internationalized domain names and ip literals in quare brackets
        const domainPart = text.length - 1 > text.indexOf("@") ? text.substr(text.indexOf("@") + 1, text.length - 1) : ""
        const domainPartValid = containsAtChar && domainPart.match(/^[a-z0-9]+([-.]*[a-z0-9]+)*$/i) !== null

        return localPartValid && domainPartValid
    }
}