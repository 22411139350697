import * as React from "react"

import { DropdownButton, MenuItem, Button, Card, Modal } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import { ExportIntegrationEdit, ExportIntegrationFilter } from "./ExportIntegrationEdit"
import { RoleRouterProps, withRoleRouter } from '../../../routes';
import { child, get, off, onValue, remove, set } from "firebase/database"

interface ExportIntegrationDeleteRequest {
    type: string
    id: string
}

interface ExportIntegrationsState {
    editIntegrationId?: string
    addIntegrationType?: ExportIntegrationType
    configurations?: any
    deleteRequest?: ExportIntegrationDeleteRequest
}

export enum ExportIntegrationType {
    newsletter = "newsletter",
    products = "products",
    registerCloseStatements = "register_close_statements",
    sales = "sales",
    stockEvents = "stock_events",
    externalOrderEvents = "external_order_events",
    checkouts = "checkouts",
    outgoingOrders = "outgoing_orders",
    depositBoxReconciliations = "deposit_box_reconciliations",
    depositBoxTransactions = "deposit_box_transactions",
    stockReplenishmentLineUpdates = "stock_replenishment_line_updates",
}

export function exportIntegrationName(type: ExportIntegrationType): string {
    switch (type) {
        case ExportIntegrationType.products:
            return "Product change"
        case ExportIntegrationType.newsletter:
            return "Newsletter signup request"
        case ExportIntegrationType.registerCloseStatements:
            return "Register close statement"
        case ExportIntegrationType.sales:
            return "Sale"
        case ExportIntegrationType.stockEvents:
            return "Stock event"
        case ExportIntegrationType.externalOrderEvents:
            return "External order event"
        case ExportIntegrationType.checkouts:
            return "Checkout"
        case ExportIntegrationType.outgoingOrders:
            return "Outgoing order"
        case ExportIntegrationType.depositBoxReconciliations:
            return "Deposit box reconciliations"
        case ExportIntegrationType.depositBoxTransactions:
            return "Deposit box transactions"
        case ExportIntegrationType.stockReplenishmentLineUpdates:
            return "Stock replenishment line updates"
    }
}

class ExportIntegrations extends React.Component<RoleRouterProps, ExportIntegrationsState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            addIntegrationType: undefined
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationConfigurationRef = child(accountRef, "configuration/export_integrations")
        const integrationConfigurations = (await get(integrationConfigurationRef)).val()
        this.setState({ configurations: integrationConfigurations })

        onValue(integrationConfigurationRef, snap => {
            this.setState({ configurations: snap.val() })
        })
    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationConfigurationRef = child(accountRef, "configuration/export_integrations")
        off(integrationConfigurationRef)
    }

    availableIntegrations(): ExportIntegrationType[] {
        return [
            ExportIntegrationType.sales,
            ExportIntegrationType.registerCloseStatements,
            ExportIntegrationType.stockEvents,
            ExportIntegrationType.products,
            ExportIntegrationType.newsletter,
            ExportIntegrationType.externalOrderEvents,
            ExportIntegrationType.checkouts,
            ExportIntegrationType.outgoingOrders,
            ExportIntegrationType.depositBoxReconciliations,
            ExportIntegrationType.depositBoxTransactions,
            ExportIntegrationType.stockReplenishmentLineUpdates
        ]
    }

    filtersForIntegrationType(type: ExportIntegrationType): _.Dictionary<ExportIntegrationFilter> | undefined {
        switch (type) {
            case ExportIntegrationType.stockEvents: {
                const stockCountResetFilter = ExportIntegrationFilter.stockCountResetFilter()
                return { [stockCountResetFilter.path]: stockCountResetFilter }
            }
            case ExportIntegrationType.sales: {
                const skipVoidedFilter = ExportIntegrationFilter.skipVoidedSalesFilter()
                const skipReturnsFilter = ExportIntegrationFilter.skipReturnsFilter()
                const ecomOnlyFilter = ExportIntegrationFilter.ecomOnlyFilter()
                return {
                    [skipVoidedFilter.path]: skipVoidedFilter,
                    [skipReturnsFilter.path]: skipReturnsFilter,
                    [ecomOnlyFilter.path]: ecomOnlyFilter
                }
            }
            default:
                return undefined
        }
    }

    async deleteIntegration(deleteRequest?: ExportIntegrationDeleteRequest) {
        if (deleteRequest === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationRef = child(child(child(accountRef, "configuration/export_integrations"), deleteRequest.type), deleteRequest.id)
        await set(child(integrationRef, "deactivated"), true)

        this.setState({ deleteRequest: undefined })
    }

    async reactivate(integrationId: string, integrationType: string) {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationRef = child(child(child(accountRef, "configuration/export_integrations"), integrationType), integrationId)
        await remove(child(integrationRef, "deactivated"))
    }

    renderExistingIntegrations() {
        const configurations = this.state.configurations || {}
        return (
            <div>
                {
                    Object.keys(configurations).map((key: string) => {
                        const integrations = configurations[key]
                        return (
                            <Card className="my-4" key={key}>
                                <Card.Header>
                                    {exportIntegrationName(key as ExportIntegrationType)} web hooks
                                </Card.Header>

                                <Card.Body>
                                    <StripedTable>
                                        <thead>
                                            <tr>
                                                <th>Web hook id</th>
                                                <th>Web hook name</th>
                                                <th>Endpoint</th>
                                                <th>Edit</th>
                                                <th>Deactivate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(integrations).map((integrationKey: string) => {
                                                    const integration = integrations[integrationKey]
                                                    const integrationName = integration.name || "-"
                                                    const deactivated = integration.deactivated || false
                                                    const showEdit = (integration.delivery || {}).type === "web_hook"
                                                    let endpoint = "-"
                                                    if ((integration.delivery || {}).type === "web_hook") {
                                                        endpoint = ((integration.delivery || {}).parameters || {}).url
                                                    } else if ((integration.delivery || {}).type === "email") {
                                                        const recipients: string[] = ((integration.delivery || {}).parameters || {}).recipients || []
                                                        endpoint = `(E-mail to: ${recipients.join(", ")})`
                                                    }

                                                    return (
                                                        <tr
                                                            key={integrationKey}
                                                            onClick={() => {
                                                                if (deactivated) { return }
                                                                this.props.router.navigate(`/export_integration/${key}/${integrationKey}`)
                                                            }}
                                                        >
                                                            <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationKey}</td>
                                                            <td style={{ color: deactivated ? "#BBBBBB" : "#333333" }}>{integrationName}</td>
                                                            <td style={{ wordWrap: "break-word", wordBreak: "break-all", color: deactivated ? "#BBBBBB" : "#333333" }}>{endpoint}</td>
                                                            <td className="narrow">
                                                                {
                                                                    showEdit ?
                                                                        <Button disabled={deactivated} variant="success" onClick={(event) => { event.stopPropagation(); this.setState({ editIntegrationId: integrationKey, addIntegrationType: key as ExportIntegrationType }) }}>Edit</Button>
                                                                        : null
                                                                }
                                                            </td>
                                                            <td className="narrow">
                                                                {deactivated ?
                                                                    <Button variant="warning" onClick={async (event) => { event.stopPropagation(); await this.reactivate(integrationKey, key) }}>Reactivate</Button>
                                                                    :
                                                                    <Button variant="danger" onClick={(event) => { event.stopPropagation(); this.setState({ deleteRequest: { id: integrationKey, type: key } }) }}>X</Button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </StripedTable>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </div>
        )
    }

    editedIntegration(): any | undefined {
        const integrationType = this.state.addIntegrationType
        const integrationId = this.state.editIntegrationId
        const configurations = this.state.configurations
        if (integrationType === undefined) { return }
        if (integrationId === undefined) { return }
        if (configurations === undefined) { return }
        return (configurations[integrationType] || {})[integrationId]
    }

    render() {
        return (
            <div>
                {
                    this.state.addIntegrationType ? (
                        <ExportIntegrationEdit
                            role={this.props.role}
                            integrationType={this.state.addIntegrationType!}
                            existingIntegrationId={this.state.editIntegrationId}
                            existingIntegration={this.editedIntegration()}
                            editComplete={() => {
                                this.setState({ addIntegrationType: undefined, editIntegrationId: undefined })
                            }}
                            availableKnownFilters={this.filtersForIntegrationType(this.state.addIntegrationType)}
                        />
                    ) : (
                        <DropdownButton
                            variant="primary"
                            title="Add web hook"
                            id="add_integration"
                            onSelect={(event: any) => {
                                this.setState({ addIntegrationType: event as ExportIntegrationType })
                            }}
                        >
                            {
                                this.availableIntegrations().map(integrationKey => {
                                    return <MenuItem key={integrationKey} eventKey={integrationKey}>Add {exportIntegrationName(integrationKey).toLowerCase() || "-"} integration</MenuItem>
                                })
                            }
                        </DropdownButton>
                    )
                }

                {
                    this.state.deleteRequest ? (
                        <Modal show={true} key="b">
                            <Modal.Header>
                                <Modal.Title>Deactivate web hook</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>Are you certain that you wish to deactivate the web hook?</Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ deleteRequest: undefined }) }}>Cancel</Button>
                                <Button variant="danger" onClick={async () => { await this.deleteIntegration(this.state.deleteRequest) }}>Deactivate</Button>
                            </Modal.Footer>
                        </Modal>
                    ) : null
                }

                <br /><br /><br />

                {this.renderExistingIntegrations()}

            </div>
        )
    }
}

export default withRoleRouter(ExportIntegrations)
