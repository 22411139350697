import * as _ from "lodash";
import '../styles.css';
import React, { useEffect, useState } from "react";
import { PageState } from "../../../../components/PageState";
import { Button, Card, Col, FormControl, FormGroup, Row } from "../../../../components/wrappers";
import { EmailTemplate, EmailTemplateLine } from "../../../../models/email_template";
import { RoleRouterProps, withRoleRouterFunc } from "../../../../routes";
import { DraggableWrapper } from "../DraggableWrapper";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { LineItem } from "./LineItem";
import { Form } from "react-bootstrap";
import { LineEdit } from "./LineEdit";
import { ComposeEmail } from "../GiftcardTemplateEditor/EmailRecieved";
import { getFunctions, httpsCallable, HttpsCallableResult } from "firebase/functions";

function EmailTemplateEditor(props: RoleRouterProps) {
    const accountId = props.role.account_id
    const typeId = props.router.params.typeId
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>()
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isPublishing, setIsPublishing] = useState<boolean>(false)
    const [isModalShowing, setIsShowingModal] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [selectedLineItem, setSelectedLineItem] = useState<EmailTemplateLine>()
    const [isShowingAddLine, setIsShowingAddLine] = useState<boolean>(false)

    useEffect(() => {
        fetchTemplatePromise(accountId, typeId).then(value => {
            const data = (value.data as any)
            const emailTemplate = EmailTemplate.fromJSON(data)
            setEmailTemplate(emailTemplate)
        }).catch(() => {
            const template = new EmailTemplate([])
            setEmailTemplate(template)
        }).finally(() => setIsLoading(false))
    }, [accountId, typeId])
    return (
        <PageState typeName="email template" dirty={isDirty} loading={isLoading} publishing={isPublishing} submit_action={() => submit(accountId, typeId, emailTemplate?.toJSON())}>
            <Card>
            <Card.Header> Edit email template </Card.Header>
            <div>
                <div className="flex-container">
                <Form className="form">
                    <FormGroup className="mb-3" as={Row}>
                        <div className="globalValueContainer">
                            <div className="globalLabel"> Title:</div>
                            <FormControl
                                disabled={false}
                                type="text"
                                name="Title"
                                value={emailTemplate?.title ?? ""}
                                placeholder="Enter title"
                                onChange={(event) => {
                                    const value = event.currentTarget.value as string;
                                    updateTemplate((template) => {
                                        template.title = value.length > 0 ? value : undefined;
                                        return template;
                                    });
                                }} />
                        </div>
                    </FormGroup>
                    <FormGroup className="mb-3" as={Row}>
                        <div className="globalValueContainer">
                            <div className="globalLabel"> Background color:</div>
                            <FormControl
                                disabled={false}
                                type="text"
                                name="Background color"
                                value={emailTemplate?.backgroundColor ?? ""}
                                placeholder="Enter background color"
                                onChange={(event) => {
                                    const value = event.currentTarget.value as string;
                                    updateTemplate((template) => {
                                        template.backgroundColor = value.length > 0 ? value : undefined;
                                        return template;
                                    });
                                }} />
                        </div>
                    </FormGroup>
                    <br></br>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={6}>
                        <div className="globalLabel">Lines:</div>
                    </Col>
                    <Col sm={6}>
                        <Button onClick={() => {setIsShowingAddLine(true)}} style={{float: "right"}}>Add line</Button>
                    </Col>
                </FormGroup>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable"> 
                        {(droppableProvided) => (
                            <div className="listContainer" ref={droppableProvided.innerRef}>
                                {createEmailLines(emailTemplate)}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                </Droppable>
                </DragDropContext>
                </Form>
                {showEditLineModal(selectedIndex, selectedLineItem)}
                {showAddLineModal()}
                <div className="preview-container">
                    <div className="preview">
                        <ComposeEmail emailTemplate={emailTemplate ?? new EmailTemplate([])}></ComposeEmail>
                    </div>
                </div>
                </div>
            </div>
        </Card>
        </PageState>
    )

    function updateTemplate(callback: (template: EmailTemplate) => EmailTemplate) {
        if (_.isNil(emailTemplate)) { return }
        let updatedTemplate = _.cloneDeep(emailTemplate);
        updatedTemplate = callback(updatedTemplate);
        setEmailTemplate(updatedTemplate);
        setIsDirty(true);
    }

    async function submit(accountId: string, type: string, templateData: any) {
        const args: any = {
            action: "giftcard-template-create",
            account: accountId,
            type: type,
            data: templateData,
            template: "email"
        };
        setIsPublishing(true)
        const client = httpsCallable(getFunctions(), "clientApi")
        await client(args)
        setIsPublishing(false)
        setIsDirty(false)
    }

    function showEditLineModal(index: number | undefined, lineItem: EmailTemplateLine | undefined) {
        if ((_.isNil(index) || _.isNil(lineItem))) { return <div></div>}
        if (!isModalShowing) { return <div></div>}

        return showLineModal(lineItem, "Edit line item", (updatedLineItem) => {
            const updatedTemplate = _.cloneDeep(emailTemplate);
                if (!_.isNil(updatedTemplate)) {
                    updatedTemplate.lines[index] = updatedLineItem;
                    updateTemplate(() => updatedTemplate)
                }
                setIsShowingModal(false);
        }, () => setIsShowingModal(false))
    }

    function showAddLineModal() {
        if (!isShowingAddLine) { return <div></div>}
        const newLineItem = new EmailTemplateLine("")

        return showLineModal(newLineItem, "Add line item", (updatedLineItem) => {
            const updatedTemplate = _.cloneDeep(emailTemplate);
                if (!_.isNil(updatedTemplate)) {
                    updatedTemplate.lines.unshift(updatedLineItem)
                    updateTemplate(() => updatedTemplate)
                }
                setIsShowingAddLine(false);
        }, () => setIsShowingAddLine(false))
    }

    function showLineModal(lineItem: EmailTemplateLine, title: string, onOK: (lineItem: EmailTemplateLine) => void, onCancel: () => void) {
        return <LineEdit title={title} onOK={onOK} onCancel={onCancel} lineItem={lineItem}></LineEdit>
    }

    function createEmailLines(template: EmailTemplate | undefined): React.ReactNode[] {
        if (_.isNil(template)) { return [] }
        const lines = template.lines 
        let array: JSX.Element[] = []

        for (const [index, lineItem] of lines.entries()) {
            let component: JSX.Element = <LineItem lineItem={lineItem} onDelete={() => {
                    let updatedLineItems = _.cloneDeep(template.lines)
                    updatedLineItems.splice(index, 1)
                    const updatedTemplate = _.cloneDeep(template)
                    if (_.isNil(updateTemplate)) { return }
                    updatedTemplate.lines = updatedLineItems
                    updateTemplate(() => updatedTemplate)
            }}></LineItem>
            let wrapper = <DraggableWrapper key={index} index={index} onClick={() => { setupModalData(index, lineItem)  }} component={component} />

            array.push(wrapper)
        }

        return array
    }

    function onDragEnd(result: any) {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
            return
        }
    
        // no movement
        if (result.destination.index === result.source.index) {
            return
        }

        const updatedTemplate = _.cloneDeep(emailTemplate)

        if (_.isNil(updatedTemplate)) { return }
    
        let newOrdering = _.cloneDeep(updatedTemplate.lines)
        const [removed] = newOrdering.splice(result.source.index, 1)
        newOrdering.splice(result.destination.index, 0, removed)

        updatedTemplate.lines = newOrdering

        setEmailTemplate(updatedTemplate)
        setIsDirty(true)
    }

    function setupModalData(index: number, lineItem: EmailTemplateLine) {
        setSelectedIndex(index)
        setSelectedLineItem(lineItem)
        setIsShowingModal(true)
    }
}
function fetchTemplatePromise(accountId: string, type: string): Promise<HttpsCallableResult> {
    const args: any = {
        action: "giftcard-template-read",
        account: accountId,
        type: type,
        template: "email"
    };

    const client = httpsCallable(getFunctions(), "clientApi")
    return client(args);
}

export default withRoleRouterFunc(EmailTemplateEditor)