import { child, DatabaseReference, runTransaction, set } from "firebase/database"

export async function publish(json: any,
    identifierProperty: string,
    identifier: string,
    isNew: boolean,
    collectionRef: DatabaseReference,
    entityWithoutId?: boolean): Promise<void> {
    // const identifier = this.newIdentifier(this.props)
    if (identifier.length === 0) {
        return Promise.reject(new Error("Identifier may not be empty"))
    }
    if (identifier === "new") {
        return Promise.reject(new Error("Identifier may not be 'new'"))
    }
    for (const character of [".", " "]) {
        if (identifier.includes(character)) {
            return Promise.reject(new Error(`Identifier must not contain the character: '${character}'`))
        }
    }

    json[identifierProperty] = identifier

    // Some entities do not store their keys as ids.
    if (entityWithoutId || false) {
        delete json[identifierProperty]
    }

    const itemRef = child(collectionRef, identifier)

    if (isNew) {
        let success = true
        await runTransaction(itemRef, existing => {
            if (existing === null) {
                return json
            } else {
                success = false
                return existing
            }
        })
        if (!success) {
            return Promise.reject(new Error("Please choose a new identifier"))
        }
    } else {
        await set(itemRef, json)
    }
    return Promise.resolve()
}
