import * as _ from "lodash"
import * as React from "react"
import {
    Col,
    
    Form,
    FormControl,
    FormGroup,
} from "../../wrappers"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Row } from "react-bootstrap"

export class TASNotificationsModule extends React.Component<ModuleProps, {}> {

    // Component

    render() {
        const config = this.props.moduleData
        const addCustomerReminder: any = config["add_customer_reminder"] || {}
        config["add_customer_reminder"] = addCustomerReminder

        return (
            <Form>
                <FormGroup className="mb-3" as={Row}>
                    <Col sm={2}>Add Customer</Col>
                    <Col sm={10}>
                        {"Show a reminder about adding a customer to each sale."}
                        <br />
                        <br />
                        {"Title of the reminder"}
                        <FormControl
                            type="text"
                            name="title"
                            value={addCustomerReminder.title || ""}
                            placeholder="Please enter the title to display"
                            onChange={async (event) => {
                                const target = event.target
                                const value = (target as any).value
                                if (_.isNil(value) || value.length === 0) {
                                    delete addCustomerReminder.title
                                } else {
                                    addCustomerReminder.title = value
                                }
                                this.props.updateConfiguration(existing => {
                                    return config
                                })
                            }}
                        />
                        <br />
                        {"Body text of the reminder"}
                        <FormControl
                            type="text"
                            name="body"
                            value={addCustomerReminder.body || ""}
                            placeholder="Please enter the body text to display"
                            onChange={async (event) => {
                                const target = event.target
                                const value = (target as any).value
                                if (_.isNil(value) || value.length === 0) {
                                    delete addCustomerReminder.body
                                } else {
                                    addCustomerReminder.body = value
                                }

                                this.props.updateConfiguration(existing => {
                                    return config
                                })
                            }}
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
