import * as _ from "lodash"
import { currentDatabaseRef } from "../config/constants"
import { ProductGroup } from "../models/Product"
import { child, DataSnapshot, off, onValue } from "firebase/database"

export class ProductGroupObserver {

    // Properties

    accountId: string
    productGroupsArray?: ProductGroup[]
    productGroupsDict?: _.Dictionary<ProductGroup>
    productGroupsChangedCallback: () => void = () => { }

    // Lifecycle

    constructor(accountId: string) {
        this.accountId = accountId
        this.productGroupsArray = undefined
        this.productGroupsDict = undefined
    }

    // Public methods

    start() {
        this.observeProductGroups()
    }

    stop() {
        off(this.productGroupsRef())
    }

    // Private methods

    private productGroupsRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/inventory/product_groups`)
    }

    private observeProductGroups() {
        const tagsRef = this.productGroupsRef()
        if (!tagsRef) {
            return
        }
        onValue(tagsRef, (snapshot: DataSnapshot) => {
            const productGroupsDict = snapshot.val()
            if (!productGroupsDict) {
                // Set state to empty array in case we've just deleted the last product
                this.productGroupsArray = []
                this.productGroupsDict = {}

                if (this.productGroupsChangedCallback) {
                    this.productGroupsChangedCallback()
                }
                return
            }

            const keys = Object.keys(productGroupsDict)
            const values: ProductGroup[] = keys.map(v => {
                return new ProductGroup(productGroupsDict[v])
            })

            const sorted = values.sort((a: ProductGroup, b: ProductGroup) => {
                return a.group < b.group ? -1 : 1
            })

            this.productGroupsArray = sorted
            this.productGroupsDict = _.reduce(values, (aggregated, value: ProductGroup, key) => {
                aggregated[value.group] = value
                return aggregated
            }, {})

            if (this.productGroupsChangedCallback) {
                this.productGroupsChangedCallback()
            }
        })
    }
}