import React from "react";
import { Col, DescriptionCol, FormGroup, Row, ToggleButtonGroup } from "../../../../../../wrappers";
import { ToggleButton } from "react-bootstrap";
import { ValueEditProps } from "../../ComponentEdit";

export function AlginmentValueEdit(props: ValueEditProps) {
    return <FormGroup className="mb-3" as={Row}>
        <DescriptionCol sm={3}>Alignment</DescriptionCol>
        <Col sm={9}>
        <ToggleButtonGroup
                type="radio"
                name="Alignment"
                value={props.lineItem.alignment ?? ""}
                onChange={(value) => {
                    props.updateLineItem((lineItem) => {
                        lineItem.alignment = value as string;
                        return lineItem;
                    });
                }}
            >
                {alignmentValues.map((alignment) => {
                    return <ToggleButton id={`props-${alignment}`} key={alignment} value={alignment}>{alignment}</ToggleButton>;
                })}
            </ToggleButtonGroup>
        </Col>
    </FormGroup>;
}

const alignmentValues = ['center', 'justify', 'left', 'right']
