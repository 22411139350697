import * as React from "react"

import { Button, Card } from "../../wrappers"
import LoadingButton, { PageState } from "../../PageState"
import { currentDatabaseRef } from "../../../config/constants"
import { ToggleButton } from "../../ToggleButton"
import { RoleProps, withRole } from "../../../routes"
import { child, get, set } from "firebase/database"

interface ShowCameraScanConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    enabled: boolean
}

class ShowCameraScanConfiguration extends React.Component<RoleProps, ShowCameraScanConfigurationState> {

    constructor(props: RoleProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            enabled: false,
        }
    }

    // Helpers

    publish = async () => {
        this.setState({
            publishing: true
        })
        const account = this.props.role.account_id
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/show_camera_scan`)
        await set(configRef, this.state.enabled)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    toggleEnabled = () => {
        this.setState({
            dirty: true,
            enabled: !this.state.enabled
        })
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id

        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/show_camera_scan`)
        const configSnap = await get(configRef)

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                loaded: true,
                enabled: value
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Show Camera Scan in POS">
                <Card className="my-4" key="markdown" /* XXX TODO defaultExpanded={false} */>
                    <Card.Header>
                        Show Camera Scan Configuration
                    </Card.Header>
                    <Card.Body>
                        <p> Enable this in order to use the camera on your POS device for scanning. </p>
                        <div>
                            <ToggleButton active={this.state.enabled} performToggle={async () => { this.toggleEnabled() }} />
                            <br /><br />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                    <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
}

export default ShowCameraScanConfiguration