import * as React from "react"
import { FormattedTime } from "react-intl"
import { currentDatabaseRef, firestore } from "../../config/constants"
import { Label, Image, Card, Pager } from "../wrappers"
import { FormattedCurrency } from "../FormattedCurrency"
import { FormattedPercent } from "../FormattedPercent"
import { paymentTypeMetadata, paymentTypeNameFromId } from "../../helpers/PaymentTypeNames"
import { soldProductName } from "../../helpers/productName"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { saleSummaryType } from "./SalesList"
import * as _ from "lodash"
import { StripedTable } from "../StripedTable"
import { FormLabel, Pagination } from "react-bootstrap"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, limit, orderBy, query, Query, startAfter, where } from "firebase/firestore"
import { child, get } from "firebase/database"

export function renderExpenseDocumentation(expense: any) {
    const urls: string[] | undefined = expense?.image_urls
    if (urls) {
        return <span>{urls.map((url, index) => {
            return <a href={url} key={`id_${index}`}><Image src={url} thumbnail /></a>
        })}</span>
    } else {
        return null
    }
}

function renderShippingInfo(shipping: any) {
    const entries: string[] = []
    if (!_.isNil(shipping.method_title)) {
        const l10n = new L10nString(shipping.method_title)
        entries.push(l10n.localized(null))
    }
    if (!_.isNil(shipping.method_subtitle)) {
        const l10n = new L10nString(shipping.method_subtitle)
        entries.push(l10n.localized(null))
    }
    if (!_.isNil(shipping.customer_info?.customer_id)) {
        entries.push("Customer id: " + shipping.customer_info.customer_id)
    }
    if (!_.isNil(shipping.customer_info?.phone)) {
        entries.push("Phone: " + shipping.customer_info.phone)
    }
    if (!_.isNil(shipping.customer_info?.email)) {
        entries.push("Email: " + shipping.customer_info.email)
    }
    if (!_.isNil(shipping.address?.name)) {
        entries.push(shipping.address.name)
    }
    if (!_.isNil(shipping.address?.street)) {
        entries.push(shipping.address.street)
    }
    if (!_.isNil(shipping.address?.postal_code)) {
        entries.push(shipping.address.postal_code)
    }
    if (!_.isNil(shipping.address?.city)) {
        entries.push(shipping.address.city)
    }

    return entries.map((entry, index) => { return <div key={`key_${index}`}>{entry}</div> })
}

export function orderKindName(kind: string) {
    switch (kind) {
        case "ship_from_store":
            return "Ship from Store"
        case "click_and_collect":
            return "Click & Collect"
        case "reserve_and_collect":
            return "Reserve & Collect"
        case "ecom":
            return "Online"
        case "inventory_transfer":
            return "Inventory transfer"
    }
    return "-"
}

function baskets(basket: any): any[] {
    if (!_.isNil(basket.behavior)) {
        return [["Basket", basket.line_items ?? []]]
    }
    const lineItemMap: _.Dictionary<any[]> = {}
    const nameMap: _.Dictionary<string> = {}
    nameMap["..local"] = "Line items"
    for (const lineItem of basket.line_items ?? []) {
        let id = "..local"
        if (!_.isNil(lineItem.stock_location_id) && !_.isNil(lineItem.order_kind)) {
            id = `${lineItem.order_kind}_${lineItem.stock_location_id}`
            nameMap[id] = `${orderKindName(lineItem.order_kind)}: ${lineItem.location_name ?? lineItem.stock_location_id}`
        }
        const newArray = lineItemMap[id] ?? []
        newArray.push(lineItem)
        lineItemMap[id] = newArray
    }
    // If there is more than one 'order', name the local items 'Shop basket' instead of 'Line items'
    if (Object.keys(lineItemMap).length > 1) {
        nameMap["..local"] = "Shop basket"
    }
    const sorted: any[] = []
    for (const key of Object.keys(lineItemMap).sort()) {
        sorted.push([nameMap[key], lineItemMap[key]])
    }
    return sorted
}

export function renderLineItemBehaviorDetails(lineItem: any) {
    if (_.isNil(lineItem.behavior)) {
        return
    }

    const behavior = lineItem.behavior

    if (behavior.expense) {
        return renderExpenseDocumentation(behavior.expense)
    } else if (behavior.giftcard) {
        return <span>&nbsp; - Gift card issued code: <a href={`/sales?giftcard_code=${behavior.giftcard.code}`}><i>{behavior.giftcard.code}</i></a></span>
    } else if (behavior.giftcard_use) {
        return <span>&nbsp; - Gift card used code: <a href={`/sales?giftcard_code=${behavior.giftcard_use.code}`}><i>{behavior.giftcard_use.code}</i></a></span>
    } else if (behavior.voucher) {
        return <span>&nbsp; - Voucher issued code: <a href={`/sales?giftcard_code=${behavior.voucher.code}`}><i>{behavior.voucher.code}</i></a></span>
    } else if (behavior.voucher_use) {
        return <span>&nbsp; - Voucher used code: <a href={`/sales?giftcard_code=${behavior.voucher_use.code}`}><i>{behavior.voucher_use.code}</i></a></span>
    } else if (behavior.shipping) {
        return renderShippingInfo(behavior.shipping)
    } else if (behavior.customer_account_deposit) {
        return <i>&nbsp;Deposit to customer account: {behavior.customer_account_deposit.customer_id}</i>
    } else if (behavior.container_deposit) {
        return <i>&nbsp;Container deposit</i>
    } else if (behavior.employee_purchase) {
        return <i>&nbsp;Employee purchase</i>
    }
    return null
}

export function renderComment(lineItem: any) {
    const comment: string | undefined = lineItem.comment
    if (!_.isNil(comment)) {
        return <span><br />Comment: {comment}</span>
    } else {
        return
    }
}

interface SaleViewState {
    checkout?: any,
    snapshot?: DocumentSnapshot<DocumentData>
    paymentMethods: string[],
}

class SaleView extends React.Component<RoleRouterProps, SaleViewState> {
    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            checkout: undefined,
            snapshot: undefined,
            paymentMethods: []
        }
    }

    salesRef(props = this.props) {
        const account = props.role.account_id
        const checkoutsRef = collection(firestore, `accounts/${account}/checkouts`)

        return checkoutsRef
    }

    async getPaymentMethods(): Promise<string[]> {
        const snapshot = await get(this.paymentMethodsRef())
        if (snapshot.exists()) {
            return snapshot.val() as string[]
        }
        return []
    }

    paymentMethodsRef(props = this.props) {
        const account = props.role.account_id
        return child(currentDatabaseRef(),`v1/accounts/${account}/configuration/payment_types/available`)
    }

    async componentDidMount() {
        const key = this.props.router.params.saleKey
        const saleRef = doc(this.salesRef(), key)
        const snapshot = await getDoc(saleRef)
        const paymentMethods = await this.getPaymentMethods()
        this.setState({ checkout: snapshot.data(), paymentMethods: paymentMethods, snapshot: snapshot })
    }

    get resolvedShop() {
        return this.props.role.shop_id ?? this.props.router.params.shopKey
    }

    async loadNext() {
        if (_.isNil(this.state.snapshot)) {
            return
        }
        let q: Query<DocumentData> = this.salesRef()
        if (!_.isNil(this.resolvedShop)) {
            q = query(q, where("source.shop_id", "==", this.resolvedShop))
        }
        q = query(q, orderBy("timing.timestamp_date_string", "desc"), startAfter(this.state.snapshot), limit(1))
        const snapshot = await getDocs(q)
        if (snapshot.size === 1) {
            const docSnap = snapshot.docs[0]
            this.setState({ checkout: docSnap.data(), snapshot: docSnap })
        }
    }

    async loadPrevious() {
        if (_.isNil(this.state.snapshot)) {
            return
        }
        let q: Query<DocumentData> = this.salesRef()
        if (!_.isNil(this.resolvedShop)) {
            q = query(q, where("source.shop_id", "==", this.resolvedShop))
        }
        q = query(q, orderBy("timing.timestamp_date_string"), startAfter(this.state.snapshot), limit(1))
        const snapshot = await getDocs(q)
        if (snapshot.size === 1) {
            const docSnap = snapshot.docs[0]
            this.setState({ checkout: docSnap.data(), snapshot: docSnap })
        }
    }

    renderPager() {
        return <Pagination>
            <Pagination.Item disabled={false} onClick={async () => await this.loadPrevious()}>&larr; Newer</Pagination.Item>
            <Pagination.Item disabled={false} onClick={async () => { this.loadNext() }}>Older &rarr;</Pagination.Item>
        </Pagination>
    }

    renderCustomer(basket: any) {
        if (!_.isNil(basket.customer?.identifier)) {
            return <span>, customer id: <b>{basket.customer.identifier}</b></span>
        }
        return null
    }

    render() {
        if (_.isNil(this.state.checkout?.timing)) {
            return <div />
        }
        return (
            <section>
                {this.renderPager()}

                <Card className="my-4"><Card.Body>
                    {this.state.checkout?.voided
                        ?
                        <Label variant="danger">Checkout voided</Label>
                        :
                        <Label variant="success">Checkout completed</Label>
                    }
                    <br />
                    <br />
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Shop</th>
                                <th>Register</th>
                                <th>Cashier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <FormattedTime
                                        value={new Date(this.state.checkout?.timing.timestamp_string)}
                                        day="numeric"
                                        month="long"
                                        year="numeric"
                                        hour="numeric"
                                        minute="numeric"
                                    />
                                </td>
                                <td>{this.state.checkout?.source.shop_name}</td>
                                <td>{this.state.checkout?.source.register_name}</td>
                                <td>{this.state.checkout?.source.cashier_full_name ?? this.state.checkout?.source.cashier_display_name}</td>
                            </tr>
                        </tbody>
                    </StripedTable>
                </Card.Body></Card>

                {(this.state.checkout?.sales ?? []).map((sale: any) => {
                    return <Card className="my-4" key={sale.identifier}>
                        <Card.Header>
                            {saleSummaryType(sale.basket)} <b>{sale.identifier.split("-")?.[0]}</b>, sequence number: <b>{sale.sequence_number}</b> {this.renderCustomer(sale.basket)}
                        </Card.Header>
                        <Card.Body>
                            {
                                baskets(sale.basket).map((a, basketIndex) => {
                                    const [name, lineItems] = a
                                    return [
                                        <h4 key={`name_${basketIndex}`}>{name}</h4>,
                                        <StripedTable key={`items_${basketIndex}`}>
                                            <tbody>
                                                {
                                                    lineItems.map((x: any, lineItemIndex: number) => {
                                                        const rows: any = []
                                                        rows.push(
                                                            <tr key={`${basketIndex}_${lineItemIndex}`}>
                                                                <td>
                                                                    <FormLabel>{soldProductName(x, LanguageCode.da)}</FormLabel>
                                                                    {renderLineItemBehaviorDetails(x)}
                                                                    {renderComment(x)}
                                                                </td>
                                                                <td style={{ textAlign: "right", width: "1%", whiteSpace: "nowrap" }}>x{x.quantity}</td>
                                                                <td style={{ textAlign: "right", width: "150px", whiteSpace: "nowrap" }}>
                                                                    <FormattedCurrency value={x.retail_price} currency={this.state.checkout?.base_currency_code} />
                                                                </td>
                                                            </tr>
                                                        )
                                                        const discountRows = (x.discounts || []).map((discount: any, discountIndex: number) => {
                                                            return (
                                                                <tr key={`${basketIndex}_${lineItemIndex}_${discountIndex}`}>
                                                                    <td>
                                                                        <i>{new L10nString(discount.discount.name || "Discount").localized(LanguageCode.da)}</i>
                                                                    </td>
                                                                    <td />
                                                                    <td style={{ textAlign: "right" }}>
                                                                        <FormattedCurrency value={discount.amount * -1} currency={this.state.checkout?.base_currency_code} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        return rows.concat(discountRows)
                                                    })
                                                }
                                            </tbody>
                                        </StripedTable>
                                    ]
                                })
                            }

                            <h4>Totals</h4>
                            <StripedTable>
                                <tbody>
                                    <tr>
                                        <td>
                                            Total
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <FormattedCurrency value={sale.basket.total} currency={this.state.checkout?.base_currency_code} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Taxes
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <FormattedCurrency value={sale.basket.total_tax_amount} currency={this.state.checkout?.base_currency_code} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Total excl. taxes
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            <FormattedCurrency value={sale.basket.base_price} currency={this.state.checkout?.base_currency_code} />
                                        </td>
                                    </tr>

                                    {
                                        sale.basket.margin_total === sale.basket.base_price && [

                                            <tr key="a">
                                                <td>
                                                    Margin
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <FormattedCurrency value={sale.basket.margin} currency={this.state.checkout?.base_currency_code} />
                                                </td>
                                            </tr>,
                                            <tr key="b">
                                                <td>
                                                    Contribution ratio
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    <FormattedPercent value={sale.basket.margin / sale.basket.margin_total} />
                                                </td>
                                            </tr>
                                        ]
                                    }
                                </tbody>
                            </StripedTable>
                        </Card.Body>
                    </Card>
                })}

                {this.state.checkout?.payments &&

                    <Card className="my-4">
                        <Card.Header>
                            Payments
                        </Card.Header>
                        <Card.Body>
                            <StripedTable>
                                <tbody>

                                    {(this.state.checkout?.payments || []).map((x: any, i: number) => {
                                        if (x.payment_type.includes("rounding")) {
                                            return null
                                        }
                                        let lineProps: React.CSSProperties = {}
                                        if (x.success) {
                                            lineProps = {}
                                        } else {
                                            lineProps = { color: "#FF0000" }
                                        }
                                        return (
                                            <tr key={i.toString()}>
                                                <td style={lineProps}>
                                                    {paymentTypeNameFromId(x.payment_type, this.includeTitleDescriminator(x.payment_type)) + (x.success ? "" : " - Failed")}
                                                    {paymentTypeMetadata(x)}
                                                </td>
                                                <td style={{ ...{ textAlign: "right" }, ...lineProps }}>
                                                    {
                                                        !_.isNil(x.foreign_currency_amount) ?
                                                            <span>
                                                                (<FormattedCurrency value={x.foreign_currency_amount} currency={x.foreign_currency} />)
                                                                &nbsp;&nbsp;&nbsp;
                                                                <FormattedCurrency value={x.amount} currency={this.state.checkout?.base_currency_code} />
                                                            </span>
                                                            :
                                                            <FormattedCurrency value={x.amount} currency={this.state.checkout?.base_currency_code} />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </StripedTable>

                        </Card.Body>
                    </Card>
                }

                <br /><br /><br />


            </section>
        )
    }

    includeTitleDescriminator(id: string)  {
        let trimmedId = id.split(".")[0]
        
        let filtered = this.state.paymentMethods.filter((id: any) => {
            let trimmed = id.split(".")[0]
            return trimmed == trimmedId
        })

        return filtered.length > 1
    }
}

export default withRoleRouter(SaleView)
