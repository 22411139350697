import * as React from "react"
import { Card, FormControl} from "../../wrappers"
import { CashierSelectionMode } from "../../../models/CashierSelection"
import { currentDatabaseRef } from "../../../config/constants"
import { Role } from "../../../config/role"
import LoadingButton, { PageState } from "../../../components/PageState"
import { child, get, set } from "firebase/database"

interface CashierSelectionModeConfigurationProps {
    cashierSelectionMode: CashierSelectionMode 
    role: Role
    
}

interface CashierSelectionModeConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    cashier_selection_mode: CashierSelectionMode
}

export default class CashierSelectionModeConfiguration extends React.Component<CashierSelectionModeConfigurationProps, CashierSelectionModeConfigurationState> {
    constructor(props: CashierSelectionModeConfigurationProps) {
        super(props)

        this.state = {
            dirty: false, 
            loaded: false,
            publishing: false,
            showSuccess: false,
            cashier_selection_mode: this.props.cashierSelectionMode,
        }
    }
    async componentDidMount() {
        const account = this.props.role.account_id
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/cashier_selection`)
        const configSnap = await get(configRef)
        let cashierSelectionMode = CashierSelectionMode.manual
        if (configSnap.exists()) {
            cashierSelectionMode = configSnap.val()
        }
        this.setState({ cashier_selection_mode: cashierSelectionMode, loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="cashier selection mode">
                <Card className="my-4" key="upper-panel" border={this.state.showSuccess ? "success" : "default"}>
                    <Card.Header>
                        {this.state.showSuccess ? "Cashier selection mode published successfully" : "Cashier selection mode"}
                    </Card.Header>  
                    <Card.Body>
                        <p><b>Manual cashier selection</b> allows the users of the POS to manually switch between cashiers.</p>
                        <p><b>Re-select before each sale</b> forces the users of the POS to select a cashier before each sale.</p>
                        <FormControl
                            as="select"
                            name="cashier_selection_mode"
                            placeholder="Select mode"
                            value={this.state.cashier_selection_mode}
                            onChange={this.handleChange}
                        >
                            <option value={CashierSelectionMode.manual}>Manual cashier selection</option>
                            <option value={CashierSelectionMode.beforeEachSale}>Re-select before each sale</option>
                        </FormControl>
                    </Card.Body>
                    
                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
    handleChange = (event: any) => {
        const newValue = event.target.value
        this.setState({ cashier_selection_mode: newValue, dirty: true })
        this.publish
    }

    publish = async() => {
        const account = this.props.role.account_id         
        const configRef = child(currentDatabaseRef(), `v1/accounts/${account}/configuration/pos/cashier_selection/selection_point`)
        await set(configRef, this.state.cashier_selection_mode)
        this.setState({ showSuccess: true, publishing: false, dirty: false})
        
    }
}