export function stripEmptyValues(obj: any, leaveEmptyStrings?: boolean) {
    for (const k in obj) {
        const prop = obj[k]
        if (typeof prop === "object" && prop !== null) {
            stripEmptyValues(obj[k])
        } else {
            if (!leaveEmptyStrings && typeof prop === "string" && prop.length === 0) {
                obj[k] = null
            } else if (typeof prop === "undefined") {
                obj[k] = null
            }
        }
    }
}