import { child, get } from "firebase/database"
import { currentDatabaseRef } from "../config/constants"

export async function getExistingApiKey(account: string): Promise<string | undefined> {
    const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
    const importIntegrationKeysRef = child(child(accountRef, "api_keys"), "import_integrations")
    const integrationTypesSnap = await get(importIntegrationKeysRef)
    const integrationTypes = integrationTypesSnap.val()
    let existingAPIKey: string | undefined
    // 1: See if any integrations already exist
    if (integrationTypes) {
        for (const integrationTypeKey in integrationTypes) {
            const integrations = integrationTypes[integrationTypeKey]
            for (const integrationKey in integrations) {
                const integration = integrations[integrationKey]
                for (const apiKey in integration) {
                    existingAPIKey = apiKey
                    break
                }
                if (existingAPIKey) {
                    break
                }
            }
            if (existingAPIKey) {
                break
            }
        }
    }
    return existingAPIKey
}