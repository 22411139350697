import { L10nString } from "../helpers/L10n"

export class StockRemovalReasonCode {
    description: L10nString
    id: string

    constructor(json: any) {
        this.description = new L10nString(json.description)
        this.id = json.id
    }

    json(): any {
        return {
            description: this.description.json(),
            id: this.id
        }
    }
}