import { L10nString } from "../helpers/L10n"

export class RecommendationCategory {
    id: string
    name: L10nString
    rank: number
    constructor(json: any, rank: number) {
        this.id = json["id"]
        this.name = new L10nString(json["name"])
        this.rank = rank
    }

    json(): object {
        return {
            id: this.id,
            name: this.name.json()
        }
    }
}