import _ from 'lodash';
import React from 'react';
import { AlginmentValueEdit } from './ValueEditComponents/AlginmentValueEdit';
import { BarcodeValueEdit } from './ValueEditComponents/BarcodeValueEdit';
import { Form } from 'react-bootstrap';
import { SpacingValueEdit } from './ValueEditComponents/SpacingValueEdit';
import { TextValueEdit } from './ValueEditComponents/TextValueEdit';
import { ValueEditProps } from '../ComponentEdit';

export function WalletEdit(props: ValueEditProps) {
    return (
        <Form>
            <TextValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></TextValueEdit>
            <AlginmentValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></AlginmentValueEdit>
            <SpacingValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></SpacingValueEdit>
            <BarcodeValueEdit lineItem={props.lineItem} updateLineItem={props.updateLineItem}></BarcodeValueEdit>
        </Form>
    );
}