import { DataSnapshot, child, onValue, off } from "firebase/database"
import { currentDatabaseRef } from "../config/constants"
import * as _ from "lodash"

type StockDBEntry = number | _.Dictionary<number>

export class StockObserver {

    // Properties

    accountId: string
    stockLocationId: string
    stockDict: _.Dictionary<StockDBEntry> = {}
    reservedDict: _.Dictionary<StockDBEntry> = {}
    stockChangedCallback: () => void = () => {}

    // Lifecycle

    constructor(accountId: string, stockLocationId: string) {
        this.accountId = accountId
        this.stockLocationId = stockLocationId
        this.stockDict = {}
    }

    // Public methods
    
    start() {
        this.observeStock()
    }
    
    stop() {
        off(this.stockRef())
        off(this.reservedRef())
    }

    // Private methods

    private stockRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/stock_locations/${this.stockLocationId}/inventory/stock`)
    }

    private reservedRef() {
        return child(currentDatabaseRef(), `v1/accounts/${this.accountId}/stock_locations/${this.stockLocationId}/inventory/reserved`)
    }

    private observeStock() {
        const stockRef = this.stockRef()
        onValue(stockRef, (snapshot: DataSnapshot) => {
            if (!snapshot.exists()) { 
                this.stockDict = {}
            } else {
                this.stockDict = snapshot.val()
            }

            if (this.stockChangedCallback) {
                this.stockChangedCallback()
            }
        })

        const reservedRef = this.reservedRef()
        onValue(reservedRef, (snapshot: DataSnapshot) => {
            if (!snapshot.exists()) { 
                this.reservedDict = {}
            } else {
                this.reservedDict = snapshot.val()
            }

            if (this.stockChangedCallback) {
                this.stockChangedCallback()
            }
        })

    }
}
