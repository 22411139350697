import React from "react";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "../../../../../../wrappers";
import { Form } from "react-bootstrap";
import { ValueEditProps } from "../../ComponentEdit";

export function TextValueEdit(props: ValueEditProps) {
    return <FormGroup className="mb-3" as={Row}>
        <DescriptionCol sm={3}>
            <Form.Label>Text</Form.Label>
        </DescriptionCol>

        <Col sm={9}>
            <FormControl
                disabled={false}
                type="text"
                name="identifier"
                value={props.lineItem.text ?? ""}
                placeholder="Enter text"
                onChange={(event) => {
                    props.updateLineItem((lineItem) => {
                        lineItem.text = event.currentTarget.value as string;
                        return lineItem;
                    });
                }} />
        </Col>
    </FormGroup>;
}
