import * as React from "react"
import { firestore, currentDatabaseRef } from "../../config/constants"
import { Col, Button, Form, FormControl, FormGroup, Card, DescriptionCol } from "../wrappers"
import { PageState } from "../PageState"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { RoleRouterProps, withRoleRouter } from "../../routes"
import { Row } from "react-bootstrap"
import { collection, doc, setDoc } from "firebase/firestore"
import { child, get, update } from "firebase/database"

interface StockLocationEditState {
    market: string
    available_markets: string[]
    loaded: boolean
    publishing: boolean
    dirty: boolean
    name: string
    identifier: string
    identifierInvalid: boolean
    isNew: boolean
}

class StockLocationEdit extends React.Component<RoleRouterProps, StockLocationEditState> {
    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            name: "",
            identifier: "",
            market: "",
            available_markets: [],
            loaded: false,
            publishing: false,
            dirty: false,
            identifierInvalid: false,
            isNew: true
        }
    }

    stockLocationKey(props: RoleRouterProps) {
        return props.router.params.stockLocationKey
    }

    isNewLocation(props: RoleRouterProps) {
        return this.stockLocationKey(props) === "new"
    }

    async componentDidMount() {
        await this.load(this.props)
    }

    async UNSAFE_componentWillReceiveProps(nextProps: Readonly<RoleRouterProps>, nextContext: any) {
        await this.load(nextProps)
    }

    async load(props: RoleRouterProps) {
        const key = this.stockLocationKey(props)
        const account = props.role.account_id
        const isNewLocation = this.isNewLocation(props)

        this.setState({ loaded: false, isNew: isNewLocation, identifier: key === "new" ? "" : key, name: "", market: "", available_markets: [] })

        const promises: any[] = []
        const accountMarketsRef = child(currentDatabaseRef(), `v1/accounts/${account}/markets`)
        promises.push(get(accountMarketsRef))
        if (!isNewLocation) {
            const marketRef = child(currentDatabaseRef(), `v1/accounts/${account}/stock_locations/${key}/market`)
            promises.push(get(marketRef))
            const locationRef = child(currentDatabaseRef(), `v1/accounts/${account}/stock_location_index/${key}`)
            promises.push(get(locationRef))
        }

        const [accountMarketsSnap, locationMarketSnap, locationIndexSnap] = await Promise.all(promises)
        const accountMarketsDict = accountMarketsSnap.val()

        this.setState({ loaded: true })

        if (isNewLocation) {
            this.setState({
                available_markets: accountMarketsDict,
                market: Object.keys(accountMarketsDict)[0]
            })
        } else {
            const newState = {}

            if (accountMarketsDict) {
                newState["available_markets"] = accountMarketsDict
            }

            if (locationMarketSnap.exists()) {
                const market = locationMarketSnap.val()
                newState["market"] = market
            }
            if (locationIndexSnap.exists()) {
                const name = locationIndexSnap.val()?.["name"] ?? ""
                newState["name"] = name
            }

            this.setState(newState)
        }
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        const newState: any = {
            [name]: value,
            dirty: true
        }

        this.setState(newState)
    }

    handleUpdateResponse = (error: any) => {
        this.setState({ publishing: false, dirty: false })
        if (error) {
            alert("An error occurred while saving changes.")
        }
    }

    validate() {
        if (!this.state.name || !this.state.market || this.state.identifierInvalid || !this.state.identifier) {
            return false
        }

        return true
    }

    create = async () => {
        if (!this.validate()) {
            alert("A new stock location must have a name, an identifier and a market.")
            return
        }
        const accountKey = this.props.role.account_id
        const locationKey = this.state.identifier

        const newIndex = { name: this.state.name, type: "ecom" }

        const updates = {}
        updates[`v1/accounts/${accountKey}/stock_locations/${locationKey}/market`] = this.state.market
        updates[`v1/accounts/${accountKey}/stock_location_index/${locationKey}`] = newIndex
        await setDoc(doc(collection(firestore, `accounts/${accountKey}/stock_locations`), locationKey), newIndex)

        this.setState({ publishing: true })

        try {
            await update(currentDatabaseRef(), updates)
            this.handleUpdateResponse(null)
        } catch (error) {
            this.handleUpdateResponse(error)
        }

        const path = `/stock_location/${locationKey}/edit`
        this.props.router.navigate(path)
    }

    publish = async () => {
        if (!this.validate()) {
            alert("A stock location must have a name, an identifier and a market.")
            return
        }

        const accountKey = this.props.role.account_id
        const locationKey = this.stockLocationKey(this.props)

        const newIndex = { name: this.state.name, type: "ecom" }

        const updates = {}
        updates[`v1/accounts/${accountKey}/stock_locations/${locationKey}/market`] = this.state.market
        updates[`v1/accounts/${accountKey}/stock_location_index/${locationKey}`] = newIndex

        await setDoc(doc(collection(firestore, `accounts/${accountKey}/stock_locations`), locationKey), newIndex)

        this.setState({ publishing: true })

        try {
            await update(currentDatabaseRef(), updates)
            this.handleUpdateResponse(null)
        } catch (error) {
            this.handleUpdateResponse(error)
        }
    }

    handleIdChange(identifier: string, valid: boolean) {
        if (identifier !== this.state.identifier) {
            this.setState({ dirty: true, identifierInvalid: !valid, identifier: identifier })
        }
    }

    stockLocationsRef(props: RoleRouterProps) {
        return child(child(currentDatabaseRef(), `v1/accounts/${props.role.account_id}`), "stock_location_index")
    }

    render() {
        const publishDisabled = !this.state.dirty
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} dirty={this.state.dirty} typeName="stock location">
                <Card className="my-4" key="beefdead">
                    <Card.Header>
                        {this.isNewLocation(this.props) ?
                            <Button onClick={this.create} disabled={publishDisabled}>Create</Button>
                            :
                            <Button onClick={this.publish} disabled={publishDisabled}>Publish</Button>
                        }
                    </Card.Header>
                </Card>
                <Card className="my-4">
                    <Card.Header>General</Card.Header>
                    <Card.Body>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                            <DescriptionCol sm={2}>Name</DescriptionCol>
                            <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        placeholder="Enter location name"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.stockLocationsRef(this.props)}
                                isNew={this.state.isNew}
                                typeName="stock location"
                                identifierSource={this.state.name}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id, valid) }}
                                invalidIdentifiers={["new"]}
                                showExistingIdentifier={true}
                            />
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Market</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        as="select"
                                        name="market"
                                        placeholder="Select market"
                                        value={this.state.market}
                                        onChange={this.handleInputChange}
                                    >
                                        {
                                            Object.keys(this.state.available_markets).map((key, index) => {
                                                return <option key={index} value={key}>{this.state.available_markets[key].name}</option>
                                            })
                                        }
                                    </FormControl>
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                </Card>

                <Card className="my-4" key="deadbeef">
                    <Card.Header>
                        {this.isNewLocation(this.props) ?
                            <Button onClick={this.create} disabled={publishDisabled}>Create</Button>
                            :
                            <Button onClick={this.publish} disabled={publishDisabled}>Publish</Button>
                        }
                    </Card.Header>
                </Card>
            </PageState>
        )
    }
}

export default withRoleRouter(StockLocationEdit)
