import React, { useEffect, useState } from "react";
import { Button, Modal } from "../../wrappers";
import { GiftcardConfig } from "./GiftcardTypeList";
import * as _ from "lodash";

interface GiftcardImageModalProps {
    giftcardConfig: GiftcardConfig;
    imageURL: string;
    onOk: () => void;
}
export function GiftcardImageModal(props: GiftcardImageModalProps) {
    const [imageURL, setImageUrl] = useState<string>();

    useEffect(() => {
        (async () => {
            const imageURL = await getImage(props.giftcardConfig, props.imageURL);
            setImageUrl(imageURL);
        })();

    }, [props.imageURL]);

    return (
        <Modal show={true} centered>
            <Modal.Body style={{ padding: 10 }}>
                {!_.isNil(imageURL) ? <img src={imageURL} style={{ width: '100%', height: 'auto' }}></img> : <div style={{ padding: 20 }}> Loading... </div>}
            </Modal.Body>
            <Modal.Footer><Button onClick={props.onOk}>OK</Button></Modal.Footer>
        </Modal>
    );
}

async function getImage(giftcardConfig: GiftcardConfig, name: string): Promise<string> {
    const assetPath = `/api/v1/asset/image/${name}`;
    const url = giftcardConfig.baseURL + assetPath;

    const headers = new Headers();

    headers.append("token", giftcardConfig.token);
    headers.append("uid", giftcardConfig.uid);

    const requestInit: RequestInit = {
        method: "GET",
        headers: headers,
    };

    const request = new Request(url, requestInit);
    const response = await fetch(request);
    const blob = await response.blob();

    return URL.createObjectURL(blob);
}
