import * as React from "react"
import { Button, Col, Form, FormGroup, Modal, Row, Table } from "../wrappers"
import * as _ from "lodash"
import { Role } from "../../config/role"
import { ProductAsset } from "../../models/ProductAsset"
import { DocumentAssetEditComponent } from "../DocumentAssetEditComponent"
import { LanguageCode, L10nString } from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { L10nFormControl } from "../L10nFormControl"
import { StripedTable } from "../StripedTable"

interface ProductDocumentAssetsEditProps {
    assets: () => ProductAsset[]
    role: Role
    productId: string,
    setLanguage: (language: LanguageCode | null) => void,
    currentLanguage: () => LanguageCode | null,
    resolveLanguages: () => LanguageCode[]
    closeDialog: () => any
    updateAssets: (assets: ProductAsset[]) => void
}

export class ProductDocumentAssetsEdit extends React.Component<ProductDocumentAssetsEditProps, any> {

    closeDialog = () => {
        this.props.closeDialog()
    }

    addDocumentAsset(asset: ProductAsset) {
        const assets = _.cloneDeep(this.props.assets())
        assets.push(asset)

        this.props.updateAssets(assets)
    }

    removeDocumentAsset(index: number) {
        const assets = _.cloneDeep(this.props.assets())
        assets.splice(index, 1)
        this.props.updateAssets(assets)
    }

    updateDocumentAsset(name: L10nString, index: number) {
        const assets = _.cloneDeep(this.props.assets())
        const asset = assets[index]
        asset.name = name

        assets[index] = asset
        this.props.updateAssets(assets)
    }

    // React component

    render() {
        return (
            <Modal size="lg" show={true}>
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit document assets</span>
                        <span style={{ float: "right" }}>
                            <LanguagePicker
                                typeName="variant"
                                resolveLanguages={() => { return this.props.resolveLanguages() }}
                                onChange={this.props.setLanguage}
                                initialLanguage={this.props.currentLanguage()}
                                hideRemove={true}
                            />
                        </span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={(e: any) => e.preventDefault()}>
                        <FormGroup className="mb-3" as={Row}>
                            <Col sm={12}>
                                <StripedTable>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Document</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.assets().filter((asset) => { return asset.isDocumentAsset() }).map((asset: ProductAsset, index: any) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ "verticalAlign": "middle" }}>
                                                            <L10nFormControl
                                                                l10n={asset.name || null}
                                                                type="text"
                                                                language={this.props.currentLanguage()}
                                                                style={{ resize: "vertical" }}
                                                                onLocalizationChanged={(l10n: any) => {
                                                                    this.updateDocumentAsset(l10n, index)
                                                                }}
                                                            />
                                                        </td>
                                                        <td style={{ "verticalAlign": "middle", textAlign: "center" }}>
                                                            <img key="img" src={asset.url} alt="" height="100" onClick={(event) => { event.preventDefault(); window.open(asset.url) }} />
                                                        </td>
                                                        <td style={{ "verticalAlign": "middle", textAlign: "center", "width": "1%" }}>
                                                            <Button
                                                                variant="danger"
                                                                key={index}
                                                                onClick={(event: any) => {
                                                                    this.removeDocumentAsset(index)
                                                                    event.stopPropagation()
                                                                }}
                                                            >
                                                                X
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </StripedTable>
                            </Col>
                        </FormGroup>
                    </Form>

                    <DocumentAssetEditComponent
                        productId={this.props.productId}
                        role={this.props.role}
                        setLanguage={this.props.setLanguage}
                        currentLanguage={this.props.currentLanguage}
                        resolveLanguages={this.props.resolveLanguages}
                        addAsset={(asset) => { this.addDocumentAsset(asset) }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeDialog}>Done</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}
