export class Role {
    constructor(account_id: string, shop_id?: string, rtdb_instance?: string, region?: string) {
        this.account_id = account_id
        this.shop_id = shop_id
        this.region = region
        this.rtdb_instance = rtdb_instance
    }

    account_id: string
    shop_id?: string
    region?: string
    rtdb_instance?: string

    isShopOwner(): boolean {
        return this.shop_id !== undefined && this.shop_id !== null
    }

    isAccountOwner(): boolean {
        return this.shop_id === undefined || this.shop_id === null
    }

    json(): any {
        return { account_id: this.account_id, shop_id: this.shop_id, rtdb_instance: this.rtdb_instance, region: this.region }
    }
}

export class RoleAccessRequirement {
    account_access: boolean
    shop_access: boolean
    constructor(account: boolean, shop: boolean) {
        this.account_access = account
        this.shop_access = shop
    }

    isSatisfied(role: Role) {
        if (this.account_access === false && this.shop_access === false) {
            return true
        }
        if (this.account_access === true && role.isAccountOwner()) {
            return true
        }
        if (this.shop_access === true && (role.isAccountOwner() || role.isShopOwner())) {
            return true
        }
        return false
    }
}

export function AccountOwnerRequirement(): RoleAccessRequirement {
    return new RoleAccessRequirement(true, false)
}

export function ShopOwnerRequirement(): RoleAccessRequirement {
    return new RoleAccessRequirement(false, true)
}

export function NoRoleRequirement(): RoleAccessRequirement {
    return new RoleAccessRequirement(false, false)
}