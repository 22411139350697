import * as SharedModels from "../ProductOrganizationModels"
import { LanguageCode } from "../../../../helpers/L10n"
import { TreeItem } from "@nosferatu500/react-sortable-tree"
import * as _ from "lodash"

export interface FilterTreeItem extends TreeItem {
    filter: SharedModels.Filter
}

export class Folder {
    children?: Folder[]
    expanded?: boolean
    filter: SharedModels.Filter

    constructor(filter: SharedModels.Filter, expanded?: boolean, children?: Folder[]) {
        this.children = children
        this.expanded = expanded
        this.filter = filter
    }

    static fromTreeItem(treeItem: FilterTreeItem): Folder {
        let children: Folder[] | undefined = undefined
        if (treeItem.children && treeItem.children.length > 0 && _.isArray(treeItem.children)) {
            children = treeItem.children.map((c: any) => { return Folder.fromTreeItem(c) })
        }
        return new Folder(treeItem.filter, treeItem.expanded, children)
    }

    static fromJson(json: SharedModels.FolderJson): Folder {
        let children: Folder[] | undefined = undefined
        if (json.children) {
            children = json.children.map((c: any) => { return Folder.fromJson(c) })
        }
        return new Folder(SharedModels.Filter.fromJson(json.filter), true, children)
    }

    treeData(filters: SharedModels.Filters): FilterTreeItem {
        let name = ""
        switch (this.filter.type) {
            case SharedModels.FilterType.tag: {
                const tag = filters.tag[this.filter.id]
                if (tag) {
                    name = tag.name.localized(LanguageCode.da)
                } else {
                    name = this.filter.id
                }
                break
            }
            default:
                break
        }
        const children = (this.children || []).map(c => { return c.treeData(filters) })
        const expanded = this.expanded === undefined ? true : this.expanded
        return {
            children: children,
            expanded: expanded,
            filter: this.filter,
            title: name
        }
    }

    toJson() {
        const children = this.children ? this.children.map(folder => folder.toJson()) : undefined
        const result = {
            filter: this.filter.toJson()
        }
        if (children) {
            result["children"] = children
        }
        return result
    }
}