import * as React from "react"
import { currentDatabaseRef } from "../../../config/constants"
import { Button, Col, Form, FormGroup, Card, Alert, Row, FormControlStatic } from "../../wrappers"
import { PageState } from "../../PageState"
import { StockRemovalReasonCode } from "../../../models/StockRemovalReasonCode"
import { L10nFormControl } from "../../L10nFormControl"
import { LanguageCode, L10nString } from "../../../helpers/L10n"
import { Role } from "../../../config/role"
import { LanguagePicker } from "../../LanguagePicker"
import { cloneDeep } from "lodash"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { publish } from "../../../helpers/ModelPublisher"
import { RoleRouterProps, withRoleRouter } from "../../../routes"
import { child, get, off } from "firebase/database"

interface StockRemovalReasonCodeEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
}

interface StockRemovalReasonCodeEditState {
    code: StockRemovalReasonCode
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
}

class StockRemovalReasonCodeEdit extends React.Component<StockRemovalReasonCodeEditProps, StockRemovalReasonCodeEditState> {
    constructor(props: StockRemovalReasonCodeEditProps) {
        super(props)
        this.state = {
            code: new StockRemovalReasonCode({
                id: "",
                description: ""
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null
        }
    }

    pop() {
        const path = `/configuration`
        this.props.router.navigate(path)
    }

    codeKey() {
        return this.props.router.params.codeKey
    }

    isNewStockRemovalReasonCode() {
        return this.codeKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.code.description.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    reasonCodesRef() {
        return child(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}`), "app_data/stock/stock_removal_reason_codes")
    }

    async publish() {
        const json = this.state.code.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "id", this.state.identifier, this.isNewStockRemovalReasonCode(), this.reasonCodesRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    componentWillUnmount() {
        const key = this.codeKey()
        off(child(currentDatabaseRef(), `v1/accounts/${this.props.role.account_id}/app_data/stock/stock_removal_reason_codes/${key}`))
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewStockRemovalReasonCode()) {
            const snapshot = await get(child(this.reasonCodesRef(), this.codeKey()))
            const code = new StockRemovalReasonCode(snapshot.val())
            this.setState({ code: code, identifier: code.id, loaded: true })
        } else {
            this.setState({ loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.code.description.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const code = cloneDeep(this.state.code)
        code.description.removeLocalization(language)
        this.setState({ code: code, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const code = cloneDeep(this.state.code)
        code.description = l10n || new L10nString("")
        this.setState({ code: code, dirty: true })
    }

    handleIdChange(identifier: string) {
        this.setState({ dirty: true, error: null, identifier: identifier })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="stock removal reason code"
            >
                <Form>

                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="reason code"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewStockRemovalReasonCode() ? "Create new reason code" : `Edit reason code '${this.state.code.description.localized(this.state.currentLanguage)}'`}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <FormGroup className="mb-3" as={Row}>
                                    <Row>
                                        <Col sm={2}>Name</Col>
                                        <Col sm={10}>
                                            <L10nFormControl
                                                l10n={this.state.code.description}
                                                placeholder="Enter localized name"
                                                language={this.state.currentLanguage}
                                                onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        !this.isNewStockRemovalReasonCode()
                                            ? (
                                                <Row>
                                                    <Col sm={2}>Identifier</Col>
                                                    <Col sm={10}>
                                                        <FormControlStatic>{this.state.code.id}</FormControlStatic>
                                                    </Col>
                                                </Row>
                                            ) : null
                                    }
                                </FormGroup>
                            </span>
                            <ValidatingIdEntryControl
                                collectionRef={this.reasonCodesRef()}
                                isNew={this.isNewStockRemovalReasonCode()}
                                typeName="reason code"
                                identifierSource={this.state.code.description.localized(this.state.currentLanguage)}
                                existingIdentifier={this.state.identifier}
                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                            />
                        </Card.Body>
                        <Card.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Card.Footer>
                    </Card>
                </Form>
                {this.state.error ? (
                    <Alert variant="danger">
                        <strong>Error publishing reason code:</strong> {this.state.error}
                    </Alert>
                ) : []}
            </PageState>
        )
    }
}

export default withRoleRouter(StockRemovalReasonCodeEdit)
