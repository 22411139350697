import * as SharedModels from "../ProductOrganizationModels"
import { L10nString } from "../../../../helpers/L10n"

export class Tab {
    filter: SharedModels.Filter
    name: L10nString
    order: number

    constructor(name: L10nString, order: number, filter: SharedModels.Filter) {
        this.filter = filter
        this.name = name
        this.order = order
    }

    toJson(): any {
        return {
            filter: this.filter.toJson()
        }
    }
}