import * as _ from "lodash"
import * as React from "react"
import {
    FormControl
} from "../../wrappers"
import { Form } from "react-bootstrap"
import { ModuleProps } from "../Configuration/ModuleConfiguration"

export class GiftReceiptsModule extends React.Component<ModuleProps, {}> {

    render() {
        const config = this.props.moduleData
        const header: any = config["header"] ?? ""
        const footer: any = config["footer"] ?? ""
        const exchangeLimitMonths: number = config["exchange_limit_months"] ?? ""
        const useLabelPrinter: boolean = config["use_label_printer"] ?? false
        const useSingleItemReceipts: boolean = config["use_single_item_receipts"] ?? false
        if (!this.props.moduleData.enabled) {
            return null
        }

        return (
            <Form>
                <Form.Group className="mb-3">

                    <Form.Label>Gift receipt header</Form.Label>
                    <FormControl
                        type="text"
                        name="header"
                        value={header}
                        placeholder="Please enter the header for the gift receipt"
                        onChange={async (event) => {
                            const target = event.target
                            const value = (target as any).value
                            if (_.isNil(value)) {
                                delete this.props.moduleData?.header
                            } else {
                                this.props.moduleData.header = value
                            }
                            this.props.updateConfiguration(existing => {
                                return config
                            })
                        }}
                    />
                    <Form.Text>Header for the gift receipt. If you include the text <b>&#123;DATE&#125;</b>, it will be replaced with the limit for exchanging. </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Gift receipt footer</Form.Label>
                    <FormControl
                        type="text"
                        name="footer"
                        value={footer}
                        placeholder="Please enter the footer for the gift receipt"
                        onChange={async (event) => {
                            const target = event.target
                            const value = (target as any).value
                            if (_.isNil(value)) {
                                delete this.props.moduleData?.footer
                            } else {
                                this.props.moduleData.footer = value
                            }
                            this.props.updateConfiguration(existing => {
                                return config
                            })
                        }}
                    />
                    <Form.Text>Footer for the gift receipt. If you include the text <b>&#123;DATE&#125;</b>, it will be replaced with the limit for exchanging. </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Exchange limit</Form.Label>
                    <FormControl
                        type="number"
                        name="exchange_limit_months"
                        value={exchangeLimitMonths}
                        placeholder="Please the exchange limit in months"
                        onChange={async (event) => {
                            const target = event.target
                            const value = (target as any).value
                            if (_.isNil(value)) {
                                delete this.props.moduleData?.exchange_limit_months
                            } else {
                                this.props.moduleData.exchange_limit_months = Number(value)
                            }
                            this.props.updateConfiguration(existing => {
                                return config
                            })
                        }}
                    />
                    <Form.Text>How many months is the exchanging of the gift valid? (only used if you add a <b>&#123;DATE&#125;</b> to header or footer)</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Gift receipt output</Form.Label>
                    <Form.Check // prettier-ignore
                        type="radio"
                        id={`receipt_printer`}
                        name="gift_receipt_output"
                        label={`Receipt printer`}
                        checked={!useLabelPrinter}
                        onChange={() => {
                            this.props.updateConfiguration(data => {
                                // For now we only support single item
                                // receipts for label printers, so we remove
                                // the single item receipt configuration when
                                // switching to receipt printers
                                delete data.use_label_printer
                                delete data.use_single_item_receipts
                            })
                        }}
                    />
                    <Form.Check // prettier-ignore
                        type="radio"
                        id={`label_printer`}
                        name="gift_receipt_output"
                        label={`Label printer`}
                        checked={useLabelPrinter}
                        onChange={() => {
                            this.props.updateConfiguration(data => {
                                data.use_label_printer = true
                            })
                        }}
                    />
                    <Form.Text>Would you like to use your receipt printer for gift receipts, or do you have dedicated label printers for gift receipt labels?</Form.Text>
                </Form.Group>

                {useLabelPrinter && <Form.Group className="mb-3">
                    <Form.Label>Label type</Form.Label>
                    <Form.Check // prettier-ignore
                        type="radio"
                        id={`per_sale`}
                        name="gift_receipt_label_type"
                        label={`One label per sale`}
                        checked={!useSingleItemReceipts}
                        onChange={() => {
                            this.props.updateConfiguration(data => {
                                delete data.use_single_item_receipts
                            })
                        }}
                    />
                    <Form.Check // prettier-ignore
                        type="radio"
                        id={`per_item`}
                        name="gift_receipt_label_type"
                        label={`One label per item`}
                        checked={useSingleItemReceipts}
                        onChange={() => {
                            this.props.updateConfiguration(data => {
                                data.use_single_item_receipts = true
                            })
                        }}
                    />
                    <Form.Text>Would you like to create a gift receipt representing an entire sale or a single item?</Form.Text>

                </Form.Group>
                }
            </Form>
        )
    }
}
