import * as React from "react"
import { PageHeader } from "../wrappers"

interface AccessDeniedProps { }

class AccessDenied extends React.Component<AccessDeniedProps> {
    render() {
        return (
            <section>
                <PageHeader>
                    You do not have access to this page
                </PageHeader>
            </section>
        )
    }
}

export default AccessDenied