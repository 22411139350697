import { Tag } from "../../../models/Product"

export enum FilterType {
    tag = "tag"
}

export class Filter {
    type: FilterType
    id: string

    constructor(type: FilterType, id: string) {
        this.type = type
        this.id = id
    }

    static fromJson(json: any): Filter {
        const type = Object.keys(json)[0]
        switch (FilterType[type]) {
            case FilterType.tag:
                return new Filter(FilterType.tag, json.tag)
            default:
                throw new Error(`Unsupported filter type: ${type}`)
        }
    }

    toJson(): any {
        const result: any = {}
        let value: any = undefined
        switch (this.type) {
            case FilterType.tag:
                value = this.id
                break

            default:
                break
        }
        if (!value) {
            throw new Error(`Unsupported filter type: ${this.type}`)
        }
        result[this.type] = value
        return result
    }
}

export interface FolderJson {
    filter: Filter
    children?: FolderJson[]
}

export class Filters {
    tag: _.Dictionary<Tag>

    constructor(tags: _.Dictionary<Tag>) {
        this.tag = tags
    }
}